import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store/index'
import Vuex from 'vuex'
import router from './router'
import userConfig from './store/userConfig'
import account from './store/account'
import axios from 'axios'
import { apolloClient } from './vue-apollo'
import CountryFlag from 'vue-country-flag'
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import VueObserveVisibility from 'vue-observe-visibility'
import { Plugin } from 'vue-fragment'
import { draggable, droppable } from '../src/directives/sortable'
import { moveable } from './directives/moveable'
import VueApollo from 'vue-apollo'
import '@aws-amplify/ui-vue'
import '@/plugins/apexcharts'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

Amplify.configure(awsExports)
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueObserveVisibility)
Vue.use(VueApollo)
Vue.component('country-flag', CountryFlag)
Vue.directive('draggable', draggable)
Vue.directive('droppable', droppable)
Vue.directive('moveable', moveable)
Vue.use(Plugin)

store.modules = { ...store.modules, userConfig, account }
const s = new Vuex.Store(store)

axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT_API

axios.interceptors.request.use(async config => {
  // get token from local storage, if expired, updateAuthToken will refresh the token
  await s.dispatch('account/updateAuthToken')
  const token = window.localStorage.getItem('auth_token')
  config.headers.Authorization = `Bearer ${token}`

  if (account.state.isTrial && (process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'int' || process.env.VUE_APP_ENV === 'uat' || process.env.VUE_APP_ENV === 'prodc')) {
    delete config.headers.Authorization
  }

  return config
}, err => Promise.reject(err))

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})

const dataDogConfig = {
  applicationId: '9e7b82c6-14ca-401c-bf6a-7de731459c12',
  clientToken: 'pub1ef7104ca84bb3c04543b6ad4db5fe62',
  site: 'datadoghq.com',
  service: 'Dynamicodds-ui',
  env: process.env.VUE_APP_ENV || 'development',
  version: '2.0.0',
  sampleRate: 100,
  trackInteractions: true
  // defaultPrivacyLevel: 'mask-user-input'
}

const features = process.env.VUE_APP_FEATURE_FLAGS.split(',')
if (features.some((x) => x.trim() === 'datadog')) {
  datadogRum.init(dataDogConfig)
}

datadogLogs.init({
  ...dataDogConfig,
  forwardErrorsToLogs: true
})

const dfApp = new Vue({
  store: s,
  router,
  vuetify,
  apolloProvider,
  render: h => h(App)
}).$mount('#app')

window.dfApp = dfApp
