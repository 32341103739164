<template>
  <div class="long-form runner-table hello" :style="`max-height: ${longFormHeight}px;`">
    <div v-for="history in mutateDataForm(runnerHistoryFilter, runner.runner?.id)" :key="history.track + '-' + history.raceNumber + '-' + history.date">
      <v-simple-table :class="history.raceName === 'Barrier Trial' || history.raceName === 'BTrial' ? 'barrier-trial longFormTable my-3' : 'longFormTable my-3'">
        <tbody>
          <tr>
            <td>
              <div v-if="!isEmpty(history.failedToFinishReason)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="red--text">{{ failedToFinishReason({reason: history.failedToFinishReason}).abbeviation }}</span>
                  </template>
                  <span>{{ failedToFinishReason({reason: history.failedToFinishReason}).full }}</span>
                </v-tooltip>
              </div>
              <div v-else>
                <v-icon v-if="history.finishingPosition === 1" style="margin-left: -5px; margin-right: 2px;" color="#ffd700" small>mdi-trophy</v-icon>
                <span v-if="history.finishingPosition === 1" style="font-weight:bold">{{history.finishingPosition}}</span><span v-else>{{history.finishingPosition}}</span> / {{history.starters}}
              </div>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ getReadableDate({ utcDate: history.date }) }}</span>
                </template>
                <span>{{ `${getReadableDate({ utcDate: history.date })}` }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="long-form__active-hover" @click="onOpenNewRace({ history })">Venue: {{ historyTextDisplay({ history, key: 'track' }) }}</span>
                </template>
                <span>Venue: {{ historyTextDisplay({ history, key: 'track' }) }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">Race Number: {{history.raceNumber}}</span>
                </template>
                <span>Race Number: {{history.raceNumber}}</span>
              </v-tooltip>
            </td>
            <td>
              Total Prize: <span :class="numeral({ number: history.prizeTotal, decimalPlaces: 1 }) && 'color-Good3'">${{ numeral(history.prizeTotal).format('0a') }}</span>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" :class="history.raceName === 'Barrier Trial' || history.raceName === 'BTrial' ? 'barrier-trialname' : ''">Class: {{ returnCorrectClass({ history })}}</span>
                </template>
                <span>{{ `Class: ${history.class} - ${history.raceName}` }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">Distance: {{history.distance }}m</span>
                </template>
                <span>Distance: {{history.distance }}m</span>
              </v-tooltip>
            </td>
            <td>
              Condition:
              <b :class="['color-' + history.trackCondition + history.trackRating]">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{  history.trackCondition }} ({{ history.trackRating }})</span>
                  </template>
                  <span>Condition {{  history.trackCondition }} ({{ history.trackRating }})</span>
                </v-tooltip>
              </b>
            </td>
            <td>
              Limit Weight: {{ history.limitWeight }}kg
            </td>
          </tr>
          <tr>
            <td>{{ marginReCalc({ history }) }}L</td>
            <td>Jockey: {{ history.jockey }} ({{ history.barrier }})</td>
            <td>Weight: {{ history.weight }}kg</td>
            <td>Race Time: {{ formatTimeShortAndLongForm(history.elapsedTime) }}</td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on"> {{ historyTextDisplay({ history, key: 'raceSectionals' }) }}</span>
                </template>
                <span> Sectional Time: {{ historyTextDisplay({ history, key: 'raceSectionals' }) }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">In Run: {{ uiTruncateTxt({ txt: formatInRun({ inRun: history.inRun }), max: 22 }) }}</span>
                </template>
                <span>In Run: {{ formatInRun({ inRun: history.inRun }) }}</span>
              </v-tooltip>
            </td>
            <td>Opening Price: ${{ numeral(history.openingPrice).format('0.00') }}</td>
            <td>Mid Price: ${{ numeral(history.midPrice).format('0.00') }}</td>
            <td>Start Price: ${{ numeral(history.startingPrice).format('0.00') }}</td>
          </tr>
          <tr>
            <td colspan="1" class="text-left">{{ history.ratingUnadjusted }}</td>
            <td colspan="3">
              <span>Placings:{{ ' ' }}</span>
              <span v-for="placement in runnersPlacementToString({ selectedRunner: runner, history })" :key="placement.worded">
                <span @click="onOpenHorseSearchTab({ placement })" class="long-form__active-hover cursor" v-if="placement.placement === 1 && placement.name"><span class="placment-gold ml-1">1st</span> - {{ placement.worded }}</span>
                <span @click="onOpenHorseSearchTab({ placement })" class="long-form__active-hover cursor" v-if="placement.placement === 2 && placement.name"><span class="placment-silver ml-1">2nd</span> - {{ placement.worded }}</span>
                <span @click="onOpenHorseSearchTab({ placement })" class="long-form__active-hover cursor" v-if="placement.placement === 3 && placement.name"><span class="placment-bronze ml-1">3rd</span> - {{ placement.worded }}</span>
                <span @click="onOpenHorseSearchTab({ placement })" class="long-form__active-hover cursor" v-if="placement.placement === 4 && placement.name"><span class="placment-fourth ml-1">4th</span> - {{ placement.worded }}</span>
              </span>
            </td>
            <td colspan="3">
              Prize Money Won:
              <span :class="numeral({ number: history.prizeMoneyWonThisRace, decimalPlaces: 1 }) && 'color-Good3'">${{numeral(history.prizeMoneyWonThisRace).format('0.00') }}</span>
            </td>
            <td colspan="5">
              <span> Margin: <span :class="history.placeGetters.margin > 0 && 'color-Good4'">{{placeGetterCalc(history.placeGetters)}}</span></span>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="!isEmpty(history.stewardsComment) || !isEmpty(history.competitorNotes) || !isEmpty(history.failedToFinishReason)">
          <tr>
            <td colspan="12">
              <p v-if="!isEmpty(history.stewardsComment)" class="my-1" style="font-size: 12px; width: 400px !important;"><b>Stewards Comment:</b> {{ history.stewardsComment }} </p>
              <p v-if="!isEmpty(history.competitorNotes) && !isEmptyOrSpaces(history.competitorNotes[0].notes)" style="font-size: 12px; width: 400px !important;" class="my-1"><b>User Notes:</b> {{ history.competitorNotes[0].notes }} </p>
              <p v-if="!isEmpty(history.failedToFinishReason)" style="font-size: 12px; width: 400px !important;" class="my-1"><b>Failed To FInish Reason:</b> {{ failedToFinishReason({ reason: history.failedToFinishReason }).full }} </p>
            </td>
          </tr>
        </tfoot>
      </v-simple-table>
      <div class="pl-3" style="text-align: left; background-color: #dadada">
        <b style="color: grey; font-size: 11px !important;" class="black--text">---{{ history.dsls }} DAY {{ checkSpell(history.dsls) }}---</b>
      </div>
    </div>

    <div v-if="messageType.isFilter" class="text-center mt-8">
      <b style="color: black; font-size: 18px !important;">No match to Filter Form settings</b>
    </div>

    <div v-if="messageType.isFirstStart" class="text-center">
      <b style="color: black; font-size: 18px !important;">First Start</b>
    </div>
  </div>
</template>

<script>
import differenceInDays from 'date-fns/differenceInDays'
import { mapGetters, mapState, mapActions } from 'vuex'
import { getReadableDate, isEmpty, isEmptyOrSpaces, returnCorrectClass, historyTextDisplay, formatTimeShortAndLongForm, runnersPlacementToString, placeGetterCalc } from '../common/methods'
import numeral from 'numeral'

export default {
  props: {
    race: Object,
    runner: Object
  },
  data () {
    return {
      longFormHeight: null
    }
  },
  computed: {
    ...mapGetters(['getCompetitorsNotesFromDB']),
    ...mapState(['windowDimension', 'runnerFormHistoryFiltered', 'raceFilters']),
    runnerHistoryFilter () {
      const formHistoryFiltered = this.runnerFormHistoryFiltered[this.runner?.runner?.id] || []
      if (this.checkDefaultFilters()) return this.runner.formHistory
      return formHistoryFiltered
    },
    messageType () {
      if (isEmpty(this.runner.formHistory)) {
        return { isFirstStart: true, isFilter: false }
      }

      if (this.checkDefaultFilters()) {
        return { isFirstStart: false, isFilter: false }
      }

      return { isFirstStart: false, isFilter: isEmpty(this.runnerHistoryFilter) }
    }
  },
  mounted () {
    const yPos = this.getLongFromRect()?.y
    if (!isEmpty(yPos)) {
      this.longFormHeight = this.windowDimension.height - yPos
    }
  },
  watch: {
    windowDimension (newState) {
      const yPos = this.getLongFromRect()?.y
      if (!isEmpty(yPos)) {
        this.longFormHeight = newState.height - yPos
      }
    },
    runnerHistoryFilter () {
      const yPos = this.getLongFromRect()?.y
      if (!isEmpty(yPos)) {
        this.longFormHeight = this.windowDimension.height - yPos
      }
    }
  },
  methods: {
    ...mapActions(['fetchSingularRunnerWithGlobalIdAndName', 'apiErrosAXN', 'fetchRace']),
    numeral,
    isEmpty,
    isEmptyOrSpaces,
    getReadableDate,
    returnCorrectClass,
    historyTextDisplay,
    formatTimeShortAndLongForm,
    runnersPlacementToString,
    placeGetterCalc,
    daysBetweenDates (dateFrom, dateTo) {
      const date1 = new Date(dateFrom)
      const date2 = new Date(dateTo)
      let days = differenceInDays(date1, date2)
      const text = this.checkSpell(days)
      let valueToReturn = `${text} (${days} days)`
      if (days >= 42) {
        days = Math.floor(days / 7)
        valueToReturn = `${text} (${days} weeks)`
      }
      return valueToReturn
    },
    checkSpell (dsls) {
      if (dsls >= 21 && dsls <= 41) {
        return 'FRESHENED'
      } else if (dsls >= 42 && dsls <= 83) {
        return 'LET UP'
      } else if (dsls >= 84) {
        return 'SPELL'
      } else {
        return ''
      }
    },
    mutateDataForm (dataSet, runnerId) {
      const competitorNotes = this.getCompetitorsNotesFromDB(runnerId)
      dataSet = dataSet.filter(history => history.finishingPosition !== 0 && history.starters !== 0) // Filter out abandoned races
      // eslint-disable-next-line array-callback-return
      dataSet.map((b, index) => {
        if (index !== dataSet?.length - 1) {
          const nowTime = dataSet[index].date
          const pastTimer = dataSet[index + 1].date
          const nowTimeMili = new Date(nowTime).getTime()
          const pastTimeMili = new Date(pastTimer).getTime()
          const timeBetween = nowTimeMili - pastTimeMili
          const daysBetween = timeBetween / (1000 * 60 * 60 * 24)
          const daysFormatted = Math.floor(daysBetween)
          b.dsls = daysFormatted
        }
        if (!isEmpty(competitorNotes)) {
          const compNotes = competitorNotes.filter(note => note.gbsMeetingId === b.gbsMeetingId && note.raceNumber === b.raceNumber)
          b.competitorNotes = compNotes
        }
      })
      return dataSet
    },
    uiTruncateTxt ({ txt }) {
      const splitInRun = txt.split(',')
      const inRunText = []
      splitInRun.forEach(x => {
        inRunText.push(x.split('-')[1])
      })
      if (inRunText?.length > 1) {
        return inRunText.join(', ')
      } else {
        return '-'
      }
    },
    formatInRun ({ inRun }) {
      if (!isEmpty(inRun)) {
        const inRunSplitArr = inRun.split(',').reverse()

        if (!isEmpty(inRunSplitArr)) {
          const inRunFormated = inRunSplitArr.map((runStr, index) => {
            const position = Number(runStr.split('()')[0])
            const incrementer = 400
            const raceDistance = incrementer * (index + 1)
            if (raceDistance === inRunSplitArr?.length * incrementer) {
              return `Settled - ${numeral(position).format('0o')}`
            } else {
              return `${raceDistance}m - ${numeral(position).format('0o')}`
            }
          })

          return inRunFormated.reverse().join(', ')
        } else {
          return ''
        }
      } else {
        return 'None'
      }
    },
    onOpenHorseSearchTab ({ placement }) {
      this.fetchSingularRunnerWithGlobalIdAndName({ runnerGlobalId: placement.runnerGlobalId, runnerName: placement.name })
    },
    getLongFromRect () {
      const longForm = document.querySelector('.long-form')
      const longFormRec = longForm?.getBoundingClientRect()
      return longFormRec
    },
    checkDefaultFilters () {
      const defaultChecks = []
      if (this.raceFilters?.trackConditions?.length === 1 && this.raceFilters?.trackConditions.includes('Any')) {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }

      if (this.raceFilters?.finishingPosition === 'All') {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (this.raceFilters?.track === 'All') {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (this.raceFilters?.spell === 'All') {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (this.raceFilters?.margin === 12.5 || this.raceFilters?.margin === '12.5') {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (this.raceFilters?.finishPositionOrMargin === false) {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (isEmpty(this.raceFilters?.distance?.from)) {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (this.raceFilters?.classes?.length === 1 && this.raceFilters?.classes.includes('all')) {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }

      return !defaultChecks.includes(false)
    },
    failedToFinishReason ({ reason }) {
      switch (reason) {
        case 'LostRider':
          return { abbeviation: 'LR', full: 'Lost Rider' }
        case 'FailedToFinish':
          return { abbeviation: 'FF', full: 'Failed To Finish' }
        case 'Fell':
          return { abbeviation: 'FF', full: 'Fell' }
        default:
          return { abbeviation: '-', full: '-' }
      }
    },
    marginReCalc ({ history }) {
      // TODO: ADD DEAD HEAT - DEAD HEAD HERE
      if (history.finishingPosition === 1) {
        return Math.abs(history?.margin)
      } else {
        return history?.margin
      }
    },
    onOpenNewRace ({ history }) {
      if (!isEmpty(history.raceId)) {
        this.fetchRace({ id: history.raceId })
      } else {
        this.apiErrosAXN({ error: 'Can\'t find race...', type: 'error' })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.long-form {

  &__active-hover {
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
}

.barrier-trialname {
  color:red;
}

.longFormTable tr td {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  font-size: 12.5px !important;
  height: 25px !important;
  text-align: left !important;
}

.longFormTable tr td:first-child {
  padding-left: 15px;
  width: 0;
}

.longFormTable tr td:last-child {
  padding-right: 15px;
}

.color-Firm {
  color: rgb(0, 204, 255);
}

.color-Firm1 {
  color: rgb(0, 204, 255);
}

.color-Firm2 {
  color: rgb(0, 204, 255);
}

.color-Good {
  color: rgb(0, 219, 0);
}

.color-Good3 {
  color: rgb(0, 219, 0);
}

.color-Good4 {
  color: rgb(0, 219, 0);
}

.color-Soft {
  color: rgb(224, 210, 56);
}

.color-Soft5 {
  color: rgb(224, 210, 56);
}

.color-Soft6 {
  color: rgb(224, 210, 56);
}

.color-Soft7 {
  color: rgb(224, 210, 56);
}

.color-Heavy {
  color: orange;
}

.color-Heavy8 {
  color: orange;
}

.color-Heavy9 {
  color: orange;
}

.color-Heavy10 {
  color: orange;
}

.color-Synthetic {
  color: purple;
}

.placment-gold {
  color: #FFD700
}
.placment-silver {
  color: #C0C0C0
}
.placment-bronze {
  color: #CD7F32
}
.placment-fourth {
  color: green
}
.barrier-trial {
  font-style: italic;
  color:#999;
  background-color: rgba(0,0,0,0.04)
}

.runner-table {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  padding-bottom: 40px;
}

@media only screen and (min-width: 1903px) {
  .runner-table {
    height: 1000px !important;
    padding-bottom: 50px !important;
  }
}
</style>
