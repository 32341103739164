<template>
  <v-card class="pb-16" elevation="0">
    <v-toolbar
      dense
      color="green lighten-4 px-0"
      elevation="1"
      height="42"
    >
      <v-icon v-if="runner.tabType === 'jockeyTab'" class="mr-1 pa-1" size="20" color="black" >mdi-account</v-icon>
      <v-icon v-if="runner.tabType === 'trainerTab'" class="mr-1 pa-1" size="20" color="black" >mdi-binoculars</v-icon>
      <h2 class="d-flex justify-center align-center text-lg-h6">
        {{ runner.selectedTabTypeName }}
        <v-chip
            v-if="isBlackbook"
            class="ml-2 font-weight-regular"
            color="black"
            outlined
            small
            >
            <v-icon left small>
              mdi-book-open-page-variant
            </v-icon>
            Saved To Blackbook
          </v-chip>
       </h2>
      <v-spacer></v-spacer>
      <div v-if="!isHorseSearch">
        <v-btn v-if="isJockeyBlackbook"  @click="removeRunnerFromBlackbook()" small color="#f44336" dark>Remove from blackbook <v-icon class="ml-1" small cl>mdi-trash-can</v-icon></v-btn>
        <v-btn v-else-if="isTrainerBlackbook"  @click="removeRunnerFromBlackbook()" small color="#f44336" dark>Remove from blackbook <v-icon class="ml-1" small cl>mdi-trash-can</v-icon></v-btn>
        <v-btn v-else @click="addRunnerToBlackbook()" small color="black" dark>Add to Blackbook</v-btn>
      </div>
    </v-toolbar>
    <div>
      <v-row class="pa-2">
        <v-col cols="3">
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="mb-1"><span class="text-md-body-1 font-weight-medium">Current Season Prizemoney:</span> <span class="text-sm-body-2">{{thisSeasonPrizeMoney}}</span></div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="mb-1"><span class="text-md-body-1 font-weight-medium">Last Season Prizemoney:</span> <span class="text-sm-body-2">{{lastSeasonPrizeMoney}}</span></div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="mb-1"><span class="text-md-body-1 font-weight-medium">Current Season Win:</span> <span class="text-sm-body-2">{{thisSeasonWins}}</span></div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="mb-1"><span class="text-md-body-1 font-weight-medium">Current Season Place:</span> <span class="text-sm-body-2">{{thisSeasonPlaces}}</span></div>
        </v-col>
        <v-col cols="3">
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="mb-1"><span class="text-md-body-1 font-weight-medium">Current Season Statistics</span> <span class="text-sm-body-2">{{thisSeasonStats}}</span></div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="mb-1"><span class="text-md-body-1 font-weight-medium">Last Season Statistics</span> <span class="text-sm-body-2">{{lastSeasonStats}}</span></div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="mb-1"><span class="text-md-body-1 font-weight-medium">Last Season Win:</span> <span class="text-sm-body-2">{{lastSeasonWins}}</span></div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="mb-1"><span class="text-md-body-1 font-weight-medium">Last Season Place:</span> <span class="text-sm-body-2">{{lastSeasonPlaces}}</span></div>
        </v-col>
        <v-col cols="6">
          <v-simple-table dense style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
            <template v-slot:default>
              <thead class="green lighten-4 font-weight-bold">
                <tr>
                  <th class="text-left">
                    Premiership Rankings -- COMING SOON
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>33rd</td>
                  <td>Total Wins</td>
                </tr>
                <tr>
                  <td>43rd</td>
                  <td>Metropolitan</td>
                </tr>
                <tr>
                  <td>N/A</td>
                  <td>Country</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <div class="pa-2 mt-6 jt-table">
        <div class="mb-9">
          <v-card-title class="pl-0 pt-0 pb-0">Acceptances</v-card-title>
          <v-simple-table dense style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
            <template v-slot:default>
              <thead class="green lighten-4 font-weight-bold">
                <tr>
                  <th class="text-left">
                    Date
                  </th>
                  <th class="text-left">
                    Track
                  </th>
                  <th class="text-left">
                    Race
                  </th>
                  <th class="text-left">
                    Race Name
                  </th>
                  <th class="text-left">
                    Time
                  </th>
                  <th class="text-left">
                    Prize
                  </th>
                  <th class="text-left">
                    Restr.
                  </th>
                  <th class="text-left">
                    Class
                  </th>
                  <th class="text-left">
                    Dist
                  </th>
                  <th class="text-left">
                    Horse
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in acceptances"
                  :key="index"
                >
                  <td>{{ format(parseISO(item.date), 'dd MMM yy') }}</td>
                  <td>{{ item.track }}</td>
                  <td>{{ item.raceNumber }}</td>
                  <td>{{ item.raceName }}</td>
                  <td>{{ format(parseISO(item.startTime), 'HH:mm') }}</td>
                  <td>{{ item.prizeTotal }}</td>
                  <td>{{ formatAge(item.ageRestriction)}} {{ formatSex(item.sexRestriction) }}</td>
                  <td>{{ item.class }}</td>
                  <td>{{ item.distance }}</td>
                  <td @click="addRunnerToChromeTab({ ride: item })" class="clickable-runner">{{ item.runner }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <div>
          <v-card-title class="pl-0 pt-0 pb-0" v-if="runner.tabType === 'jockeyTab'">Last 50 Rides</v-card-title>
          <v-card-title class="pl-0 pt-0 pb-0" v-if="runner.tabType === 'trainerTab'">Last 50 Starters</v-card-title>
          <v-simple-table dense style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;">
            <template v-slot:default>
              <thead class="green lighten-4 font-weight-bold">
                <tr>
                  <th class="text-left">
                    FP
                  </th>
                  <th class="text-left">
                    MGN
                  </th>
                  <th class="text-left">
                    Date
                  </th>
                  <th class="text-left">
                    Track
                  </th>
                  <th class="text-left">
                    Age
                  </th>
                  <th class="text-left">
                    Sex
                  </th>
                  <th class="text-left">
                    G
                  </th>
                  <th class="text-left">
                    Dist
                  </th>
                  <th class="text-left">
                    SP
                  </th>
                  <th class="text-left">
                    WGT
                  </th>
                  <th class="text-left">
                    Horse
                  </th>
                  <th class="text-left">
                    Bar
                  </th>
                  <th class="text-left">
                    In Run
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ride, index) in last50Rides"
                  :key="index">
                  <td>{{ ride.finishingPosition }}-{{ ride.starters }}</td>
                  <td>{{ ride.margin }}</td>
                  <td>{{ format(parseISO(ride.date), 'dd MMM yy') }}</td>
                  <td>{{ ride.track }} R{{ ride.raceNumber }}</td>
                  <td>{{ formatAge(ride.ageRestriction) }}</td>
                  <td>{{ formatSex(ride.sexRestriction) }}</td>
                  <td>{{ ride.trackCondition.charAt(0) }}{{ ride.trackRating }}</td>
                  <td>{{ ride.distance }}</td>
                  <td>${{ ride.startingPrice }}</td>
                  <td>{{ ride.weight }}</td>
                  <td @click="addRunnerToChromeTab({ ride })" class="clickable-runner">{{ ride.runner }}</td>
                  <td>{{ ride.barrier }}</td>
                  <td>{{ formatInRun(ride.inRun) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { isEmpty } from '../common/methods'
import { format, parseISO } from 'date-fns'

export default {
  data () {
    return {}
  },
  props: {
    runner: Object
  },
  mounted () {
    const isJockey = this.runner.tabType === 'jockeyTab'
    const isTrainer = this.runner.tabType === 'trainerTab'

    if (isJockey) {
      const doesCurrentJockeyExists = this.getLast50RidesJockeyAndTrainer(this.runner.jockeyId)
      if (isEmpty(doesCurrentJockeyExists)) {
        this.fetchGlobalIdAndLast50RidesJockey({ jockeyId: this.runner.jockeyId })
      }
    } else if (isTrainer) {
      const doesCurrentJockeyExists = this.getLast50RidesJockeyAndTrainer(this.runner.trainerId)
      if (isEmpty(doesCurrentJockeyExists)) {
        this.fetchGlobalIdAndLast50RidesTrainer({ trainerId: this.runner.trainerId })
      }
    }
  },
  computed: {
    ...mapState(['runnerBlackbook', 'jockeyBlackbook', 'trainerBlackbook', 'removeJockeyFromBlackbook', 'removeTrainerFromBlackbook', 'currentVenue']),
    ...mapGetters(['getSelectedRace', 'getLast50RidesJockeyAndTrainer']),
    race: function () {
      return this.getSelectedRace()
    },
    currentGlobalId () {
      const globalId = this.runner.tabType === 'jockeyTab' ? this.runner.jockeyId : this.runner.trainerId
      return globalId
    },
    isJockeyBlackbook: function () {
      return this.jockeyBlackbook.find(co => co.runnerId === this.runner?.runner?.id && co.tabType === this.runner.tabType)
    },
    isTrainerBlackbook: function () {
      return this.trainerBlackbook.find(co => co.runnerId === this.runner?.runner?.id && co.tabType === this.runner.tabType)
    },
    isBlackbook: function () {
      const combined = [...this.runnerBlackbook, ...this.jockeyBlackbook, ...this.trainerBlackbook]
      const found = combined.find(co => `${co.runnerId}-${co.tabType}` === `${this.runner.runnerId}-${this.runner.tabType}`)
      return !isEmpty(found)
    },
    last50Rides () {
      const last50Rides = this.getLast50RidesJockeyAndTrainer(this.currentGlobalId)
      return last50Rides?.formHistory
    },
    isHorseSearch: function () {
      return this.currentVenue?.name === 'Horse Search'
    },
    thisSeasonPrizeMoney: function () {
      const stats = this.getLast50RidesJockeyAndTrainer(this.currentGlobalId)
      const res = `$${stats?.statistics?.thisSeason?.averagePrizeMoneyWon}`
      if (res.includes('undefined')) return '-'
      return res
    },
    lastSeasonPrizeMoney: function () {
      const stats = this.getLast50RidesJockeyAndTrainer(this.currentGlobalId)
      const res = `$${stats?.statistics?.lastSeason?.averagePrizeMoneyWon}`
      if (res.includes('undefined')) return '-'
      return res
    },
    thisSeasonStats: function () {
      const stats = this.getLast50RidesJockeyAndTrainer(this.currentGlobalId)
      const numberOfRuns = stats?.statistics?.thisSeason?.numberOfRuns
      const firsts = stats?.statistics?.thisSeason?.firsts
      const seconds = stats?.statistics?.thisSeason?.seconds
      const thirds = stats?.statistics?.thisSeason?.thirds
      const res = `${numberOfRuns}: ${firsts} - ${seconds} - ${thirds}`
      if (res.includes('undefined')) return '-'
      return res
    },
    lastSeasonStats: function () {
      const stats = this.getLast50RidesJockeyAndTrainer(this.currentGlobalId)
      const numberOfRuns = stats?.statistics?.lastSeason?.numberOfRuns
      const firsts = stats?.statistics?.lastSeason?.firsts
      const seconds = stats?.statistics?.lastSeason?.seconds
      const thirds = stats?.statistics?.lastSeason?.thirds
      const res = `${numberOfRuns}: ${firsts} - ${seconds} - ${thirds}`
      if (res.includes('undefined')) return '-'
      return res
    },
    thisSeasonWins: function () {
      const stats = this.getLast50RidesJockeyAndTrainer(this.currentGlobalId)
      const res = `${stats?.statistics?.thisSeason?.winPercentage}%`
      if (res.includes('undefined')) return '-'
      return res
    },
    lastSeasonWins: function () {
      const stats = this.getLast50RidesJockeyAndTrainer(this.currentGlobalId)
      const res = `${stats?.statistics?.lastSeason?.winPercentage}%`
      if (res.includes('undefined')) return '-'
      return res
    },
    thisSeasonPlaces: function () {
      const stats = this.getLast50RidesJockeyAndTrainer(this.currentGlobalId)
      const res = `${stats?.statistics?.thisSeason?.placePercentage}%`
      if (res.includes('undefined')) return '-'
      return res
    },
    lastSeasonPlaces: function () {
      const stats = this.getLast50RidesJockeyAndTrainer(this.currentGlobalId)
      const res = `${stats?.statistics?.lastSeason?.placePercentage}%`
      if (res.includes('undefined')) return '-'
      return res
    },
    acceptances () {
      const last50Rides = this.getLast50RidesJockeyAndTrainer(this.currentGlobalId)
      return last50Rides?.acceptances.sort(function (a, b) {
        if (a.startTime < b.startTime) { return -1 }
        if (a.startTime > b.startTime) { return 1 }
        return 0
      })
    }
  },
  methods: {
    ...mapActions(['addToBlackBook', 'deleteRunnerFromBlackbook', 'deleteJockeyFromBlackbook', 'deleteTrainerFromBlackbook', 'triggerHorseSearchChrome', 'fetchSingularRunner', 'fetchGlobalIdAndLast50RidesJockey', 'fetchGlobalIdAndLast50RidesTrainer']),
    format,
    parseISO,
    addRunnerToBlackbook () {
      const { runner } = this

      const saveCurrentRunner = {
        tabType: runner.tabType,
        runnerId: runner?.runner?.id,
        selectedTabTypeName: runner.selectedTabTypeName,
        raceNumber: this.race?.number,
        meetingId: this.race?.meeting?.id
      }

      this.addToBlackBook({ blackbook: saveCurrentRunner })
    },
    removeRunnerFromBlackbook () {
      const { runner } = this

      const removeCurrentRunner = {
        tabType: runner.tabType,
        runnerId: runner?.runner?.id
      }

      if (this.runner.tabType === 'jockeyTab') {
        this.deleteJockeyFromBlackbook({ blackbook: removeCurrentRunner })
      } else if (this.runner.tabType === 'trainerTab') {
        this.deleteTrainerFromBlackbook({ blackbook: removeCurrentRunner })
      }
    },
    formatAge (age) {
      if (age.minimumAgeInclusive > -1 && age.maximumAgeInclusive === -1) {
        return `${age.minimumAgeInclusive}+`
      } else if (age.minimumAgeInclusive > -1 && age.maximumAgeInclusive > -1 && age.minimumAgeInclusive !== age.maximumAgeInclusive) {
        return `${age.minimumAgeInclusive}/${age.maximumAgeInclusive}`
      } else if (age.minimumAgeInclusive > -1 && age.maximumAgeInclusive > -1 && age.minimumAgeInclusive === age.maximumAgeInclusive) {
        return age.minimumAgeInclusive
      } else if (age.minimumAgeInclusive === -1 && age.maximumAgeInclusive === -1) {
        return ''
      }
    },
    formatSex (sex) {
      const sexFormattedArray = []
      sex.restrictedTo.forEach(s => {
        sexFormattedArray.push(s.charAt(0).toLowerCase())
      })
      return sexFormattedArray.join(',')
    },
    formatInRun (inRun) {
      const inRunArray = inRun.split(',')
      inRunArray.forEach((ir, index) => {
        inRunArray[index] = ir.replace(/ *\([^)]*\) */g, '')
      })

      return inRunArray.join('-')
    },
    addRunnerToChromeTab ({ ride }) {
      this.fetchSingularRunner({ runnerName: ride.runner })
    }
  }
}
</script>

<style scoped>
  .jt-table {
    height: 700px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    padding-bottom: 100px !important;
  }

  .clickable-runner {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

@media only screen and (min-width: 1903px) {
  .jt-table {
    height: 1000px !important;
    padding-bottom: 100px !important;
  }
}
</style>
