<template>
  <td v-if="!item.scratched" class="text-start font-weight-medium pl-1" :class="'stdMode runnerNameDiv'" :style="resultsMode">
    <div style="float: left" :class="getPosition ? 'runnerNameContainerResulted' : 'runnerNameContainer'">
      <div class="runnerNameBoxContent">
        <div>
          <div class="runnerNameBox">
            <div v-if="!getPosition" :class="'runnerNameResulted font-weight-medium d-inline mr-2'" class="runnerName d-inline mr-2" style="width: 140px" >
              {{ item.name }}
            </div>
            <div v-else :class="'runnerName font-weight-medium d-inline mr-2'" class="runnerName d-inline mr-2" style="width: 90px" >
              {{ item.name }}
            </div>
            <div style="clear: both"></div>
          </div>
        </div>
      </div>
    </div>
    <div style="float: right; -webkit-flex: 1; -moz-flex: 1; flex: 1">
      <span v-if="getPosition === 1" class="placingFirst">{{ getPosition }}st</span>
      <span v-else-if="getPosition === 2" class="placingSecond">{{ getPosition }}nd</span>
      <span v-else-if="getPosition === 3" class="placingThird">{{ getPosition }}rd</span>
      <span v-else-if="getPosition" class="placingFourth">{{ getPosition }}th</span>
    </div>
  </td>
  <td v-else-if="item.scratched" :class="'stdMode'" class="text-start font-weight-medium pl-1 runnerNameDiv">
    <div class="runnerNameContainer" style="float: left; opacity: 0.5; width: 100%">
      <div class="runnerNameBox">
        <div :class="item.scratchingType === 'LATESCRATCHING' ? 'runnerName-lateScratching' : 'runnerNameScratched d-inline float-left'">{{ item.name }}</div>
        <div v-if="meeting.type === 'THOROUGHBRED'" class="barrierText d-inline float-right">
          <span v-if="item.emergencyIndicator" class="ml-1 red--text font-weight-bold">E</span>
        </div>
        <div style="clear: both"></div>
        <div class="scratched font-weight-light mt-n2 float-left">
          <strong>SCRATCHED</strong><span class="ml-2"> {{ item.scratchingType === 'LATESCRATCHING' ? 'LS' : null }}</span>
        </div>
      </div>
    </div>
  </td>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import numeral from 'numeral'

export default {
  data: () => ({}),
  props: {
    item: Object,
    meeting: Object,
    raceId: String,
    results: Array,
    resultsMode: String
  },
  computed: {
    ...mapGetters(['hasFeature', 'getGridSettings', 'getGridSettings']),
    ...mapGetters('betting', ['getTodaysBets']),
    displayImperial: function () {
      return this.getGridSettings.includes('displayImperial')
    },
    allowanceWeight () {
      if (!this.item.allowanceWeight) {
        return
      }
      return `(${this.item.allowanceWeight})`
    },
    getPosition: function () {
      if (this.item.finalPosition && this.item.finalPosition > 0) {
        return this.item.finalPosition
      }
      if (this.results && this.results[0] && this.results[0].positions) {
        const position = this.results[0].positions.find((p) => p.tabNo === this.item.tabNo)
        if (position) {
          return position.position
        }
      }
      if (this.results && this.results[0] && this.results[0].positions && this.results[0].positions[0].competitor) {
        const position = this.results[0].positions.find((p) => p.competitor.tabNo === this.item.tabNo)
        if (position) {
          return position.position
        }
      }
      return null
    }
  },
  methods: {
    ...mapActions(['fetchRunner', 'fetchRunnerDO']),
    ...mapGetters(['getRunners']),
    ...mapState(['detailedRunner']),
    numeral,
    weightSet (weight) {
      const weightInLbs = weight * 2.205
      if (this.displayImperial) {
        return `${numeral(weightInLbs).format('0,00')}lbs`
      } else {
        return `${weight}kg`
      }
    }
  }
}
</script>

<style>
.runnerNameBoxContent {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.runnerNameContainer {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  max-width: 185px;
  height: 30px;
}
.runnerNameContainerResulted {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  max-width: 130px;
  height: 30px;
}
.runnerNameBox {
  float: left;
  width: 100%;
  height: 29px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.runnerNameDiv {
  /* background:#fff !important; */
}
.placingFirst {
  display: inline-block;
  margin-left: 6px;
  font-weight: bold;
  color: #fff;
  background: #057faf;
  width: 40px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
  float: right;
}
.placingSecond {
  display: inline-block;
  margin-left: 6px;
  font-weight: bold;
  color: #fff;
  background: #ec0101;
  width: 40px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
  float: right;
}
.placingThird {
  display: inline-block;
  margin-left: 6px;
  font-weight: bold;
  color: #fff;
  background: #00a500;
  width: 40px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
  float: right;
}
.placingFourth {
  display: inline-block;
  margin-left: 6px;
  font-weight: bold;
  color: #fff;
  background: #9b9b9b;
  width: 40px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
  float: right;
}
.barrierText {
  font-weight: 600;
  color: #333;
  font-size: 0.8em;
}
.runnerNameResulted {
  float: left;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
.runnerName {
  float: left;
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}
.runnerNameScratched {
  text-decoration: line-through;
  font-size: 0.9em;
  font-weight: bold;
  color: #333;
}
.runnerName-lateScratching {
  text-decoration: line-through;
  font-size: 0.9em;
  font-weight: bold;
  color: #333;
  text-decoration-color: red;
}
.scratched {
  text-transform: uppercase;
  color: red;
  padding-top: 5px;
  font-size: 10px;
}
</style>
