<template>
  <v-dialog v-model="dialog" max-width="1000">
    <template v-slot:activator="{ on }">
      <v-btn tile value="Filter Form" :style="btnStyles" :elevation="btnStyles.elevation" x-small :color="`${btnStyles.btnColor}`" class="d-inline mr-2 rounded-tr-md rounded-br-md" v-on="on" @click.stop="openFilterForm">
        <v-icon class="mr-1" small>
          mdi-filter
        </v-icon>
        <span>Filter Form</span>
      </v-btn>
    </template>
    <v-card class="pb-4">
      <v-toolbar class="black--text mb-8" color="#37474f" dark dense style="position: sticky; top: 0; z-index: 999;">
        <div style="width: 100%;" class="d-flex justify-center align-center justify-space-between white--text">
          <v-toolbar-title>Filter Form</v-toolbar-title>
          <v-btn class="mr-n2" icon small @click.stop="dialog = false"><v-icon size="20">mdi-close</v-icon></v-btn>
        </div>
      </v-toolbar>
      <v-card-text class="pb-0">
        <v-row>
          <v-col cols="2" class="pt-0">
            <p class="ma-0 font-weight-medium">Conditions:</p>
            <v-checkbox dense hide-details color="#37474f" label="Firm" v-model="trackConditions" value="Firm" @change="updateTrackConditions('Firm')"></v-checkbox>
            <v-checkbox dense hide-details color="#37474f" label="Good" v-model="trackConditions" value="Good" @change="updateTrackConditions('Good')"></v-checkbox>
            <v-checkbox dense hide-details color="#37474f" label="Synthetic" v-model="trackConditions" value="Synthetic" @change="updateTrackConditions('Synthetic')"></v-checkbox>
            <v-checkbox dense hide-details color="#37474f" label="Soft" v-model="trackConditions" value="Soft" @change="updateTrackConditions('Soft')"></v-checkbox>
            <v-checkbox dense hide-details color="#37474f" label="Heavy" v-model="trackConditions" value="Heavy" @change="updateTrackConditions('Heavy')"></v-checkbox>
            <v-checkbox dense hide-details color="#37474f" label="Any" v-model="trackConditions" value="Any" @change="updateTrackConditions('Any')"></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-select label="TRACK" dense hide-details outlined item-disabled="disable" item-text="track" :items="tracks" v-model="track"></v-select>
            <v-select label="FROM SPELL" dense hide-details outlined class="mt-4" :items="fromSpell" item-disabled="disable" item-text="spell"  v-model="spell"></v-select>
            <div style="width:100%;" class="d-flex align-center justify-center">
              <v-text-field label="DISTANCE FROM" dense hide-details outlined suffix="m" class="mt-4 mr-1 float-left d-inline" type="number" v-model="distanceFrom"></v-text-field>
              <v-text-field label="DISTANCE TO" dense hide-details outlined suffix="m" class="mt-4 ml-1 float-right d-inline" type="number" v-model="distanceTo"></v-text-field>
            </div>
          </v-col>
          <v-col cols="4">
            <v-select label="FINISH POSITION" dense hide-details outlined item-disabled="disable" class="mb-5" item-text="position" :items="finishingPositions" v-model="finishingPosition" :disabled="finishPositionOrMargin"></v-select>
            <v-checkbox dense hide-details color="#37474f" label="Or" v-model="finishPositionOrMargin"></v-checkbox>
            <v-text-field label="MARGIN <=" dense hide-details outlined suffix="Lengths" class="mt-6" type="number" :disabled="!finishPositionOrMargin" v-model="margin"></v-text-field>
          </v-col>
          <v-col cols="2" class="pt-0">
            <p class="ma-0 font-weight-medium">Class:</p>
            <v-checkbox dense hide-details label="Group 1" v-model="classes" value="1" @change="updateClasses('group1')"></v-checkbox>
            <v-checkbox dense hide-details label="Group 2" v-model="classes" value="2" @change="updateClasses('group2')"></v-checkbox>
            <v-checkbox dense hide-details label="Group 3" v-model="classes" value="3" @change="updateClasses('group3')"></v-checkbox>
            <v-checkbox dense hide-details label="Listed" v-model="classes" value="4" @change="updateClasses('listed')"></v-checkbox>
            <v-checkbox dense hide-details label="All" v-model="classes" value="all" @change="updateClasses('all')"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <div  class="d-flex justify-center">
        <p class="mb-1 mt-n2 text-sm-body-2 font-weight-medium red--text" v-if="isTrial">Limited Functionality in Free Trial</p>
      </div>
      <v-card-actions class="d-flex justify-center">
        <v-btn elevation="1" small color="#37474f" class="white--text" @click="onFilterForm" :disabled="isTrial">Save</v-btn>
        <v-btn elevation="1" small @click="onClearFilterFrom" :disabled="isTrial">Clear Filters</v-btn>
        <v-btn elevation="1" small color="#37474f" class="white--text" @click="dialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: {
    btnStyles: Object
  },
  data () {
    return {
      dialog: false,
      tracks: [
        { track: 'All', disable: false },
        { track: 'This track', disable: false },
        { track: 'Metropolitan', disable: true },
        { track: 'Country', disable: true }
      ],
      fromSpell: [
        { spell: 'All', disable: false },
        { spell: '1st Up', disable: false },
        { spell: '2nd Up', disable: false },
        { spell: '3rd Up', disable: false },
        { spell: '4th Up', disable: false }
      ],
      finishingPositions: [
        { position: 'All', disable: false },
        { position: 'Win', disable: false },
        { position: 'Place', disable: false }
      ],
      trackConditions: ['Any'],
      classes: ['all'],
      track: 'All',
      spell: 'All',
      finishingPosition: 'All',
      distanceFrom: '',
      distanceTo: '',
      finishPositionOrMargin: false,
      margin: 12.5
    }
  },
  computed: {
    ...mapState(['selectedRace', 'raceFilters']),
    ...mapState('account', ['isTrial']),
    ...mapGetters(['getSelectedRace']),
    race: function () {
      return this.getSelectedRace()
    }
  },
  methods: {
    ...mapActions(['saveRaceFilters', 'clearRaceFilters']),
    openFilterForm () {
      this.trackConditions = this.raceFilters.trackConditions
      this.finishingPosition = this.raceFilters.finishingPosition
      this.track = this.raceFilters.track
      this.spell = this.raceFilters.spell
      this.margin = this.raceFilters.margin
      this.finishPositionOrMargin = this.raceFilters.finishPositionOrMargin
      this.distanceFrom = this.raceFilters.distance.from
      this.distanceTo = this.raceFilters.distance.to
      this.classes = this.raceFilters.classes
      this.dialog = true
    },
    updateTrackConditions (option) {
      if (option === 'Any') {
        this.trackConditions = ['Any']
      } else {
        this.trackConditions = this.trackConditions.filter((option) => option !== 'Any')
      }
    },
    updateClasses (classSelected) {
      if (classSelected === 'all') {
        this.classes = ['all']
      } else {
        this.classes = this.classes.filter((c) => c !== 'all')
      }
    },
    filterByTrackConditions () {
      // if (this.trackConditions.includes('any')) return
      this.saveRaceFilters({ opt: 'trackConditions', value: this.trackConditions })
    },
    filterByFinishingPosition () {
      this.saveRaceFilters({ opt: 'finishPositionOrMargin', value: this.finishPositionOrMargin })
      this.saveRaceFilters({ opt: 'margin', value: this.margin })
      // if (this.finishingPosition === 'All') return
      this.saveRaceFilters({ opt: 'finishingPosition', value: this.finishingPosition })
    },
    filterByTrackLocation () {
      // if (this.track === 'All') return
      this.saveRaceFilters({ opt: 'track', value: this.track })
    },
    filterBySpell () {
      // if (this.spell === 'All') return
      this.saveRaceFilters({ opt: 'spell', value: this.spell })
    },
    filterByDistance () {
      this.saveRaceFilters({ opt: 'distance', value: { from: this.distanceFrom, to: this.distanceTo } })
    },
    filterByClasses () {
      this.saveRaceFilters({ opt: 'classes', value: this.classes })
    },
    onFilterForm () {
      this.filterByTrackConditions()
      this.filterByFinishingPosition()
      this.filterByTrackLocation()
      this.filterBySpell()
      this.filterByDistance()
      this.filterByClasses()
      this.dialog = false
    },
    onClearFilterFrom () {
      // Object.assign(this.$data, { ...this.initialState(), dialog: true })
      this.dialog = true
      this.trackConditions = ['Any']
      this.classes = ['all']
      this.track = 'All'
      this.spell = 'All'
      this.finishingPosition = 'All'
      this.distanceFrom = ''
      this.distanceTo = ''
      this.finishPositionOrMargin = false
      this.margin = 12.5
      this.clearRaceFilters()
      this.onFilterForm()
    }
  }
}
</script>
