<template>
    <div v-if="item">
      <div v-if="meeting.type === 'THOROUGHBRED' || meeting.type === 'HARNESS'" style="vertical-align:bottom" :class="!editMode ? 'tooltipRunner silkSprite' : 'silkSprite'">
        <div style="overflow:hidden;width:30px;height: 30px;">
          <img @error="setAltImage" :src="'https://d36frgpkvm82k8.cloudfront.net/'+race.meeting.externalIDs[0].id+'_'+race.number+'_'+item.tabNo+'_32x32.png'" alt="jockey silks">
        </div>
      </div>
      <div v-if="meeting.type === 'GREYHOUND' && meeting.track.country === 'GBR' && item.tabNo" style="width:28px;margin-top:2px;overflow:hidden;" :class="['greyhoundSpriteUk-' + item.tabNo]" />
      <div v-if="meeting.type === 'GREYHOUND' && meeting.track.country === 'AUS' || meeting.type === 'GREYHOUND' && meeting.track.country === 'NZL' && item.tabNo" style="width:28px;margin-top:2px;overflow:hidden;" :class="['greyhoundSpriteAUS-' + item.tabNo]" />
      <div v-if="meeting.type === 'GREYHOUND' && meeting.track.country === 'USA' || (meeting.type === 'GREYHOUND' && meeting.track.country === 'MEX' && item.tabNo)" style="width:28px;margin-top:2px;overflow:hidden;" :class="['greyhoundSpriteUs-' + item.tabNo]" />
    </div>
</template>

<script>
export default {
  props: {
    meeting: Object,
    item: Object,
    race: Object,
    editMode: Boolean,
    speedMap: Boolean,
    highlight: Boolean
  },
  methods: {
    setAltImage (event) {
      event.target.src = require('../assets/default_silk.png')
    }
  }
}
</script>

<style scoped>
.defaultImage {
  background-repeat: no-repeat;
  background-image: url('~@/assets/default_silk.png')
}
.silkSprite {
  width: 30px;
  height: 30px;
}
.silkSprite-1 {
  margin: 0px;
}
.silkSprite-2 {
  margin: 0 0 0 -32px;
}
.silkSprite-3 {
  margin: 0 0 0 -64px;
}
.silkSprite-4 {
  margin: 0 0 0 -96px;
}
.silkSprite-5 {
  margin: 0 0 0 -128px;
}
.silkSprite-6 {
  margin: 0 0 0 -160px;
}
.silkSprite-7 {
  margin: 0 0 0 -192px;
}
.silkSprite-8 {
  margin: 0 0 0 -224px;
}
.silkSprite-9 {
  margin: 0 0 0 -256px;
}
.silkSprite-10 {
  margin: 0 0 0 -288px;
}
.silkSprite-11 {
  margin: 0 0 0 -320px;
}
.silkSprite-12 {
  margin: 0 0 0 -352px;
}
.silkSprite-13 {
  margin: 0 0 0 -384px;
}
.silkSprite-14 {
  margin: 0 0 0 -416px;
}
.silkSprite-15 {
  margin: 0 0 0 -448px;
}
.silkSprite-16 {
  margin: 0 0 0 -480px;
}
.silkSprite-17 {
  margin: 0 0 0 -512px;
}
.silkSprite-18 {
  margin: 0 0 0 -544px;
}
.silkSprite-19 {
  margin: 0 0 0 -576px;
}
.silkSprite-20 {
  margin: 0 0 0 -608px;
}
.silkSprite-21 {
  margin: 0 0 0 -640px;
}
.silkSprite-22 {
  margin: 0 0 0 -672px;
}
.silkSprite-23 {
  margin: 0 0 0 -704px;
}
.silkSprite-24 {
  margin: 0 0 0 -736px;
}

.tooltipRunner {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipRunner .tooltiptextRunner {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 0%;
  left: 105%;
  white-space: nowrap;
}

.tooltipRunner:hover .tooltiptextRunner {
  visibility: visible;
}
/* UK Greys */
.greyhoundSpriteUk-1 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 0px;
}
.greyhoundSpriteUk-2 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -28px;
}
.greyhoundSpriteUk-3 {
  width: 28px;
  height: 28px;
  border: 1px solid #333;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -56px;
}
.greyhoundSpriteUk-4 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -84px;
}
.greyhoundSpriteUk-5 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -112px;
}
.greyhoundSpriteUk-6 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -140px;
}
.greyhoundSpriteUk-7 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -168px;
}
.greyhoundSpriteUk-8 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_UKGreysSprite_lrg.png') 0 -196px;
}
/* AUS Greys */
.greyhoundSpriteAUS-1 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 0px;
}
.greyhoundSpriteAUS-2 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -28px;
}
.greyhoundSpriteAUS-3 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -56px;
}
.greyhoundSpriteAUS-4 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -84px;
}
.greyhoundSpriteAUS-5 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -112px;
}
.greyhoundSpriteAUS-6 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -140px;
}
.greyhoundSpriteAUS-7 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -168px;
}
.greyhoundSpriteAUS-8 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -196px;
}
.greyhoundSpriteAUS-9 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -224px;
}
.greyhoundSpriteAUS-10 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_AUSGreysSprite_lrg.png') 0 -252px;
}
/* US Greys */
.greyhoundSpriteUs-1 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 0px;
}
.greyhoundSpriteUs-2 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -28px;
}
.greyhoundSpriteUs-3 {
  width: 28px;
  height: 28px;
  border: 1px solid #333;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -56px;
}
.greyhoundSpriteUs-4 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -84px;
}
.greyhoundSpriteUs-5 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -112px;
}
.greyhoundSpriteUs-6 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -140px;
}
.greyhoundSpriteUs-7 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -168px;
}
.greyhoundSpriteUs-8 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -196px;
}
.greyhoundSpriteUs-9 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -224px;
}
.greyhoundSpriteUs-10 {
  width: 28px;
  height: 28px;
  background: url('~@/assets/greyhounds/DO_USGreysSprite_lrg.png') 0 -252px;
}
</style>
