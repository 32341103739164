<script>
import { mapState } from 'vuex'
import { format, parseISO } from 'date-fns'

import { isEmpty } from '../common/methods'
export default {
  name: 'ChromeTabsItem',
  props: {
    icon: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    },
    isBlackbook: {
      type: Boolean,
      required: true
    },
    isHorseSearch: {
      type: Boolean,
      required: true
    },
    onPinTab: {
      type: Function,
      requried: true
    },
    onCloseTab: {
      type: Function,
      requried: true
    },
    onSelectTab: {
      type: Function,
      requried: true
    },
    tab: {
      type: Object,
      requried: true
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(['pindedTabs', 'chromeGrouping']),
    activeRunners () {
      const venue = this.chromeGrouping.find(venue => Object.keys(venue)[0] === this.tab.venueName)
      if (!isEmpty(venue)) {
        const runners = venue[this.tab.venueName]
        const currectActiveRunners = []

        runners.forEach(co => {
          if (co.tabType === 'runnerTab') {
            const obj = { selectedTabTypeName: co.selectedTabTypeName, icon: 'mdi-horseshoe', runnerId: co.runnerId, combinedId: this.returnCombinedId({ competitor: co }) }
            currectActiveRunners.push(obj)
          } else if (co.tabType === 'jockeyTab') {
            const obj = { selectedTabTypeName: co.selectedTabTypeName, icon: 'mdi-account', runnerId: co.runnerId, combinedId: this.returnCombinedId({ competitor: co }) }
            currectActiveRunners.push(obj)
          } else if (co.tabType === 'trainerTab') {
            const obj = { selectedTabTypeName: co.selectedTabTypeName, icon: 'mdi-binoculars', runnerId: co.runnerId, combinedId: this.returnCombinedId({ competitor: co }) }
            currectActiveRunners.push(obj)
          }
        })

        return currectActiveRunners
      } else {
        return []
      }
    }
  },
  methods: {
    isEmpty,
    onClick ({ event, tab }) {
      this.onSelectTab({ event, tab })
    },
    formatDate ({ tab }) {
      if (isEmpty(tab.date)) return ''
      return `(${format(parseISO(tab.date), 'yyyy/MM/dd')})`
    },
    returnCombinedId ({ competitor }) {
      return `${competitor.runnerId}-${competitor.tabType}`
    }
  }
}
</script>

<template>
  <v-tooltip nudge-bottom="110" class="p2-b">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" >
        <div class="chrome-tabs__header">
          <div @click="onClick({ event: $event, tab })"><v-icon class="chrome-tabs__blackbook-icon" style="margin-bottom: -3px;" :blackbook="isBlackbook" :horse-search="isHorseSearch"  size="16" color="black">{{ icon }}</v-icon></div>
          <p :blackbook="isBlackbook" :horse-search="isHorseSearch"  @click="onClick({ event: $event, tab })" class="chrome-tabs__header-title">{{ tab.venueName }} {{ formatDate({ tab }) }}</p>
        </div>

        <div v-if="!tab.isPinned" @click="onCloseTab({ event: $event, tab })" class="chrome-tabs__close"><v-icon :blackbook="isBlackbook" :horse-search="isHorseSearch"  class="chrome-tabs__blackbook-icon" size="14" color="black">mdi-close</v-icon></div>

        <div v-if="isActive && !tab.isPinned" class="chrome-tabs__pin cursor" @click="onPinTab({ venueName: tab.venueName, isPining: true })">
          <v-icon :blackbook="isBlackbook" :horse-search="isHorseSearch"  size="12" color="white" style="margin-left: 4px; margin-top: 2px; color: white !important;" class="chrome-tabs__blackbook-icon cursor" >mdi-pin</v-icon>
        </div>

        <div v-else-if="isActive && tab.isPinned" class="chrome-tabs__pin chrome-tabs__pin-off cursor" @click="onPinTab({ venueName: tab.venueName, isPining: false })">
          <v-icon :blackbook="isBlackbook" :horse-search="isHorseSearch"  size="12" color="white" style="margin-left: 4px; margin-top: 2px; color: white !important;" class="chrome-tabs__blackbook-icon cursor">mdi-pin-off</v-icon>
        </div>

        <div class="chrome-tabs__background" @click="onClick({ event: $event, tab })">
          <svg id="chrome-tabs__background-svg" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <symbol id="chrome-tab-geometry-left" viewBox="0 0 214 36"><path d="M17 0h197v36H0v-2c4.5 0 9-3.5 9-8V8c0-4.5 3.5-8 8-8z" /></symbol>
              <symbol id="chrome-tab-geometry-right" viewBox="0 0 214 36"><use xlink:href="#chrome-tab-geometry-left" /></symbol>
              <clipPath id="crop"><rect class="mask" width="100%" height="100%" x="0" /></clipPath>
            </defs>
            <svg width="52%" height="100%"><use xlink:href="#chrome-tab-geometry-left" width="214" height="36" class="chrome-tabs__item-geometry" :blackbook="isBlackbook" :horse-search="isHorseSearch" /></svg>
            <g transform="scale(-1, 1)">
              <svg width="52%" height="100%" x="-100%" y="0"><use xlink:href="#chrome-tab-geometry-right" width="214" height="36" class="chrome-tabs__item-geometry" :blackbook="isBlackbook" :horse-search="isHorseSearch"  /></svg>
            </g>
          </svg>
        </div>
      </div>
    </template>
    <div>
      <p class="ma-0 font-weight-bold d-flex align-center" :style="!isEmpty(activeRunners) ? 'border-bottom: 1px solid white;' : ''">
        <v-icon size="16" color="white" class="mr-1 ">{{ icon }}</v-icon><span>{{ tab.venueName }} {{ formatDate({ tab }) }}</span>
      </p>
      <div v-if="!isEmpty(activeRunners)">
        <p class="ma-0 d-block mb-n1 d-flex align-center" v-for="(runner, index) in activeRunners" :key="`${returnCombinedId({ competitor: runner })}-${index}`"><v-icon size="14" color="white" class="mr-1">{{ runner.icon }}</v-icon> <span>{{ runner.selectedTabTypeName }}</span></p>
      </div>
    </div>
  </v-tooltip>
</template>
