<template>
  <div class="px-4">
    <v-card class="nav-date pt-2" color="green lighten-5" elevation="0">
      <div class="d-flex align-center justify-center justify-space-between">
        <div class="calendar">
          <v-menu ref="menu" v-model="menu" transition="scale-transition" offset-y min-width="0%" :nudge-right="40">
            <template v-slot:activator="{ on }">
              <v-text-field class="formattedDate  ml-n1" v-model="formattedDate" small prepend-icon="mdi-calendar" readonly v-on="on" style="font-size:14px;" hide-details=""></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable actions>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>

        <!--Day Filter Buttons-->
        <v-btn outlined :color="dateColor('today')" x-small class="ma-1 float-left todayButton" @click="selectToday" style="overflow: auto;">
          Today
        </v-btn>
        <v-btn outlined :color="dateColor('tomorrow')" x-small class="ma-1 float-left tomorrowButton" @click="selectTomorrow" style="overflow: auto;">
          Tomorrow
        </v-btn>
        <v-btn outlined :color="dateColor('saturday')" x-small class="ma-1 float-left saturdayButton" @click="selectSaturday" style="overflow: auto;">
          Saturday
        </v-btn>
      </div>
    </v-card>

    <!-- Domestic races -->
    <v-data-table
      :headers="headersRacesGroupByMeeting"
      :items="sorted[2]"
      :single-expand="true"
      item-key="meetingName"
      show-expand
      class="nav-tabel"
      hide-default-header
      hide-default-footer
      dense
      elevation="0"
      :items-per-page="9999"
    >
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr class="mb-2 cursor" v-if="item.meetingType === 'THOROUGHBRED'" @click="expand(!isExpanded)">
          <td class="font-weight-medium text-no-wrap pointer" @click="onAddRunner({ blackbook: item.blackbook })">
            <country-flag style="vertical-align: middle; transform: scale(0.3);" :country="item.meetingCountry" size="small" :title="item.meetingCountry" />
          </td>
          <td class="text-sm-body-2 text-no-wrap pointer">
            <img :src="raceTypeImg[item.meetingType]" style="max-width:20px" />
          </td>
          <td
            class="tdRacesGroupByMeeting font-weight-medium"
            style="white-space: nowrap; text-overflow: ellipsis; max-width: 200px; padding-right:0px; overflow:hidden; margin-left:15px; font-weight: bold;"
            title="item.meetingName"
          >
            {{ truncateTxtUI({ txt: item.meetingName, max: 15 }) }} ({{ sorted[1][item.meetingName + '-' + item.meetingType].length }})
          </td>
          <td align="right">
            <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            dense
            overflow-y="hidden"
            class="raceDayTable"
            :items="sorted[1][item.meetingName + '-' + item.meetingType]"
            hide-default-footer
            :items-per-page="9999"
            :header-props="{ sortIcon: null }"
          >
            <template v-slot:item="{ item }">
              <v-tooltip right nudge-bottom="40" nudge-left="70" z-index="999" color="black" open-delay="400">
                <template v-slot:activator="{ on }">
                  <tr
                    v-if="Object.keys(races).length !== 0"
                    style="cursor:pointer"
                    @click="clickRace(item)"
                    :class="{ selected: highlightedRace === item.id }"
                    class="raceNavigationRow font-weight-light"
                    v-on="on"
                  >
                    <td class="px-3">
                      {{ formattedStartTime(item) }}
                    </td>
                    <td class="px-3" :style="item.bets ? 'color: orange; font-weight: bold;' : null" style="max-width:20px;">
                      {{ `R${item.number}` }}
                    </td>
                    <td
                      :style="item.bets ? 'color: orange;font-weight: bold;' : null"
                      style="white-space: nowrap; text-overflow: ellipsis; max-width:100px; padding-right:0px; overflow:hidden; margin-left:15px; padding: 0 5px;"
                    >
                      <div
                        v-if="item.fixedAvailable"
                        style="background:#00A500;height:8px;width:8px;display:inline-block;margin-right:5px;border-radius:5px;"
                        title="Fixed odds available"
                      ></div>
                      {{ item.name }}
                    </td>
                    <td class="px-3 raceDayTime" align="right" v-if="item.status === 'FINAL'">
                      Final
                    </td>
                    <td class="px-3 raceDayTime" align="right" v-if="item.status === 'CLOSED'" style="color:red">
                      Closed
                    </td>
                    <td class="px-3 raceDayTime" align="right" v-if="item.status === 'ABANDONED'" style="color:red">
                      Abandoned
                    </td>
                    <td class="px-3 raceDayTime" align="right" v-if="item.status === 'INTERIM'" style="color:orange">
                      Interim
                    </td>
                    <td class="px-3 raceDayTime" align="right" v-else-if="item.status === 'OPEN'">
                      <Countdown :key="item.startTime" :startTime="item.startTime" :formattedStartTime="races[item.id].formattedStartTime" />
                    </td>
                  </tr>
                </template>
                <span class="tooltiptextRaceNavigation caption"
                  ><pre class="tooltipTextRaceNavigationPreformatted">{{ getHoverText(item) }}</pre></span
                >
              </v-tooltip>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <p class="mb-1 mt-n2 ml-2 text-sm-body-2 font-weight-medium red--text" v-if="isTrial">Limited Races available on Free Trial</p>

    <!-- International races -->
    <p class="mb-1 mt-n2 ml-2 text-sm-body-2 font-weight-medium" v-if="!isTrial">International races</p>
    <v-data-table
      :headers="headersRacesGroupByMeeting"
      :items="sorted[3]"
      :single-expand="true"
      item-key="meetingName"
      class="mb-8"
      show-expand
      hide-default-header
      hide-default-footer
      dense
      elevation="0"
      v-if="!isTrial"
      :items-per-page="9999"
    >
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr class="mb-2 cursor" v-if="item.meetingType === 'THOROUGHBRED'" @click="expand(!isExpanded)">
          <td class="font-weight-medium text-no-wrap pointer" @click="onAddRunner({ blackbook: item.blackbook })">
            <country-flag style="vertical-align: middle; transform: scale(0.3);" :country="item.meetingCountry" size="small" :title="item.meetingCountry" />
          </td>
          <td class="text-sm-body-2 text-no-wrap pointer">
            <img :src="raceTypeImg[item.meetingType]" style="max-width:20px" />
          </td>
          <td
            class="tdRacesGroupByMeeting font-weight-medium"
            style="white-space: nowrap; text-overflow: ellipsis; max-width: 200px; padding-right:0px; overflow:hidden; margin-left:15px; font-weight: bold;"
            title="item.meetingName"
          >
            {{ truncateTxtUI({ txt: item.meetingName, max: 15 }) }} ({{ sorted[1][item.meetingName + '-' + item.meetingType].length }})
          </td>
          <td align="right">
            <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
            dense
            overflow-y="hidden"
            class="raceDayTable"
            :items="sorted[1][item.meetingName + '-' + item.meetingType]"
            hide-default-footer
            :items-per-page="9999"
            :header-props="{ sortIcon: null }"
          >
            <template v-slot:item="{ item }">
              <v-tooltip right nudge-bottom="40" nudge-left="70" z-index="999" color="black" open-delay="400">
                <template v-slot:activator="{ on }">
                  <tr
                    v-if="Object.keys(races).length !== 0"
                    style="cursor:pointer"
                    @click="clickRace(item)"
                    :class="{ selected: highlightedRace === item.id }"
                    class="raceNavigationRow font-weight-light"
                    v-on="on"
                  >
                    <td class="px-3">
                      {{ formattedStartTime(item) }}
                    </td>
                    <td class="px-3" :style="item.bets ? 'color: orange; font-weight: bold;' : null" style="max-width:20px;">
                      {{ `R${item.number}` }}
                    </td>
                    <td
                      :style="item.bets ? 'color: orange;font-weight: bold;' : null"
                      style="white-space: nowrap; text-overflow: ellipsis; max-width:100px; padding-right:0px; overflow:hidden; margin-left:15px; padding: 0 5px;"
                    >
                      <div
                        v-if="item.fixedAvailable"
                        style="background:#00A500;height:8px;width:8px;display:inline-block;margin-right:5px;border-radius:5px;"
                        title="Fixed odds available"
                      ></div>
                      {{ item.name }}
                    </td>
                    <td class="px-3 raceDayTime" align="right" v-if="item.status === 'FINAL'">
                      Final
                    </td>
                    <td class="px-3 raceDayTime" align="right" v-if="item.status === 'CLOSED'" style="color:red">
                      Closed
                    </td>
                    <td class="px-3 raceDayTime" align="right" v-if="item.status === 'ABANDONED'" style="color:red">
                      Abandoned
                    </td>
                    <td class="px-3 raceDayTime" align="right" v-if="item.status === 'INTERIM'" style="color:orange">
                      Interim
                    </td>
                    <td class="px-3 raceDayTime" align="right" v-else-if="item.status === 'OPEN'">
                      <Countdown :key="item.startTime" :startTime="item.startTime" :formattedStartTime="races[item.id].formattedStartTime" />
                    </td>
                  </tr>
                </template>
                <span class="tooltiptextRaceNavigation caption"
                  ><pre class="tooltipTextRaceNavigationPreformatted">{{ getHoverText(item) }}</pre></span
                >
              </v-tooltip>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { startOfDay, format, startOfTomorrow, add, eachWeekendOfInterval, getUnixTime, isSameDay, parseISO } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import goTo from 'vuetify/es5/services/goto'
import Countdown from './Countdown'
import { isEmpty, sortByKeyValue, truncateTxt } from '../common/methods'

export default {
  name: 'RaceDayNavigation',
  data: () => ({
    search: '',
    drawer: true,
    isAutoHideCheckbox: false,
    checked: false,
    setRaceOnLoad: 0,
    meetingSummaryToggle: false,
    closeMeetingSummaryModal: false,
    clickedRace: null,
    raceTypeImg: {
      HARNESS: '/img/race_types/DO_RaceType_Harness-BLACK.svg',
      GREYHOUND: '/img/race_types/DO_RaceType_Greyhounds-BLACK.svg',
      THOROUGHBRED: '/img/race_types/DO_RaceType_Gallops-BLACK.svg'
    },
    azList: false,
    raceDaySearchOpen: false,
    headersRaces: [
      {
        value: 'time'
      },
      {
        value: 'country'
      },
      {
        value: 'raceType'
      },
      {
        value: 'raceNum'
      },
      {
        text: 'Race',
        value: 'displayName'
      },
      {
        text: 'Status',
        value: 'timeToGo'
      }
    ],
    headersRacesGroupByMeeting: [
      {
        value: 'meetingCountry',
        class: 'racesGroupByMeeting'
      },
      {
        value: 'meetingType',
        class: 'racesGroupByMeeting'
      },
      {
        value: 'meetingName',
        class: 'racesGroupByMeeting'
      },
      {
        text: 'Close',
        value: 'data-table-expand',
        class: 'racesGroupByMeeting'
      }
    ],
    menu: false,
    filtersOpen: false,
    expanded: [],
    sortBy: 'meetingType'
  }),
  components: {
    Countdown
  },
  props: {
    clickFeature: Function,
    expandOnHoverFunc: Function
  },
  computed: {
    ...mapState(['selectedDate', 'selectedRace', 'races', 'selectedNavRace', 'acMode', 'selectRaceNavSet', 'isDrawer', 'isAutoHide']),
    ...mapState('account', ['isTrial']),
    ...mapGetters(['getNextToGo', 'hasFeature', 'getRaces', 'getRacesGroupByMeeting']),
    highlightedRace: function () {
      return this.clickedRace || this.selectedRace.id
    },
    groupByMeeting: {
      get () {
        return this.getRacesGroupByMeeting
      },
      set (value) {
        this.setRacesGroupByMeeting({ value })
      }
    },
    sorted: function () {
      // const rtype = this.raceTypeImg
      const races = this.getRaces
      const meetingsGrouped = []
      // if (this.getRacesGroupByMeeting) {
      const racesGroupByMeeting = races.reduce((acc, obj) => {
        const property = obj.meeting.track.name + '-' + obj.meeting.type
        if (!acc[property]) {
          meetingsGrouped.push({ meetingCountry: obj.meeting.track.country, meetingType: obj.meeting.type, meetingName: obj.meeting.track.name })
        }
        acc[property] = acc[property] || []
        acc[property].push(obj)
        return acc
      }, {})
      for (const property in racesGroupByMeeting) {
        racesGroupByMeeting[property] = racesGroupByMeeting[property].filter(r => isSameDay(startOfDay(this.selectedDate), parseISO(r.meeting.date)))
      }
      meetingsGrouped.sort((a, b) => (a.meetingName < b.meetingName ? -1 : 1))
      meetingsGrouped.forEach(m => {
        if (racesGroupByMeeting[`${m.meetingName}-${m.meetingType}`]?.length === 0) {
          const index = meetingsGrouped.indexOf(m)
          if (index > -1) {
            meetingsGrouped.splice(index, 1)
          }
        }
      })

      // Seperate domenstic from international races
      let domestic = meetingsGrouped.filter(venue => venue.meetingCountry === 'AUS' || venue.meetingCountry === 'NZL')
      let international = meetingsGrouped.filter(venue => venue.meetingCountry !== 'AUS' && venue.meetingCountry !== 'NZL')

      // Sort `meetingCountry` aphabeticaly
      if (!isEmpty(domestic)) domestic = domestic.sort((a, b) => sortByKeyValue({ key: 'meetingName', a, b }))
      if (!isEmpty(international)) international = international.sort((a, b) => sortByKeyValue({ key: 'meetingName', a, b }))

      return [meetingsGrouped, racesGroupByMeeting, domestic, international]

      // } else {
      //   return races.sort((a, b) => isBefore(a.isoStartTime, b.isoStartTime) ? -1 : 1).filter(race => (isSameDay(startOfDay(this.selectedDate), parseISO(race.meeting.date))))
      // }
    },
    date: {
      get () {
        const d = format(this.selectedDate, 'yyyy-MM-dd')
        return d
      },
      async set (n) {
        this.loadingToggleAXN({ isLoading: true })
        try {
          await this.setDate(utcToZonedTime(new Date(n)))
          await this.reFetchRaces()
          this.loadingToggleAXN({ isLoading: false })
        } catch (err) {
          this.loadingToggleAXN({ isLoading: false })
        }
      }
    },
    formattedDate () {
      return format(this.selectedDate, 'MMM d, yyyy')
    },
    selectedNextRaceScroll () {
      return `${this.setRaceOnLoad}`
    }
  },
  async created () {
    // Select the next race to go on mount
    await this.setDate(startOfDay(Date.now()))
    // await this.fetchRaces()
    const r = this.getNextToGo
    if (r) {
      this.selectRace({ id: r.id })
    }
    this.setThisRaceOnLoad(1)
    setInterval(() => {
      this.updateClocks()
    }, 1000)
  },
  watch: {
    selectedNextRaceScroll: function () {
      if (this.setRaceOnLoad !== 0) {
        try {
          goTo('.raceNavigation .selected', { container: this.$el.querySelector('.v-navigation-drawer__content'), offset: 0 })
        } catch {}
      }
    },
    selectRaceNavSet (v) {
      if (v !== 0) {
        this.setThisRaceOnLoad(1)
        this.selectRaceNav(0)
      }
      return null
    },
    filtersOpen: function (open) {
      if (!open && this.checked) {
        this.expandOnHoverFunc(true)
      }
    },
    selectedRace: function (newRace, oldRace) {
      if (newRace.id !== oldRace.id) {
        this.clickedRace = null
      }
    }
    // TODO: Add a feature here when the user has servey chrome tabs open, you can click on the race Schedule and be able to automatically switch to the current tab...
  },
  methods: {
    ...mapActions([
      'selectRace',
      'updateClocks',
      'selectRaceNav',
      'setDate',
      'fetchRaces',
      'selectNavRace',
      'reFetchRaces',
      'setRaceLoading',
      'setRacesGroupByMeeting',
      'toggleAutoHide',
      'loadingToggleAXN'
    ]),
    closeAllExpanded () {
      this.expanded = []
    },
    raceDaySearch () {
      this.raceDaySearchOpen = true
      this.showAzList()
    },
    setThisRaceOnLoad (value) {
      const races = this.getRaces
      const racesLoad = Object.keys(races).length
      if (racesLoad !== 0) {
        setTimeout(() => {
          this.setRaceOnLoad = value
        }, 1000)
        this.setRaceOnLoad = 0
      } else {
        setTimeout(() => {
          this.setThisRaceOnLoad()
        }, 3000)
      }
    },
    formattedStartTime: function (x) {
      return format(x.isoStartTime, 'HH:mm')
    },
    dateColor: function (v) {
      const weekend = eachWeekendOfInterval({
        start: startOfDay(Date.now()),
        end: add(startOfDay(Date.now()), { days: 7 })
      })
      if (isSameDay(startOfDay(Date.now()), this.selectedDate)) {
        if (v === 'today') {
          return 'info'
        } else {
          return 'normal'
        }
      }
      if (isSameDay(startOfTomorrow(), this.selectedDate)) {
        if (v === 'tomorrow') {
          return 'info'
        } else {
          return 'normal'
        }
      }
      if (isSameDay(weekend[0], this.selectedDate)) {
        if (v === 'saturday') {
          return 'info'
        } else {
          return 'normal'
        }
      }
      return 'normal'
    },
    async clickRace (v) {
      this.clickedRace = v.id
      this.selectRace({ id: v.id })
    },
    selectNextToGo () {
      this.setDate(startOfDay(Date.now()))
      const r = this.getNextToGo
      if (r) {
        this.selectRace({ id: r.id })
      }
    },
    getUnixTime,
    async clickNextToGoRace (v) {
      await this.setDate(startOfDay(Date.now()))
      this.setRaceLoading({ loading: true })
      await this.reFetchRaces()
      this.selectRace({
        id: v.id
      })
      this.selectNavRace({
        id: v.id
      })
      this.setThisRaceOnLoad(2)
    },
    async selectToday () {
      this.loadingToggleAXN({ isLoading: true })
      try {
        await this.setDate(startOfDay(Date.now()))
        await this.reFetchRaces()
        this.loadingToggleAXN({ isLoading: false })
      } catch (err) {
        this.loadingToggleAXN({ isLoading: false })
      }
    },
    async selectTomorrow () {
      this.loadingToggleAXN({ isLoading: true })
      try {
        await this.setDate(startOfTomorrow())
        await this.reFetchRaces()
        this.loadingToggleAXN({ isLoading: false })
      } catch (err) {
        this.loadingToggleAXN({ isLoading: false })
      }
    },
    async selectSaturday () {
      this.loadingToggleAXN({ isLoading: true })
      try {
        const weekend = eachWeekendOfInterval({
          start: startOfDay(Date.now()),
          end: add(startOfDay(Date.now()), { days: 7 })
        })
        await this.setDate(weekend[0])
        await this.reFetchRaces()
        this.loadingToggleAXN({ isLoading: false })
      } catch (err) {
        this.loadingToggleAXN({ isLoading: false })
      }
    },
    getHoverText (item) {
      return `R${item.number} ${item.meeting.track.name} - ${item.name} \nDist: ${item.distance} | Cond: ${item.trackCondition}`
    },
    setFiltersOpen (state) {
      this.filtersOpen = state
    },
    truncateTxtUI ({ txt, max }) {
      return truncateTxt({ txt, max })
    }
  }
}
</script>

<style scoped>
.msErrorButton {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}
.raceNavigation,
.fixesOddsAvailable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.acMode .theme--light.v-data-table {
  color: #000;
  font-weight: 500;
}
.acMode .theme--light.v-data-table tbody tr:not(:last-child) td:not(.v-data-table__mobile-row) {
  border-bottom: 1px solid #333;
}
.stdMode .theme--light.v-data-table {
  color: #000;
  font-weight: 300;
}
.betsPlaced {
  color: orange;
  font-weight: bold;
}
.raceDayName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}
.raceDayTime {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 55px;
}
.raceDayNavigation {
  z-index: 5;
  padding-bottom: 30px;
}
.selected {
  background: #4caf50;
}
.scheduleLabels .v-input__slot .v-label {
  font-size: 12px;
  line-height: 13px !important;
}
.scheduleLabels {
  margin-top: 4px;
  padding-top: 0px;
  margin-bottom: 10px;
}
.v-data-table__wrapper .raceDayTable {
  overflow-x: hidden;
}
.v-navigation-drawer__border {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 0px;
}
.v-data-table table {
  min-width: 100%;
  border-spacing: 0;
}
.tooltipTextRaceNavigationPreformatted {
  font-family: 'Roboto', sans-serif;
}
.raceDayTable tbody td:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0);
}
.v-input__icon--prepend .v-icon {
  font-size: 16px;
}
.v-input__prepend-outer {
  margin-right: 0px !important;
}
.ma-1 {
  padding: 0 2.8888888889px !important;
}

.calendar {
  width: 110px !important;
}

.todayButton, .saturdayButton, .tomorrowButton {
  position: relative;
  top: 5px;
}

::v-deep .formattedDate i {
  font-size: 20px !important;
}
::v-deep .v-text-field input {
  padding: 0 !important;
  margin-top: 5px;
  border-style: none !important;
  width: 10px !important;
}

::v-deep .v-input__slot::before {
  /* border-style: none !important; */
  transition: none !important;
  transition-timing-function: none !important;
}

.formattedDate {
  padding-top: 0px;
  font-size: 12px !important;
  margin-bottom: -10px !important;
  margin-top: 0px;
}

label {
  height: 10px;
}

.raceAlertCheckbox {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.raceAlertTD {
  padding: 0 5px !important;
}

.v-input__slot {
  align-items: center;
  justify-content: center;
}

.titleBar-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.racesGroupByMeeting .v-data-table-header__icon {
  opacity: 1;
}

.tdRacesGroupByMeeting {
  padding: 0px 3px !important;
}

.v-text-field input {
  padding: 0 !important;
}

.v-input__append-outer,
.v-input__prepend-outer {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.auto-hide-checkbox {
  margin-top: -5px;
  color: red;
}

.nav-date {
  background-color: white !important;
  position: fixed;
  width: calc(100% - 26px);
  z-index: 10;
}

.nav-tabel {
  position: relative;
  top: 50px;
  margin-bottom: 80px;
}
</style>
