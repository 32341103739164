<template>
  <div :class="'logoWidth' + bookieCode" class="tooltip bookieHead" style="min-width:42px;width: 42px;vertical-align: bottom;">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <img v-if="bookieCode !== 'SPC'" :style="!editMode ? 'margin-top:-9px;height:28px; width:28px;' : 'height:38px; width:38px'" :src="logoFromPriceCode(bookieCode)" class="opacityHover tooltip center" v-bind="attrs" v-on="on">
        <div v-else style="height:56px;width:42px;"></div>
      </template>
    </v-tooltip>
    <div v-if="bookieCode !== 'SPC'" class="priceTypeHead">{{columnHeader}}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RaceBookmakers',
  data: () => ({}),
  props: {
    bookieCode: String,
    columnHeader: String,
    columnType: String,
    editMode: Boolean
  },
  computed: {
    ...mapGetters(['getSelectedRace']),
    meeting: function () {
      return this.getSelectedRace()?.meeting
    }
  },
  methods: {
    logoFromPriceCode (code) {
      return 'https://betmakers.com/img/circle_logos/' + code + '.png'
    }
  }
}
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.logoWidth {
  width: 55px;
  text-align: center;
}
.logo-WH {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 450px;
  background-size: 90px;
  margin: 0 auto;
}
/* RealBookie*/
.logo-RB3 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 45px 0px;
  background-size: 90px;
  margin: 0 auto;
}
/* BetDeluxe*/
.logo-BD2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 45px -180px;
  background-size: 90px;
  margin: 0 auto;
}
/* Dave Dwyer*/
.logo-DD {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 45px -585px;
  background-size: 90px;
  margin: 0 auto;
}
/* Tab Touch WA*/
.logo-OB {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 45px;
  background-size: 90px;
  margin: 0 auto;
}
/* Topsport*/
.logo-TS2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 360px;
  background-size: 90px;
  margin: 0 auto;
}
/* Sportsbet*/
.logo-SB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 540px;
  background-size: 90px;
  margin: 0 auto;
}
/* Betfair*/
.logo-BF {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -225px;
  background-size: 90px;
  margin: 0 auto;
}
/* Ladbrokes AU*/
.logo-LB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -495px;
  background-size: 90px;
  margin: 0 auto;
}
/* Topbetta with a twisit*/
.logo-TB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 315px;
  background-size: 90px;
  margin: 0 auto;
}
/* Official Price*/
.logo-OP {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -585px;
  background-size: 90px;
  margin: 0 auto;
}
/* BetVictor*/
.logo-BV {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -270px;
  background-size: 90px;
  margin: 0 auto;
}
/* SkyBet*/
.logo-SB6 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 585px;
  background-size: 90px;
  margin: 0 auto;
}
/* Coral*/
.logo-CB3 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -405px;
  background-size: 90px;
  margin: 0 auto;
}
/* Boyle Sports*/
.logo-BS3 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -360px;
  background-size: 90px;
  margin: 0 auto;
}
/* RaceBets*/
.logo-RB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 630px;
  background-size: 90px;
  margin: 0 auto;
}
/* BlueBet*/
.logo-BB3 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -315px;
  background-size: 90px;
  margin: 0 auto;
}
/* Unibet*/
.logo-UB {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 405px;
  background-size: 90px;
  margin: 0 auto;
}
/* Ladbrokes UK*/
.logo-LB2U {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 675px;
  background-size: 90px;
  margin: 0 auto;
}
/* Playup*/
.logo-CB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 45px -135px;
  background-size: 90px;
  margin: 0 auto;
}
/* Bet365*/
.logo-BT {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -90px;
  background-size: 90px;
  margin: 0 auto;
}
/* BetEasy*/
.logo-BE {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -180px;
  background-size: 90px;
  margin: 0 auto;
}
/* BoomBet*/
.logo-SB5 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 495px;
  background-size: 90px;
  margin: 0 auto;
}
/* GT*/
.logo-GT {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -450px;
  background-size: 90px;
  margin: 0 auto;
}
/* BestBookies*/
.logo-BB2 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -45px;
  background-size: 90px;
  margin: 0 auto;
}
/* BBet*/
.logo-BB4 {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 0px;
  background-size: 90px;
  margin: 0 auto;
}
/* BetDogs*/
.logo-BD {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -135px;
  background-size: 90px;
  margin: 0 auto;
}
/* TAB NZ*/
.logo-NZ {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 90px;
  background-size: 90px;
  margin: 0 auto;
}
/* TAB Sportsbet (TAB QLD)*/
.logo-TS_Q {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 180px;
  background-size: 90px;
  margin: 0 auto;
}
/* TAB (TAB QLD)*/
.logo-Q {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 180px;
  background-size: 90px;
  margin: 0 auto;
}
/* TAB Sportsbet (TAB VIC)*/
.logo-TS_V {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 270px;
  background-size: 90px;
  margin: 0 auto;
}
/* TAB (TAB VIC)*/
.logo-V {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 270px;
  background-size: 90px;
  margin: 0 auto;
}
/* TAB NSW*/
.logo-N {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 225px;
  background-size: 90px;
  margin: 0 auto;
}
/* TAB NSW Sportsbet*/
.logo-TS_N {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 225px;
  background-size: 90px;
  margin: 0 auto;
}
/* Neds*/
.logo-ND {
  width: 45px;
  height: 45px;
  background: url('~@/assets/b_logos/DO_BookieLogosSprite_icons.png') 0 -540px;
  background-size: 90px;
  margin: 0 auto;
}
.priceTypeHead {
  width: 90%;
  background: #000;
  color: #fff;
  font-size: 10px;
  text-align: center;
  margin: 0 auto;
  margin-top: 2.5px;
  margin-bottom:2px;
}
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: #666;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 10px;
  z-index:202;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 105%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.bookieHead {
  background:#fff !important;
}
</style>
