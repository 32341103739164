<script>
import { mapState } from 'vuex'
import { SlickList, SlickItem } from 'vue-slicksort'
import ChromeTabsItem from './ChromeTabsItem'

export default {
  name: 'ChromeTabs',
  props: {
    onPinTab: {
      type: Function,
      requried: true
    },
    onCloseTab: {
      type: Function,
      requried: true
    },
    onSelectTab: {
      type: Function,
      requried: true
    },
    onDragEnd: {
      type: Function,
      requried: true
    }
  },
  components: {
    ChromeTabsItem,
    SlickItem,
    SlickList
  },
  mounted () {
  },
  data () {
    return {
      items: []
    }
  },
  watch: {
    chromeGrouping (newState) {
    },
    chromeTabs (newState) {
      this.items = [...newState]
    }
  },
  computed: {
    ...mapState(['currentVenue', 'chromeGrouping', 'chromeTabs'])
  },
  methods: {
    returnMDIIconBasedOnVenueName ({ tab }) {
      if (tab.venueName === 'Blackbook') {
        return 'mdi-book-open-variant'
      } else if (tab.venueName === 'Horse Search') {
        return 'mdi-horse-variant-fast'
      } else {
        return 'mdi-map-marker'
      }
    }
  }
}
// mdiHorseHuman
// horse-variant-fast
</script>
<template>
  <div class="chrome-tabs">
    <SlickList @sort-end="onDragEnd({ event: $event, tabs: items })" :distance="5" v-model="items" lockAxis="x" axis="x" class="chrome-tabs__content d-flex">
      <SlickItem v-for="(tab, index) in items" :key="tab.venueName" :index="index" :item="tab.venueName" :active="tab.venueName === currentVenue.name" class="chrome-tabs__item cursor">
        <ChromeTabsItem :icon="returnMDIIconBasedOnVenueName({ tab })" :isActive="tab.venueName === currentVenue.name" :isBlackbook="tab.venueName === 'Blackbook' && currentVenue.name === 'Blackbook'" :isHorseSearch="tab.venueName === 'Horse Search'  && currentVenue.name === 'Horse Search'" :onPinTab="onPinTab" :onCloseTab="onCloseTab" :onSelectTab="onSelectTab" :tab="tab" />
      </SlickItem>
    </SlickList>
  </div>
</template>

<style>
.chrome-tabs {
  box-sizing: border-box;
  position: relative;
  height: 46px;
  padding: 8px 3px 4px 3px;
  background: #c8e6c9;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  width: calc(100% - 115px);
  font-size: 12px;
}

.chrome-tabs__content {
}

.chrome-tabs__item {
  width: 258px;
  position: relative;
  bottom: -4px;
}

.chrome-tabs__item {

}

.chrome-tabs__item:not(:first-child) {
  margin-left: -20px;
}

.chrome-tabs__header {
  position: absolute;
  z-index: 10;
  width: calc(100% - 50px);
  top: 8px;
  left: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chrome-tabs__header-title {
  position: relative;
  z-index: 5;
  width: 100%;
  top: -15px;
  left: 22px;
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.chrome-tabs__close:hover {
  background-color: rgba(55, 71, 95, 0.1);
  transition: 0.3s;
  border-radius: 50%;
}

.chrome-tabs__close {
  position: absolute;
  right: 18px;
  top: 6px;
  z-index: 11;
  padding: 3px;
  align-items: center;
}

.chrome-tabs__pin {
  background-color: black;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  top: -8px;
  z-index: 100;
}

.chrome-tabs__pin:hover {
  background-color: #4caf50;
  transition: 0.3s;
}

.chrome-tabs__pin-off {
  background-color: #4caf50;
}

.chrome-tabs__background {
  position: absolute;
}

#chrome-tabs__background-svg {
  width: 100%;
  height: 100%;
}

.chrome-tabs__item-geometry {
  width: 100%;
  height: 100%;
}

.chrome-tabs__item-geometry {
  fill: white;
}

.chrome-tabs .chrome-tabs__item:not([active]) svg {
  transition: opacity 0.2s ease;
  opacity: 0;
}

.chrome-tabs .chrome-tabs__item:hover:not([active]) svg {
  transition: opacity 0.2s ease;
  opacity: 0.65;
}

.chrome-tabs__item-geometry[blackbook] {
  fill: black !important;
}

.chrome-tabs__item-geometry[horse-search] {
  fill: #37474f !important;
}

#chrome-tabs__background-svg {}

.chrome-tabs__header-title[blackbook] {
  color: white !important;
}

.chrome-tabs__header-title[horse-search] {
  color: white !important;
}

.chrome-tabs__blackbook-icon[blackbook] {
  color: white !important;
}

.chrome-tabs__blackbook-icon[horse-search] {
  color: white !important;
}
</style>
