import { startOfDay } from 'date-fns'

export const getDefaultStateMain = () => {
  return {
    appErrors: { error: '', type: '' },
    isLoading: false,
    isAppMounted: false,
    isInitalRaceLoad: true,
    smartTabs: {},
    activeRunners: {},
    upcomingRaces: [],
    chromeTabs: [],
    pindedTabs: [],
    chromeRemovedItem: {},
    currentVenue: { name: '', raceId: '', date: '' },
    chromeGrouping: [],
    runnerTabs: [],
    runnerTabsAZList: [],
    blackbookChrome: [],
    horseSearchChrome: [],
    runnerBlackbook: [],
    jockeyBlackbook: [],
    trainerBlackbook: [],
    runnerBlackbookComments: {},
    jockeyBlackbookComments: {},
    trainerBlackbookComments: {},
    globalRunnerNotes: {},
    singularRaces: [],
    selectedRace: {
      id: null,
      MeetingID: null,
      RaceNumber: null
    },
    selectedNavRace: {
      id: null,
      MeetingID: null,
      RaceNumber: null
    },
    meetings: {},
    races: {},
    todaysRaces: {},
    detailedRaces: {},
    selectedDate: startOfDay(Date.now()),
    todaysDate: startOfDay(Date.now()),
    racesGroupByMeeting: false,
    azListRunners: {
      A: [],
      B: [],
      C: [],
      D: [],
      E: [],
      F: [],
      G: [],
      H: [],
      I: [],
      J: [],
      K: [],
      L: [],
      M: [],
      N: [],
      O: [],
      P: [],
      Q: [],
      R: [],
      S: [],
      T: [],
      U: [],
      V: [],
      W: [],
      X: [],
      Y: [],
      Z: []
    },
    azListJockeysDrivers: {
      A: [],
      B: [],
      C: [],
      D: [],
      E: [],
      F: [],
      G: [],
      H: [],
      I: [],
      J: [],
      K: [],
      L: [],
      M: [],
      N: [],
      O: [],
      P: [],
      Q: [],
      R: [],
      S: [],
      T: [],
      U: [],
      V: [],
      W: [],
      X: [],
      Y: [],
      Z: []
    },
    azListTrainers: {
      A: [],
      B: [],
      C: [],
      D: [],
      E: [],
      F: [],
      G: [],
      H: [],
      I: [],
      J: [],
      K: [],
      L: [],
      M: [],
      N: [],
      O: [],
      P: [],
      Q: [],
      R: [],
      S: [],
      T: [],
      U: [],
      V: [],
      W: [],
      X: [],
      Y: [],
      Z: []
    },
    azSearchResults: [],
    azList: [],
    loadAZListData: false,
    singleRunner: [],
    raceFilters: {
      trackConditions: ['Any'],
      finishingPosition: 'All',
      track: 'All',
      spell: 'All',
      margin: 12.5,
      finishPositionOrMargin: false,
      distance: { from: '', to: '' },
      classes: ['all']
    },
    runnerFormHistoryFiltered: {},
    isDrawer: false,
    isAutoHide: true,
    sortFieldsBy: 'tabNo',
    defaultBestPriceBookies: ['BB4', 'BT', 'SB2', 'TS2', 'TS_V', 'TS_N', 'TS_Q', 'BD2', 'LB2', 'ND', 'UB', 'CB2', 'BB2', 'SB5', 'DD'],
    bestPriceBookiesArrays: [],
    bestOddsArray: [],
    flucsRace: {},
    raceVenueNote: '',
    isAppLoading: false,
    last50Rides: {},
    marketRange: 5.0,
    marketPercentage: 100,
    oddsToUse: 'calculated',
    oddsFormat: 'decimal',
    ratingToUse: 'dry',
    raceSubscription: null,
    singularRunners: {},
    horseSearchRes: [],
    competitorsNotesFromDB: {},
    myCompetitorPricesFromDB: {},
    myCompetitorRatingsFromDB: {},
    windowDimension: {
      width: 0,
      height: 0
    },
    prices: {},
    isReauthorize: false,
    meetingSelected: {},
    isDualAcceptances: false,
    dualAcceptances: [],
    dualAcceptancesHasLoaded: false
  }
}

export const stateMain = getDefaultStateMain()
