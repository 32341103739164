import Vue from 'vue'
import { startOfDay, isSameDay, parseISO, format, formatISO } from 'date-fns'
import { datadogLogs } from '@datadog/browser-logs'
import { zonedTimeToUtc } from 'date-fns-tz'
import axios from 'axios'
import gql from 'graphql-tag'
import { meetingsDated, queryRace, meetingsDatedAz, speedMapQuery, subscribePricesQuery, last50RidesJockeyQuery, last50RidesTrainerQuery, getCurrentRunnerGlobalIdQuery, horseSearchQuery, raceRunnerFormQuery, competitorQuery, meetingQuery, subscribeRaceUpdatesQuery, subscribeResultsQuery } from '../../common/queries'
import { setUserData, batchFetch, fetchSpeedMap, setSpeedMap, resetSpeedMap, setUserFilter, deleteBlackbookEntry, insertBlackbookEntry } from '../../services/userConfig'
import { apolloClient } from '../../vue-apollo'
import { isEmpty, sortCompetitors } from '../../common/methods'
import { DATA_BASE_KEY_LIST, ALL_BOOKIES_CODES, CUSTOM_CHROME_TAB } from '../../common/constants'
import runnerDummy from '../../common/dummy.json'

let bookieCount = 0

const actions = {
  setAppMounted (context, { boolean }) {
    context.commit('updateAppMount', { boolean })
  },
  apiErrosAXN (context, { error, type }) {
    context.commit('updateApiErrors', { error, type })
    setTimeout(() => {
      context.commit('updateApiErrors', { error: '', type })
    }, 2000)
  },
  loadingToggleAXN (context, { isLoading }) {
    context.commit('updateLoadingToggle', { isLoading })
  },
  deleteTab (context, { competitor }) {
    const tabs = context.state.runnerTabs.filter(tab => tab !== competitor)
    context.commit('updateRunnerTabs', { tabs })
  },
  smartTriggerTabsAXN (context, { ...tabPositions }) {
    const { smartTabs } = context.state
    context.commit('updateSmartTriggerTabs', { smartTabs: { ...smartTabs, ...tabPositions } })
  },
  addToRunnerTabsFromAZList (context, { co, tabType }) {
    context.commit('setToRunnerTabsFromAZList', { co, tabType })
  },
  clearRaceFieldTabPosition (context, { venueName }) {
    context.commit('clearTabPosition', { venueName })
  },
  async clearChromeTabs (context) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']
    const { chromeTabs, chromeGrouping } = context.state

    // Filter Chrome Tabs - Only pinned tabs are persisted
    const chromeTabsPinned = []
    const chromeTabsFiltered = chromeTabs.filter(tab => {
      if (tab.isPinned) {
        chromeTabsPinned.push(tab.venueName) // Pushed pined chrome tabs
        return tab.isPinned
      }
      return false
    })
    context.commit('updateNewChromeTab', { newTab: chromeTabsFiltered, isNew: false })

    // Filter Chrome Groupoing - Only pinned tabs are persisted
    const chromeGroupingFiltered = chromeGrouping.filter(venue => chromeTabsPinned.includes(Object.keys(venue)[0]))
    context.commit('updateRunnerTabGroup', { venue: chromeGroupingFiltered, isNew: false })
    if (!isTrial) {
      setUserData('chromeTabs', chromeTabsFiltered, config)
      setUserData('chromeGrouping', chromeGroupingFiltered, config)
    }
  },
  async addActiveRunners (context, { runner }) {
    const { currentVenue } = context.state
    if (CUSTOM_CHROME_TAB.includes(currentVenue.name)) {
      await context.dispatch('fetchActiveRunnerStats', { runner })
    } else {
      const race = { ...context.getters.getSelectedRace() }
      const competitor = race.competitors.find(co => co.runner.id === runner.runnerId)
      context.commit('updateActiveRunners', { competitor: { ...competitor, ...runner } })
    }
  },
  removeActiveRunners (context, { runner }) {
    const activeRunners = { ...context.state.activeRunners }
    const concatVenueName = context.state.currentVenue.name.split(' ').join('')
    const cmbinedId = `${concatVenueName}-${runner.runnerId}-${runner.tabType}`
    if (!isEmpty(activeRunners[cmbinedId])) {
      delete activeRunners[cmbinedId]
      context.commit('setActiveRunners', { competitors: activeRunners })
    }
  },
  async fetchActiveRunnerStats (context, { runner }) {
    context.commit('updateLoadingToggle', { isLoading: true })

    try {
      const competitorHistoryREsults = await apolloClient.query({
        query: gql`${raceRunnerFormQuery({ id: runner.runnerId })}`
      })

      const runnerId = runner.runnerId
      const config = context.getters['account/authConfig']

      const keysToBeFetched = [`competitorsNotes-${runnerId}`, `competitorPrices-${runnerId}`, `competitorRatings-${runnerId}`]
      const userDataResultsCompNotes = await batchFetch(keysToBeFetched, config)

      if (!isEmpty(userDataResultsCompNotes)) {
        if (userDataResultsCompNotes[`competitorsNotes-${runnerId}`]) {
          const competitorsNotes = JSON.parse(userDataResultsCompNotes[`competitorsNotes-${runnerId}`]) || []
          context.commit('saveCompetitorNotes', { competitorObj: competitorsNotes, runnerId })
        }
      }

      const formHistory = competitorHistoryREsults?.data?.raceRunner?.formHistory ?? []
      const runnerIdentifier = competitorHistoryREsults?.data?.raceRunner?.runnerIdentifier ?? {}
      const runnerData = competitorHistoryREsults?.data?.raceRunner?.runnerData ?? {}
      const runnerStatistics = competitorHistoryREsults?.data?.raceRunner?.statistics ?? {}
      const competitor = { runner: { id: runner.runnerId }, dob: runnerIdentifier.dob, owners: runnerData.owners, sex: runnerData.sex, name: runnerIdentifier.name, dam: runnerIdentifier.dam, sire: runnerIdentifier.sire, countryOfBirth: runnerIdentifier.countryOfBirth }
      const results = { ...competitor, ...runner, formHistory, runnerStatistics }

      context.commit('updateActiveRunners', { competitor: results })
      context.commit('updateLoadingToggle', { isLoading: false })
    } catch (error) {
      context.commit('updateLoadingToggle', { isLoading: false })
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  async addRunnerToTabGroup (context, { venue, isNew }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const chromeGroupingCopy = [...context.state.chromeGrouping]

    if (isNew) {
      chromeGroupingCopy.push(venue)
      context.commit('updateRunnerTabGroup', { venue, isNew, config })

      if (!isTrial) {
        setUserData('chromeGrouping', chromeGroupingCopy, config)
      }
    } else {
      context.commit('updateRunnerTabGroup', { venue: chromeGroupingCopy, isNew: false })

      if (!isTrial) {
        setUserData('chromeGrouping', chromeGroupingCopy, config)
      }
    }
  },
  async editChromeTabAXN (context, { newTab }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']
    const { state } = context
    const { chromeTabs } = state
    const chromeTabsCopy = [...chromeTabs]

    const newTabs = chromeTabsCopy.map(tab => {
      if (tab.isPhantom) return newTab // Replace previuos phantom tab with current phantom tab
      return tab
    })

    context.commit('updateNewChromeTab', { newTab: newTabs, isNew: false })
    if (!isTrial) {
      const filtered = newTabs.filter(tab => !tab.isPhantom)
      setUserData('chromeTabs', filtered, config)
    }
  },
  async addNewChromeTabAXN (context, { newTab, isNew }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']
    const chromeTabsCopy = [...context.state.chromeTabs]
    const isDuplicate = chromeTabsCopy.filter(tab => tab.key === newTab.key)[0] // Check fo duplicates

    if (isNew && isEmpty(isDuplicate)) {
      // Add new if there is no duplicates and `isNew` truthy
      chromeTabsCopy.push(newTab)
      context.commit('updateNewChromeTab', { newTab, isNew: true })
      if (!isTrial) {
        const filtered = chromeTabsCopy.filter(tab => !tab.isPhantom) // Filter out phantom tabs...
        setUserData('chromeTabs', filtered, config)
      }
    } else {
      // If Duplicate is found
      chromeTabsCopy.forEach((tab, index) => {
        if (tab.key === newTab.key) {
          chromeTabsCopy[index] = newTab // Replace with new tab
        }
      })

      context.commit('updateNewChromeTab', { newTab: chromeTabsCopy, isNew: false })
      if (!isTrial) {
        const filtered = chromeTabsCopy.filter(tab => !tab.isPhantom) // Filter out phantom tabs...
        setUserData('chromeTabs', filtered, config)
      }
    }
  },
  async deleteRaceFieldSelectedRunnerFromTabsAXN (context, { currentRunner }) {
    if (currentRunner.tabType === 'jockeyTab') {
      Vue.delete(context.state.last50Rides, currentRunner.jockey)
    } else if (currentRunner.tabType === 'trainerTab') {
      Vue.delete(context.state.last50Rides, currentRunner.trainer)
    }

    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const { state } = context
    const { chromeGrouping, currentVenue } = state
    const chromeGroupingCopy = [...chromeGrouping]

    const index = chromeGroupingCopy.findIndex(race => {
      return Object.keys(race)[0] === currentVenue.name
    })

    const runnersFilted = chromeGroupingCopy[index][currentVenue.name].filter(co => {
      if (co.runnerId === currentRunner.runnerId && co.tabType === currentRunner.tabType) {
        return currentRunner.tabType !== co.tabType
      } else {
        return co.runnerId !== currentRunner.runnerId
      }
    })

    chromeGroupingCopy[index][currentVenue.name] = runnersFilted

    context.commit('updateRunnerTabGroup', { venue: chromeGroupingCopy, isNew: false })
    if (!isTrial) setUserData('chromeGrouping', chromeGroupingCopy, config)
  },
  async deleteSelectedChromeTabAXN (context, { tab: tabOuter }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const { state } = context
    // const { chromeGrouping, chromeTabs, currentVenue } = state
    const { chromeGrouping, chromeTabs } = state
    const chromeGroupingCopy = [...chromeGrouping]

    const filterChromeGrouping = chromeGroupingCopy.filter(race => {
      return Object.keys(race)[0] !== tabOuter.venueName
    })

    const filterChromeTab = chromeTabs.filter(tab => {
      return tab.venueName !== tabOuter.venueName
    })

    context.commit('updateNewChromeTab', { newTab: filterChromeTab, isNew: false })
    context.commit('updateRunnerTabGroup', { venue: filterChromeGrouping, isNew: false })
    if (!isTrial) {
      setUserData('chromeTabs', filterChromeTab, config)
      setUserData('chromeGrouping', filterChromeGrouping, config)
    }
  },
  windowDimensionAXN (context, { width, height }) {
    context.commit('updateWindowDimension', { width, height })
  },
  setCurrentVenue (context, { venue }) {
    const config = context.getters['account/authConfig']
    const isTrial = context.getters['account/getIsTrial']
    context.commit('updateCurentVenue', { venue, config, isTrial })
    if (!isTrial) localStorage.setItem('currentVenue', JSON.stringify(venue))
  },
  async setChromeTabs (context, { chromeTabs, isNew }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    context.commit('updateNewChromeTab', { newTab: chromeTabs, isNew })
    if (!isTrial) setUserData('chromeTabs', chromeTabs, config)
  },
  addTabUpcomingRaces (context, { upcoming }) {
    context.commit('updateUpcomingRaces', { upcoming })
  },
  triggerBlackbookChrome (context, { blackbook }) {
    context.commit('updateBlackbookChrome', { blackbook })
  },
  triggerHorseSearchChrome (context, { horseSearch }) {
    context.commit('updateHorseSearchChrome', { horseSearch })
  },
  async setBlackbookComments (context, { blackbook, newBlackbookComments }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const { runnerBlackbookComments, jockeyBlackbookComments, trainerBlackbookComments } = context.state

    if (blackbook.tabType === 'runnerTab') {
      const comments = { ...runnerBlackbookComments, ...newBlackbookComments }
      context.commit('updateRunnerBlackbookComments', { comments })
      if (!isTrial) setUserData('runnerBlackbookComments', comments, config)
    } else if (blackbook.tabType === 'jockeyTab') {
      const comments = { ...jockeyBlackbookComments, ...newBlackbookComments }
      context.commit('updateJockeyBlackbookComments', { comments })
      if (!isTrial) setUserData('jockeyBlackbookComments', comments, config)
    } else if (blackbook.tabType === 'trainerTab') {
      const comments = { ...trainerBlackbookComments, ...newBlackbookComments }
      context.commit('updateTrainerBlackbookComments', { comments })
      if (!isTrial) setUserData('trainerBlackbookComments', comments, config)
    }
  },
  async addToBlackBook (context, { blackbook }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    if (blackbook.tabType === 'runnerTab') {
      context.commit('updateRunnerBlackbook', { blackbook, initialLoad: false })
      // if (!isTrial) setUserData('runnerBlackbook', context.state.runnerBlackbook, config)
      // if (!isTrial) setUserData('runnerBlackbook', [blackbook], config)
      if (!isTrial) insertBlackbookEntry(blackbook.tabType, blackbook.runnerId, blackbook.selectedTabTypeName, blackbook.raceNumber, blackbook.meetingId, blackbook.meetingType, 'runnerBlackbook', config)
    } else if (blackbook.tabType === 'jockeyTab') {
      context.commit('updateJockeyBlackbook', { blackbook, initialLoad: false })
      // if (!isTrial) setUserData('jockeyBlackbook', context.state.jockeyBlackbook, config)
      // if (!isTrial) setUserData('jockeyBlackbook', [blackbook], config)
      if (!isTrial) insertBlackbookEntry(blackbook.tabType, blackbook.runnerId, blackbook.selectedTabTypeName, blackbook.raceNumber, blackbook.meetingId, blackbook.meetingType, 'jockeyBlackbook', config)
    } else if (blackbook.tabType === 'trainerTab') {
      context.commit('updateTrainerBlackbook', { blackbook, initialLoad: false })
      // if (!isTrial) setUserData('trainerBlackbook', context.state.trainerBlackbook, config)
      // if (!isTrial) setUserData('trainerBlackbook', [blackbook], config)
      if (!isTrial) insertBlackbookEntry(blackbook.tabType, blackbook.runnerId, blackbook.selectedTabTypeName, blackbook.raceNumber, blackbook.meetingId, blackbook.meetingType, 'trainerBlackbook', config)
    }
  },
  async deleteRunnerFromBlackbook (context, { blackbook }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const runnerBlackbook = context.state.runnerBlackbook.filter(co => `${co.runnerId}-${co.tabType}` !== `${blackbook.runnerId}-${blackbook.tabType}`)
    // const runnerBlackbook = [blackbook]

    const runnerComments = { ...context.state.runnerBlackbookComments }
    delete runnerComments[blackbook.runnerId]

    context.commit('removeRunnerBlackbook', { runnerBlackbook })
    context.commit('updateRunnerBlackbookComments', { comments: runnerComments })

    if (!isTrial) {
      // setUserData('runnerBlackbook', runnerBlackbook, config)
      deleteBlackbookEntry(blackbook.runnerId, 'runnerBlackbook', config)
      setUserData('runnerBlackbookComments', runnerComments, config)
    }
  },
  async deleteJockeyFromBlackbook (context, { blackbook }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const jockeyBlackbook = context.state.jockeyBlackbook.filter(co => `${co.runnerId}-${co.tabType}` !== `${blackbook.runnerId}-${blackbook.tabType}`)
    // const jockeyBlackbook = [blackbook]

    const jockeyComments = { ...context.state.jockeyBlackbookComments }
    delete jockeyComments[blackbook.runnerId]

    context.commit('removeJockeyFromBlackbook', { jockeyBlackbook })
    context.commit('updateJockeyBlackbookComments', { comments: jockeyComments })

    if (!isTrial) {
      // setUserData('jockeyBlackbook', jockeyBlackbook, config)
      deleteBlackbookEntry(blackbook.runnerId, 'jockeyBlackbook', config)
      setUserData('jockeyBlackbookComments', jockeyComments, config)
    }
  },
  async deleteTrainerFromBlackbook (context, { blackbook }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const trainerBlackbook = context.state.trainerBlackbook.filter(co => `${co.runnerId}-${co.tabType}` !== `${blackbook.runnerId}-${blackbook.tabType}`)
    // const trainerBlackbook = [blackbook]

    const trainerComments = { ...context.state.trainerBlackbookComments }
    delete trainerComments[`${blackbook.runnerId}-${blackbook.tabType}`]

    context.commit('removeTrainerFromBlackbook', { trainerBlackbook })
    context.commit('updateTrainerBlackbookComments', { comments: trainerComments })

    if (!isTrial) {
      // setUserData('trainerBlackbook', trainerBlackbook, config)
      deleteBlackbookEntry(blackbook.runnerId, 'trainerBlackbook', config)
      setUserData('trainerBlackbookComments', trainerComments, config)
    }
  },
  async multiDeleteRunnerFromBlackbook (context, { blackbooks, runnersToDelete }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    context.commit('removeRunnerBlackbook', { runnerBlackbook: blackbooks })
    // if (!isTrial) setUserData('runnerBlackbook', blackbooks, config)
    if (!isTrial) {
      runnersToDelete.forEach(r => {
        deleteBlackbookEntry(r.runnerId, 'runnerBlackbook', config)
      })
    }
  },
  async multiDeleteJockeyFromBlackbook (context, { blackbooks, jockeysToDelete }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    context.commit('removeJockeyFromBlackbook', { jockeyBlackbook: blackbooks })
    // if (!isTrial) setUserData('jockeyBlackbook', blackbooks, config)
    if (!isTrial) {
      jockeysToDelete.forEach(r => {
        deleteBlackbookEntry(r.runnerId, 'jockeyBlackbook', config)
      })
    }
  },
  async multiDeleteTrainerFromBlackbook (context, { blackbooks, trainersToDelete }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    context.commit('removeTrainerFromBlackbook', { trainerBlackbook: blackbooks })
    // if (!isTrial) setUserData('trainerBlackbook', blackbooks, config)
    if (!isTrial) {
      trainersToDelete.forEach(r => {
        deleteBlackbookEntry(r.runnerId, 'trainerBlackbook', config)
      })
    }
  },
  updateClocks (context) {
    context.commit('updateRaceCountdown')
  },
  selectRaceNav (context, v) {
    context.commit('setSelectRaceNav', v)
  },
  async fetchRaces (context) {
    // const config = context.getters['account/authConfig']
    const isTrial = context.getters['account/getIsTrial']

    // const racesGroupByMeeting = await getRacesGroupByMeeting(config)
    // context.commit('setRacesGroupByMeeting', { value: racesGroupByMeeting })
    const d = zonedTimeToUtc(startOfDay(context.state.selectedDate), 'GMT')
    // context.dispatch('account/updateAuthToken')
    context.commit('updateLoadingToggle', { isLoading: true })
    try {
      const date = JSON.stringify(d)
      const query = meetingsDated(date)
      const result = await apolloClient.query({
        query: gql`
          ${query}
        `
      })

      const meetings = isTrial ? [result.data.meetingsDated.filter(meeting => meeting.type === 'THOROUGHBRED')[0]] : result.data.meetingsDated.filter(meeting => meeting.type === 'THOROUGHBRED')
      context.commit('clearRaces', { isTrial })
      if (isSameDay(d, startOfDay(Date.now()))) {
        context.commit('clearTodaysRaces')
      }

      if (isTrial && isEmpty(meetings[0])) {
        return context.commit('updateLoadingToggle', { isLoading: false })
      }

      meetings.forEach(m => {
        m.races.forEach(r => {
          // if (r.status !== 'ABANDONED') {
          context.commit('updateRaceSummary', { race: r, meeting: m })
          // }
        })
      })

      const nextRace = context.getters.getNextToGo

      if (nextRace && nextRace.id) {
        const venue = { name: `${nextRace.meeting.track.name} R${nextRace.number}`, raceId: nextRace.id }

        context.commit('updateCurentVenue', { venue })

        // if (!isTrial) setUserData('currentVenue', venue, config)

        context.dispatch('selectRace', { id: nextRace.id })
      } else {
        const races = context.state.races

        const keys = Object.keys(races)

        const firstRace = races[keys[0]]

        if (firstRace.id) context.dispatch('selectRace', { id: firstRace.id })
      }
      context.commit('updateRaceCountdown')
      // context.dispatch('subscribeRaceUpdates', { date })
      // context.dispatch('subscribeResults')
      // context.dispatch('fetchWatchList')
      // context.dispatch('fetchMyRaces')
      // const dateMeetingSummary = format(context.state.selectedDateMeetingSummary, 'yyyy-MM-dd')
      // context.dispatch('fetchCustomShowHideMeetingRacesData', { id: dateMeetingSummary })
    } catch (error) {
      context.commit('updateLoadingToggle', { isLoading: false })
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  updateReloginAXN (context, { isReauthorize }) {
    context.commit('updateRelogin', { isReauthorize })
  },
  async reFetchRaces (context) {
    // const d = context.state.selectedDate
    const isTrial = context.getters['account/getIsTrial']
    const d = zonedTimeToUtc(startOfDay(context.state.selectedDate), 'GMT')
    // context.dispatch('account/updateAuthToken')
    try {
      const date = JSON.stringify(d)
      const query = meetingsDated(date)
      const result = await apolloClient.query({
        query: gql`
          ${query}
        `
      })
      const meetings = isTrial ? [result.data.meetingsDated.filter(meeting => meeting.type === 'THOROUGHBRED')[0]] : result.data.meetingsDated.filter(meeting => meeting.type === 'THOROUGHBRED')
      context.commit('clearRaces', { isTrial })
      if (isSameDay(d, startOfDay(Date.now()))) {
        context.commit('clearTodaysRaces')
      }

      if (isTrial && isEmpty(meetings[0])) {
        return context.commit('updateLoadingToggle', { isLoading: false })
      }

      meetings.forEach(m => {
        m.races.forEach(r => {
          // if (r.status !== 'ABANDONED') {
          context.commit('updateRaceSummary', { race: r, meeting: m })
          // }
        })
      })
      // context.dispatch('subscribeRaceUpdates', { date })
      // context.dispatch('subscribeResults')
    } catch (error) {
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  async fetchRace (context, { id }) {
    const isTrial = context.getters['account/getIsTrial'] // Check if user is running appp on trial mode or full release
    context.commit('updateLoadingToggle', { isLoading: true })
    context.commit('updateBestBookie', [])

    // const config = context.getters['account/authConfig']
    // if (!isTrial) setUserData('runnerBlackbook', [], config)

    // Restore previous chrome tab / race
    if (localStorage.currentVenue && context.state.isInitalRaceLoad) {
      const currentVenue = JSON.parse(localStorage.currentVenue)
      if (!isEmpty(currentVenue?.raceId)) id = currentVenue?.raceId
    }

    if (localStorage.currentVenue && context.state.isInitalRaceLoad) {
      const currentVenue = JSON.parse(localStorage.currentVenue)
      if (isEmpty(currentVenue?.raceId)) {
        context.dispatch('setCurrentVenue', { venue: { name: currentVenue.name, raceId: currentVenue.raceId } })
      }
    }

    try {
      const codes = ALL_BOOKIES_CODES.map(c => c.source)
      const raceIdStringified = JSON.stringify(id)
      const singularRaceQuery = queryRace(raceIdStringified, JSON.stringify(codes))
      const raceResult = await apolloClient.query({ query: gql`${singularRaceQuery}` })

      const { race } = await raceResult.data

      const selectedDate = context.getters.getSelectedDate
      if (!isSameDay(startOfDay(selectedDate), parseISO(race.meeting.date))) {
        await context.dispatch('fetchMeeting', { id: race.meeting.id })
      }

      const config = context.getters['account/authConfig']

      if (!isTrial) {
        let keysToBeFetched = [...DATA_BASE_KEY_LIST, `raceVenueNote-${id}`]

        if (!context.state.isInitalRaceLoad) {
          keysToBeFetched = [`raceVenueNote-${id}`]
        }

        const userDataResults = await batchFetch(keysToBeFetched, config)

        if (isEmpty(userDataResults)) {
          context.commit('updateUserRaceVenueRace', { raceVenueNote: {}, isRaceNoteEmpty: true })
        } else {
          context.commit('updateUserData', { userData: userDataResults })
        }

        if (!isEmpty(userDataResults)) {
          if (userDataResults[`raceVenueNote-${id}`]) {
            const parsed = JSON.parse(userDataResults[`raceVenueNote-${id}`]) || {}
            context.commit('updateUserRaceVenueRace', { ...parsed, isRaceNoteEmpty: false })
          }
        }

        for (const competitor of race.competitors) {
          const runnerId = competitor.runner.id
          keysToBeFetched = [`competitorsNotes-${runnerId}`, `competitorPrices-${runnerId}`, `competitorRatings-${runnerId}`]
          const userDataResultsCompNotes = await batchFetch(keysToBeFetched, config)

          if (!isEmpty(userDataResultsCompNotes)) {
            if (userDataResultsCompNotes[`competitorsNotes-${runnerId}`]) {
              const competitorsNotes = JSON.parse(userDataResultsCompNotes[`competitorsNotes-${runnerId}`]) || []
              context.commit('saveCompetitorNotes', { competitorObj: competitorsNotes, runnerId })
            }

            if (userDataResultsCompNotes[`competitorPrices-${runnerId}`]) {
              const competitorPrices = JSON.parse(userDataResultsCompNotes[`competitorPrices-${runnerId}`]) || []
              competitor.myPrice = parseFloat(competitorPrices[0].price)
              context.commit('saveCompetitorPrices', { competitorObj: competitorPrices, runnerId })
            }

            if (userDataResultsCompNotes[`competitorRatings-${runnerId}`]) {
              const competitorRatings = JSON.parse(userDataResultsCompNotes[`competitorRatings-${runnerId}`]) || []
              context.commit('saveCompetitorRatings', { competitorObj: competitorRatings, runnerId })
            }
          }
        }

        const dataFilteredToOdds = []
        for (const c of race.competitors) {
          dataFilteredToOdds.push({ ratingDry: c.rating.dry, ratingWet: c.rating.wet, scratched: c.scratched })
        }

        const calculationResult = await axios.post('api.public.Public/OddsCalculation', {
          competitors: dataFilteredToOdds,
          marketRange: context.state.marketRange,
          marketPercentage: context.state.marketPercentage,
          oddsToUse: context.state.oddsToUse,
          oddsFormat: context.state.oddsFormat,
          ratingToUse: context.state.ratingToUse
        }, config)

        const oddsCalculation = calculationResult.data.competitorOdds

        race.competitors.forEach((c, index) => {
          c.oddsToDisplay = oddsCalculation[index]?.oddsToDisplay ?? ''
        })
      }
      const prices = race.priceSets
      delete race.priceSets
      context.commit('updateArrayOfBookies', [])
      context.commit('updateDetailedRace', { race })
      context.commit('selectRace', { id })
      const date = JSON.stringify(context.state.selectedDate)
      if (!isTrial) {
        context.dispatch('subscribeRaceUpdates', { date })
        context.dispatch('subscribeResults')
      }

      // context.dispatch('fetchSavedRaceData', { id })
      context.commit('updateLoadingToggle', { isLoading: false })
      bookieCount = 0
      await context.state.defaultBestPriceBookies.forEach(b => {
        context.dispatch('fetchFlucs', b)
      })
      context.dispatch('handlePrices', { prices, id })
      // await context.dispatch('subscribePrices', id)
    } catch (error) {
      context.commit('updateLoadingToggle', { isLoading: false })
      context.dispatch('reloginInformTrigger', { error })
    }

    context.commit('updateInitalRaceLoad', { boolean: false })
  },
  async fetchFormHistory (context, { runnerId }) {
    const raceRunnerQuery = raceRunnerFormQuery({ id: runnerId })
    const runnerHistory = await apolloClient.query({
      query: gql`
        ${raceRunnerQuery}
      `
    })

    const competitor = {
      formHistory: runnerHistory?.data?.raceRunner?.formHistory ?? [],
      runnerStatistics: runnerHistory?.data?.raceRunner?.statistics ?? {}
    }

    return competitor
  },
  async fetchRaceRunner (context, { raceId }) {
    context.commit('updateLoadingToggle', { isLoading: true })

    try {
      const raceIdStringified = JSON.stringify(raceId)
      const codes = ALL_BOOKIES_CODES.map(c => c.source)
      const singularRaceQuery = queryRace(raceIdStringified, JSON.stringify(codes))
      const { data } = await apolloClient.query({ query: gql`${singularRaceQuery}` })
      context.commit('updateSingularRaces', { race: data.race })
      context.commit('updateLoadingToggle', { isLoading: false })
    } catch (err) {
      context.dispatch('apiErrosAXN', { error: 'Opps, something went wrong... please try again later.', type: 'error' })
      context.commit('updateLoadingToggle', { isLoading: false })
    }
  },
  updateRaceSummary (context, { meeting, race }) {
    context.commit('updateRaceSummary', { race, meeting })
  },
  async selectRace (context, { id }) {
    if (!id) return
    if (id === context.state.selectedRace.id) return
    await context.dispatch('fetchRace', { id })
    // context.commit('setMarketMoverBookie', { bookie: 'SB2' })
    // Vue.nextTick()
    //   .then(function () {
    //     context.dispatch('fetchRace', { id, columns: context.rootState.userConfig.selectedColumns })
    //   }
  },
  selectNavRace (context, { id }) {
    context.commit('selectNavRace', { id })
  },
  async setDate (context, d) {
    context.commit('setDate', d)
    // await context.dispatch('fetchRaces')
    // const date = format(d, 'yyyy-MM-dd')
    // await context.dispatch('fetchCustomShowHideMeetingRacesData', { id: date })
  },
  setRacesGroupByMeeting (context, { value }) {
    context.commit('setRacesGroupByMeeting', { value })
  },
  async fetchAzList (context) {
    context.dispatch('loadingToggleAXN', { isLoading: true })
    const isTrial = context.getters['account/getIsTrial']
    const today = new Date()
    const d = zonedTimeToUtc(startOfDay(today), 'GMT')
    let t = null
    if (isTrial) {
      t = zonedTimeToUtc(startOfDay(today), 'GMT')
    } else {
      t = zonedTimeToUtc(startOfDay(today.setDate(today.getDate() + 4)), 'GMT')
    }

    try {
      const date = JSON.stringify(d)
      const to = JSON.stringify(t)
      const query = meetingsDatedAz(date, to)

      const result = await apolloClient.query({
        query: gql`
          ${query}
        `
      })

      let meetings = []
      if (result.data !== null) {
        meetings = result.data.meetingsDated
      }

      context.commit('clearAzLists')
      meetings.forEach(m => {
        m.races.forEach(r => {
          r.competitors.forEach(c => {
            const formHistory = !isEmpty(c.formHistory) || []
            context.commit('addAzList', {
              meetingType: m.type,
              runner: c.name === '' ? '-' : c.name,
              jockeyDriver: c.jockey === '' ? '-' : c.jockey,
              trainer: c.trainer === '' ? '-' : c.trainer,
              race: format(parseISO(r.startTime), 'HH:mm') + ' ' + m.track.name + ' ' + r.number,
              date: format(parseISO(r.startTime), 'dd/MM/yyyy'),
              competitorNumber: c.tabNo,
              barrier: c.barrier,
              handicap: c.weightAllocated,
              raceID: r.id,
              startTime: r.startTime,
              status: r.status,
              raceName: m.track.name,
              raceNumber: r.number,
              formHistory,
              runnerId: c.runner.id,
              externalMeetingID: m.externalIDs[0]?.id,
              tabNo: c.tabNo,
              raceCountry: m.track?.country,
              scratched: c?.scratched,
              weight: c?.weightTotal
            })
          })
        })
      })
      if (context.getters.hasFeature('dualAcceptances')) context.commit('updateDualAcceptances', { azList: context.state.azList })
      context.commit('updateLoadAZListData', false)
      context.dispatch('loadingToggleAXN', { isLoading: false })
    } catch (error) {
      context.dispatch('loadingToggleAXN', { isLoading: false })
      // context.dispatch('apiErrosAXN', { error: 'Opps, something went wrong... please try again later.', type: 'error' })
    }
  },
  async getCompetitorAge (context, runnerId) {
    try {
      const competitorQry = await apolloClient.query({ query: gql`${competitorQuery({ id: runnerId })}` })
      const competitorAge = competitorQry?.data?.runner?.competitorsConnection?.competitors[0]?.age
      return competitorAge
    } catch (error) {
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  async openHorse (context, identity) {
    try {
      const globalId = JSON.stringify(identity.globalId)
      const raceRunnerResults = await apolloClient.query({ query: gql`${raceRunnerFormQuery({ id: identity.globalId })}` })
      const runnerId = identity.globalId
      const config = context.getters['account/authConfig']

      const keysToBeFetched = [`competitorsNotes-${runnerId}`, `competitorPrices-${runnerId}`, `competitorRatings-${runnerId}`]
      const userDataResultsCompNotes = await batchFetch(keysToBeFetched, config)

      if (!isEmpty(userDataResultsCompNotes)) {
        if (userDataResultsCompNotes[`competitorsNotes-${runnerId}`]) {
          const competitorsNotes = JSON.parse(userDataResultsCompNotes[`competitorsNotes-${runnerId}`]) || []
          context.commit('saveCompetitorNotes', { competitorObj: competitorsNotes, runnerId })
        }
      }

      if (!isEmpty(raceRunnerResults?.data?.raceRunner)) {
        const raceRunner = raceRunnerResults?.data?.raceRunner ?? {}
        const runnerName = raceRunner.formHistory[0].runner
        const runner = { runnerData: raceRunner.runnerData, runnerIdent: identity, runnerStatistics: raceRunner.statistics, formHistory: raceRunner?.data?.raceRunner?.formHistory || raceRunner.formHistory, name: runnerName, runner: { id: JSON.parse(globalId) } }
        await context.dispatch('triggerHorseSearchChrome', { horseSearch: { runner } })
      } else {
        const runnerName = 'BETA'
        const runner = { runnerData: {}, runnerIdent: identity, runnerStatistics: {}, formHistory: [], name: runnerName, runner: { id: globalId } }
        await context.dispatch('triggerHorseSearchChrome', { horseSearch: { runner } })
      }
    } catch (error) {
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  async searchHorse (context, name) {
    context.commit('clearHorseSearchResults')
    try {
      const runnerName = JSON.stringify(name)
      const horseSearch = horseSearchQuery(runnerName)
      const horseSearchQueryResult = await apolloClient.query({ query: gql`${horseSearch}` })
      const horseResults = await horseSearchQueryResult?.data?.searchRaceRunners
      context.commit('setHorseSearchResults', horseResults, name)
    } catch (error) {
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  searchAzLists (context, { search }) {
    context.commit('clearAzSearchResults')

    if (typeof search === 'undefined' || search === '') {
      context.commit('clearAzSearchResults')
    } else {
      context.state.azList.forEach(competitor => {
        if (
          competitor.runner.toLowerCase().includes(search.toLowerCase()) ||
          competitor.jockeyDriver.toLowerCase().includes(search.toLowerCase()) ||
          competitor.trainer.toLowerCase().includes(search.toLowerCase())
        ) {
          context.state.azSearchResults.push(competitor)
        }
      })
    }
  },
  async fetchSpeedMap (context, { id }) {
    const isTrial = context.getters['account/getIsTrial']
    if (!isTrial) {
      const config = context.getters['account/authConfig']
      const speedMap = await fetchSpeedMap(id, config)
      if (speedMap) {
        context.commit('setSpeedMap', speedMap)
      }
    }
  },
  async fetchSavedRaceData (context, { id }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']
    if (isTrial) {
      context.dispatch('fetchDefaultSpeedMap', { id })
    } else {
      try {
        const speedMap = await fetchSpeedMap(id, config)
        if (speedMap) {
          context.commit('setSpeedMap', speedMap)
        }
        if (!speedMap) {
          context.dispatch('fetchDefaultSpeedMap', { id })
        }
      } catch (error) {
        context.dispatch('reloginInformTrigger', { error })
      }
    }
  },
  async fetchDefaultSpeedMap (context, { id }) {
    const race = context.state.detailedRaces[id]
    if (race) {
      const defaultRunners = race?.competitors?.map(c => {
        return {
          tabNo: c.tabNo,
          name: c.name,
          barrier: c.barrier,
          speedmapPosition: c.speedmapPosition,
          scratched: c.scratched,
          jockey: c.jockey,
          weight: c.weightAllocated
        }
      })
      context.commit('setSpeedMap', { defaultRunners })
    } else {
      // context.dispatch('account/updateAuthToken')
      try {
        const raceID = JSON.stringify(id)
        const query = speedMapQuery(raceID)
        const result = await apolloClient.query({
          query: gql`
            ${query}
          `
        })
        context.commit('setSpeedMap', { defaultRunners: result.data.race.competitors })
      } catch (error) {
        context.dispatch('reloginInformTrigger', { error })
      }
    }
  },
  saveSpeedMap (context, { id, speedMap }) {
    const config = context.getters['account/authConfig']
    try {
      context.commit('setSpeedMap', speedMap)
      setSpeedMap(id, speedMap, config)
    } catch (error) {
      //
    }
  },
  resetSpeedMap (context, { id }) {
    const config = context.getters['account/authConfig']
    try {
      resetSpeedMap(id, config)
      context.dispatch('fetchDefaultSpeedMap', { id })
    } catch (error) {
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  clearRunnerTabs (context) {
    context.commit('updateRunnerTabs', { tabs: [] })
  },
  async saveRaceFilters (context, { opt, value }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const raceFiltersCopy = { ...context.state.raceFilters }
    raceFiltersCopy[opt] = value
    context.commit('updateRaceFilters', { value: raceFiltersCopy })
    if (!isTrial) setUserData(opt, value, config)
  },
  clearRaceFilters (context) {
    const resetState = {
      trackConditions: ['Any'],
      finishingPosition: 'All',
      track: 'All',
      spell: 'All',
      margin: 12.5,
      finishPositionOrMargin: false,
      distance: { from: '', to: '' },
      classes: ['all']
    }
    context.commit('resetRaceFilters', {
      value: resetState
    })
    context.commit('resetRunnerFormHistoryFiltered')
  },
  setRunnerFormHistoryFiltered (context, { formHistory, runnerId }) {
    context.commit('saveRunnerFormHistoryFiltered', { value: formHistory, runnerId })
  },
  toggleDrawer (context) {
    context.commit('updateDrawer')
  },
  async toggleAutoHide (context) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const isAutoHide = !context.state.isAutoHide
    context.commit('updateAutoHide', { isAutoHide })
    if (!isTrial) setUserData('isAutoHide', isAutoHide, config)
  },
  async sortFieldsByAXN (context, { sortBy }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    context.commit('sortFieldsMTN', { sortBy })
    if (!isTrial) setUserData('sortFieldsBy', sortBy, config)
  },
  async fetchFlucs (context, b) {
    context.commit('updateBestBookie', [])
    context.commit('updateFlucsRace', [])
    const auth = '02bdd529d4d3407aa033f8da4b383cf5'
    const raceID = context.state.selectedRace.id
    const source = b
    const params = {
      raceId: raceID,
      bookie: b
    }
    try {
      const axiosInstance = axios.create({
        baseURL: process.env.VUE_APP_SHORTFORM_URL
      })

      const newResult = await axiosInstance.get(`${process.env.VUE_APP_SHORTFORM_URL}getFlucs`, {
        params,
        headers: { Authorization: `Bearer ${auth}` }
      })
      const race = newResult.data.race
      context.commit('updateFlucsRace', race)
      bookieCount += 1
      const flucs = {
        source,
        competitors: newResult.data.race.competitors
      }
      context.state.bestPriceBookiesArrays.push(flucs)
      if (bookieCount === context.state.defaultBestPriceBookies.length) {
        await context.dispatch('mutateFlucs')
      }
    } catch (error) {
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  async mutateFlucs (context) {
    const bookieArr = context.state.bestPriceBookiesArrays
    const finalBookieArr = []
    const allCompetitors = []
    bookieArr.forEach(d => {
      if (!isEmpty(d?.competitors)) {
        const compArr = []
        d.competitors.forEach(c => {
          const flucs = c.flucs
          if (flucs.length > 0) {
            const compObj = {
              price: flucs[flucs.length - 1].price,
              time: flucs[flucs.length - 1].time,
              tabNo: flucs[flucs.length - 1].tabNo,
              source: d.source
            }
            compArr.push(compObj)
            allCompetitors.push(flucs[flucs.length - 1].tabNo)
          }
        })
        finalBookieArr.push(compArr)
      }
    })
    finalBookieArr.filter(a => a.length >= 1)
    const uniqueComps = [...new Set(allCompetitors)]
    const bestPriceArrComp = []
    uniqueComps.forEach(b => {
      const bestPriceArr = []
      if (finalBookieArr.length >= 1) {
        // eslint-disable-next-line array-callback-return
        finalBookieArr.map(f => {
          const price = f.find(r => r.tabNo === b)
          if (price) {
            bestPriceArr.push(price)
          }
        })
      }
      const maximum = bestPriceArr.reduce((acc, i) => (i.price > acc.price ? i : acc))
      bestPriceArrComp.push(maximum)
    })
    context.commit('updateBestBookie', bestPriceArrComp)
  },
  setAuthItems (context, { token, username }) {
    window.localStorage.setItem('fa_auth_token', token)
    window.localStorage.setItem('user', username)
  },
  async subscribePrices (context, id) {
    const raceID = JSON.stringify(id)
    const sourceTypes = []
    ALL_BOOKIES_CODES.forEach(b => {
      const obj = {
        source: b.source,
        type: 'WIN_FIXED_ODDS'
      }
      sourceTypes.push(obj)
    })
    // const codes = ALL_BOOKIES_CODES.map(c => c.source)
    const query = subscribePricesQuery(raceID, JSON.stringify(sourceTypes))
      .replace(/"(\w+)"\s*:/g, '$1:')
      .replace(/['"]+(WIN)/g, 'WIN')
      .replace(/(WIN)+['"]/g, 'WIN')
      .replace(/['"]+(PLACE)/g, 'PLACE')
      .replace(/(PLACE)+['"]/g, 'PLACE')
      .replace(/(ODDS)+['"]/g, 'ODDS')
    const result = await apolloClient.subscribe({
      query: gql`
        ${query}
      `
    })
    result.subscribe({
      next (data) {
        // if (window.location.host.split('.')[0] === 'do') {
        // const blah = arrayOfBookies
        const arrayOfBookies = context.state.bestPriceBookiesArrays
        // const prices = prices from subscription
        const prices = data?.data?.priceUpdates?.prices
        // get source from sub and find array in arrayOfBookies that corresponds
        const priceSource = prices[0].source
        // loop that array and push the prices into the flucs
        arrayOfBookies.forEach(k => {
          if (priceSource === k.source) {
            k?.competitors?.forEach(c => {
              const tabNo = c.flucs[0]?.tabNo
              const price = prices.find(p => p.tabNo === tabNo && p.type === 'WIN_FIXED_ODDS')
              if (price) {
                price.time = formatISO(Date.now())
                c.prices = [price]
                c.flucs.push(price)
              }
            })
          }
        })

        const price = {
          ...data.data.priceUpdates,
          prices: data.data.priceUpdates.prices.map(price => {
            return {
              ...price,
              price: price?.price ? Math.round(price.price * 100) / 100 : null
            }
          })
        }
        if (price) {
          price.id = id
          context.commit('updatePrice', { price })
        }

        context.commit('updateArrayOfBookies', arrayOfBookies)
        context.dispatch('mutateFlucs')
      },
      error (error) {
        return error
      }
    })
  },
  async fetchUserDetails (context) {
    // await context.commit('account/setIsTrial', { value: false })
    await context.dispatch('account/fetchUser', { isTrial: false })
    await context.dispatch('account/fetchCoreAPIToken')
  },
  async freeTrialSelected (context) {
    // await context.commit('account/setIsTrial', { value: true })
    await context.dispatch('account/fetchUser', { isTrial: true })
    await context.dispatch('account/getFreeTrialToken')
  },
  async fetchLast50RidesJockey (context, { jockeyName }) {
    const nameJockey = JSON.stringify(jockeyName)
    const query = last50RidesJockeyQuery(nameJockey)
    const result = await apolloClient.query({
      query: gql`
        ${query}
      `,
      context: {
        headers: {
          'api-key': 'pancakes'
        }
      }
    })

    await context.commit('setLast50RidesJockeyAndTrainer', { last50Rides: result.data.jockey, name: jockeyName })
  },
  async fetchGlobalIdAndLast50RidesJockey (context, { jockeyId }) {
    context.dispatch('loadingToggleAXN', { isLoading: true })

    try {
      const jockeyIdStringified = JSON.stringify(jockeyId)

      const last50Query = last50RidesJockeyQuery({ jockeyId: jockeyIdStringified })
      const last50QueryResult = await apolloClient.query({
        query: gql`
          ${last50Query}
        `
      })

      const last50Rides = await last50QueryResult?.data?.raceJockey
      await context.commit('setLast50RidesJockeyAndTrainer', { last50Rides, id: jockeyId })
      context.dispatch('loadingToggleAXN', { isLoading: false })
    } catch (error) {
      context.dispatch('loadingToggleAXN', { isLoading: false })
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  async fetchSingularRunner (context, { runnerName }) {
    context.dispatch('loadingToggleAXN', { isLoading: true })

    try {
      const runnerStringified = JSON.stringify(runnerName)

      // Get current runner global id
      const runnerGlobalIdQuery = getCurrentRunnerGlobalIdQuery({ runnerName: runnerStringified })
      const runnerGlobalIdQueryResult = await apolloClient.query({
        query: gql`
          ${runnerGlobalIdQuery}
        `
      })

      const runnerGlobalId = await runnerGlobalIdQueryResult?.data?.searchRaceRunners[0]?.runnerIdentifier?.globalId

      // Get curent runner
      const runnerQuery = raceRunnerFormQuery({ id: runnerGlobalId })
      const singularRunnerResult = await apolloClient.query({
        query: gql`
          ${runnerQuery}
        `
      })

      const name = JSON.stringify(runnerName)
      const horseQuery = horseSearchQuery(name)
      const horseSearchResult = await apolloClient.query({
        query: gql`
          ${horseQuery}
        `
      })

      const horseResults = await horseSearchResult?.data?.searchRaceRunners
      let findHorse = {}
      if (!isEmpty(horseResults)) {
        findHorse = horseResults.find(horse => horse.runnerIdentifier.globalId === runnerGlobalId)?.runnerIdentifier || {}
      }

      const raceRunner = await singularRunnerResult?.data?.raceRunner

      if (!isEmpty(raceRunner)) {
        const runner = { ...runnerDummy, runnerData: raceRunner.runnerData, formHistory: raceRunner.formHistory, runnerStatistics: raceRunner.statistics, name: runnerName, runner: { id: runnerGlobalId }, runnerIdent: findHorse }
        context.commit('updateHorseSearchChrome', { horseSearch: { runner } }) // ADD TO CHROME TAB
      } else {
        const runner = { ...runnerDummy, runnerData: {}, formHistory: [], runnerStatistics: null, name: runnerName, runner: { id: runnerGlobalId }, runnerIdent: findHorse }
        context.commit('updateHorseSearchChrome', { horseSearch: { runner } }) // ADD TO CHROME TAB
      }
      context.dispatch('loadingToggleAXN', { isLoading: false })
    } catch (error) {
      context.dispatch('loadingToggleAXN', { isLoading: false })
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  async fetchSingularRunnerWithGlobalIdAndName (context, { runnerGlobalId, runnerName }) {
    context.dispatch('loadingToggleAXN', { isLoading: true })

    try {
      const runnerNameStringified = JSON.stringify(runnerName)

      // Get curent runner
      const runnerQuery = raceRunnerFormQuery({ id: runnerGlobalId })
      const singularRunnerResult = await apolloClient.query({
        query: gql`
          ${runnerQuery}
        `
      })

      const horseQuery = horseSearchQuery(runnerNameStringified)
      const horseSearchResult = await apolloClient.query({
        query: gql`
          ${horseQuery}
        `
      })

      const horseResults = await horseSearchResult?.data?.searchRaceRunners
      let findHorse = {}
      if (!isEmpty(horseResults)) {
        findHorse = horseResults.find(horse => horse.runnerIdentifier.globalId === runnerGlobalId)?.runnerIdentifier || {}
      }

      const raceRunner = await singularRunnerResult?.data?.raceRunner

      if (!isEmpty(raceRunner)) {
        const runner = { ...runnerDummy, runnerData: raceRunner.runnerData, formHistory: raceRunner.formHistory, runnerStatistics: raceRunner.statistics, name: runnerName, runner: { id: runnerGlobalId }, runnerIdent: findHorse }
        context.commit('updateHorseSearchChrome', { horseSearch: { runner } }) // ADD TO CHROME TAB
      } else {
        const runner = { ...runnerDummy, runnerData: {}, formHistory: [], runnerStatistics: null, name: runnerName, runner: { id: runnerGlobalId }, runnerIdent: findHorse }
        context.commit('updateHorseSearchChrome', { horseSearch: { runner } }) // ADD TO CHROME TAB
      }
      context.dispatch('loadingToggleAXN', { isLoading: false })
    } catch (error) {
      context.dispatch('loadingToggleAXN', { isLoading: false })
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  async fetchGlobalIdAndLast50RidesTrainer (context, { trainerId }) {
    context.dispatch('loadingToggleAXN', { isLoading: true })

    try {
      const trainerIdStringified = JSON.stringify(trainerId)
      const last50Query = last50RidesTrainerQuery({ globalId: trainerIdStringified })
      const last50QueryResult = await apolloClient.query({
        query: gql`
          ${last50Query}
        `
      })

      const last50Rides = await last50QueryResult?.data?.raceTrainer
      await context.commit('setLast50RidesJockeyAndTrainer', { last50Rides, id: trainerId })
      context.dispatch('loadingToggleAXN', { isLoading: false })
    } catch (error) {
      context.dispatch('reloginInformTrigger', { error })
      context.dispatch('loadingToggleAXN', { isLoading: false })
    }
  },
  resetStateAXN (context) {
    context.commit('resetToDefault')
  },
  async setOptions (context) {
    const isTrial = context.getters['account/getIsTrial']

    if (!isTrial) {
      const config = context.getters['account/authConfig']
      setUserData('marketRange', context.state.marketRange, config)
      setUserData('marketPercentage', context.state.marketPercentage, config)
      setUserData('oddsToUse', context.state.oddsToUse, config)
      setUserData('oddsFormat', context.state.oddsFormat, config)
      setUserData('ratingToUse', context.state.ratingToUse, config)
    }
  },
  setMarketRange (context, { value }) {
    context.commit('updateMarketRange', value)
  },
  setMarketPercentage (context, { value }) {
    context.commit('updateMarketPercentage', value)
  },
  setOddsToUse (context, { value }) {
    context.commit('updateOddsToUse', value)
  },
  setOddsFormat (context, { value }) {
    context.commit('updateOddsFormat', value)
  },
  setRatingToUse (context, { value }) {
    context.commit('updateRatingToUse', value)
  },
  async calculateOdds (context) {
    try {
      const race = context.getters.getSelectedRace()
      const raceNumber = race.number
      const gbsMeetingId = race.meeting?.externalIDs[0].id
      const config = context.getters['account/authConfig']
      const dataFilteredToOdds = []
      const { myCompetitorRatingsFromDB } = context.state

      for (const c of race.competitors) {
        const runnerId = c.runner.id
        if (!isEmpty(myCompetitorRatingsFromDB[runnerId])) {
          const customizedRating = myCompetitorRatingsFromDB[runnerId].find(rating => rating.gbsMeetingId === gbsMeetingId && rating.raceNumber === raceNumber)
          const ratingDryAdjusted = !isEmpty(customizedRating) ? customizedRating.rating : c.rating.dry
          dataFilteredToOdds.push({ ratingDry: parseFloat(ratingDryAdjusted), ratingWet: c.rating.wet, scratched: c.scratched })
        } else {
          dataFilteredToOdds.push({ ratingDry: c.rating.dry, ratingWet: c.rating.wet, scratched: c.scratched })
        }
      }

      const calculationResult = await axios.post('api.public.Public/OddsCalculation', {
        competitors: dataFilteredToOdds,
        marketRange: context.state.marketRange,
        marketPercentage: context.state.marketPercentage,
        oddsToUse: context.state.oddsToUse,
        oddsFormat: context.state.oddsFormat,
        ratingToUse: context.state.ratingToUse
      }, config)

      const oddsCalculation = calculationResult.data.competitorOdds

      race.competitors.forEach((c, index) => {
        c.oddsToDisplay = oddsCalculation[index]?.oddsToDisplay ?? ''
      })
    } catch (error) {
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  async setCompetitorNotes (context, { notes, runnerId }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const race = context.getters.getSelectedRace()
    const gbsMeetingId = race?.meeting?.externalIDs[0]?.id
    const raceNumber = race?.number

    const { competitorsNotesFromDB } = context.state

    const competitorsNotes = competitorsNotesFromDB[runnerId] ? [...competitorsNotesFromDB[runnerId]] : []

    if (isEmpty(competitorsNotes)) {
      competitorsNotes.push({ runnerId, notes, gbsMeetingId, raceNumber })
    } else {
      const currentRunnerIndex = competitorsNotes.findIndex(runner => runner.gbsMeetingId === gbsMeetingId && runner.raceNumber === raceNumber)
      if (currentRunnerIndex > -1) {
        competitorsNotes[currentRunnerIndex] = { runnerId, notes, gbsMeetingId, raceNumber }
      } else {
        competitorsNotes.push({ runnerId, notes, gbsMeetingId, raceNumber })
      }
    }

    context.commit('saveCompetitorNotes', { competitorObj: competitorsNotes, runnerId })

    if (!isTrial) {
      setUserData(`competitorsNotes-${runnerId}`, competitorsNotes, config)
    }
  },
  async setGlobalRunerCompetitorNotes (context, { notes, runnerId }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']
    try {
      const globalRunnerNotes = { ...context.state.globalRunnerNotes, [runnerId]: notes }

      await context.commit('updateGlobalRunnerNotes', { runnerId, notes })

      if (!isTrial) {
        setUserData('globalRunnerNotes', globalRunnerNotes, config)
      }
    } catch (error) {
      context.dispatch('reloginInformTrigger', { error })
    }
  },
  async setMyCompetitorPrice (context, { price, runnerId }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const race = context.getters.getSelectedRace()
    race.competitors.find(co => co.runner.id === runnerId).myPrice = parseFloat(price)
    context.commit('updateDetailedRace', { race })

    const gbsMeetingId = race?.meeting?.externalIDs[0]?.id
    const raceNumber = race?.number

    const { myCompetitorPricesFromDB } = context.state

    const competitorPrices = myCompetitorPricesFromDB[runnerId] ? [...myCompetitorPricesFromDB[runnerId]] : []

    if (isEmpty(competitorPrices)) {
      competitorPrices.push({ runnerId, price, gbsMeetingId, raceNumber })
    } else {
      const currentRunnerIndex = competitorPrices.findIndex(runner => runner.gbsMeetingId === gbsMeetingId && runner.raceNumber === raceNumber)
      if (currentRunnerIndex > -1) {
        competitorPrices[currentRunnerIndex] = { runnerId, price, gbsMeetingId, raceNumber }
      } else {
        competitorPrices.push({ runnerId, price, gbsMeetingId, raceNumber })
      }
    }

    context.commit('saveCompetitorPrices', { competitorObj: competitorPrices, runnerId })

    if (!isTrial) {
      setUserData(`competitorPrices-${runnerId}`, competitorPrices, config)
    }
  },
  async setMyCompetitorRating (context, { rating, runnerId }) {
    const isTrial = context.getters['account/getIsTrial']
    const config = context.getters['account/authConfig']

    const race = context.getters.getSelectedRace()
    const gbsMeetingId = race?.meeting?.externalIDs[0]?.id
    const raceNumber = race?.number

    const { myCompetitorRatingsFromDB } = context.state

    const competitorRatings = myCompetitorRatingsFromDB[runnerId] ? [...myCompetitorRatingsFromDB[runnerId]] : []

    if (isEmpty(competitorRatings)) {
      competitorRatings.push({ runnerId, rating, gbsMeetingId, raceNumber })
    } else {
      const currentRunnerIndex = competitorRatings.findIndex(runner => runner.gbsMeetingId === gbsMeetingId && runner.raceNumber === raceNumber)
      if (currentRunnerIndex > -1) {
        competitorRatings[currentRunnerIndex] = { runnerId, rating, gbsMeetingId, raceNumber }
      } else {
        competitorRatings.push({ runnerId, rating, gbsMeetingId, raceNumber })
      }
    }

    context.commit('saveCompetitorRatings', { competitorObj: competitorRatings, runnerId })

    if (!isTrial) {
      setUserData(`competitorRatings-${runnerId}`, competitorRatings, config)
    }
  },
  async fetchRunnerCommentFromUserData (context, { runnerGlobalId }) {
    try {
      const config = context.getters['account/authConfig']
      // const gbsMeetingIdStringified = JSON.stringify(gbsMeetingId)
      // const raceNumberStringified = JSON.stringify(raceNumber)

      // const raceIdQuery = getRaceIdQueryQuery({ gbsMeetingId: gbsMeetingIdStringified, raceNumber: raceNumberStringified })
      // const raceIdQueryResult = await apolloClient.query({
      //   query: gql`
      //     ${raceIdQuery}
      //   `
      // })

      // const raceId = raceIdQueryResult.data.meeting.races[0].id // TODO: CHECK IF THE QUERY IS NOT EMPTY
      const keyList = [
        `competitorsNotes-${runnerGlobalId}`
      ]
      const userDataCommentsResults = await batchFetch(keyList, config)
      const competitorNotes = JSON.parse(userDataCommentsResults[`competitorsNotes-${runnerGlobalId}`])
      context.commit('saveCompetitorNotes', { competitorObj: competitorNotes, runnerGlobalId })
    } catch (error) {
      //
    }
  },
  async handlePrices (context, { prices, id }) {
    if (prices) {
      prices.forEach(x => {
        x.id = id
        context.commit('updatePrice', { price: x })
      })
      const isTrial = context.getters['account/getIsTrial'] // Check if user is running appp on trial mode or full release
      if (!isTrial) {
        context.dispatch('subscribePrices', id)
      }
    }
  },
  async updateRaceGridSettings (context, { filter, value }) {
    try {
      const config = context.getters['account/authConfig']
      switch (filter) {
        case 'raceResults':
          context.commit('updateResultsShowHide', value)
          setUserFilter('raceResultsShowHide', value, config)
          break
      }
    } catch (error) {
      //
    }
  },
  reloginInformTrigger (context, { error }) {
    const response = error?.networkError?.response
    if (response?.status === 403 || error?.message === 'Token is expired') {
      context.commit('updateRelogin', { isReauthorize: true })
    } else {
      if (error?.name !== 'TypeError') {
        context.dispatch('apiErrosAXN', { error: 'Opps, something went wrong... please try again later.', type: 'error' })
      } else {
        // Do somthing
      }
    }
  },
  clearSelectedRace (context) {
    context.commit('clearSelectedRace')
  },
  async loginCreateUser (context) {
    const config = context.getters['account/authConfig']
    const result = await axios.post('api.public.Public/Login', {}, config)
    const memberId = result.data.memberId
    context.commit('account/setMemberId', memberId)
  },
  async fetchMeetingsAndRaces (context, { date }) {
    const config = context.getters['account/authConfig']

    context.commit('updateLoadingToggle', { isLoading: true })
    const codes = ALL_BOOKIES_CODES.map(c => c.source)
    const selectedRace = context.getters.getSelectedRace()
    const currentRaces = []

    try {
      const meetingResults = await apolloClient.query({ query: gql`${meetingsDated(JSON.stringify(date))}` })

      const currentMeeting = meetingResults.data.meetingsDated.find((meeting) => meeting.id === selectedRace.meeting.id)

      for (const race of currentMeeting.races) {
        const raceResults = await apolloClient.query({ query: gql`${queryRace(JSON.stringify(race.id), JSON.stringify(codes))}` })

        const dataFilteredToOdds = []

        if (!isEmpty(raceResults?.data?.race)) {
          const race = raceResults.data.race ?? {}

          for (const co of race.competitors) {
            const runnerId = co.runner.id
            const keysToBeFetched = [`competitorsNotes-${runnerId}`, `competitorRatings-${runnerId}`]
            const dbData = await batchFetch(keysToBeFetched, config)

            if (!isEmpty(dbData)) {
              if (dbData[`competitorsNotes-${runnerId}`]) {
                const competitorsNotes = JSON.parse(dbData[`competitorsNotes-${runnerId}`]) || []
                const runnerCurrentRaceNotes = competitorsNotes.find(co => {
                  const gbsMeetingId = race?.meeting?.externalIDs[0]?.id
                  return co.runnerId === runnerId && co.gbsMeetingId === gbsMeetingId
                })

                co.competitorsNotes = runnerCurrentRaceNotes?.notes ?? ''
              }

              if (dbData[`competitorRatings-${runnerId}`]) {
                const competitorRatings = JSON.parse(dbData[`competitorRatings-${runnerId}`]) || []
                const runnerCurrentRatings = competitorRatings.find(co => {
                  const gbsMeetingId = race?.meeting?.externalIDs[0]?.id
                  return co.runnerId === runnerId && co.gbsMeetingId === gbsMeetingId
                })

                if (!isEmpty(runnerCurrentRatings?.rating)) {
                  dataFilteredToOdds.push({ ratingDry: runnerCurrentRatings?.rating, ratingWet: co.rating.wet, scratched: co.scratched })
                  co.ratingDry = runnerCurrentRatings?.rating
                } else {
                  dataFilteredToOdds.push({ ratingDry: co.rating.dry, ratingWet: co.rating.wet, scratched: co.scratched })
                  co.ratingDry = ''
                }
              }
            } else {
              dataFilteredToOdds.push({ ratingDry: co.rating.dry, ratingWet: co.rating.wet, scratched: co.scratched })
              co.ratingDry = co?.rating?.dry ?? ''
            }
          }

          const calculationResult = await axios.post('api.public.Public/OddsCalculation', {
            competitors: dataFilteredToOdds,
            marketRange: context.state.marketRange,
            marketPercentage: context.state.marketPercentage,
            oddsToUse: context.state.oddsToUse,
            oddsFormat: context.state.oddsFormat,
            ratingToUse: context.state.ratingToUse
          }, config)

          const oddsCalculation = calculationResult.data.competitorOdds

          race.competitors.forEach((co, index) => {
            co.oddsToDisplay = oddsCalculation[index]?.oddsToDisplay ?? ''
          })

          // race.competitors = race.competitors.sort((a, b) => sortByKeyValue({ key: context.state.sortFieldsBy, secondKey: null, a, b }))
          race.competitors = sortCompetitors({ race, sortFieldsBy: context.state.sortFieldsBy })
          currentRaces.push(race)
        }
      }

      context.commit('updateLoadingToggle', { isLoading: false })
      return { currentRaces, currentMeeting }
    } catch (error) {
      datadogLogs.logger.info(error, 'DynamicForm - error fetchMeetingsAndRaces')
      context.commit('updateLoadingToggle', { isLoading: false })
      context.dispatch('reloginInformTrigger', { error })
      return {}
    }
  },
  async fetchMeeting (context, { id }) {
    try {
      const meetingId = JSON.stringify(id)
      const query = meetingQuery(meetingId)
      const result = await apolloClient.query({
        query: gql`
          ${query}
        `
      })

      await context.commit('setMeetingSelected', { meeting: result.data.meeting })
    } catch (error) {
      //
    }
  },
  async subscribeRaceUpdates (context, { date }) {
    // context.dispatch('account/updateAuthToken')
    try {
      const query = subscribeRaceUpdatesQuery(date)
      const result = await apolloClient.subscribe({
        query: gql`${query}`
      })
      const subscription = result.subscribe({
        next (data) {
          const raceUpdate = data.data.raceUpdated
          const key = `${raceUpdate.id}`
          if (isSameDay(parseISO(raceUpdate.startTime), context.state.selectedDate) || isSameDay(parseISO(raceUpdate.startTime), startOfDay(Date.now()))) {
            const race = context.state.races[key] || context.state.todaysRaces[key]
            if (race) {
              const updates = {}
              if (race.status !== raceUpdate.status) {
                updates.status = raceUpdate.status
              }
              if (race.startTime !== raceUpdate.startTime) {
                updates.startTime = raceUpdate.startTime
                updates.isoStartTime = parseISO(race.startTime)
              }
              // if (race.trackCondition !== raceUpdate.trackCondition) {
              //   updates.trackCondition = raceUpdate.trackCondition
              //   context.commit('setRacedayUpdates', { meetingDate: raceUpdate.meeting.date, raceId: raceUpdate.id, raceNumber: raceUpdate.number, dateUpdate: raceUpdate.updated, raceName: raceUpdate.meeting.track.name, updateType: 'Track Condition', oldValue: race.trackCondition, newValue: raceUpdate.trackCondition })
              // }
              if (!isEmpty(updates)) {
                const newRace = { ...race, ...updates }
                context.commit('updateRace', { raceUpdate: newRace })
              }
            } else {
              context.commit('updateRaceSummary', { race: raceUpdate, meeting: raceUpdate.meeting })
            }
          }
        },
        error (error) {
          return error
        }
      })
      context.commit('updateRaceSubscription', { subscription })
    } catch (error) {
    }
  },
  async subscribeResults (context) {
    // context.dispatch('account/updateAuthToken')
    try {
      const query = subscribeResultsQuery()
      const result = await apolloClient.subscribe({
        query: gql`${query}`
      })

      result.subscribe({
        next (res) {
          const results = res.data.results
          const key = `${results.raceId}`
          const race = context.state.races[key]

          const resultsArray = []
          resultsArray.push(results)

          if (race) {
            const newRace = { ...race, status: results.status, results: resultsArray }
            context.commit('updateRace', { raceUpdate: newRace })
          }
          const detailedRace = context.state.detailedRaces[key]
          if (detailedRace) {
            const newDetailedRace = { ...detailedRace, status: results.status, results: resultsArray }
            context.commit('updateRaceDetail', { detailedRace: newDetailedRace })
          }
        }
      })
    } catch (error) {
    }
  },
  toggleDualAcceptances (context, { boolean }) {
    context.commit('updateDualAccepteances', { boolean })
  }
}

export const actionsMain = actions
