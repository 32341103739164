import axios from 'axios'

export const setUserData = async function (name, value, config) {
  try {
    await axios.post('api.public.Public/UpsertUserKey', { key: name, value: JSON.stringify(value) }, config)
  } catch {
    //
  }
}

export const fetchUserData = async function (name, config) {
  try {
    const result = await axios.post('api.public.Public/GetUserKeys', { key: name }, config)
    const filter = result.data.records ? JSON.parse(result.data.records[name]) : null
    if (filter) {
      return filter
    }
  } catch {
    //
  }
}

export const batchFetch = async function (keys, config) {
  try {
    const result = await axios.post('api.public.Public/GetUserKeys', { keys }, config)
    const results = result.data.records
    return results
  } catch (error) {
    //
  }
}

export const fetchSpeedMap = async function (id, config) {
  try {
    const result = await axios.post('api.public.Public/GetUserKeys', { key: `speedMap:${id}` }, config)
    const speedMap = result.data.records ? JSON.parse(result.data.records[`speedMap:${id}`]) : null
    if (speedMap) {
      return speedMap
    }
  } catch (error) {
  }
  return null
}

export const setSpeedMap = async function (id, value, config) {
  try {
    await axios.post('api.public.Public/UpsertUserKey', { key: `speedMap:${id}`, value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const resetSpeedMap = async function (id, config) {
  try {
    await axios.post('api.public.Public/DeleteUserKey', { key: `speedMap:${id}` }, config)
  } catch (error) {
  }
}

export const setUserFilter = async function (name, value, config) {
  try {
    await axios.post('api.public.Public/UpsertUserKey', { key: name, value: JSON.stringify(value) }, config)
  } catch (error) {
  }
}

export const deleteBlackbookEntry = async function (runnerId, key, config) {
  try {
    await axios.post('api.public.Public/DeleteBlackbookEntry', { runnerId, key }, config)
  } catch (error) {
    //
  }
}

export const insertBlackbookEntry = async function (tabType, runnerId, selectedTabTypeName, raceNumber, meetingId, meetingType, key, config) {
  try {
    await axios.post('api.public.Public/InsertBlackbookEntry', { tabType, runnerId, selectedTabTypeName, raceNumber, meetingId, meetingType, key }, config)
  } catch (error) {
    //
  }
}
