<template>
  <v-card elevation="0" v-if="!isEmpty(runner)">
    <v-toolbar elevation="1" dense color="green lighten-4" height="42">
      <div v-if="hasFeature('nextPrevButton')" class="mr-3">
        <v-btn title="Previous Runner" v-if="onShowArrows?.left" @click="onShowNextOrPreviousRunner({ operator: '-' })" fab dark width="22px" height="22px" color="#000000" class="ml-2"><v-icon size="18px">mdi-chevron-left</v-icon></v-btn>
        <v-btn title="Next Runner" v-if="onShowArrows?.right" @click="onShowNextOrPreviousRunner({ operator: '+' })" fab dark width="22px" height="22px" color="#000000" class="ml-2"><v-icon size="18px">mdi-chevron-right</v-icon></v-btn>
      </div>
      <h2 class="d-flex justify-center align-center text-lg-h6">
        {{ `${getRunner.tabNo ? `${getRunner.tabNo}.` : '' }` }} {{ getRunner.name }}
        <v-chip v-if="isBlackbook" class="ml-2 font-weight-regular" color="black" outlined small>
          <v-icon left small>
            mdi-book-open-page-variant
          </v-icon>
          Saved To Blackbook
        </v-chip>
      </h2>
      <div v-if="isScratched">
        <span class="race-grid__weight ellipsis d-block scratchedDownName ml-2 mt-1">SCRATCHED</span>
      </div>
      <div v-if="isEmergency">
        <span class="ml-1 red--text">E</span>
      </div>
      <v-spacer></v-spacer>
      <div v-if="!isBlackbookTab">
        <v-btn v-if="hasFeature('runnerFormPDFPrint')" @click="onPrintRunnerFrom" title="Generage Runner Form As PDF" elevation="1" fab x-small color="#272727" class="font-weight-bold mr-2"><v-icon class="white--text">mdi-printer-settings</v-icon></v-btn>
        <v-btn v-if="checkBlackBook" @click="removeRunnerFromBlackbook()" small color="#f44336" dark
          >Remove from Blackbook <v-icon class="ml-1" small>mdi-trash-can</v-icon></v-btn
        >
        <v-btn v-else @click="addRunnerToBlackbook()" small color="#272727" dark>Add to Blackbook</v-btn>
      </div>
    </v-toolbar>
    <div>
      <v-row v-if="!isCustomTab" class="pa-2 py-4">
        <v-col class="pa-0 d-flex align-center justify-center" cols="1">
          <v-avatar size="100" tile v-if="!isCustomTab">
            <img @error="setAltImg" :src="'https://d36frgpkvm82k8.cloudfront.net/' + meetingDetails.externalIDs + '_' + race.number + '_' + getRunner.tabNo + '_64x64.png'" />
          </v-avatar>
          <v-avatar size="100" tile v-else>
            <img @error="setAltImg" :src="getRunnerSilk" v-if="getRunnerSilk" />
            <div v-else class="d-flex align-center flex-column">
              <v-icon>mdi-horseshoe</v-icon>
              <div class="caption"> Silk not available</div>
            </div>
          </v-avatar>
        </v-col>
        <v-col cols="2">
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2);" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Barrier: </span> <span>{{ getRunner.barrier }}</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Weight: </span> <span>{{ getRunner.weightTotal }}</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Jockey: </span> <span>{{ getRunner.jockey }} <span v-if="getRunner.apprenticeIndicator">(a {{ getRunner.allowanceWeight.toFixed(1) }})</span></span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Trainer: </span> <span>{{ getRunner.trainer }}</span>
          </div>
        </v-col>
        <v-col cols="2">
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Age/Sex: </span> <span>{{ getRunner.age }} | {{getRunner.sex}}</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Colour: </span> <span>{{ getRunner.colour }}</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Sire: </span> <span>{{ getRunner.sire }}</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Dam: </span> <span>{{ getRunner.dam }}</span>
          </div>
        </v-col>
        <v-col cols="2">
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Prize: </span> <span>{{ getRunner.prizeMoney }}</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Win Percentage: </span>
            <span>{{ runnerStats.winPercentage }}%</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Place Percentage: </span>
            <span>{{ runnerStats.placePercentage }}%</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">API: </span>
            <span>{{ runnerStats.averagePrizeMoneyWon }}</span>
          </div>
        </v-col>
        <v-col cols="2">
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1" :title="getRunner.owners">
            <span class="font-weight-medium">Owners: </span>
            <span><Tooltip :text="getRunner.owners" :maxCharacters="20" /></span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Wins: </span>
            <span v-if="!isEmpty(runnerForm.runnerStatistics)">
              <span v-for="(win, index) in calculateWins({ runner: runnerForm })" :key="`${win[0]} ${index}`">
                <span>{{ win[1] }}</span><span>({{ win[0] }})</span><span v-if="index !== calculateWins({ runner: runnerForm }).length - 1">, </span>
              </span>
              <span v-if="isEmpty(calculateWins({ runner: runnerForm }))">0</span>
            </span>
            <span v-else>-</span>
          </div>
        </v-col>
        <v-col cols="3">
          <v-textarea v-if="!isCustomTab" v-model="globalNotes" @input="onInputSetGlobalRunerCompetitorNotes({ notes: $event, runnerId: runnerId })" label="Competitor notes" hide-details outlined height="115" filled dense color="green" background-color="white"></v-textarea>
          <v-textarea v-else  v-model="blackbookCompetitorNotes" @input="onInputSaveBlackbookCompetitorComments({ notes: $event, runnerId: runnerId })" label="Blackbook competitor notes" hide-details outlined height="115" filled dense color="green" background-color="white"></v-textarea>
        </v-col>
      </v-row>
      <v-row v-if="isCustomTab" class="pa-2 py-4">
       <v-col class="pa-0 d-flex align-center justify-center" cols="1">
          <v-avatar size="100" tile  v-if="getRunnerSilk">
            <img
              @error="setAltImg"
              :src="getRunnerSilk"
              size="64" />
          </v-avatar>
          <div size="100" tile v-else class="d-flex align-center flex-column">
            <v-icon>mdi-horseshoe</v-icon>
            <div class="caption"> Silk not available</div>
          </div>
        </v-col>
        <v-col cols="2">
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Trainer: </span>
            <span v-if="!isEmpty(runnerForm.formHistory)">{{ runnerForm.formHistory[0].trainer }}</span
            ><span v-else>-</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Age/Sex: </span> <span>{{ this.runnerAge }} | {{ runner.sex }}</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Sire: </span> <span>{{ runner.sire }}</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Dam: </span> <span>{{ runner.dam }}</span>
          </div>
        </v-col>
        <v-col cols="2">
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Total Prize: </span>
            <span>{{ runnerStats.totalPrizeMoneyWon }}</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Win Percentage: </span>
            <span>{{ runnerStats.winPercentage }}%</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Place Percentage: </span>
            <span>{{ runnerStats.placePercentage }}%</span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">API: </span>
            <span>{{ runnerStats.averagePrizeMoneyWon }}</span>
          </div>
        </v-col>
        <v-col cols="3">
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Wins: </span>
            <span v-if="!isEmpty(runner.runnerStatistics)">
              <span v-for="(win, index) in calculateWins({ runner })" :key="`${win[0]} ${index}`">
                <span>{{ win[1] }}</span><span>({{ win[0] }})</span><span v-if="index !== calculateWins({ runner }).length - 1">, </span>
              </span>
              <span v-if="isEmpty(calculateWins({ runner }))">0</span>
            </span>
          </div>
          <div style="border-bottom: .5px solid rgba(0,0,0,0.2)" class="runner-form__stat mb-1">
            <span class="font-weight-medium">Owners: </span> <span><Tooltip :text="getRunner.owners" :maxCharacters="30" /></span>
          </div>
        </v-col>
        <v-col cols="4">
          <v-textarea v-model="globalNotes" @input="onInputSetGlobalRunerCompetitorNotes({ notes: $event, runnerId: runnerId })" label="Competitor notes" hide-details outlined height="115" filled dense color="green" background-color="white" class="runner-form__stat"></v-textarea>
        </v-col>
      </v-row>

      <v-col cols="12" class="pt-0 px-0">
        <v-btn elevation="0" value="Short" x-small :class="optionDisplay === 'Short' ? 'activeButton' : ''" color="" @click="onChangeOptionDisplay('Short')">
          <v-icon class="mr-1" small>
            mdi-format-align-left
          </v-icon>
          <span class="font-weight-medium">Short Form</span>
        </v-btn>

        <v-btn elevation="0" value="Long" x-small :class="optionDisplay === 'Long' ? 'activeButton' : ''" @click="onChangeOptionDisplay('Long')">
          <v-icon class="mr-1" small>
            mdi-format-align-justify
          </v-icon>
          <span class="font-weight-medium">Long Form</span>
        </v-btn>

        <v-btn elevation="0" value="Graph" class="rounded-tr-0 rounded-br-0" x-small :class="optionDisplay === 'Graph' ? 'activeButton' : ''" @click="onChangeOptionDisplay('Graph')">
          <v-icon class="mr-1" small>
            mdi-graph
          </v-icon>
          <span class="font-weight-medium">Graph</span>
        </v-btn>
        <FilterForm :btnStyles="{ elevation: '0', btnColor: '', margin: '0 0 0px 0' }"/>
      </v-col>

      <v-simple-table class="common-table table" elevation="1" dense>
        <template v-slot:default>
          <thead class="green lighten-4 font-weight-bold">
            <tr>
              <th>
                Career
              </th>
              <th>
                Last
              </th>
              <th>
                This Prep
              </th>
              <th v-if="!isCustomTab">
                Dist
              </th>
              <th v-if="!isCustomTab">
                Track
              </th>
              <th v-if="!isCustomTab">
                Dst &amp; Trk
              </th>
              <th>
                1st Up
              </th>
              <th>
                2nd Up
              </th>
              <th>
                Firm
              </th>
              <th>
                Good
              </th>
              <th>
                Soft
              </th>
              <th>
                Heavy
              </th>
              <th>
                Synthetic
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ runnerStats?.numberOfRuns }}:{{ runnerStats?.firsts }}-{{ runnerStats?.seconds }}-{{ runnerStats?.thirds }}
              </td>
              <td>
                {{ checkFinalPos({...runner, ...runnerForm})?.slice(-1) }}
              </td>
              <td>
                -
              </td>
              <td v-if="!isCustomTab">
                <span>{{ careerByDistance(runnerStats?.byDistance) }}</span>
              </td>
              <td v-if="!isCustomTab">
                <span>{{ careerByTrack(runnerStats?.byTrack) }}</span>
              </td>
              <td v-if="!isCustomTab">
                <span>{{ careerByTrackAndDistance(runnerStats?.byTrackAndDistance) }}</span>
              </td>
              <td>{{ careerByFirstUp(runnerStats?.firstUp) }}</td>
              <td>{{ careerBySecondUp(runnerStats?.secondUp) }}</td>
              <td>{{ careerByCondFirm(runnerStats?.byCondition) }}</td>
              <td>{{ careerByCondGood(runnerStats?.byCondition) }}</td>
              <td>{{ careerByCondSoft(runnerStats?.byCondition) }}</td>
              <td>{{ careerByCondHeavy(runnerStats?.byCondition) }}</td>
              <td>{{ careerBySurface(runnerStats?.surfacePlaces) }}</td>
            </tr>
            <tr v-if="isDomestic  && hasFeature('runnerStatistics')">
              <td>{{ runner?.rating?.career }}</td>
              <td>{{ ratings?.lastStart }}</td>
              <td>-</td>
              <td>{{ runner?.rating?.distance }}</td>
              <td>{{ runner?.rating?.track }}</td>
              <td>{{ runner?.rating?.distanceTrack }}</td>
              <td>{{ runner?.rating?.firstUp }}</td>
              <td>{{ runner?.rating?.secondUp }}</td>
              <td>{{ runner?.rating?.firm }}</td>
              <td>{{ runner?.rating?.good }}</td>
              <td>{{ runner?.rating?.soft }}</td>
              <td>{{ runner?.rating?.heavy }}</td>
              <td>{{ runner?.rating?.synthetic }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <RunnerStartsColumns v-if="optionDisplay === 'Short'" :race="race" :runner="this.runnerForm" />
      <LongForm :race="race" v-else-if="optionDisplay === 'Long'" :runner="this.runnerForm"  />
      <RatingsGraph :race="race" v-else-if="optionDisplay === 'Graph'" :runner="this.runnerForm"  />
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import numeral from 'numeral'
import RunnerStartsColumns from './RunnerStartsColumns'
import LongForm from './LongForm'
import RatingsGraph from './RatingsGraph'
import Tooltip from './Tooltip'
import { isEmpty, filterFormHistory, historyTextDisplay, findWinnerForm, formatToMinutes, lastRating } from '../common/methods'
import FilterForm from './FilterForm.vue'
import { format } from 'date-fns'
import jsPDF from 'jspdf'

import autoTable from 'jspdf-autotable'
import { CUSTOM_CHROME_TAB, LOGO_BASE_64 } from '../common/constants'

export default {
  data () {
    return {
      optionDisplay: 'Short',
      globalNotes: '',
      blackbookCompetitorNotes: '',
      debounce: null,
      runnerForm: {},
      runnerAge: ''
    }
  },
  props: {
    runner: Object,
    addRunnerToChromeTab: Function
  },
  mounted () {
    this.setGlobalRunnerNotes({ globalRunnerNotes: this.globalRunnerNotes })
    this.setBlackbookCompetitorNotes({ blackbookComments: this.runnerBlackbookComments })
    this.setInitialLoadOfCustomTabs()
    this.getCompleteFormHistory()
  },
  components: {
    RunnerStartsColumns,
    LongForm,
    RatingsGraph,
    Tooltip,
    FilterForm
  },
  watch: {
    runner (newState) {
      this.setBlackbookCompetitorNotes({ blackbookComments: this.runnerBlackbookComments })
      this.setGlobalRunnerNotes({ globalRunnerNotes: this.globalRunnerNotes })
      this.getCompleteFormHistory()
    },
    globalRunnerNotes (newState) {
      this.setGlobalRunnerNotes({ globalRunnerNotes: newState })
    },
    runnerBlackbookComments (newState) {
      this.setBlackbookCompetitorNotes({ blackbookComments: newState })
    },
    optionDisplay (newState, oldState) {
      if (newState === 'Filter Form' || !isNaN(newState)) this.optionDisplay = oldState
    },
    raceFilters () {
      this.getFilterFormHistory()
    }
  },
  computed: {
    ...mapState(['runnerBlackbook', 'jockeyBlackbook', 'trainerBlackbook', 'currentVenue', 'runnerBlackbookComments', 'globalRunnerNotes', 'activeRunners', 'chromeGrouping', 'smartTabs', 'raceFilters']),
    ...mapGetters(['getSelectedRace', 'hasFeature']),
    race: function () {
      const race = this.getSelectedRace() ?? {}
      return race
    },
    meetingDetails () {
      return {
        country: this.race?.meeting?.track?.country,
        externalIDs: this.race?.meeting?.externalIDs[0]?.id
      }
    },
    checkBlackBook: function () {
      const found = this.runnerBlackbook.find(co => co.selectedTabTypeName === this.runner.selectedTabTypeName)
      return !isEmpty(found)
    },
    isBlackbook: function () {
      const combined = [...this.runnerBlackbook, ...this.jockeyBlackbook, ...this.trainerBlackbook]
      const found = combined.find(co => co.runnerId === this.runner?.runner?.id && co.tabType === this.runner.tabType)
      return !isEmpty(found)
    },
    isCustomTab: function () {
      return CUSTOM_CHROME_TAB.includes(this.currentVenue?.name)
    },

    isScratched: function () {
      return this.getRunner.scratched
    },
    isEmergency: function () {
      return this.getRunner.emergencyIndicator
    },
    isDomestic: function () {
      return this.meetingDetails.country === 'NZL' || this.meetingDetails.country === 'AUS'
    },
    isBlackbookTab: function () {
      return this.currentVenue?.name === 'Blackbook'
    },
    getRunnerSilk: function () {
      if (isEmpty(this.runnerForm?.formHistory)) return null
      const oldMeeting = this.runnerForm?.formHistory[0]?.gbsMeetingId
      const raceNo = this.runnerForm?.formHistory[0]?.raceNumber
      const runnerNo = this.runnerForm?.formHistory[0]?.tabNumber

      if (isEmpty(oldMeeting) || raceNo <= 0 || runnerNo <= 0) {
        return null
      }

      return `https://d36frgpkvm82k8.cloudfront.net/${oldMeeting}_${raceNo}_${runnerNo}_64x64.png`
    },
    ratings: function () {
      if (!isEmpty(this.runnerForm?.runnerStatistics)) {
        const { distancePlaces, trackPlaces, trackAndDistancePlaces, conditionPlaces, surfacePlaces, firstUp, secondUp, career } = this.runnerForm.runnerStatistics

        const lastStart = lastRating(this.runnerForm)

        const { distance } = this.race
        const venueName = this.race?.meeting?.track?.name

        const distancePlacesRating = distancePlaces.find(track => track?.key.includes(`${distance}`))
        const trackPlacesRating = trackPlaces.find(track => track?.key.includes(venueName))
        const trackAndDistancePlacesRating = trackAndDistancePlaces.find(track => track?.key.includes(`${venueName}, ${distance}`))
        const good = conditionPlaces.find(cond => cond?.key.includes('Good'))
        const soft = conditionPlaces.find(cond => cond?.key.includes('Soft'))
        const heavy = conditionPlaces.find(cond => cond?.key.includes('Heavy'))
        const firm = conditionPlaces.find(cond => cond?.key.includes('Firm'))
        const synthetic = surfacePlaces?.find(cond => cond?.key.includes('Synthetic'))

        return {
          distancePlaces: !isEmpty(distancePlacesRating) ? distancePlacesRating?.rating : '-',
          trackPlaces: !isEmpty(trackPlacesRating) ? trackPlacesRating?.rating : '-',
          trackAndDistancePlaces: !isEmpty(trackAndDistancePlacesRating) ? trackAndDistancePlacesRating?.rating : '-',
          firstUp: firstUp?.rating,
          secondUp: secondUp?.rating,
          career: career?.all?.rating,
          lastStart: lastStart,
          conditionPlaces: {
            good: !isEmpty(good) ? good?.rating : '-',
            soft: !isEmpty(soft) ? soft?.rating : '-',
            heavy: !isEmpty(heavy) ? heavy?.rating : '-',
            firm: !isEmpty(firm) ? firm?.rating : '-',
            synthetic: !isEmpty(synthetic) ? synthetic?.rating : '-'
          }
        }
      }

      return {
        distancePlaces: '-',
        trackPlaces: '-',
        trackAndDistancePlaces: '-',
        firstUp: '-',
        secondUp: '',
        career: '-',
        lastStart: '-',
        conditionPlaces: {
          good: '-',
          soft: '-',
          heavy: '-',
          firm: '-',
          synthetic: '-'
        }
      }
    },
    runnerId () {
      return this.runner?.runner?.id
    },
    runnerStats () {
      return {
        totalPrizeMoneyWon: numeral(this.runnerForm?.runnerStatistics?.all?.totalPrizeMoneyWon).format('$0,0.00a') ?? '-',
        winPercentage: this.runnerForm?.runnerStatistics?.career?.all?.winPercentage ?? '-',
        placePercentage: this.runnerForm?.runnerStatistics?.career?.all?.placePercentage ?? '-',
        numberOfRuns: this.runnerForm?.runnerStatistics?.career?.all?.numberOfRuns ?? '-',
        averagePrizeMoneyWon: numeral(this.runnerForm?.runnerStatistics?.career?.all?.averagePrizeMoneyWon).format('$0,0.00a') ?? '-',
        firsts: this.runnerForm?.runnerStatistics?.career?.all?.firsts ?? '-',
        seconds: this.runnerForm?.runnerStatistics?.career?.all?.seconds ?? '-',
        thirds: this.runnerForm?.runnerStatistics?.career?.all?.thirds ?? '-',
        byDistance: this.runnerForm?.runnerStatistics?.career?.detail?.byDistance ?? '-',
        byTrack: this.runnerForm?.runnerStatistics?.career?.detail?.byTrack ?? '-',
        byTrackAndDistance: this.runnerForm?.runnerStatistics?.career?.detail?.byTrackAndDistance ?? '-',
        byCondition: this.runnerForm?.runnerStatistics?.career?.detail?.byCondition ?? '-',
        firstUp: this.runnerForm?.runnerStatistics?.career?.spell?.firstUp ?? '-',
        secondUp: this.runnerForm?.runnerStatistics?.career?.spell?.secondUp ?? '-',
        surfacePlaces: this.runnerForm?.runnerStatistics?.surfacePlaces ?? '-'
      }
    },
    getRunner () {
      return {
        tabNo: this.runner?.tabNo ?? '',
        name: this.runner?.name ?? '',
        barrier: this.runner?.barrier ?? '',
        weightTotal: this.runner?.weightTotal ?? '',
        jockey: this.runner?.jockey ?? '',
        apprenticeIndicator: this.runner?.apprenticeIndicator ?? '',
        allowanceWeight: this.runner?.allowanceWeight ?? '',
        trainer: this.runner?.trainer ?? '',
        age: this.runner?.age ?? '',
        dob: this.runner?.dob ?? '',
        sex: this.runner?.sex ?? '',
        colour: this.runner?.colour ?? '',
        sire: this.runner?.sire ?? '',
        dam: this.runner?.dam ?? '',
        prizeMoney: numeral(this.runner?.prizeMoney).format('$0,0.00a') ?? '',
        owners: this.runner?.owners ?? '',
        scratched: this.runner?.scratched,
        emergencyIndicator: this.runner?.emergencyIndicator
      }
    },
    onShowArrows () {
      if ((CUSTOM_CHROME_TAB.includes(this.currentVenue?.name)) || isEmpty(this.race)) {
        return {
          left: false,
          right: false
        }
      }

      const { competitors } = this.race
      const runner = this.runner
      const index = competitors?.findIndex(co => co?.runner?.id === runner?.runner?.id)

      return {
        left: !isEmpty(competitors[index - 1]),
        right: !isEmpty(competitors[index + 1])
      }
    }
  },
  methods: {
    ...mapActions(['addToBlackBook', 'deleteRunnerFromBlackbook', 'addToRunnerTabs', 'setGlobalRunerCompetitorNotes', 'setBlackbookComments', 'addActiveRunners', 'fetchFormHistory', 'setRunnerFormHistoryFiltered', 'getCompetitorAge']),
    isEmpty,
    async getCompleteFormHistory () {
      const a = await this.fetchFormHistory({ runnerId: this.runner.runnerId })
      // a.formHistory.forEach((fh) => {
      //   fh.placeGetters.sort((a, b) => {
      //     return a.finishingPosition - b.finishingPosition
      //   })
      // })
      let fh = []
      if (a.formHistory.length > 0) {
        const formDates = this.runner.formHistory.map((a, index) => {
          return a.date
        })
        const missingForms = a.formHistory.filter(form => !formDates.includes(form.date))
        fh = this.runner.formHistory.concat(missingForms).sort((a, b) => new Date(b.date) - new Date(a.date))
      } else {
        fh = this.runner.formHistory
      }

      let rs = {}
      if (a.runnerStatistics.length > 0) {
        rs = { ...this.runner.runnerStatistics, ...a.runnerStatistics }
      } else {
        rs = this.runner.runnerStatistics
      }
      this.runnerForm = {
        ...this.runner,
        formHistory: fh,
        runnerStatistics: rs
      }
      this.getFilterFormHistory()
    },
    getFilterFormHistory () {
      const { runnerFormHistoryFiltered } = filterFormHistory({ runner: this.runnerForm })
      this.setRunnerFormHistoryFiltered({ formHistory: runnerFormHistoryFiltered, runnerId: this.runner.runnerId })
    },
    onChangeOptionDisplay (opt) {
      this.optionDisplay = opt
    },
    careerByCondFirm (careerDetail) {
      if (isEmpty(careerDetail) || careerDetail === '-') return '-'
      const d = careerDetail
      let firsts = 0
      let numberOfRuns = 0
      let seconds = 0
      let thirds = 0
      if (!d) return '-'
      d.forEach(v => {
        if (v.key === 'Firm' || v.key === 'Firm1' || v.key === 'Firm2' || v.key === 'Dead') {
          firsts += v.firsts
          numberOfRuns += v.numberOfRuns
          seconds += v.seconds
          thirds += v.thirds
        }
      })
      return `${numberOfRuns}: ${firsts}-${seconds}-${thirds}`
    },
    careerBySurface (careerDetail) {
      if (isEmpty(careerDetail) || careerDetail === '-') return '-'
      const synthetic = careerDetail.find(cond => cond.key === 'Synthetic')
      return `${synthetic?.numberOfRuns ?? ''}: ${synthetic?.firsts ?? ''}-${synthetic?.seconds ?? ''}-${synthetic?.thirds ?? ''}`
    },
    careerByCondGood (careerDetail) {
      if (isEmpty(careerDetail) || careerDetail === '-') return '-'

      const d = careerDetail
      let firsts = 0
      let numberOfRuns = 0
      let seconds = 0
      let thirds = 0
      if (!d) return '-'
      d.forEach(v => {
        if (v.key === 'Good3' || v.key === 'Good4' || v.key === 'Good' || v.key === 'Dead') {
          firsts += v.firsts
          numberOfRuns += v.numberOfRuns
          seconds += v.seconds
          thirds += v.thirds
        }
      })
      return `${numberOfRuns}: ${firsts}-${seconds}-${thirds}`
    },
    careerByCondSoft (careerDetail) {
      if (isEmpty(careerDetail) || careerDetail === '-') return '-'

      const d = careerDetail
      let firsts = 0
      let numberOfRuns = 0
      let seconds = 0
      let thirds = 0
      if (!d) return '-'
      d.forEach(v => {
        if (v.key === 'Soft5' || v.key === 'Soft6' || v.key === 'Soft7' || v.key === 'Slow') {
          firsts += v.firsts
          numberOfRuns += v.numberOfRuns
          seconds += v.seconds
          thirds += v.thirds
        }
      })
      return `${numberOfRuns}: ${firsts}-${seconds}-${thirds}`
    },
    careerByCondHeavy (careerDetail) {
      if (isEmpty(careerDetail) || careerDetail === '-') return '-'

      const d = careerDetail
      let firsts = 0
      let numberOfRuns = 0
      let seconds = 0
      let thirds = 0
      if (!d) return '-'
      d.forEach(v => {
        if (v.key === 'Heavy8' || v.key === 'Heavy9' || v.key === 'Heavy10' || v.key === 'Heavy') {
          firsts += v.firsts
          numberOfRuns += v.numberOfRuns
          seconds += v.seconds
          thirds += v.thirds
        }
      })
      return `${numberOfRuns}: ${firsts}-${seconds}-${thirds}`
    },
    careerByTrack (careerDetail) {
      if (isEmpty(careerDetail) || careerDetail === '-') return '-'
      const byTrack = careerDetail.filter(detail => detail.key === this.race?.meeting?.track?.name)
      if (byTrack.length === 0) {
        return '0:0-0-0'
      }
      return `${byTrack[0].numberOfRuns}:${byTrack[0].firsts}-${byTrack[0].seconds}-${byTrack[0].thirds}`
    },
    careerByDistance (careerDetail) {
      if (isEmpty(careerDetail) || careerDetail === '-') return '-'
      const byDistance = careerDetail.filter(detail => detail.key === this.race?.distance?.toString())
      if (byDistance.length === 0) {
        return '0:0-0-0'
      }
      return `${byDistance[0].numberOfRuns}:${byDistance[0].firsts}-${byDistance[0].seconds}-${byDistance[0].thirds}`
    },
    careerByTrackAndDistance (careerDetail) {
      if (isEmpty(careerDetail) || careerDetail === '-') return '-'
      const byDistance = careerDetail.filter(detail => detail.key === `(${this.race?.meeting?.track?.name}, ${this.race?.distance})`)
      if (byDistance.length === 0) {
        return '0:0-0-0'
      }
      return `${byDistance[0].numberOfRuns}:${byDistance[0].firsts}-${byDistance[0].seconds}-${byDistance[0].thirds}`
    },
    careerByFirstUp (careerDetail) {
      if (isEmpty(careerDetail) || careerDetail === '-') return '-'
      if (careerDetail?.numberOfRuns === 0) {
        return '0:0-0-0'
      }
      return `${careerDetail.numberOfRuns}:${careerDetail.firsts}-${careerDetail.seconds}-${careerDetail.thirds}`
    },
    careerBySecondUp (careerDetail) {
      if (isEmpty(careerDetail) || careerDetail === '-') return '-'
      if (careerDetail?.numberOfRuns === 0) {
        return '0:0-0-0'
      }
      return `${careerDetail.numberOfRuns}:${careerDetail.firsts}-${careerDetail.seconds}-${careerDetail.thirds}`
    },
    checkFinalPos (competitor) {
      if (competitor) {
        const pastStartsFilter = competitor.formHistory?.filter(e => e.class !== 'BTrial' && e.class !== 'Barrier Trial')
        const pastStarts = pastStartsFilter?.reverse()
        const finalPos = pastStarts?.map((b, index) => {
          let a = { ...b }
          if (index !== pastStarts.length - 1) {
            const startTime = pastStarts[index].date
            const pastTimer = pastStarts[index + 1].date
            const startTimeMili = new Date(startTime).getTime()
            const pastTimeMili = new Date(pastTimer).getTime()
            const timeBetween = startTimeMili - pastTimeMili
            const daysBetween = timeBetween / (1000 * 60 * 60 * 24)
            const daysFormatted = Math.floor(daysBetween)
            a = { ...a, dslsLast5: Math.abs(daysFormatted) }
            // b.dslsLast5 = Math.abs(daysFormatted)
          }
          if (a.finishingPosition > 9) {
            return 0
          }
          if (a.dslsLast5 && a.dslsLast5 >= 84) {
            return `${a.finishingPosition}x`
          }
          return a.finishingPosition
        })
        const finalPosStr = finalPos?.toString()
        const final = finalPosStr?.replace(/,/g, '')
        return final
      }
      return '-'
    },
    addToTab ({ co, tabType }) {
      this.addToRunnerTabs({ competitor: co, tabType })
    },
    addRunnerToBlackbook () {
      const saveCurrentRunner = {
        tabType: this.runnerForm?.tabType,
        runnerId: this.runnerForm?.runnerId,
        selectedTabTypeName: this.runnerForm?.selectedTabTypeName,
        raceNumber: this.race?.number,
        meetingId: this.race?.meeting?.id,
        meetingType: this.race?.meeting?.type
      }

      this.addToBlackBook({ blackbook: saveCurrentRunner })
    },
    removeRunnerFromBlackbook () {
      const removeCurrentRunner = {
        tabType: this.runnerForm?.tabType,
        runnerId: this.runnerForm?.runnerId
      }

      this.deleteRunnerFromBlackbook({ blackbook: removeCurrentRunner })
    },
    setAltImg (e) {
      e.target.src = require('../assets/d_silk.png')
      e.target.style = 'width: 43px; height: 43px;'
    },
    saveBlackbookCompetitorComments ({ notes, runnerId }) {
      const runnerBlackbookComments = {
        [runnerId]: notes
      }
      const blackbook = { runner: { tabType: 'runnerTab' } }
      this.setBlackbookComments({ blackbook, newBlackbookComments: runnerBlackbookComments })
    },
    setGlobalRunnerNotes ({ globalRunnerNotes }) {
      if (!isEmpty(globalRunnerNotes[this.runner?.runner?.id])) {
        this.globalNotes = globalRunnerNotes[this.runner?.runner?.id]
      }
    },
    setBlackbookCompetitorNotes ({ blackbookComments }) {
      if (!isEmpty(blackbookComments[this.runner?.runner?.id])) {
        this.blackbookCompetitorNotes = blackbookComments[this.runner?.runner?.id] //  Set current blackbook compeditor notes
      }
    },
    calculateWins ({ runner: co }) {
      const { formHistory, runner } = co
      const formHistoryFiltered = formHistory.filter(e => e.class !== 'BTrial' && e.class !== 'Barrier Trial')

      const wins = {}
      formHistoryFiltered.forEach(history => {
        const { distance, placeGetters } = history
        const filteredPlaceGetters = placeGetters.filter(e => e.runnerGlobalId === runner.id && e.finishingPosition === 1)

        filteredPlaceGetters.forEach(place => {
          if (!isEmpty(wins[distance])) wins[distance] = wins[distance] += 1
          else wins[distance] = 1
        })
      })

      const entries = Object.entries(wins)
      const sorted = entries.sort((a, b) => a[0] - b[0])
      return sorted
    },
    async setInitialLoadOfCustomTabs () {
      if (CUSTOM_CHROME_TAB.includes(this.currentVenue?.name)) {
        const found = this.chromeGrouping?.find(venue => Object.keys(venue)[0] === this.currentVenue?.name)
        const position = this.smartTabs[this.currentVenue?.name] ?? 0
        const runner = found[this.currentVenue?.name][position]
        await this.addActiveRunners({ runner })
        this.runnerAge = await this.getCompetitorAge(this.runner.runnerId)
      }
    },
    onInputSetGlobalRunerCompetitorNotes ({ notes, runnerId }) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.setGlobalRunerCompetitorNotes({ notes, runnerId })
      }, 1200)
    },
    onInputSaveBlackbookCompetitorComments ({ notes, runnerId }) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.saveBlackbookCompetitorComments({ notes, runnerId })
      }, 1200)
    },
    onShowNextOrPreviousRunner ({ operator }) {
      const competitorPosition = (this.runner.tabNo - 1)

      if (operator === '-') {
        const newCompetitorPosition = competitorPosition - 1
        const competitor = this.race.competitors[newCompetitorPosition]
        if (isEmpty(competitor)) return
        this.addRunnerToChromeTab({ co: competitor, tabType: 'runnerTab' })
      } else if (operator === '+') {
        const newCompetitorPosition = competitorPosition + 1
        const competitor = this.race.competitors[newCompetitorPosition]
        if (isEmpty(competitor)) return
        this.addRunnerToChromeTab({ co: competitor, tabType: 'runnerTab' })
      }
    },
    drawRunnerStats ({ pdf, settings }) {
      const runnerStats = this.runnerStats

      const primaryHeader = [{ content: `${this.runnerForm?.name}`, colSpan: 10, styles: { halign: 'left', fontStyle: 'bold', fontSize: settings.fontSize.tertirary } }]
      const secondaryHeader = ['Weight', 'Jockey', 'Trainer', 'Colour', 'Sire', 'Dam', 'Age/Sex', 'Win%', 'Place%', 'API']

      const age = isEmpty(this.runnerForm.age) ? '' : this.runnerForm.age
      const body = [[
        !isEmpty(this.getRunner.weightTotal) ? `${this.getRunner.weightTotal}kg` : '-',
        this.getRunner?.jockey || '-',
        this.getRunner?.trainer || '-',
        this.getRunner?.colour || '-',
        this.getRunner?.sire,
        this.getRunner?.dam,
        `${age || this.runnerAge}/${this.getRunner.sex}`,
        runnerStats?.winPercentage,
        runnerStats?.placePercentage,
        runnerStats?.averagePrizeMoneyWon
      ]]

      autoTable(pdf, {
        head: [primaryHeader, secondaryHeader],
        body,
        startY: settings.firstContentStartY,
        showHead: 'firstPage',
        margin: { left: settings.margin.left, right: settings.margin.right },
        styles: {
          halign: 'left'
        },
        headStyles: {
          fillColor: settings.colors.background,
          lineColor: settings.colors.lineColor,
          fontSize: settings.fontSize.primary,
          textColor: settings.colors.textPrimary,
          lineWidth: 0.01,
          halign: 'left'
        },
        bodyStyles: {
          fillColor: settings.colors.background,
          lineColor: settings.colors.lineColor,
          fontSize: settings.fontSize.secondary,
          textColor: settings.colors.textSecondary,
          lineWidth: 0.01
        },
        didParseCell: (data) => {
          if (data.section === 'body') {
            data.cell.styles.fillColor = settings.colors.background //  Default background as white
          }
        }
      })
    },
    drawRunnerFormHistory ({ pdf, settings }) {
      const { formHistory } = this.runnerForm
      const extraSpacing = 10

      const header = [['Placing', 'RTG', 'Dist', 'Venue', 'Track Condition', 'Bar', 'Margin', 'Winner', 'Date', 'Time']]
      const body = formHistory.map(history => {
        return [
          `${history?.finishingPosition}/${history?.starters}`,
          history?.ratingUnadjusted,
          history?.distance,
          historyTextDisplay({ history, key: 'track' }),
          history?.trackCondition,
          history?.barrier,
          history?.margin,
          findWinnerForm({ history }) || `${history?.runnerName} (${history?.weight}kg)`,
          format(new Date(history?.date), 'dd MMM yy'),
          formatToMinutes(history?.elapsedTime)
        ]
      })

      autoTable(pdf, {
        head: header,
        body,
        startY: pdf.lastAutoTable.finalY + extraSpacing,
        showHead: 'firstPage',
        margin: { left: settings.margin.left, right: settings.margin.right },
        styles: {
          halign: 'left'
        },
        headStyles: {
          fillColor: settings.colors.background,
          lineColor: settings.colors.lineColor,
          fontSize: settings.fontSize.primary,
          textColor: settings.colors.textPrimary,
          lineWidth: 0.01,
          halign: 'left'
        },
        bodyStyles: {
          fillColor: settings.colors.background,
          lineColor: settings.colors.lineColor,
          fontSize: settings.fontSize.secondary,
          textColor: settings.colors.textSecondary,
          lineWidth: 0.01
        },
        didParseCell: (data) => {
          if (data.section === 'body') {
            data.cell.styles.fillColor = settings.colors.background //  Default background as white
            const trackConditionIndex = 4

            // Change color of track condition text based on track conditio
            if (data.section === 'body' && data.column.index === trackConditionIndex) {
              data.cell.styles.fontStyle = 'bold'
              if (data.cell.text[0] === 'Firm') {
                data.cell.styles.textColor = [0, 204, 255]
              } else if (data.cell.text[0] === 'Good') {
                data.cell.styles.textColor = [0, 219, 0]
              } else if (data.cell.text[0] === 'Soft') {
                data.cell.styles.textColor = [224, 210, 56]
              } else if (data.cell.text[0] === 'Heavy') {
                data.cell.styles.textColor = [255, 165, 0]
              } else if (data.cell.text[0] === 'Synthetic') {
                data.cell.styles.textColor = [128, 0, 128]
              }
            }

            // Barial trial color grey and italic
            if (formHistory[data.row.index]?.isTrial) {
              data.cell.styles.fontStyle = 'normal'
              data.cell.styles.textColor = [179, 179, 179]
            }
          }
        }
      })
    },
    onPrintRunnerFrom () {
      /* eslint new-cap: ["error", { "newIsCap": false }] */
      console.log(this.runner)
      const pdf = new jsPDF({ orientation: 'p', unit: 'px', format: 'a4', putOnlyUsedFonts: true, compress: true })
      const title = `${this.runner?.name} (Runner Report)`

      const settings = {
        margin: {
          left: 15,
          right: 15,
          bottom: 15,
          top: 15
        },
        colors: {
          primary: [76, 175, 80],
          primaryLight: [200, 230, 201],
          textPrimary: [52, 52, 52],
          textSecondary: [111, 114, 129],
          backgroundPrimary: [255, 255, 255],
          lineColor: [128, 128, 128]
        },
        fontSize: {
          primary: 9.5,
          secondary: 8.5,
          tertirary: 10
        },
        firstContentStartY: 55,
        pdfWidth: pdf.internal.pageSize.getWidth(),
        pdfHeight: pdf.internal.pageSize.getHeight()
      }

      pdf.setProperties({ title })

      const posX = settings.margin.left
      const posY = settings.margin.top
      const width = 130
      const height = 25
      pdf.addImage(LOGO_BASE_64, 'PNG', posX, posY, width, height)

      pdf.setFontSize(13)
      pdf.setTextColor(40, 47, 102)
      pdf.text('Runner Report', settings.pdfWidth - 79, 35)

      this.drawRunnerStats({ pdf, settings })
      this.drawRunnerFormHistory({ pdf, settings })

      pdf.output('save', title)
    }
  }
}
</script>

<style>
  .activeButton {
    background: #c8e6c9 !important;
  }
</style>d

<style scoped lang="scss">
  .runner-form {

    &__stat {
      font-size: 15px;
    }
  }
  .table td {
    height: 25px !important;
  }
</style>
