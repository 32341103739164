<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { startOfDay } from 'date-fns'
import NavigationPanel from './components/NavigationPanel.vue'
import Loader from './components/Loader.vue'
import { Auth } from 'aws-amplify'
import AmplifyAuth from './components/AmplifyAuth.vue'
import AlertMsg from './components/AlertMsg.vue'
import SupportModal from './components/SupportModal.vue'
import HorseSearchModal from './components/HorseSearchModal.vue'
import { debounce, isEmpty } from './common/methods'
import RaceNavNextToGo from './components/RaceNavNextToGo.vue'
import ReLoginModal from './components/ReLoginModal.vue'
import SignupForFynamicformModal from './components/SignupForFynamicformModal.vue'

export default {
  name: 'App',
  data () {
    return {
      isDrawer: true,
      setRaceHeight: {},
      runEvent: true,
      showRouter: true,
      dialog: false
    }
  },
  components: {
    'navigation-panel': NavigationPanel,
    'loader-indicator': Loader,
    'amplify-auth': AmplifyAuth,
    'horse-search-modal': HorseSearchModal,
    'alert-msg': AlertMsg,
    SupportModal,
    RaceNavNextToGo,
    're-login-modal': ReLoginModal,
    'signup-for-dynamicform': SignupForFynamicformModal
  },
  computed: {
    ...mapState(['isAutoHide', 'isLoading', 'appErrors']),
    ...mapState('account', ['authorized', 'isTrial', 'user']),
    ...mapGetters(['getNextToGo', 'hasFeature']),
    ...mapGetters('account', ['getMemberId'])
  },
  async created () {
    this.setAppMounted({ boolean: true })
    this.windowDimensionAXN({
      height: window.innerHeight,
      width: window.innerWidth
    })

    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user) {
        await this.fetchUser({ isTrial: false })
      }
    } catch (error) {
      // await this.fetchUser({ isTrial: true })
    }

    if (!this.authorized) {
      this.dialog = true
    } else {
      if (!this.isTrial && this.isTrial !== null) {
        await this.fetchUserDetails()
      }
      this.$store.commit('updateLoadAZListData', true)
      await this.fetchRaces()
      this.fetchAzList()

      window.addEventListener('resize', debounce({ fn: this.onWindowResize, ms: 1000 }))
    }
  },
  BeforeDestroy () {
    window.addEventListener('resize', debounce({ fn: this.onWindowResize, ms: 1000 }))
  },
  watch: {
    isAutoHide (newState) {
      this.isDrawer = newState
    },
    authorized: async function (n) {
      if (n) {
        // signed in
        this.updateAuthToken()
        if (this.isTrial && !this.isTrial) {
          await this.fetchUserDetails()
          await this.fetchRaces()
        }
        this.loginCreateUser().then(() => {
          if (this.hasFeature('newSubscription')) {
            // upsert user in new sub system
            window.__tbm__widget__sub.exposed.createCustomer({
              userToken: this.user?.getSignInUserSession().getAccessToken().jwtToken,
              email: this.user?.attributes?.email,
              phoneNumber: this.user?.attributes?.phone_number,
              userName: this.user.username,
              notes: 'DF Signup',
              memberId: Number(this.getMemberId)
            })
          }
        })
      }
    }
  },
  methods: {
    ...mapActions(['toggleAutoHide', 'updateAuthState', 'fetchUserDetails', 'fetchRaces', 'fetchAzList', 'setAppMounted', 'windowDimensionAXN', 'setDate', 'reFetchRaces', 'selectRace', 'selectNavRace', 'resetStateAXN', 'loginCreateUser']),
    ...mapActions('account', ['logout', 'updateAuthToken', 'fetchUser', 'triggerDoToggle']),
    isEmpty,
    async clickNextToGoRace (v) {
      await this.setDate(startOfDay(Date.now()))
      await this.reFetchRaces()
      this.selectRace({
        id: v.id
      })
      this.selectNavRace({
        id: v.id
      })
    },
    onMouseEnter () {
      this.toggleAutoHide()
    },
    onLogout () {
      this.logout()
      setTimeout(() => {
        this.resetStateAXN()
      }, 1000)
    },
    onWindowResize () {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      this.windowDimensionAXN({
        width: window.innerWidth,
        height: window.innerHeight
      })
    },
    onSignUp () {
      const { email, u_name: userName } = this.$route.query
      if (!isEmpty(email) && !isEmpty(userName)) {
        this.triggerDoToggle({ value: true })
      } else {
        this.onLogout()
        this.$router.push('/?signup=true')
        window.location.reload()
      }
    },
    async onOpenDynamicOdds () {
      if (process.env.VUE_APP_ENV === 'prodc') {
        window.open('https://new.dynamicodds.com/')
      } else if (process.env.VUE_APP_ENV === 'uat' || process.env.VUE_APP_ENV === 'int' || process.env.VUE_APP_ENV === 'local') {
        window.open('http://uat.dyn.tbm.sh/')
      }
    }
  }
}
</script>

<template>
  <v-app>
    <amplify-auth />
    <v-navigation-drawer v-if="authorized" v-model="isDrawer" enable-resize-watcher app width="350px" color="green lighten-5">
      <navigation-panel />
    </v-navigation-drawer>

    <v-app-bar app class="app-bar" color="#37474f" height="45" elevation="2" dark clipped-right>
      <v-toolbar-title><v-img width="170" :src="require('./assets/fa-logo.svg')" alt="Dynamic Form Logo"/></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isTrial" @click="onSignUp" class="mr-4 urgency" small><v-icon class="mr-1" small>mdi-account</v-icon>
        <span v-if="isEmpty($route.query.email) && isEmpty($route.query.u_name)">Signup Now!</span>
        <span v-else>Subscribe to DynamicForm</span>
      </v-btn>
      <v-btn small color="#272727" class="mr-4" @click="onOpenDynamicOdds"><v-icon class="mr-1" small>mdi-horseshoe</v-icon> <span class="white--text">DynamicOdds</span></v-btn>
      <support-modal />
      <horse-search-modal />
      <v-btn small color="white" @click="onLogout"><v-icon class="ml-1" color="black" small>mdi-logout</v-icon> <span class="black--text">Log out</span></v-btn>
    </v-app-bar>

    <loader-indicator v-if="isLoading" />
    <alert-msg />
    <re-login-modal />
    <!-- <signup-for-dynamicform v-if="isTrial" /> -->
    <signup-for-dynamicform :onSignUp="onSignUp" :onLogout="onLogout"/>

    <v-main style="background-color: #FFF;" v-if="authorized">
      <v-container fluid>
        <div ref="raceBox">
          <div v-if="!isAutoHide" class="panel-btns-container" style="position: absolute; width: 54px; top: 40%;">
            <div class="text-to-go">
              <RaceNavNextToGo :race="getNextToGo" :onClick="clickNextToGoRace" :widthNextToGo="'min-width: 230px; height: 28px; margin-top: -2px;'" />
            </div>
            <v-btn block color="#37474f" class="panel-btns btn text-sm-body-2 white--text font-weight-medium" small @mouseenter="onMouseEnter"><span>SHOW LEFT PANEL</span></v-btn>
          </div>
          <v-main v-if="showRouter" style="padding:0px">
            <router-view></router-view>
          </v-main>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped lang="scss">
.panel-btns {
  transform: rotate(-90deg); margin-left:-110px;margin-top:125px;z-index:200;
  font-size: 14px !important;
}

.text-to-go {
  transform: rotate(-90deg) !important;
  margin-right: 20px !important;
  z-index: 1000;
  position: relative;

  .raceTimeNtg {
    padding-right: 10px !important;
    display: block;
  }

  button {
    & :first-child {
      margin-left: -5px;
    }

    & {
      font-size: 14px !important;
    }
  }
}

.urgency {
  animation-name: pulse;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  background-color: #272727;
}

@keyframes pulse {
  0% {
    background-color: #272727;
  }
  50% {
    background-color: #4bb04f;
  }
  100% {
    background-color: #272727;
  }
}

@-webkit-keyframes pulse {
   0% {
    background-color: #272727;
  }
  50% {
    background-color: #4bb04f;
  }
  100% {
    background-color: #272727;
  }
}
</style>
