import gql from 'graphql-tag'

export const meetingsDated = date => {
  return `query {
    meetingsDated(date: ${date}){
      id
      type
      date
      tabIndicator
      railPosition
      externalIDs(sources: "gbs"){
        id
      }
      track{
        name
        country
        state
      }
      races{
        id
        class
        distance
        name
        number
        startTime
        status
        trackCondition
        prizeMoney
        fixedAvailable
        starters
      }
    }
  }`
}

export const competitorQuery = ({ id }) => {
  return `query {
    runner(id: "${id}") {
      competitorsConnection(first: 1) {
        competitors {
          rating {
            dry
            wet
            price
          },
          apprenticeIndicator
          emergencyIndicator
          allowanceWeight
          jockeyID
          trainerID
          barrier
          name
          tabNo
          jockey
          trainer
          scratched
          prizeMoney
          weightTotal
          age
          colour
          sire
          sex
          silksColour
          owners
          dam
          finalPosition
          speedmapPosition
          weightAllocated
          runner{
            id
          }
          prices(sources: []) {
            source
            type
            price
          }
        }
      }
    }
  }`
}

export const raceRunnerFormQuery = ({ id }) => {
  return `query {
    raceRunner(globalId: "${id}", formHistoryStart: 0, formHistoryLimit: 99) {
      runnerData {
        owners,
        sex
      }
      runnerIdentifier {
        globalId
        name
        dam
        sire
        dob
        countryOfBirth
        meetingType
      }
      formHistory {
        raceId
        raceSectionals {
          distance
          seconds
          location
        }
        failedToFinishReason
        midPrice
        tabNumber
        openingPrice
        startingPrice
        prizeMoneyWon
        prizeMoneyWonThisRace
        limitWeight
        isTrial
        trackRating
        date
        track
        raceName
        raceNumber
        finishingPosition
        starters
        distance
        raceGroup
        class
        barrier
        jockey
        trainer
        runner
        margin
        trackCondition
        ratingUnadjusted
        inRun
        elapsedTime
        weight
        startingPrice
        gearChanges
        stewardsComment
        prizeTotal
        runnerGlobalId
        jockeyGlobalId
        trainerGlobalId
        gbsMeetingId
        raceNumber
        placeGetters {
          finishingPosition
          name
          weight
          tabNumber
          margin
          jockeyName
          startingPrice
          name
          runnerGlobalId
        }
      }
      statistics {
        all {
          totalPrizeMoneyWon
        }
        surfacePlaces {
          key
          firsts
          seconds
          thirds
          numberOfRuns
          rating
        }
        career {
          all {
            numberOfRuns
            firsts
            seconds
            thirds
            winPercentage
            placePercentage
            averagePrizeMoneyWon
            rating
          }
          spell {
            firstUp {
              numberOfRuns
              firsts
              seconds
              thirds
              rating
            }
            secondUp {
              numberOfRuns
              firsts
              seconds
              thirds
              rating
            }
            thirdUp {
              numberOfRuns
              firsts
              seconds
              thirds
            }
            fourthUp {
              numberOfRuns
              firsts
              seconds
              thirds
            }
          }
          detail {
            byDistance {
              key
              numberOfRuns
              firsts
              seconds
              thirds
              rating
            }
            byTrackAndDistance {
              key
              numberOfRuns
              firsts
              seconds
              thirds
              rating
            }
            byTrack {
              key
              numberOfRuns
              firsts
              seconds
              thirds
              rating
            }
            byCondition {
              key
              numberOfRuns
              firsts
              seconds
              thirds
              rating
            }
          }
        }
        distancePlaces {
          key
          rating
        }
        trackAndDistancePlaces {
          key
          rating
        }
        trackPlaces {
          key
          rating
        }
        conditionPlaces {
          key
          rating
        }
        firstUp {
          rating
        }
        secondUp {
          rating
        }
        lastSeason {
          rating
        }
        career {
          all {
            rating
          }
        }
      }
    }
  }`
}

export const subscribeRaceUpdatesQuery = date => {
  return `subscription {
    raceUpdated(dated: ${date}){
      id
      startTime
      status
      meeting{
        id
      }
    }
  }`
}

export const queryRace = (id, sources) => {
  return `query {
    race(id: ${id}){
      id
      class
      name
      number
      startTime
      status
      trackCondition
      distance
      starters
      prizeMoney
      priceSets(sources: ${sources}){
        source,
        meetingID,
        raceNumber,
        type,
        poolSize,
        updated,
        prices{
          tabNo,
          price
        }
      }
      competitors{
        formHistory(maxRuns: 99) {
          raceId
          raceSectionals {
            distance
            seconds
            location
          }
          midPrice
          tabNumber
          openingPrice
          startingPrice
          limitWeight
          isTrial
          trackRating
          date
          track
          raceName
          raceNumber
          finishingPosition
          starters
          distance
          raceGroup
          class
          barrier
          jockey
          trainer
          runner
          margin
          trackCondition
          ratingUnadjusted
          inRun
          elapsedTime
          weight
          startingPrice
          gearChanges
          stewardsComment
          prizeTotal
          runnerGlobalId
          jockeyGlobalId
          trainerGlobalId
          gbsMeetingId
          raceNumber
          prizeMoneyWon
          prizeMoneyWonThisRace
          placeGetters {
            finishingPosition
            name
            weight
            tabNumber
            margin
            jockeyName
            startingPrice
            name
            runnerGlobalId
          }
        }
        rating {
          career
          distance
          track
          distanceTrack
          jumps
          firstUp
          secondUp
          firm
          good
          soft
          heavy
          synthetic
          dry
          wet
          price
        }
        runnerStatistics {
          recent {
            last10Starts
          }
          career {
            all {
              numberOfRuns
              firsts
              seconds
              thirds
              winPercentage
              placePercentage
              averagePrizeMoneyWon
              rating
            }
            spell {
              firstUp {
                numberOfRuns
                firsts
                seconds
                thirds
                rating
              }
              secondUp {
                numberOfRuns
                firsts
                seconds
                thirds
                rating
              }
              thirdUp {
                numberOfRuns
                firsts
                seconds
                thirds
              }
              fourthUp {
                numberOfRuns
                firsts
                seconds
                thirds
              }
            }
            detail {
              byDistance {
                key
                numberOfRuns
                firsts
                seconds
                thirds
                rating
              }
              byTrackAndDistance {
                key
                numberOfRuns
                firsts
                seconds
                thirds
                rating
              }
              byTrack {
                key
                numberOfRuns
                firsts
                seconds
                thirds
                rating
              }
              byCondition {
                key
                numberOfRuns
                firsts
                seconds
                thirds
                rating
              }
            }
          }
          distancePlaces {
            key
            rating
          }
          trackAndDistancePlaces {
            key
            rating
          }
          trackPlaces {
            key
            rating
          }
          conditionPlaces {
            key
            rating
          }
          firstUp {
            rating
          }
          secondUp {
            rating
          }
          lastSeason {
            rating
          }
          career {
            all {
              rating
            }
          }
        }
        apprenticeIndicator
        emergencyIndicator
        allowanceWeight
        jockeyID
        trainerID
        barrier
        name
        tabNo
        jockey
        trainer
        scratched
        prizeMoney
        weightTotal
        age
        colour
        sire
        sex
        silksColour
        owners
        dam
        finalPosition
        speedmapPosition
        weightAllocated
        runner{
          id
        }
        prices(sources: []) {
          source
          type
          price
        }
      }
      meeting{
        id
        date
        railPosition
        externalIDs(sources: "gbs"){
          id
        }
        track{
          name
          country
          state
        }
        type
      }
      results(sources: []){
        positions{
          tabNo
          position
          margin
        }
      }
    }
  }`
}

export const subscribeResultsQuery = () => {
  return `subscription {
    results{
      raceId
      status
      source
      positions{
        tabNo
        position
        margin
      }
    }
  }`
}

export const meetingsDatedAz = (date, to) => {
  return `query {
    meetingsDated(date: ${date}, to: ${to}){
      id
      date
      type
      externalIDs(sources: "gbs") {
        id
      }
      track{
        name
        country
      }
      races{
        name
        id
        number
        startTime
        status
        competitors{
          name
          jockey
          trainer
          barrier
          weightAllocated
          tabNo
          scratched
          weightTotal
          runner {
            id
          }
        }
      }
    }
  }`
}

export const speedMapQuery = id => {
  return `query {
    race(id: ${id}){
      id
      competitors{
        tabNo
        name
        barrier
        speedmapPosition
      }
    }
  }`
}

export const subscribePricesQuery = (id, sources) => {
  return `subscription {
    priceUpdates(raceId: ${id}, sourceTypes: ${sources}){
      updated,
      source,
      meetingID,
      raceNumber,
      type,
      poolSize,
      prices{
        source,
        tabNo,
        price,
        type
      }
    }
  }`
}

// export const subscribePricesQuery = (id, sources) => {
//   return `subscription subscribePrices ($raceID: ID!, $sourceTypes: [inputSourceType!]) {
//     priceUpdates(raceId: $raceID, sourceTypes: $sourceTypes){
//       updated
//       meetingID
//       prices{
//         tabNo
//         price
//         type
//         source
//       }
//     }
//   }`
// }

// export const singularRunnerQuery = ({ globalId }) => {
//   return `query {
//     raceRunner(globalId: ${globalId}, formHistoryStart: 0, formHistoryLimit: 50) {
//       runnerData {
//         owners
//       }
//       formHistory {
//         raceSectionals {
//           distance
//           seconds
//           location
//         }
//         midPrice
//         tabNumber
//         openingPrice
//         startingPrice
//         limitWeight
//         standardisedAge
//         ratingUnadjusted
//         elapsedTime
//         class
//         isTrial
//         finishingPosition
//         starters
//         margin
//         date
//         track
//         raceNumber
//         raceName
//         ageRestriction {
//           minimumAgeInclusive
//           maximumAgeInclusive
//         }
//         sexRestriction {
//           restrictedTo
//         }
//         distance
//         weight
//         runner
//         barrier
//         jockey
//         trainer
//         inRun
//         gbsMeetingId
//         trackCondition
//         trackRating
//         placeGetters {
//           finishingPosition
//           name
//           weight
//           tabNumber
//           margin
//           jockeyName
//           startingPrice
//           name
//           runnerGlobalId
//         }
//       }
//       statistics {
//         career {
//           all {
//             numberOfRuns
//             firsts
//             seconds
//             thirds
//             winPercentage
//             placePercentage
//             averagePrizeMoneyWon
//             rating
//           }
//           spell {
//             firstUp {
//               numberOfRuns
//               firsts
//               seconds
//               thirds
//             }
//             secondUp {
//               numberOfRuns
//               firsts
//               seconds
//               thirds
//             }
//             thirdUp {
//               numberOfRuns
//               firsts
//               seconds
//               thirds
//             }
//             fourthUp {
//               numberOfRuns
//               firsts
//               seconds
//               thirds
//             }
//           }
//           detail {
//             byDistance {
//               key
//               numberOfRuns
//               firsts
//               seconds
//               thirds
//               rating
//             }
//             byTrackAndDistance {
//               key
//               numberOfRuns
//               firsts
//               seconds
//               thirds
//             }
//             byTrack {
//               key
//               numberOfRuns
//               firsts
//               seconds
//               thirds
//               rating
//             }
//             byCondition {
//               key
//               numberOfRuns
//               firsts
//               seconds
//               thirds
//             }
//           }
//         }
//         distancePlaces {
//           key
//           rating
//         }
//         trackAndDistancePlaces {
//           key
//           rating
//         }
//         trackPlaces {
//           key
//           rating
//         }
//         conditionPlaces {
//           key
//           rating
//         }
//         firstUp {
//           rating
//         }
//         secondUp {
//           rating
//         }
//         lastSeason {
//           rating
//         }
//       }
//     }
//   }`
// }

export const last50RidesJockeyQuery = ({ jockeyId }) => {
  return `query {
    raceJockey(globalId: ${jockeyId}, formHistoryStart: 0, formHistoryLimit: 50) {
      formHistory {
        elapsedTime
        class
        isTrial
        finishingPosition
        starters
        margin
        date
        track
        raceNumber
        raceName
        ageRestriction {
          minimumAgeInclusive
          maximumAgeInclusive
        }
        sexRestriction {
          restrictedTo
        }
        distance
        startingPrice
        weight
        runner
        barrier
        inRun
        gbsMeetingId
        trackCondition
        trackRating
        placeGetters {
          finishingPosition
          name
          weight
          tabNumber
          margin
          jockeyName
          startingPrice
          name
          runnerGlobalId
        }
      },
      statistics {
        thisSeason {
          numberOfRuns,
          firsts,
          seconds,
          thirds,
          winPercentage,
          placePercentage,
          averagePrizeMoneyWon,
          rating
        },
        lastSeason {
          numberOfRuns,
          firsts,
          seconds,
          thirds,
          winPercentage,
          placePercentage,
          averagePrizeMoneyWon,
          rating
        }
        distancePlaces {
          key
          rating
        }
        trackAndDistancePlaces {
          key
          rating
        }
        trackPlaces {
          key
          rating
        }
        conditionPlaces {
          key
          rating
        }
        firstUp {
          rating
        }
        secondUp {
          rating
        }
        lastSeason {
          rating
        }
      }
      acceptances {
        date
        track
        raceNumber
        raceName
        prizeTotal
        ageRestriction {
          minimumAgeInclusive
          maximumAgeInclusive
        }
        sexRestriction {
          restrictedTo
        }
        class
        distance
        startTime
        runnerGlobalId
        runner
        jockeyGlobalId
        jockey
        trainerGlobalId
        trainer
      }
    }
  }
`
}

export const horseSearchQuery = (name) => {
  return `query {
    searchRaceRunners(runnerName: ${name}, pageLimit: 10, meetingType: "HorseRacing") {
      runnerIdentifier {
        globalId
        name
        dam
        sire
        dob
        countryOfBirth
        meetingType
      }
    }
  }`
}

export const last50RidesTrainerQuery = ({ globalId }) => {
  return `query {
    raceTrainer(globalId: ${globalId}, formHistoryStart: 0, formHistoryLimit: 50) {
      formHistory {
        isTrial
        finishingPosition
        starters
        margin
        date
        track
        raceNumber
        raceName
        ageRestriction {
          minimumAgeInclusive
          maximumAgeInclusive
        }
        sexRestriction {
          restrictedTo
        }
        distance
        startingPrice
        weight
        runner
        barrier
        inRun
        gbsMeetingId
        trackCondition
        trackRating
      }
      statistics {
        career {
          all {
            numberOfRuns
            firsts
            seconds
            thirds
            winPercentage
            placePercentage
            averagePrizeMoneyWon
            rating
          }
          spell {
            firstUp {
              numberOfRuns
              firsts
              seconds
              thirds
            }
            secondUp {
              numberOfRuns
              firsts
              seconds
              thirds
            }
            thirdUp {
              numberOfRuns
              firsts
              seconds
              thirds
            }
            fourthUp {
              numberOfRuns
              firsts
              seconds
              thirds
            }
          }
          detail {
            byDistance {
              key
              numberOfRuns
              firsts
              seconds
              thirds
              rating
            }
            byTrackAndDistance {
              key
              numberOfRuns
              firsts
              seconds
              thirds
            }
            byTrack {
              key
              numberOfRuns
              firsts
              seconds
              thirds
              rating
            }
            byCondition {
              key
              numberOfRuns
              firsts
              seconds
              thirds
            }
          }
        }
        distancePlaces {
          key
          rating
        }
        trackAndDistancePlaces {
          key
          rating
        }
        trackPlaces {
          key
          rating
        }
        conditionPlaces {
          key
          rating
        }
        firstUp {
          rating
        }
        secondUp {
          rating
        }
        lastSeason {
          rating
        }
      }
      acceptances {
        date
        track
        raceNumber
        raceName
        prizeTotal
        ageRestriction {
          minimumAgeInclusive
          maximumAgeInclusive
        }
        sexRestriction {
          restrictedTo
        }
        class
        distance
        startTime
        runnerGlobalId
        runner
        jockeyGlobalId
        jockey
        trainerGlobalId
        trainer
      }
    }
  }`
}

// TEMPORARY SOLUTION
export const getCurrentRunnerGlobalIdQuery = ({ runnerName }) => {
  return `query { 
    searchRaceRunners(runnerName: ${runnerName}, meetingType:"HorseRacing", pageStart:0, pageLimit:1) {
      runnerIdentifier{
        globalId
      }
    }
  }`
}

export const exoticsPriceQuery = id => {
  const idString = JSON.stringify(id)
  return gql`{
    race(id: ${idString}){
      id,
      status,
      betFairMarkets{
        selections{
          number,
          sellingPrice
        }
      },
      results(sources: []){
        source,
        positions{
          position,
          competitor{
            tabNo,
            name,
            barrier,
            finalPosition
            prices(sources: ["OP", "V", "Q", "N", "BF"]){
              source,
              type,
              price
            }
          }
        },
        dividends{
          type,
          dividend,
          dividendNumbers,
          poolSize
        }
      }
    }
  }`
}

export const meetingQuery = id => {
  return `query {
    meeting(id: ${id}){
      id
      type
      date
      tabIndicator
      railPosition
      externalIDs(sources: "gbs"){
        id
      }
      track{
        name
        country
        state
      }
      races{
        id
        class
        distance
        name
        number
        startTime
        status
        trackCondition
        prizeMoney
        fixedAvailable
        starters
      }
    }
  }`
}
