<template>
  <div v-if="!isEmpty(race)" class="common-table last-5-starts">
    <v-btn v-if="isVisible" fab fixed bottom right @click="toTop" class="scroll-btn">
      <v-icon>mdi-arrow-up-bold-box-outline</v-icon>
    </v-btn>
    <v-col class="pb-1 cursor-pointer" v-for="(runner, runnerIndex) in race.competitors" :key="runnerIndex" :class="dynamicFilters(runner)" @click="addRunnerToChromeTab({ co: runner,  tabType: 'runnerTab' })">
      <div class="competitor">
        <span class="small-pill">{{runner.tabNo}}</span>
        <img @error="setAltImg" :src="'https://d36frgpkvm82k8.cloudfront.net/'+race.meeting.externalIDs[0].id+'_'+race.number+'_'+runner.tabNo+'_64x64.png'" alt="jockey silks">
        <p v-if="runner.scratched"> <span class="isScratched">{{runner.name}}</span> <span>SCRATCHED</span></p>
        <div v-else>
          <p style="min-width: 250px; float: left;">{{runner.name}}</p>
          <table class="competitor-detail-table">
            <thead>
              <tr>
                <th>Weight</th>
                <th>Barrier</th>
                <th>Trainer</th>
                <th>Jockey</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ runner.weightTotal }}</td>
                <td>{{ runner.barrier }}</td>
                <td>{{ runner.trainer }}</td>
                <td>{{ runner.jockey }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <v-simple-table dense class="simpleTable" v-if="runner.formHistory.length">
        <thead class="grey lighten-2">
          <tr>
            <th>Placing</th>
            <th>RTG</th>
            <th>Margin</th>
            <th>Dist</th>
            <th>Venue</th>
            <th>SP</th>
            <th>Class</th>
            <th>Condition</th>
            <th>Prize</th>
            <th>Date</th>
            <th>Days</th>
            <th>Bar</th>
            <th>Jockey</th>
            <th>Winner</th>
            <th>Time</th>
            <th>In Run</th>
          </tr>
        </thead>

        <tbody v-for="(history, historyIndex) in getDaysSinceLastStart(runner?.formHistory?.filter(x => !x.isTrial)).slice(0, 5)" :key="historyIndex">
          <td>
            <v-icon v-if="history.finishingPosition === 1" style="margin-left: -5px; margin-right: 2px;" color="#ffd700" small>mdi-trophy</v-icon>
            <span v-if="history.finishingPosition === 1" style="font-weight:bold">{{history.finishingPosition}}</span><span v-else>{{history.finishingPosition}}</span> / {{history.starters}}
          </td>
          <td>{{history.ratingUnadjusted}}</td>
          <td>{{history.margin}}</td>
          <td>{{history.distance}}</td>
          <td>{{history.track}} R{{history.raceNumber}}</td>
          <td>${{history.startingPrice.toFixed(2)}}</td>
          <td>Class: {{history.class}}</td>
          <td>{{history.trackCondition}}</td>
          <td>${{numeral(history.prizeTotal).format('0a')}}</td>
          <td>{{format(new Date(history.date), 'dd MMM yy')}}</td>
          <td>{{history.dsls}}</td>
          <td>{{history.barrier}}</td>
          <td>{{history.jockey}}</td>
          <td>{{findWinnerForm({ history: history }) || `${history.name} (${history.weight}kg)`}}</td>
          <td>{{formatToMinutes(history.elapsedTime)}}</td>

          <td>{{ uiTruncateTxt({ txt: formatInRun({ inRun: history.inRun }), max: 22 }) }}</td>

        </tbody>
      </v-simple-table>
    </v-col>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { isEmpty, findWinnerForm, formatToMinutes, filterFormHistory } from '../common/methods'
import { format } from 'date-fns'
import numeral from 'numeral'

export default {
  name: 'Last5RunnerStartsColumns',
  props: {
    runner: Object,
    race: Object,
    addRunnerToChromeTab: Function
  },
  dataSet () {
    return {
      format,
      numeral
    }
  },
  data () {
    return {
      historyExpanded: {},
      prevHistoryExpanded: '',
      shortFormHeight: null,
      isVisible: false,
      elementOffset: 0
    }
  },
  computed: {
    ...mapState(['singleRunner', 'currentVenue', 'windowDimension', 'runnerFormHistoryFiltered', 'raceFilters', 'activeRunners']),
    ...mapGetters(['getRunnerFormHistoryFiltered', 'getCompetitorsNotesFromDB'])
  },
  mounted () {
    window.addEventListener('wheel', this.onScroll)
  },
  watch: {
  },
  methods: {
    ...mapActions(['windowDimensionAXN', 'apiErrosAXN', 'fetchRace', 'fetchSingularRunnerWithGlobalIdAndName']),
    isEmpty,
    format,
    findWinnerForm,
    formatToMinutes,
    numeral,
    dynamicFilters (runner) {
      const { highlight } = filterFormHistory({ runner })
      return highlight
    },
    setAltImg (e) {
      e.target.src = require('../assets/d_silk.png')
    },
    getDays (runnerIndex, history) {
      console.log('last 5 runner starts data set: ', history)
    },
    getDaysSinceLastStart (dataSet) {
      dataSet = dataSet.filter(history => history.starters !== 0) // Filter out abandoned races
      // eslint-disable-next-line array-callback-return
      dataSet.map((b, index) => {
        if (index !== dataSet.length - 1) {
          const nowTime = dataSet[index].date
          const pastTimer = dataSet[index + 1].date
          const nowTimeMili = new Date(nowTime).getTime()
          const pastTimeMili = new Date(pastTimer).getTime()
          const timeBetween = nowTimeMili - pastTimeMili
          const daysBetween = timeBetween / (1000 * 60 * 60 * 24)
          const daysFormatted = Math.floor(daysBetween)
          b.dsls = daysFormatted
        }
      })
      return dataSet
    },
    uiTruncateTxt ({ txt }) {
      const splitInRun = txt.split(',')
      const inRunText = []
      splitInRun.forEach(x => {
        inRunText.push(x.split('-')[1])
      })
      if (inRunText?.length > 1) {
        return inRunText.join(', ')
      } else {
        return '-'
      }
    },
    formatInRun ({ inRun }) {
      if (!isEmpty(inRun)) {
        const inRunSplitArr = inRun.split(',').reverse()

        if (!isEmpty(inRunSplitArr)) {
          const inRunFormated = inRunSplitArr.map((runStr, index) => {
            const position = Number(runStr.split('()')[0])
            const incrementer = 400
            const raceDistance = incrementer * (index + 1)
            if (raceDistance === inRunSplitArr?.length * incrementer) {
              return `Settled - ${numeral(position).format('0o')}`
            } else {
              return `${raceDistance}m - ${numeral(position).format('0o')}`
            }
          })

          return inRunFormated.reverse().join(', ')
        } else {
          return ''
        }
      } else {
        return 'None'
      }
    },
    onScroll () {
      this.isVisible = true
    },
    toTop () {
      document.getElementById('raceGrid').scroll(0, 0)
      this.isVisible = false
    }
  }
}
</script>

<style scoped lang="scss">

.last-5-starts {
  width: 100%;
}
.last-5-starts .competitor{
    background: #2c3841;
    color: white;
    padding: 5px 15px;
    margin: 0;
  }

  .last-5-starts td{
    font-size: 12px !important;
  }

  .last-5-starts img{
    width: 31px;
    float: left;
    margin: 0 10px;
  }

  .competitor p {
      margin-top: 6px;
      margin-bottom: 6px;
  }
  .small-pill {
    float: left;
    width: 27px;
    height: 26px;
    border: 1px solid rgb(216, 216, 216);
    background-color: rgb(255, 255, 255);
    font-weight: 900; text-align: center;
    margin-bottom: 3px;
    margin-top: 3px;
    color: #2c3841;
  }
  .isScratched {
    text-decoration: line-through;
    display: inline-flex;
    min-width: 20%;
  }

.competitor-detail-table thead{
    background: none;
  }

  .competitor-detail-table th{
    min-width: 100px;
    color:  white !important;
    text-align: left;
  }

 .competitor-detail-table td{
    border-bottom: none;
    text-align: left;
  }

  .scroll-btn {
    background: #C8E6C9 !important;
  }
</style>
