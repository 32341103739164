<template>
  <div v-if="!isEmpty(runner)" :id="newClassId" class="short-form common-table runner-table" :style="`max-height: ${shortFormHeight}px;`">
    <v-simple-table
      dense
      class="simpleTable mt-6"
      v-if="runnerHasPastStartsDO()">
      <thead class="grey lighten-2">
      <tr>
        <th></th>
        <th>Placing</th>
        <th>RTG</th>
        <th>Margin</th>
        <th>Dist</th>
        <th>Venue</th>
        <th>SP</th>
        <th>Class</th>
        <th>Condition</th>
        <th>Prize</th>
        <th>Date</th>
        <th>Days</th>
        <th>Bar</th>
        <th>Jockey</th>
        <th>Winner</th>
        <th>Time</th>
        <th>In Run</th>
      </tr>
      </thead>
      <tbody
        v-for="(b, index) in mutateDataForm(runnerHistoryFilter, runner?.runner?.id)"
        :key="index"
      >
      <tr style="padding-bottom: 2px;" :class="formClassSet(b.isTrial)" v-if="!historyExpanded[`${b.date}-${index}`]">
        <td style="font-size: 12px; white-space: nowrap">
          <v-icon @click="onShowLongForm({ history: b, index })" class="pointer" color="#C0C0C0" small>mdi-plus-circle</v-icon>
        </td>
        <td style="font-size: 12px; white-space: nowrap">
          <span v-if="(b.finishingPosition === 1 || b.finishingPosition === 2) && checkDeadHeat(b)" style="margin-left: -18px; margin-right: 2px;">DH</span><v-icon v-if="b.finishingPosition === 1" color="#ffd700" small>mdi-trophy</v-icon>
          <span v-if="b.finishingPosition === 1" style="font-weight:bold">{{b.finishingPosition}}</span><span v-else>{{b.finishingPosition}}</span> / {{b.starters}}
        </td>
        <td style="font-size: 12px">
          {{ b.ratingUnadjusted }}
        </td>
        <td style="font-size: 12px">
          {{ marginReCalc({ history: b }) }}
        </td>
        <td
          class="pr-2"
          style="font-size: 12px"
        >
          {{b.distance}}
        </td>
        <td
          style="font-size: 12px; white-space: nowrap"
          class="active-hover"
          @click="onOpenNewRace({ history: b })"
        >
          {{b.track}} R{{ b.raceNumber }}
        </td>
        <td
          v-if="!b.isTrial" style="font-size: 12px;">
          ${{b.startingPrice.toFixed(2)}}
        </td>
        <td v-else> - </td>
        <td
          style="font-size: 12px; white-space: nowrap"
        >
          {{returnCorrectClass({ history: b })}}
        </td>
        <td
          v-if="!b.track.includes('Synthetic')"
          style="font-size: 12px"
          :class="['color-' + checkTrackCond(b.trackCondition)]"
        >
          <b v-if="b.trackCondition === 'Unknown'">-</b>
          <!-- <b v-else>{{checkTrackCond(b.trackCondition)}} {{b.trackRating}}</b> -->
          <b v-else>{{checkTrackCond(b.trackCondition)}}</b>
        </td>
        <td v-else-if="b.track.includes('Synthetic')"
          style="font-size: 12px"
          :class="['color-Synthetic']"
        >
          <b>Synthetic</b>
        </td>
        <template>
          <td
            v-if="!b.isTrial"
            style="font-size: 12px"
          >
            ${{numeral(b.prizeTotal).format('0a')}}
          </td>
          <td v-else>
            -
          </td>
        </template>
        <td
          style="font-size: 12px; white-space: nowrap"
        >
          {{format(new Date(b.date), 'dd MMM yy')}}
        </td>
        <td
          style="font-size: 12px"
        >
          {{b.dsls || '-'}}
        </td>
        <td style="font-size: 12px">
          {{b.barrier}}
        </td>
        <td style="font-size: 12px; white-space: nowrap">
          {{b.jockey}} ({{b.weight}}kg)
        </td>
        <td style="font-size: 12px">
          {{findWinnerForm({ history: b }) || `${runner.name} (${b.weight}kg)`}}
        </td>
        <td style="font-size: 12px;">
          {{formatToMinutes(b.elapsedTime)}}
        </td>
        <td v-if="b.inRun">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ uiTruncateTxt({ txt: formatInRun({ inRun: b.inRun }), max: 22 }) }}</span>
            </template>
            <span>{{ formatInRun({ inRun: b.inRun }) }}</span>
          </v-tooltip>
        </td>
        <td v-else>-</td>
      </tr>
      <tr v-else>
        <td>
          <v-icon @click="onShowLongForm({ history: b, index })" color="#C0C0C0" class="pointer" small>mdi-minus-circle</v-icon>
        </td>
        <td class="pl-0" colspan="100%">
          <v-simple-table :class="b.raceName === 'Barrier Trial' || b.raceName === 'BTrial' ? 'barrier-trial simpleTable' : 'simpleTable'" style="overflow-y: hidden;">
            <thead class="green lighten-4 font-weight-bold">
            <tr>
              <th colspan="100%" class="pl-" style="height: 25px; text-align: center;">Long Form</th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div v-if="!isEmpty(b.failedToFinishReason)">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="red--text">{{ failedToFinishReason({reason: b.failedToFinishReason}).abbeviation }}</span>
                      </template>
                      <span>{{ failedToFinishReason({reason: b.failedToFinishReason}).full }}</span>
                    </v-tooltip>
                  </div>
                  <div v-else>
                    <span v-if="(b.finishingPosition === 1 || b.finishingPosition === 2) && checkDeadHeat(b)" style="margin-left: -5px; margin-right: 2px;">DH</span><v-icon v-if="b.finishingPosition === 1" color="#ffd700" small>mdi-trophy</v-icon>
                    <span v-if="b.finishingPosition === 1" style="font-weight:bold">{{b.finishingPosition}}</span><span v-else>{{b.finishingPosition}}</span> / {{b.starters}}
                  </div>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">{{ getReadableDate({ utcDate: b.date }) }}</span>
                    </template>
                    <span>{{ `${getReadableDate({ utcDate: b.date })}` }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" class="long-form__active-hover" @click="onOpenNewRace({ history: b })">Venue: {{ historyTextDisplay({ history: b, key: 'track' }) }}</span>
                    </template>
                    <span>Venue: {{ historyTextDisplay({ history: b, key: 'track' }) }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">Race Number: {{b.raceNumber}}</span>
                    </template>
                    <span>Race Number: {{b.raceNumber}}</span>
                  </v-tooltip>
                </td>
                <td>
                  Total Prize: <span :class="numeral({ number: b.prizeTotal, decimalPlaces: 1 }) && 'color-Good3'">${{ numeral(b.prizeTotal).format('0a') }}</span>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on" :class="b.raceName === 'Barrier Trial' || b.raceName === 'BTrial' ? 'barrier-trialname' : ''">Class: {{ returnCorrectClass({ history: b })}}</span>
                    </template>
                    <span>{{ `Class: ${b.class} - ${b.raceName}` }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">Distance: {{b.distance }}m</span>
                    </template>
                    <span>Distance: {{b.distance }}m</span>
                  </v-tooltip>
                </td>
                <td>
                  Condition:
                  <b :class="['color-' + b.trackCondition + b.trackRating]">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{  b.trackCondition }} ({{ b.trackRating }})</span>
                      </template>
                      <span>Condition {{  b.trackCondition }} ({{ b.trackRating }})</span>
                    </v-tooltip>
                  </b>
                </td>
                <td>
                  Limit Weight: {{ b.limitWeight }}kg
                </td>
              </tr>
              <tr>
                <td>{{ marginReCalc({ history: b }) }}L</td>
                <td>Jockey: {{ b.jockey }} ({{ b.barrier }})</td>
                <td>Weight: {{ b.weight }}kg</td>
                <td>Race Time: {{ formatTimeShortAndLongForm(b.elapsedTime) }}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"> {{ historyTextDisplay({ history: b, key: 'raceSectionals' }) }}</span>
                    </template>
                    <span> Sectional Time: {{ historyTextDisplay({ history: b, key: 'raceSectionals' }) }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">In Run: {{ uiTruncateTxt({ txt: formatInRun({ inRun: b.inRun }), max: 22 }) }}</span>
                    </template>
                    <span>In Run: {{ formatInRun({ inRun: b.inRun }) }}</span>
                  </v-tooltip>
                </td>
                <td>Opening Price: ${{ numeral(b.openingPrice).format('0.00') }}</td>
                <td>Mid Price: ${{ numeral(b.midPrice).format('0.00') }}</td>
                <td>Start Price: ${{ numeral(b.startingPrice).format('0.00') }}</td>
              </tr>
              <tr>
                <td colspan="1" class="text-left">{{ b.ratingUnadjusted }}</td>
                <td colspan="6">
                  <span>Placings:{{ ' ' }}</span>
                  <span v-for="placement in runnersPlacementToString({ selectedRunner: runner, history: b })" :key="placement.worded">
                    <span @click="onOpenHorseSearchTab({ placement })" class="long-form__active-hover cursor" v-if="placement.placement === 1 && placement.name"><span class="placment-gold ml-1">1st</span> - {{ placement.worded }}</span>
                    <span @click="onOpenHorseSearchTab({ placement })" class="long-form__active-hover cursor" v-if="placement.placement === 2 && placement.name"><span class="placment-silver ml-1">2nd</span> - {{ placement.worded }}</span>
                    <span @click="onOpenHorseSearchTab({ placement })" class="long-form__active-hover cursor" v-if="placement.placement === 3 && placement.name"><span class="placment-bronze ml-1">3rd</span> - {{ placement.worded }}</span>
                    <span @click="onOpenHorseSearchTab({ placement })" class="long-form__active-hover cursor" v-if="placement.placement === 4 && placement.name"><span class="placment-fourth ml-1">4th</span> - {{ placement.worded }}</span>
                  </span>
                </td>
                <td colspan="5" style="border-bottom: none;">
                  <span> Margin: <span :class="b.placeGetters.margin > 0 && 'color-Good4'">{{placeGetterCalc(b.placeGetters)}}</span></span>
                </td>
              </tr>
            </tbody>
            <!-- <tfoot class="green lighten-4 font-weight-bold">
              <td style="border-bottom: none;" colspan="100%"></td>
            </tfoot> -->
          </v-simple-table>
        </td>
      </tr>

      <!-- COMMENTS... -->
      <tr v-if="b.gearChanges" class="stewardsComment">
        <td colspan="16"><b>Gear Changes:</b> <span style="font-size: 11px;">{{b.gearChanges}}</span></td>
      </tr>
      <tr v-if="b.stewardsComment" class="stewardsComment">
        <td colspan="16"><b>Stewards:</b> <span style="font-size: 11px;">{{b.stewardsComment}}</span></td>
      </tr>
      <tr v-if="!isEmpty(b.competitorNotes) && !isEmptyOrSpaces(b.competitorNotes[0].notes)" class="stewardsComment">
        <td colspan="16"><b>User Notes:</b> <span style="font-size: 11px;">{{b.competitorNotes[0].notes}}</span></td>
      </tr>
      <tr style="background-color: #dadada">
        <td style="text-align: center" colspan="17" v-if="b.dsls && b.dsls >= 42">
          <b style="color: black; font-size: 11px !important;">---{{b.dsls}} DAY {{checkSpell(b.dsls)}}---</b>
        </td>
      </tr>
      </tbody>
    </v-simple-table>
    <!--Render if runner has no previous starts-->
    <!-- <div class="text-center mt-8" v-else>
      <b style="color: black; font-size: 18px !important;">FIRST START</b>
    </div> -->
    <div v-if="messageType.isFilter" class="text-center mt-8">
      <b style="color: black; font-size: 18px !important;">No match to Filter Form settings</b>
    </div>

    <div v-if="messageType.isFirstStart" class="text-center">
      <b style="color: black; font-size: 18px !important;">First Start</b>
    </div>
  </div>
  <v-card v-else flat class="mt-8">
    <p class="text-center loading">Loading Runner...</p>
  </v-card>
</template>

<script>
import Vue from 'vue'
import { format } from 'date-fns'
import { mapState, mapGetters, mapActions } from 'vuex'
import ordinal from 'ordinal'
import numeral from 'numeral'
import { isEmpty, isEmptyOrSpaces, returnCorrectClass, failedToFinishReason, getReadableDate, historyTextDisplay, formatTimeShortAndLongForm, runnersPlacementToString, placeGetterCalc, findWinnerForm, formatToMinutes, checkTrackCond } from '../common/methods'

export default {
  name: 'RunnerStartsColumns',
  props: {
    runner: Object,
    race: Object,
    runnerTabNo: Number
  },
  dataSet () {
    return {
      numeral,
      format,
      ordinal
    }
  },
  data () {
    return {
      historyExpanded: {},
      prevHistoryExpanded: '',
      shortFormHeight: null
    }
  },
  computed: {
    ...mapState(['singleRunner', 'currentVenue', 'windowDimension', 'runnerFormHistoryFiltered', 'raceFilters', 'activeRunners']),
    ...mapGetters(['getRunnerFormHistoryFiltered', 'getCompetitorsNotesFromDB']),
    runnerHistoryFilter () {
      const formHistoryFiltered = this.currentVenue.name !== 'Horse Search' ? this.runnerFormHistoryFiltered[this.runner?.runnerId] || [] : []
      if (this.checkDefaultFilters()) {
        this.onSetExpands({ history: this.runner?.formHistory })
        return this.runner?.formHistory
      }

      this.onSetExpands({ history: formHistoryFiltered })
      return formHistoryFiltered
    },
    messageType () {
      if (isEmpty(this.runner.formHistory)) {
        return { isFirstStart: true, isFilter: false }
      }

      if (this.checkDefaultFilters()) {
        return { isFirstStart: false, isFilter: false }
      }

      return { isFirstStart: false, isFilter: isEmpty(this.runnerHistoryFilter) }
    },
    newClassId () {
      const tabType = this.runner?.tabType ?? ''
      const runnerId = this.runner?.runnerId ?? ''

      if (isEmpty(runnerId)) return undefined

      const noSpecialCharacters = runnerId.replace(/[^a-zA-Z0-9 ]/g, '')
      return `${noSpecialCharacters}-${tabType}`
    }
  },
  mounted () {
    setTimeout(() => {
      const yPos = this.getShortFromRect()?.y
      if (!isEmpty(yPos)) {
        this.shortFormHeight = this.windowDimension.height - yPos
      }
    }, 1000)
  },
  watch: {
    windowDimension (newState) {
      const yPos = this.getShortFromRect()?.y
      if (!isEmpty(yPos)) {
        this.shortFormHeight = newState.height - yPos
      }
    },
    runnerHistoryFilter () {
      const yPos = this.getShortFromRect()?.y
      if (!isEmpty(yPos)) {
        this.shortFormHeight = this.windowDimension.height - yPos
      }
    },
    historyExpanded (newState) {
    }
  },
  methods: {
    ...mapActions(['windowDimensionAXN', 'apiErrosAXN', 'fetchRace', 'fetchSingularRunnerWithGlobalIdAndName']),
    checkTrackCond,
    formatToMinutes,
    numeral,
    isEmpty,
    isEmptyOrSpaces,
    format,
    returnCorrectClass,
    failedToFinishReason,
    getReadableDate,
    historyTextDisplay,
    formatTimeShortAndLongForm,
    runnersPlacementToString,
    placeGetterCalc,
    findWinnerForm,
    findSpellCurrent (dataSet) {
      const latestForm = dataSet[0]
      const latestFormDate = latestForm.date
      const todaysDate = new Date(this.race.startTime)
      const isoTodaysDate = todaysDate.toISOString()
      const formMilli = new Date(latestFormDate).getTime()
      const todayMilli = new Date(isoTodaysDate).getTime()
      const timeBetween = todayMilli - formMilli
      const daysBetween = timeBetween / (1000 * 60 * 60 * 24)
      const daysFormatted = Math.floor(daysBetween)
      return daysFormatted
    },
    uiTruncateTxt ({ txt }) {
      const splitInRun = txt.split(',')
      const inRunText = []
      splitInRun.forEach(x => {
        inRunText.push(x.split('-')[1])
      })
      if (inRunText.length > 1) {
        return inRunText.join(', ')
      } else {
        return '-'
      }
    },
    marginReCalc ({ history }) {
      // TODO: ADD DEAD HEAT - DEAD HEAD HERE
      if (history.finishingPosition === 1) {
        return Math.abs(history?.margin)
      } else {
        return history?.margin
      }
    },
    formatInRun ({ inRun }) {
      if (!isEmpty(inRun)) {
        const inRunSplitArr = inRun.split(',').reverse()

        if (!isEmpty(inRunSplitArr)) {
          const inRunFormated = inRunSplitArr.map((runStr, index) => {
            const position = Number(runStr.split('()')[0])
            const incrementer = 400
            const raceDistance = incrementer * (index + 1)
            if (raceDistance === inRunSplitArr.length * incrementer) {
              return `Settled - ${numeral(position).format('0o')}`
            } else {
              return `${raceDistance}m - ${numeral(position).format('0o')}`
            }
          })

          return inRunFormated.reverse().join(', ')
        } else {
          return ''
        }
      } else {
        return 'None'
      }
    },
    formClassSet (v) {
      switch (v) {
        case true:
          return 'btrialColor'
        case false:
          return ''
        case '':
          return ''
      }
    },
    checkMargin (margin, race) {
      let newMargin = ''
      if (margin === 0) {
        race.forEach(d => {
          if (d.finalPosition === 2) {
            newMargin = d.margin
          }
        })
      }
      return newMargin
    },
    checkDeadHeat (dataSet) {
      const deadHeatFirstPosition = dataSet.placeGetters.filter(b => b.finishingPosition === 1).length
      const deadHeatSecondPosition = dataSet.placeGetters.filter(b => b.finishingPosition === 1).length

      if (deadHeatFirstPosition > 1 || deadHeatSecondPosition > 1) {
        return true
      } else {
        return false
      }
    },
    findWinner (dataSet) {
      let winnerName = ''
      dataSet.forEach(d => {
        if (d.finalPosition === 1) {
          winnerName = `${d.name} (${d.weightTotal}kg)`
        }
      })
      return winnerName
    },
    mutateData (dataSet) {
      // eslint-disable-next-line array-callback-return
      dataSet.map((b, index) => {
        if (
          // eslint-disable-next-line eqeqeq
          b.race.status != 'ABANDONED' &&
          !b.scratched &&
          !this.runningToday(b)
        ) {
          if (index !== dataSet.length - 1) {
            const nowTime = dataSet[index].race.startTime
            const pastTimer = dataSet[index + 1].race.startTime
            const nowTimeMili = new Date(nowTime).getTime()
            const pastTimeMili = new Date(pastTimer).getTime()
            const timeBetween = nowTimeMili - pastTimeMili
            const daysBetween = timeBetween / (1000 * 60 * 60 * 24)
            const daysFormatted = Math.floor(daysBetween)
            b.dsls = daysFormatted
          }
        }
      })
      return dataSet
    },
    mutateDataForm (dataSet, runnerId) {
      const competitorNotes = this.getCompetitorsNotesFromDB(runnerId)
      dataSet = dataSet.filter(history => history.starters !== 0) // Filter out abandoned races
      // eslint-disable-next-line array-callback-return
      dataSet.map((b, index) => {
        if (index !== dataSet.length - 1) {
          const nowTime = dataSet[index].date
          const pastTimer = dataSet[index + 1].date
          const nowTimeMili = new Date(nowTime).getTime()
          const pastTimeMili = new Date(pastTimer).getTime()
          const timeBetween = nowTimeMili - pastTimeMili
          const daysBetween = timeBetween / (1000 * 60 * 60 * 24)
          const daysFormatted = Math.floor(daysBetween)
          b.dsls = daysFormatted
        }
        if (competitorNotes) {
          const compNotes = competitorNotes.filter(note => note.gbsMeetingId === b.gbsMeetingId && note.raceNumber === b.raceNumber)
          b.competitorNotes = compNotes
        }
      })
      // dataSet.sort(function (a, b) {
      //   return a.finishingPosition - b.finishingPosition
      // })
      return dataSet
    },
    isMobile () {
      /* eslint-disable */
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    checkClass: function(x) {
      if (x.includes("Benchmark", "HANDICAP")) {
        const [first, second] = x.split(",");
        if (second === undefined) {
          const [a, b] = first.split(" ");
          return `BM ${b}`;
        }
        if (second === "HANDICAP=TRUE" || second === "HANDICAP=FALSE") {
          const [a, b] = first.split(" ");
          return `BM ${b}, HCP`;
        }
      }
      if (x.includes("HANDICAP=TRUE")) {
        // eslint-disable-next-line no-unused-vars
        const [first, second] = x.split(",");
        return `${first}, HCP`;
      }
      if (x.includes("HANDICAP=FALSE")) {
        // eslint-disable-next-line no-unused-vars
        const [first, second] = x.split(",");
        return `${first}`;
      }
      if (x.includes("Benchmark")) {
        const [first, second] = x.split(" ");
        return `BM ${second}`;
      }
      if (x.includes("Group l")) {
        return "Open,Group 1";
      }
      return x;
    },
    checkFinalPos(pos) {
      if (pos === 0) {
        return "*";
      } else {
        return pos;
      }
    },
    checkSpell(dsls) {
      if (dsls >= 21 && dsls <= 41) {
        return "FRESHENED";
      } else if (dsls >= 42 && dsls <= 83) {
        return "LET UP";
      } else if (dsls >= 84) {
        return "SPELL";
      }
    },
    totalRunners(d) {
      const runnerNo = d.race.competitors.length;
      const scratched = d.race.competitors.filter(b => b.scratched === true);
      const scratchNo = scratched.length;
      return runnerNo - scratchNo;
    },
    runningToday(d) {
      if (
        this.race.name === d.race.name &&
        this.race.startTime === d.race.startTime
      ) {
        return true;
      }
      return false;
    },
    runningFuture(d) {
      const psMilli = new Date(d.startTime);
      const csMilli = new Date(this.race.startTime);
      const pastStartTime = psMilli.getTime();
      const currStartTime = csMilli.getTime();
      if (pastStartTime > currStartTime) {
        return true;
      }
      return false;
    },
    runnerHasPastStartsDO() {
      return this.runnerHistoryFilter?.length >= 1
    },
    runnerHasPastStarts() {
      const pastStarts = this.runner.runner.competitorsConnection
        .competitors;
      const competitorList = [];
      pastStarts.forEach(d => competitorList?.push(d.scratched, d.race.status));
      if (
        pastStarts.length > 1 &&
        competitorList.status !== "ABANDONED" &&
        !competitorList.scratched
      ) {
        return true;
      }
      return false;
    },
    getShortFromRect () {
      const shortForm = document.querySelector(`#${this.newClassId}`)
      const shortFormRec = shortForm?.getBoundingClientRect()
      return shortFormRec
    },
    checkDefaultFilters () {
      const defaultChecks = []
      if (this.raceFilters.trackConditions.length === 1 && this.raceFilters.trackConditions.includes('Any')) {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }

      if (this.raceFilters.finishingPosition === 'All') {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (this.raceFilters.track === 'All') {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (this.raceFilters.spell === 'All') {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (this.raceFilters.margin === 12.5 || this.raceFilters.margin === '12.5') {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (this.raceFilters.finishPositionOrMargin === false) {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (isEmpty(this.raceFilters.distance.from)) {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }
      if (this.raceFilters.classes.length === 1 && this.raceFilters.classes.includes('all')) {
        defaultChecks.push(true)
      } else {
        defaultChecks.push(false)
      }

      return !defaultChecks.includes(false)
    },
    onOpenNewRace ({ history }) {
      if (!isEmpty(history.raceId)) {
        this.fetchRace({ id: history.raceId })
      } else {
        this.apiErrosAXN({ error: 'Can\'t find race...', type: 'error' })
      }
    },
    onShowLongForm ({ history, index }) {
      if (!isEmpty(this.prevHistoryExpanded)) Vue.set(this.historyExpanded, this.prevHistoryExpanded, !this.historyExpanded[this.prevHistoryExpanded])

      if (this.prevHistoryExpanded === `${history.date}-${index}`) {
        Vue.set(this.historyExpanded, this.prevHistoryExpanded, false)
        this.prevHistoryExpanded = ''
      } else {
        const key = `${history?.date}-${index}`
        Vue.set(this.historyExpanded, key, !this.historyExpanded[key])
        this.prevHistoryExpanded = key
      }
    },
    onSetExpands ({ history }) {
      if (!isEmpty(history)) {
        history.forEach((starts, index) => {
          Vue.set(this.historyExpanded, `${starts.date}-${index}`, false)
        });
      }
    },
    onOpenHorseSearchTab ({ placement }) {
      this.fetchSingularRunnerWithGlobalIdAndName({ runnerGlobalId: placement.runnerGlobalId, runnerName: placement.name })
    },
  }
};
</script>

<style scoped lang="scss">
.short-form {
  padding-bottom: 50px;
}

.long-form {

&__active-hover {
    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
}

.simpleTable td {
  height: 25px !important;
  font-size: 12px !important;
  white-space: nowrap !important;
}
.stewardsComment {
  border-bottom: none !important;
}
.btrialColor {
  color:rgb(179, 179, 179);
}
.barrier-trialname {
  color:red;
}
.barrier-trial {
  font-style: italic;
  color:#999;
  background-color: rgba(0,0,0,0.04)
}
.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 5px;
  padding-right: 0;
}
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th {
  padding-left: 5px;
  padding-right: 0;
}
/*tr:nth-child(even) {*/
/*  background-color: #f2f2f2;*/
/*}*/

.color-Firm {
  color: rgb(0, 204, 255);
}
.color-Firm1 {
  color: rgb(0, 204, 255);
}
.color-Firm2 {
  color: rgb(0, 204, 255);
}
.color-Good {
  color: rgb(0, 219, 0);
}
.color-Good3 {
  color: rgb(0, 219, 0);
}
.color-Good4 {
  color: rgb(0, 219, 0);
}
.color-Soft {
  color: rgb(224, 210, 56);
}
.color-Soft5 {
  color: rgb(224, 210, 56);
}
.color-Soft6 {
  color: rgb(224, 210, 56);
}
.color-Soft7 {
  color: rgb(224, 210, 56);
}
.color-Heavy {
  color: orange;
}
.color-Heavy8 {
  color: orange;
}
.color-Heavy9 {
  color: orange;
}
.color-Heavy10 {
  color: orange;
}
.color-Synthetic {
  color: purple;
}
.tableHeaders {
  /* font-size: 14px; */
  /* line-height: 14px; */
}

.active-hover:hover {
  cursor: pointer;
  font-weight: bold;
}

.pointer {
  cursor: pointer;
}

.loading {
  font-weight: 500;
  color: rgba(0,0,0,0.5);
  font-size: 13px;
}

  .runner-table {
    height: 600px !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    /* padding-bottom: 150px !important; */
  }

@media only screen and (min-width: 1903px) {
  .runner-table {
    height: 1000px !important;
    /* padding-bottom: 50px !important; */
  }
}
</style>
