<template>
  <FormAnalyzer />
</template>

<script>
import Auth from '@aws-amplify/auth'
import { mapState, mapActions, mapGetters } from 'vuex'
import FormAnalyzer from '../components/FormAnalyzer.vue'
import { isEmpty } from '../common/methods'

export default {
  data: () => ({
    isDrawerToggler: true
  }),
  components: {
    FormAnalyzer
  },
  watch: {
    isDrawer (newValue) {
      this.isDrawerToggler = newValue
    }
  },
  async created () {
    const queryParameters = this.$route.query
    try {
      const loggedInUser = await Auth.currentAuthenticatedUser()
      if (!isEmpty(queryParameters)) {
        if (queryParameters.u_name !== loggedInUser.username) {
          this.logout()
        }
      }
    } catch (error) {
      //
    }
  },
  computed: {
    ...mapState(['isDrawer', 'isAutoHide', 'races']),
    ...mapState('account', ['authorized']),
    ...mapGetters(['getSelectedRace']),
    race: function () {
      return this.getSelectedRace()
    }
  },
  methods: {
    ...mapActions(['fetchRaces', 'toggleDrawer']),
    ...mapActions('account', ['logout']),
    onMouseEnter () {
      if (this.isAutoHide) {
        this.toggleDrawer()
      }
    }
  }
}
</script>

<style>
.v-navigation-drawer--close {
  visibility: visible !important;
}

.btn {
  width: 250px !important;
}

.btn div span {
  margin-right: 5px;
}

.btn div span + span {
  color: white !important;
}
</style>
