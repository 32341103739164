<template>
  <v-snackbar :value="!isEmpty(appErrors.error)" :top="true" :timeout="3200" color="red">
    <div class="d-flex align-center">
      <v-icon class="mr-2">mdi-alert</v-icon><span class="text-md-body-1">{{ appErrors.error }}</span>
    </div>
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'
import { isEmpty } from '../common/methods'

export default {
  name: 'AlertMsg',
  props: {
    type: {
      type: String,
      default: 'error'
    }
  },
  computed: {
    ...mapState(['appErrors'])
  },
  methods: {
    isEmpty
  }
}
</script>

<style scoped>
.alert {
  height: 60px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  width: 500px;
  z-index: 9000000000;
}
</style>
