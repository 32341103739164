<template>
  <div class="overflow-x-hidden">
    <v-toolbar elevation="1" color="#37474f" class="white--text pa-0" dark dense>
      <div class="d-flex justify-center align-center justify-space-between" style="width:100%;">
        <v-toolbar-title>Race Day Search</v-toolbar-title>
        <v-btn class="mr-n2" small icon @click="closeAzListModal"><v-icon size="20">mdi-close</v-icon></v-btn>
      </div>
      <v-spacer></v-spacer>
    </v-toolbar>

    <div class="race-day pa-2 overflow-y-hidden overflow-x-hidden">
      <div class="d-flex align-center mb-3 mt-4">
        <p class="text-md-body-1 ma-0 mr-2">Mode:</p>
        <v-radio-group v-model="optionSelected" hide-details class="ma-0 pa-0" row>
          <v-radio color="#37474f" class="mr-4 radio-btn" label="Search" value="search"></v-radio>
          <v-radio color="#37474f" class="mr-4 radio-btn" label="A to Z Runners" value="aZRunners"></v-radio>
          <v-radio color="#37474f" class="mr-4 radio-btn" label="A to Z Jockeys/Drivers" value="aZJockeysDrivers"></v-radio>
          <v-radio color="#37474f" class="radio-btn" label="A to Z Trainers" value="aZTrainers"></v-radio>
        </v-radio-group>
      </div>

      <div class="d-flex align-center mt-4 mb-3" v-if="optionSelected !== 'search'">
        <p class="d-inline mb-0">Letter:</p>
        <v-row class="ml-2">
          <v-btn
            outlined
            class="letters"
            small
            v-for="letter in alphabet"
            :key="letter"
            :value="letter"
            @click="selectLetter(letter)"
            :color="letterSelected === letter ? 'info' : 'normal'"
            >{{ letter }}</v-btn
          >
        </v-row>
      </div>

      <div style="width: 34%;" class="d-flex justify-center align-center mt-4 mb-3" v-else>
        <p class="text-md-body-1 ma-0 mr-4">Search:</p>
        <v-row>
          <v-text-field class="ml-2" placeholder="Search" outlined v-model="search" height="20px" @keydown.enter="searchResults()" dense hide-details></v-text-field>
          <v-btn @click="searchResults()" height="40px" x-small outlined class="ml-1"><v-icon>mdi-magnify</v-icon></v-btn>
        </v-row>
      </div>

      <div  class="d-flex justify-center">
        <p class="mb-1 mt-n2 text-sm-body-2 font-weight-medium red--text" v-if="isTrial">Limited Functionality in Free Trial showing Results for {{ race.meeting.track.name }}</p>
      </div>

      <v-data-table class="mt-6" height="700" dense :headers="headers" fixed-header :items="azQuery" hide-default-footer :items-per-page="9999" :loading="loadingStatus.isLoading" :loading-text="loadingStatus.loadingText">
        <template v-slot:item="{ item }">
          <tr v-if="item.meetingType === 'THOROUGHBRED'">
            <td>
              <img :src="raceTypeImg[item.meetingType]" style="max-width:20px" />
            </td>
            <td>
              <div class="d-flex align-center">
                <v-btn v-if="isSavedToBlackbook({ item, tabType: 'runnerTab' })" x-small color="black" class="white--text pa-0" style="min-width: 24px;" title="Add Runner To Blackbook" @click="fetchRunnerRace({ item, isAZList: true, tabType: 'runnerTab' })">
                  <v-icon size="12" color="white" style="" class="cursor">mdi-book-open-page-variant</v-icon>
                </v-btn>
                <v-btn v-else @click="removeFromBlackbook({ co: item, tabType: 'runnerTab' })" x-small color="red" class="white--text pa-0" style="min-width: 24px;" title="Remove Runner From Blackbook">
                   <v-icon size="12" color="white" style="" class="cursor">mdi-book-open-page-variant</v-icon>
                </v-btn>
                <div class="d-flex align-center">
                  <v-icon size="14" class="ml-2 mr-1" color="black">mdi-horseshoe</v-icon>
                  <span class="cursor" @click="addThisRunner({ co: item, tabType: 'runnerTab' })">{{ item.runner }}</span>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex align-center">
                <v-btn v-if="isSavedToBlackbook({ item, tabType: 'jockeyTab' })" x-small color="black" class="white--text pa-0" style="min-width: 24px;" title="Add Jockey To Blackbook" @click="fetchRunnerRace({ item, isAZList: true, tabType: 'jockeyTab' })">
                  <v-icon size="12" color="white" style="" class="cursor">mdi-book-open-page-variant</v-icon>
                </v-btn>
                <v-btn v-else @click="removeFromBlackbook({ co: item, tabType: 'jockeyTab' })" x-small color="red" class="white--text pa-0" style="min-width: 24px;" title="Remove Jockey From Blackbook">
                   <v-icon size="12" color="white" style="" class="cursor">mdi-book-open-page-variant</v-icon>
                </v-btn>
                 <div class="d-flex align-center">
                  <v-icon size="14" class="ml-2 mr-1" color="black">mdi-account</v-icon>
                  <span class="cursor" @click="addThisRunner({ co: item, tabType: 'jockeyTab' })">{{ item.jockeyDriver }}</span>
                </div>
              </div>
            </td>
            <td>
              <div class="d-flex align-center">
                <v-btn v-if="isSavedToBlackbook({ item, tabType: 'trainerTab' })" x-small color="black" class="white--text pa-0" style="min-width: 24px;" title="Add Trainer To Blackbook" @click="fetchRunnerRace({ item, isAZList: true, tabType: 'trainerTab' })">
                  <v-icon size="12" color="white" style="" class="cursor">mdi-book-open-page-variant</v-icon>
                </v-btn>
                <v-btn v-else @click="removeFromBlackbook({ co: item, tabType: 'trainerTab' })" x-small color="red" class="white--text pa-0" style="min-width: 24px;" title="Remove Trainer From Blackbook">
                   <v-icon size="12" color="white" style="" class="cursor">mdi-book-open-page-variant</v-icon>
                </v-btn>
                <div class="d-flex align-center">
                  <v-icon size="14" class="ml-2 mr-1" color="black">mdi-binoculars</v-icon>
                  <span class="cursor" @click="addThisRunner({ co: item, tabType: 'trainerTab' })">{{ item.trainer }}</span>
                </div>
              </div>
            </td>
            <td>{{ item.race }}</td>
            <td>{{ item.date === today ? 'Today' : item.date }}</td>
            <td>{{ item.competitorNumber }}</td>
            <td>{{ item.barrier }}</td>
            <td>{{ item.handicap }}</td>
            <td :class="isTrial ? 'raceInformationDisabled' : 'raceInformation'" @click="isTrial ? '' : clickRace({ item, isAZList: false })">Load Race</td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { format, startOfDay, isSameDay, parseISO } from 'date-fns'
import { mapState, mapActions, mapGetters } from 'vuex'
import { isEmpty } from '../common/methods'

export default {
  props: {
    closeAzList: Function,
    searchOnOpen: String,
    raceDaySearchOpen: Boolean,
    azListModal: Boolean
  },
  data () {
    return {
      search: '',
      raceTypeImg: {
        HARNESS: '/img/race_types/DO_RaceType_Harness-BLACK.svg',
        GREYHOUND: '/img/race_types/DO_RaceType_Greyhounds-BLACK.svg',
        THOROUGHBRED: '/img/race_types/DO_RaceType_Gallops-BLACK.svg'
      },
      headers: [
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Runner',
          value: 'runner'
        },
        {
          text: 'Jockey/Driver',
          value: 'jockeryDriver'
        },
        {
          text: 'Trainer',
          value: 'trainer'
        },
        {
          text: 'Race',
          value: 'race'
        },
        {
          text: 'Date',
          value: 'date'
        },
        {
          text: 'No.',
          value: 'tabNo'
        },
        {
          text: 'Bar',
          value: 'barrier'
        },
        {
          text: 'HCP',
          value: 'handicap'
        },
        {
          text: '',
          value: 'race'
        }
      ],
      alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
      codes: ['THOROUGHBRED', 'HARNESS', 'GREYHOUND'],
      letterSelected: 'A',
      optionSelected: 'aZRunners',
      today: format(new Date(), 'dd/MM/yyyy'),
      isAZList: false,
      currentRace: {},
      tabType: ''
    }
  },
  computed: {
    ...mapState(['singularRaces', 'runnerBlackbook', 'jockeyBlackbook', 'trainerBlackbook']),
    ...mapState('account', ['isTrial']),
    ...mapGetters(['getAzList', 'getLoadAZListData', 'getSelectedRace']),
    race: function () {
      const race = this.getSelectedRace()
      return race
    },
    azQuery: function () {
      const azList = this.getAzList(this.letterSelected, this.optionSelected, this.codes, this.search)
      if (this.optionSelected === 'aZRunners') {
        azList.sort(function (a, b) {
          if (a.runner < b.runner) {
            return -1
          }
          if (a.runner > b.runner) {
            return 1
          }
          return 0
        })
      } else if (this.optionSelected === 'aZJockeysDrivers') {
        azList.sort(function (a, b) {
          if (a.jockeyDriver < b.jockeyDriver) {
            return -1
          }
          if (a.jockeyDriver > b.jockeyDriver) {
            return 1
          }
          return 0
        })
      } else if (this.optionSelected === 'aZTrainers') {
        azList.sort(function (a, b) {
          if (a.trainer < b.trainer) {
            return -1
          }
          if (a.trainer > b.trainer) {
            return 1
          }
          return 0
        })
      }
      // return meetingsDatedAz(new Date())
      return azList
    },
    combinedBlackbook () {
      const combined = [...this.runnerBlackbook, ...this.jockeyBlackbook, ...this.trainerBlackbook]
      return combined
    },
    selected: {
      get () {
        return this.codes
      },
      set (value, newValue) {
        return this.onClick(value)
      }
    },
    loadingStatus () {
      if (isEmpty(this.search)) {
        return { isLoading: false, loadingText: 'Data not found...' }
      } else {
        return { isLoading: isEmpty(this.azQuery), loadingText: 'Loading...' }
      }
    }
  },
  watch: {
    raceDaySearchOpen () {
      if (this.raceDaySearchOpen) return this.searchFromNav()
    },
    singularRaces (newState) {
      const race = newState[newState.length - 1]
      const co = race.competitors.find(co => co.name === this.currentRace.runner && this.currentRace.barrier === co.barrier && this.currentRace.competitorNumber === co.tabNo)

      if (!isEmpty(co)) {
        const selectedTabTypeName = this.returnSelectedRunnerName({ azRunner: this.currentRace, tabType: this.currentRace.tabType })

        const runner = {
          tabType: this.currentRace.tabType,
          runnerId: co.runner.id,
          selectedTabTypeName,
          raceNumber: race?.number,
          meetingId: race?.meeting?.id
        }

        this.addToBlackBook({ blackbook: runner })
      }
    },
    azListModal (newState) {
      if (!newState) {
        this.closeAzListModal()
      }
    }
  },
  created () {
    return this.searchFromNav()
  },
  methods: {
    ...mapActions(['selectRace', 'searchAzLists', 'setDate', 'fetchAzList', 'fetchRaces', 'addToBlackBook', 'addToRunnerTabs', 'addToRunnerTabsFromAZList', 'deleteRunnerFromBlackbook', 'deleteJockeyFromBlackbook', 'deleteTrainerFromBlackbook', 'fetchRaceRunner']),
    closeAzListModal () {
      // this.$emit('clicked')
      this.closeAzList()
      this.search = ''
      // this.optionSelected = 'aZRunners'
    },
    closeAzListHere () {
      this.closeAzList()
      this.search = ''
      this.optionSelected = 'aZRunners'
    },
    selectLetter (letterSelected) {
      this.letterSelected = letterSelected
    },
    async clickRace ({ item }) {
      const { raceID, startTime: date } = item

      await this.setDate(startOfDay(parseISO(date)))
      if (isSameDay(startOfDay(Date.now()), date)) {
        this.selectRace({
          id: raceID
        })
      } else {
        await this.fetchRaces()
        this.selectRace({
          id: raceID
        })
      }
      this.closeAzListModal()
    },
    async fetchRunnerRace ({ item, tabType }) {
      this.currentRace = { ...item, tabType }
      this.fetchRaceRunner({ raceId: item.raceID })
    },
    onClick (value) {
      this.codes = value
    },
    async searchFromNav () {
      if (this.raceDaySearchOpen) {
        this.optionSelected = 'search'
      }
      this.search = this.searchOnOpen
      if (this.getLoadAZListData) {
        await this.fetchAzList()
      }
      this.searchAzLists({ search: this.searchOnOpen })
    },
    searchResults () {
      this.searchAzLists({ search: this.search })
    },
    addThisRunner ({ co, tabType }) {
      this.addToRunnerTabsFromAZList({ co, tabType })
      this.clickRace({ item: co, isAZList: true, tabType })
    },
    isSavedToBlackbook ({ item: azRunner, tabType }) {
      const selectedTabTypeName = this.returnSelectedRunnerName({ azRunner, tabType })

      const found = this.combinedBlackbook.find(blackbook => {
        return blackbook.selectedTabTypeName === selectedTabTypeName && blackbook.tabType === tabType
      })
      return isEmpty(found)
    },
    removeFromBlackbook ({ co, tabType }) {
      const blackbook = { tabType, runnerId: co.runnerId }

      if (tabType === 'runnerTab') {
        this.deleteRunnerFromBlackbook({ blackbook })
      } else if (tabType === 'jockeyTab') {
        this.deleteJockeyFromBlackbook({ blackbook })
      } else if (tabType === 'trainerTab') {
        this.deleteTrainerFromBlackbook({ blackbook })
      }
    },
    returnSelectedRunnerName ({ azRunner, tabType }) {
      let name = azRunner.runner
      if (tabType === 'jockeyTab') {
        name = azRunner.jockeyDriver
      } else if (tabType === 'trainerTab') {
        name = azRunner.trainer
      }

      return name
    }
  }
}
</script>

<style>
.race-day {
  background-color: white;
}

.letters {
  margin-right: 4px;
}

.raceInformation {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.raceInformationDisabled {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  color: gray;
}

.v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 5px !important;
  font-size: 10px !important;
}

.v-input--selection-controls__ripple {
  font-size: 10px;
}

.radio-btn >>> label {
  font-size: 14px;
}

.radio-btn >>> i {
  font-size: 16px;
}

.cursor {
  cursor: pointer;
}
</style>
