import { ApolloClient } from 'apollo-client'
import { split, ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'node-fetch'
import account from './store/account'

const withToken = setContext(async () => {
  await window.dfApp.$store.dispatch('account/updateCoreAPIToken', null, { root: true })
  const token = window.localStorage.getItem('coreapi_auth_token')

  let headers = {
    authorization: `Bearer ${token}`,
    'api-key': '987B3C0E-A46F-4695-9187-1E5CCF36D8B3'
  }

  if (account.state.isTrial && process.env.VUE_APP_ENV === 'prodc') {
    headers = {
      'api-key': '987B3C0E-A46F-4695-9187-1E5CCF36D8B3'
    }
  }

  return {
    headers
  }
})

const httpLink = ApolloLink.from([withToken, new HttpLink({
  uri: `${process.env.VUE_APP_GRAPHQL_HTTP}query`,
  options: { mode: 'no-cors' }
})])

const subscriptionLink = process.browser
  ? new WebSocketLink({
    uri: `${process.env.VUE_APP_GRAPHQL_WS}`,
    options: {
      mode: 'no-cors',
      reconnect: true,
      lazy: true,
      connectionParams: async () => {
        await window.dfApp.$store.dispatch('account/updateCoreAPIToken', null, { root: true })
        const token = window.localStorage.getItem('coreapi_auth_token')

        return {
          Authorization: `Bearer ${token}`
        }
      }
    }
  })
  : null

const subLink = process.browser ? subscriptionLink : null

const link = process.browser
  ? split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    subLink,
    httpLink
  )
  : httpLink

const cache = new InMemoryCache({ resultCaching: false })

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore'
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
}

export const subscriptionClient = subscriptionLink?.subscriptionClient || {}

export const apolloClient = new ApolloClient({
  link,
  fetch,
  cache,
  defaultOptions
})
