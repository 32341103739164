<template>
  <div :class="!isAutoHide ? 'pannel-btn-padding' : ''">
    <RaceTable/>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import RaceTable from './RaceTable'
export default {
  computed: {
    ...mapState(['isAutoHide'])
  },
  components: {
    RaceTable
  }
}
</script>

<style>
.pannel-btn-padding {
  padding-left: 40px;
  transition: all ease 0.2s;
}
</style>
