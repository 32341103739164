<template>
  <v-dialog v-model="dialog" max-width="700">
    <template v-slot:activator="{ on }">
      <v-btn elevation="1" tile x-small color="success" class="d-inline mr-2" v-on="on" @click.stop="openOptions">
        <v-icon class="mr-1" small>
          mdi-dots-horizontal-circle
        </v-icon>
        <span>Ratings</span>
      </v-btn>
    </template>

    <v-card class="pb-4">
      <v-toolbar class="white--text mb-4" color="#37474f" dark dense style="position: sticky; top: 0; z-index: 999;">
        <div style="width: 100%;">
          <v-toolbar-title class="float-left">Ratings Options</v-toolbar-title>
          <v-btn class="float-right mr-n2" icon small @click.stop="dialog = false"><v-icon size="20">mdi-close</v-icon></v-btn>
        </div>
      </v-toolbar>
      <v-card-text class="pb-0">
        <p class="ma-0 font-weight-medium">Odds:</p>
        <v-row>
          <v-col cols="4">
            <v-text-field label="Default Market Percentage" dense hide-details outlined class="mt-6" type="number" min="0" v-model.number="defaultMarketPercentageDisplay"></v-text-field>
            <v-text-field label="Default Market Range" dense hide-details outlined class="mt-6" type="number" min="0" max="10" v-model.number="defaultMarketRangeDisplay"></v-text-field>
          </v-col>
          <!-- <v-col cols="4">
            <p class="ma-0 font-weight-medium">Odds Format:</p>
            <v-radio-group v-model="defaultOddsFormatDisplay" class="d-flex" dense :disabled="isTrial">
              <v-radio value="fractional" color="#37474f" disabled>
                <template v-slot:label>
                  <span class="ml-1">Fractional</span>
                </template>
              </v-radio>
              <v-radio value="decimal" color="#37474f">
                <template v-slot:label>
                  <span class="ml-1">Decimal</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col> -->
          <!-- <v-col cols="3">
            <p class="ma-0 font-weight-medium">Ratings to Use:</p>
            <v-radio-group v-model="defaultRatingToUseDisplay" class="d-flex" dense :disabled="isTrial">
              <v-radio value="dry" color="#37474f">
                <template v-slot:label>
                  <span class="ml-1">Dry</span>
                </template>
              </v-radio>
              <v-radio value="wet" color="#37474f">
                <template v-slot:label>
                  <span class="ml-1">Wet</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col> -->
          <v-col cols="4">
            <p class="ma-0 font-weight-medium">Odds to use:</p>
             <v-radio-group v-model="defaultOddToUseDisplay" class="d-flex" dense :disabled="isTrial">
               <v-radio value="user" color="#37474f" disabled>
                <template v-slot:label>
                  <span class="ml-1">User</span>
                </template>
              </v-radio>
              <v-radio value="calculated" color="#37474f">
                <template v-slot:label>
                  <span class="ml-1">Calculated</span>
                </template>
              </v-radio>
             </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn elevation="1" small color="#37474f" class="white--text" :disabled="isTrial" @click="saveOptions">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    ...mapState('account', ['isTrial']),
    ...mapState(['marketPercentage', 'marketRange', 'oddsFormat', 'oddsToUse', 'ratingToUse']),
    defaultMarketPercentageDisplay: {
      get: function () {
        return parseFloat(this.marketPercentage).toFixed(1)
      },
      set: function (newValue) {
        this.setMarketPercentage({ value: newValue })
      }
    },
    defaultMarketRangeDisplay: {
      get: function () {
        return parseFloat(this.marketRange).toFixed(1)
      },
      set: function (newValue) {
        this.setMarketRange({ value: newValue })
      }
    },
    defaultOddsFormatDisplay: {
      get: function () {
        return this.oddsFormat
      },
      set: function (newValue) {
        this.setOddsFormat({ value: newValue })
      }
    },
    defaultOddToUseDisplay: {
      get: function () {
        return this.oddsToUse
      },
      set: function (newValue) {
        this.setOddsToUse({ value: newValue })
      }
    },
    defaultRatingToUseDisplay: {
      get: function () {
        return this.ratingToUse
      },
      set: function (newValue) {
        this.setRatingToUse({ value: newValue })
      }
    }
  },
  methods: {
    ...mapActions(['setOptions', 'setMarketRange', 'setMarketPercentage', 'setOddsToUse', 'setOddsFormat', 'setRatingToUse', 'calculateOdds']),
    openOptions () {
      this.dialog = true
    },
    saveOptions () {
      this.setOptions()
      this.calculateOdds()
      this.dialog = false
    }
  }
}
</script>
