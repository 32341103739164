<template>
  <div class="tooltipCountry">
    <div style="vertical-align: top;display: inline-block; margin-top:-30px; padding-top:10px;">
    <country-flag :country="meeting.track.country" size="normal"/>
    </div>
    <span class="tooltiptextCountry">{{meeting.track.country}}</span>
  </div>
</template>

<script>
export default {
  props: {
    meeting: Object
  },
  methods: {
    flagClass (country) {
      return `flag-${country}`
    },
    flagSmallClass (country) {
      return `flagSmall-${country}`
    }
  }
}
</script>

<style>
.tooltipCountry {
  position: relative;
  display: inline-block;
  cursor: pointer;

}
.tooltipCountry .tooltiptextCountry {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 10%;
  left: 105%;
  white-space: nowrap;
}

.tooltipCountry:hover .tooltiptextCountry {
  visibility: visible;
}
</style>
