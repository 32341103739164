import { Auth } from 'aws-amplify'
import axios from 'axios'
import { hasTokenExpired, isEmpty } from '../common/methods'
import { DF_SUBSCRIPTION_PRODUCTCODE, DF_VALID_SUBSCRIPTION_CODES } from '../common/constants'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_GRAPHQL_HTTP
})

axiosInstance.interceptors.request.use(async function (config) {
  await window.dfApp.$store.dispatch('account/updateAuthToken')
  const token = window.localStorage.getItem('auth_token')

  config.headers.Authorization = `Bearer ${token}`

  return config
}, err => Promise.reject(err))

const store = {
  namespaced: true,
  state: {
    authorized: null,
    user: null,
    coreApiToken: null,
    isTrial: null,
    doScriptionAlert: null,
    memberId: null
  },
  mutations: {
    user (state, user) {
      state.authorized = !!user && !!user.username
      state.user = user
    },
    saveCoreApiToken (state, { token }) {
      state.coreApiToken = token
    },
    setIsTrial (state, { value }) {
      state.isTrial = value
    },
    updateDOToggle (state, { value }) {
      state.doScriptionAlert = value
    },
    setMemberId (state, memberId) {
      state.memberId = memberId
    }
  },
  actions: {
    async fetchUser (context, { isTrial }) {
      await context.commit('setIsTrial', { value: isTrial })
      if (isTrial) {
        const user = {
          username: 'trial'
        }
        await context.commit('user', user)
      } else {
        try {
          const user = await Auth.currentAuthenticatedUser()
          // context.commit('user', user)
          const subscriptionLevel = await context.dispatch('checkSubscription')
          if (DF_VALID_SUBSCRIPTION_CODES.includes(subscriptionLevel)) {
            await context.commit('user', user)
            window.localStorage.setItem('auth_token', user.getSignInUserSession().getAccessToken().getJwtToken())
          } else {
            await context.commit('user', null)
          }

          return user
        } catch (err) {
          context.commit('user', null)
        }
      }
    },
    async checkSubscription (context) {
      await context.dispatch('updateAuthToken')
      const token = window.localStorage.getItem('auth_token')
      const config = context.getters.authConfig
      const hasNewSubFeature = context.rootGetters.hasFeature('newSubscription')
      let userSubInfo = {}
      if (hasNewSubFeature) {
        userSubInfo = await window.__tbm__widget__sub.exposed.checkUserSubscriptionInfo(token, context.state.user?.attributes?.email, DF_SUBSCRIPTION_PRODUCTCODE, context.state.user?.username)
      }
      if (!userSubInfo.subStatus && context.rootGetters.hasFeature('legacySubscription')) {
        const result = await axios.post('api.public.Public/GetSubscription', {}, config)
        userSubInfo = { code: result?.data?.subscriptionLevel }
      }
      return userSubInfo.code
    },
    async bootsTrapNewSubscriptionWidget (context) {
      await context.dispatch('updateAuthToken')
      const token = window.localStorage.getItem('auth_token')
      const params = {
        userToken: token,
        email: context.state.user?.attributes?.email,
        productCode: DF_SUBSCRIPTION_PRODUCTCODE,
        userName: context.state.user?.username,
        memberId: context.state.memberId,
        isUpdatingCreditCard: false,
        callbackObj: {
          success: () => { window.location.reload() },
          cancel: () => { context.dispatch('logout') },
          cancelText: 'Log Out'
        }
      }
      window.__tbm__widget__sub.exposed.bootsTrap(params)
    },
    async updateAuthToken (context) {
      if (!context.state.isTrial) {
        if (!context.state.user || (context.state.user && context.state.user.getSignInUserSession().getAccessToken().payload.exp * 1000 < (new Date()).getTime())) {
          const user = await Auth.currentAuthenticatedUser()
          context.commit('user', user)
          window.localStorage.setItem('auth_token', user.getSignInUserSession().getAccessToken().getJwtToken())
        } else {
          const token = context.state.user
            .getSignInUserSession()
            .getAccessToken()
            .getJwtToken()
          window.localStorage.setItem('auth_token', token)
        }
      }
    },
    // async updateAuthToken (context) {
    //   if (!context.state.user) {
    //     context.dispatch('fetchUser')
    //   }
    // },
    async logout (context) {
      await Auth.signOut()
      localStorage.clear()
      window.location.reload() // Avoids any Amplify Auth issues
    },
    async fetchCoreAPIToken (context) {
      return new Promise((resolve, reject) => {
        if (!context.state.isTrial) {
          window.grecaptcha.enterprise.ready(async function () {
            try {
              // await context.dispatch('fetchUser', { isTrial: context.state.isTrial })
              const recaptureToken = await window.grecaptcha.enterprise.execute(`${process.env.VUE_APP_RECAPTCHA_SITE_KEY}`, { action: 'tokenexchange' })
              const config = context.getters.authConfig

              const lsToken = localStorage.getItem('coreapi_auth_token') ?? ''

              if (hasTokenExpired({ token: context.state.coreApiToken ?? lsToken })) {
                const result = await axiosInstance.get(`token?recaptcha=${recaptureToken}`, config)
                await context.commit('saveCoreApiToken', { token: result.data.AccessToken })
                window.localStorage.setItem('coreapi_auth_token', result.data.AccessToken)
              }

              resolve(true)
            } catch (error) {
              console.log(error)
              resolve(true)
            }
          })
        } else {
          context.commit('saveCoreApiToken', { token: 'DEMO' }).then(() => {
            resolve(true)
          })
          // if (process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'uat') {
          //   context.commit('saveCoreApiToken', { token: 'DEMO' }).then(() => {
          //     resolve(true)
          //   })
          // } else {
          //   resolve(true)
          //   return ''
          // }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    async getFreeTrialToken (context) {
      const token = 'DEMO'
      // let token
      // if (process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'uat') {
      //   token = 'DEMO'
      // } else {
      //   token = ''
      // }
      window.localStorage.setItem('coreapi_auth_token', token)
      await context.commit('saveCoreApiToken', { token })
    },
    parseJwt (context, { token }) {
      const tokenParsed = JSON.parse(atob(token.split('.')[1]))
      return tokenParsed
    },
    triggerDoToggle (context, { value }) {
      context.commit('updateDOToggle', { value })
    },
    async updateCoreAPIToken (context) {
      if (!context.state.isTrial) {
        if (hasTokenExpired({ token: context.state.coreApiToken })) {
          await context.dispatch('fetchCoreAPIToken')
        }

        const token = localStorage.getItem('coreapi_auth_token')
        if (!isEmpty(token)) {
          window.localStorage.setItem('coreapi_auth_token', token)
        } else {
          window.localStorage.setItem('coreapi_auth_token', context.state.coreApiToken)
        }
      }
    }
  },
  getters: {
    authToken: (state) => {
      if (!state.user) return null
      if (!state.isTrial) {
        const token = state.user
          .getSignInUserSession()
          .getAccessToken()
          .getJwtToken()
        return `${token}`
      } else {
        return 'DEMO'
        // if (process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'uat') {
        //   return 'DEMO'
        // } else {
        //   return ''
        // }
      }
    },
    authConfig: (state, getters) => {
      let config = {}
      const token = getters.authToken
      if (token && token !== '') {
        if (process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'uat' || process.env.VUE_APP_ENV === 'int') {
          config = {
            headers: { Authorization: `Bearer ${token}` }
          }
        }
      }
      return config
    },
    getIsTrial (state) {
      return state.isTrial
    },
    getCoreAPIToken (state) {
      return state.coreApiToken
    },
    getMemberId (state) {
      return state.memberId
    }
  }
}

export default store
