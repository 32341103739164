<template>
  <div class="mt-15">
    <apexchart width="100%" height ="400" type="bar" :options="ratingsOptions" :series="datesByForm"></apexchart>
  </div>
</template>

<script>
import { format } from 'date-fns'
import numeral from 'numeral'
import { getNumberWithOrdinal } from '../common/methods'
import { BARRIER_TRIAL } from '../common/constants'

export default {
  props: {
    race: Object,
    runner: Object
  },
  computed: {
    datesByForm () {
      const filteredHistory = this.runner?.formHistory.filter(history => !BARRIER_TRIAL.includes(history.raceName))
      const unadjustedRatings = filteredHistory.map(co => co.ratingUnadjusted)

      const series = [{
        data: unadjustedRatings,
        name: 'Ratings'
      }]

      return series
    },
    ratingsOptions () {
      const filteredHistory = this.runner?.formHistory.filter(history => !BARRIER_TRIAL.includes(history.raceName))
      const dates = filteredHistory.map(history => format(new Date(history.date), 'dd MMM yy'))

      const checkSpell = (dsls) => {
        if (dsls >= 21 && dsls <= 41) {
          return 'FRESHENED'
        } else if (dsls >= 42 && dsls <= 83) {
          return 'LET UP'
        } else if (dsls >= 84) {
          return 'SPELL'
        } else {
          return ''
        }
      }

      const options = {
        legend: {
          show: false
        },
        dataLabels: {
          enabled: true,
          orientation: 'vertical',
          style: {
            fontWeight: 'bold',
            colors: ['#FFF', '#333']
          },
          formatter: function (val, opt) {
            if (opt.seriesIndex === 0) {
              return val
            } else if (opt.seriesIndex === 1) {
              return ''
            }
          }
        },
        xaxis: {
          categories: dates,
          title: {
            text: 'Date'
          }
        },
        yaxis: {
          title: {
            text: 'Rating'
          }
        },
        tooltip: {
          custom: function ({ dataPointIndex, seriesIndex }) {
            const history = filteredHistory[dataPointIndex] ?? {}
            const finishingPosition = getNumberWithOrdinal(history?.finishingPosition ?? 0) ?? ''
            const startPrice = numeral(history?.startingPrice).format('$0a') ?? ''

            if (seriesIndex === 0) {
              return `
                <div class="pa-2">
                  <p class="ma-0 mb-1">Finished: ${finishingPosition}</p>
                  <p class="ma-0">Start Price: ${startPrice}</p>
                  <p class="ma-0">Days: ${history.dsls} ${checkSpell(history.dsls)}</p>
                </div>
              `
            } else if (seriesIndex === 1) {
              return `
                <div class="pa-2">
                  <p class="ma-0">${history.dsls} DAY ${checkSpell(history.dsls)}</p>
                </div>
              `
            }
          }
        },

        colors: [function ({ dataPointIndex }) {
          if (filteredHistory[dataPointIndex].finishingPosition === 1) {
            return '#FFD700'
          } else {
            return '#37474f'
          }
        },
        function ({ dataPointIndex }) {
          return 'transparent'
        }]
      }
      return options
    }
  },
  methods: {

  }
}
</script>

<style scoped>
.graph-hover {
  color: red;
}

.graph-hover li {
  color: pink !important;
}
</style>
