<script>
import { mapState, mapActions, mapGetters } from 'vuex'
// import { startOfDay, isSameDay, parseISO } from 'date-fns'
import BlackBookStable from './BlackBookStable.vue'
import BlackbookUpcomingStable from './BlackbookUpcomingStable.vue'
import BlackbookJockeyAndTrainer from './BlackbookJockeyAndTrainer.vue'
import BlackbookUpcomingJockeyAndtrainer from './BlackbookUpcomingJockeyAndtrainer.vue'
import { isEmpty, sortByKeyValue } from '../common/methods'
import Vue from 'vue'

export default {
  name: 'RaceDayNavigation',
  data: () => ({
    drawer: true,
    tabPosition: 0,
    blockHeaderPosition: 'Stable',
    tabHeaders: ['Stable', 'J&T', 'Upcoming'],
    radioGroupValue: 'trainer',
    isSortBy: null,
    sortBy: 'Defalut',
    upcomingRunners: [],
    upcomingJockey: [],
    upcomingTrainer: [],
    isCheckboxMode: false,
    isCheckboxMode2: false,
    loadAzList: true,
    isExpandAll: false,
    blackbookCheckboxes: {}
  }),
  computed: {
    ...mapState(['runnerBlackbook', 'jockeyBlackbook', 'trainerBlackbook', 'selectedDate', 'races', 'azList', 'isDrawer', 'smartTabs']),
    ...mapGetters(['getRaces']),
    jockeyAndTrainer: function () {
      if (this.radioGroupValue === 'trainer') {
        return this.trainerBlackbook
      } else if (this.radioGroupValue === 'jockey') {
        return this.jockeyBlackbook
      } else {
        const combined = [...this.trainerBlackbook, ...this.jockeyBlackbook]
        const sorted = combined.sort((a, b) => {
          if (a.selectedTabTypeName < b.selectedTabTypeName) return -1
          if (a.selectedTabTypeName > b.selectedTabTypeName) return 1
          return 0
        })
        return sorted
      }
    },
    upcomingCompetitors () {
      const open = this.azList.filter(el => el.status === 'OPEN')

      const blackbookFilterd = this.combinedBlackbook?.filter(co => {
        return !co?.meetingType?.includes('GREYHOUND') || co?.meetingType?.includes('THOROUGHBRED')
      })

      const current = blackbookFilterd.map(co => {
        const upcoming = open.filter(item => item.runnerId === co.runnerId)

        if (!isEmpty(upcoming[0])) {
          return {
            ...upcoming[0],
            appendedId: `${co.runnerId}-${co.tabType}`,
            appendedSelectedTabTypeName: co.selectedTabTypeName,
            appendedTabType: co.tabType
          }
        } else {
          return undefined
        }
      })

      const cleaned = current.filter(element => {
        return !isEmpty(element)
      })

      const sortedByTime = cleaned.sort((a, b) => sortByKeyValue({ key: 'startTime', a, b }))
      return sortedByTime
    },
    combinedBlackbook () {
      const combined = [...this.runnerBlackbook, ...this.jockeyBlackbook, ...this.trainerBlackbook]
      this.onSetCheckboxState({ blackbooks: combined })
      return combined
    }
  },
  methods: {
    ...mapActions(['restoreBlackBookFromLocalStorage', 'addToRunnerTabs', 'selectRace', 'triggerBlackbookChrome', 'addTabUpcomingRaces', 'multiDeleteRunnerFromBlackbook', 'multiDeleteJockeyFromBlackbook', 'multiDeleteTrainerFromBlackbook', 'fetchAzList', 'fetchRace']),
    handleUpdateRaceGrid ({ upcoming }) {
      this.fetchRace({ id: upcoming.raceID })
      // console.log(upcoming, 'upcomingupcomingupcomingupcoming')
      // const races = this.getRaces
      // const racesGroupByMeeting = races.reduce((acc, obj) => {
      //   const property = obj.meeting.track.name + '-' + obj.meeting.type
      //   acc[property] = acc[property] || []
      //   acc[property].push(obj)
      //   return acc
      // }, {})
      // for (const property in racesGroupByMeeting) {
      //   racesGroupByMeeting[property] = racesGroupByMeeting[property].filter(r => isSameDay(startOfDay(this.selectedDate), parseISO(r.meeting.date)))
      // }

      // console.log(racesGroupByMeeting, 'racesGroupByMeetingracesGroupByMeetingracesGroupByMeetingracesGroupByMeeting')

      // const racesArrObj = racesGroupByMeeting[`${upcoming.raceName}-${upcoming.meetingType}`]
      // const raceFilted = racesArrObj.find(race => race.id === upcoming.raceID)
      // this.raceGrid({ v: raceFilted })

      // // Trigger chrome tabs
      // const found = this.combinedBlackbook.find(
      //   item => item.name === upcoming.appendedName && item.tabType === upcoming.tabType && item.race.id === upcoming.raceID
      // )
      // if (!isEmpty(found)) {
      //   this.addTabUpcomingRaces({ upcoming: { upcoming, runner: found.runner } })
      // }
    },
    raceGrid ({ v }) {
      this.selectRace({ id: v.id })
    },
    handleAddToTab ({ upcoming }) {
      const blackbook = this.combinedBlackbook.find(co => `${co.runnerId}-${co.tabType}` === `${upcoming.appendedId}`)
      this.triggerBlackbookChrome({ blackbook })
    },
    uiIsEmpty (val) {
      return isEmpty(val)
    },
    onToggleDeleteMode () {
      this.isCheckboxMode = !this.isCheckboxMode
    },
    onToggleExpandAll () {
      this.isExpandAll = !this.isExpandAll
    },
    onRefreshAZList () {
      this.fetchAzList()
    },
    onSelectAll () {
      this.isCheckboxMode2 = !this.isCheckboxMode2

      for (const key in this.blackbookCheckboxes) {
        if (this.isCheckboxMode2) {
          if (this.tabPosition === 0) {
            if (key.includes('runnerTab')) Vue.set(this.blackbookCheckboxes, key, true)
          } else if (this.tabPosition === 1) {
            if (this.radioGroupValue === 'jockey') {
              if (key.includes('jockeyTab')) Vue.set(this.blackbookCheckboxes, key, true)
            } else if (this.radioGroupValue === 'trainer') {
              if (key.includes('trainerTab')) Vue.set(this.blackbookCheckboxes, key, true)
            } else if (this.radioGroupValue === 'a-z') {
              if (key.includes('jockeyTab') || key.includes('trainerTab')) Vue.set(this.blackbookCheckboxes, key, true)
            }
          } else if (this.tabPosition === 2) {
            Vue.set(this.blackbookCheckboxes, key, true)
          }
        } else {
          Vue.set(this.blackbookCheckboxes, key, false)
        }
      }
    },
    onReturnRunnerKey ({ blackbook }) {
      return `checkbox-${blackbook.runnerId}-${blackbook.tabType}`
    },
    onSetCheckboxState ({ blackbooks }) {
      if (!isEmpty(blackbooks)) {
        blackbooks.forEach(blackbook => {
          const key = this.onReturnRunnerKey({ blackbook })
          Vue.set(this.blackbookCheckboxes, key, false)
        })
      }
    },
    onReturnCheckboxState ({ blackbook }) {
      const key = this.onReturnRunnerKey({ blackbook })
      return this.blackbookCheckboxes[key]
    },
    onToggleDynamicCheckbox ({ blackbook }) {
      const key = this.onReturnRunnerKey({ blackbook })
      const isChecked = !this.blackbookCheckboxes[key]
      Vue.set(this.blackbookCheckboxes, key, isChecked)
    },
    onFilterBlackbooks ({ blackbooks }) {
      const blacklisted = []

      blackbooks.forEach(blackbook => {
        const key = this.onReturnRunnerKey({ blackbook })
        if (this.blackbookCheckboxes[key]) {
          blacklisted.push(blackbook)
        }
      })

      return blacklisted
    },
    onRemoveGroupedBlackbook () {
      const blackbooks = [...this.combinedBlackbook]

      const runnerBlackbooks = []
      const jockeyBlackbooks = []
      const trainerBlackbooks = []

      blackbooks.forEach(blackbook => {
        const key = this.onReturnRunnerKey({ blackbook })
        const isChecked = this.blackbookCheckboxes[key]

        if (blackbook.tabType === 'runnerTab' && isChecked) {
          runnerBlackbooks.push(blackbook)
        }
        if (blackbook.tabType === 'jockeyTab' && isChecked) {
          jockeyBlackbooks.push(blackbook)
        }
        if (blackbook.tabType === 'trainerTab' && isChecked) {
          trainerBlackbooks.push(blackbook)
        }
      })

      if (!isEmpty(runnerBlackbooks)) {
        const blacklisted = this.onFilterBlackbooks({ blackbooks: runnerBlackbooks })
        const filterdRunnerlackbook = blackbooks.filter(val => !blacklisted.includes(val) && val.tabType === 'runnerTab')
        this.multiDeleteRunnerFromBlackbook({ blackbooks: filterdRunnerlackbook, runnersToDelete: blacklisted })
        this.onSetCheckboxState({ blackbooks: filterdRunnerlackbook })
      }

      if (!isEmpty(jockeyBlackbooks)) {
        const blacklisted = this.onFilterBlackbooks({ blackbooks: jockeyBlackbooks })
        const filterdTrainsBlackbook = blackbooks.filter(val => !blacklisted.includes(val) && val.tabType === 'jockeyTab')
        this.multiDeleteJockeyFromBlackbook({ blackbooks: filterdTrainsBlackbook, jockeysToDelete: blacklisted })
        this.onSetCheckboxState({ blackbooks: filterdTrainsBlackbook })
      }

      if (!isEmpty(trainerBlackbooks)) {
        const blacklisted = this.onFilterBlackbooks({ blackbooks: trainerBlackbooks })
        const filterdTrainerBlackbook = blackbooks.filter(val => !blacklisted.includes(val) && val.tabType === 'trainerTab')
        this.multiDeleteTrainerFromBlackbook({ blackbooks: filterdTrainerBlackbook, trainersToDelete: blacklisted })
        this.onSetCheckboxState({ blackbooks: filterdTrainerBlackbook })
      }

      this.isCheckboxMode = false
      this.isCheckboxMode2 = false
    },
    onChangeHeaderBlocks ({ tab }) {
      this.blockHeaderPosition = tab
    }
  },
  components: {
    BlackBookStable,
    BlackbookJockeyAndTrainer,
    BlackbookUpcomingStable,
    BlackbookUpcomingJockeyAndtrainer
  }
}
</script>

<template>
  <v-container>
    <v-card class="blackbook__header" color="green lighten-5" elevation="0">
      <v-card elevation="0">
        <v-tabs v-model="tabPosition" fixed-tabs small>
          <v-tabs-slider color="green"></v-tabs-slider>
          <v-tab v-for="item in tabHeaders" @click="onChangeHeaderBlocks({ tab: item })" :key="item" class="text-caption">
            {{ item === 'Upcoming' ? `${item}(${upcomingCompetitors.length})` : item }}
          </v-tab>
        </v-tabs>
      </v-card>
      <div class="d-flex">
        <v-chip @click="onToggleDeleteMode" class="cursor my-2" color="green" outlined small>
          <v-icon v-if="!isCheckboxMode" left size="14">mdi-checkbox-blank-outline</v-icon>
          <v-icon v-else left size="14">mdi-checkbox-marked</v-icon>
          <span class="text-caption">Select</span>
        </v-chip>

        <v-chip v-if="blockHeaderPosition !== 'Upcoming'" @click="onToggleExpandAll" class="cursor ml-2 my-2" color="green" outlined small>
          <v-icon v-if="!isExpandAll" left size="14">mdi-checkbox-blank-outline</v-icon>
          <v-icon v-else left size="14">mdi-checkbox-marked</v-icon>
          <span class="text-caption">Expand All</span>
        </v-chip>

        <v-chip v-if="blockHeaderPosition === 'Upcoming'" @click="onRefreshAZList" class="cursor ml-2 my-2" color="green" outlined small>
          <v-icon left size="14">mdi-sync</v-icon>
          <span class="text-caption">Refresh (4 days in advance)</span>
        </v-chip>

        <v-chip v-if="isCheckboxMode" @click="onSelectAll" class="cursor my-2 ml-2" color="green" outlined small>
          <v-icon v-if="!isCheckboxMode2" left size="14">mdi-checkbox-blank-outline</v-icon>
          <v-icon v-else left size="14">mdi-checkbox-marked</v-icon>
          <span class="text-caption">All</span>
        </v-chip>

        <v-chip v-if="isCheckboxMode" @click="onRemoveGroupedBlackbook()" class="cursor ml-2 my-2" color="red" outlined small>
          <v-icon left size="14">mdi-trash-can</v-icon>
          <span class="text-caption">Remove</span>
        </v-chip>
      </div>
    </v-card>

    <v-tabs-items v-model="tabPosition" class="blackbook__tab-items">
      <v-tab-item :transition="false">
        <BlackBookStable :isCheckboxMode="isCheckboxMode" :isCheckboxMode2="isCheckboxMode2" :isExpandAll="isExpandAll" :onReturnCheckboxState="onReturnCheckboxState" :onToggleDynamicCheckbox="onToggleDynamicCheckbox" :onReturnRunnerKey="onReturnRunnerKey" :combinedBlackbook="combinedBlackbook" />
      </v-tab-item>

      <v-tab-item :transition="false">
        <v-radio-group hide-details dense class="ma-0 mb-2 pa-0 rounded-b-0 blackbook__radio" v-model="radioGroupValue">
          <v-card elevation="0" height="38px" class="d-flex justify-space-between align-center px-3 py-3">
            <v-radio class="ma-0 radio-btn" label="Trainer" color="blue" value="trainer"></v-radio>
            <v-radio class="ma-0 radio-btn" label="Jockey" color="blue" value="jockey"></v-radio>
            <v-radio class="ma-0 radio-btn" label="A-Z" color="blue" value="a-z"></v-radio>
          </v-card>
        </v-radio-group>

        <BlackbookJockeyAndTrainer :jt="jockeyAndTrainer" :isCheckboxMode2="isCheckboxMode2" :radioGroupValue="radioGroupValue" :isCheckboxMode="isCheckboxMode" :isExpandAll="isExpandAll" :onReturnCheckboxState="onReturnCheckboxState" :onToggleDynamicCheckbox="onToggleDynamicCheckbox" />
      </v-tab-item>

      <v-tab-item :transition="false">
        <v-container class="pa-0" v-if="!uiIsEmpty(upcomingCompetitors)">
          <div v-for="upcoming in [...upcomingCompetitors]" :key="upcoming.appendedId">
            <BlackbookUpcomingStable v-if="upcoming.appendedTabType === 'runnerTab'" :blackbooks="combinedBlackbook" :upcoming="upcoming" @add-to-tab="handleAddToTab" @update-racegrid="handleUpdateRaceGrid" :isCheckboxMode="isCheckboxMode" :isCheckboxMode2="isCheckboxMode2" :onReturnCheckboxState="onReturnCheckboxState" :onToggleDynamicCheckbox="onToggleDynamicCheckbox" />

            <BlackbookUpcomingJockeyAndtrainer
              v-if="upcoming.appendedTabType === 'jockeyTab'"
              :upcoming="upcoming"
              :isJockey="true"
              @add-to-tab="handleAddToTab"
              @update-racegrid="handleUpdateRaceGrid"
              :radioGroupValue="radioGroupValue"
              :blackbooks="combinedBlackbook"
              :isCheckboxMode="isCheckboxMode"
              :isCheckboxMode2="isCheckboxMode2"
              :onReturnCheckboxState="onReturnCheckboxState"
              :onToggleDynamicCheckbox="onToggleDynamicCheckbox"
            />

            <BlackbookUpcomingJockeyAndtrainer
              v-if="upcoming.appendedTabType === 'trainerTab'"
              :upcoming="upcoming"
              :isJockey="false"
              @add-to-tab="handleAddToTab"
              @update-racegrid="handleUpdateRaceGrid"
              :radioGroupValue="radioGroupValue"
              :blackbooks="combinedBlackbook"
              :isCheckboxMode="isCheckboxMode"
              :isCheckboxMode2="isCheckboxMode2"
              :onReturnCheckboxState="onReturnCheckboxState"
              :onToggleDynamicCheckbox="onToggleDynamicCheckbox"
            />
          </div>
        </v-container>
        <v-card elevation="0" v-else class="pa-2 d-flex justify-center align-center" height="32px">
          <span class="text-caption ma-0 grey--text text-sm-body-2">No data avaiable</span>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<style scoped lang="scss">
  .blackbook {
    &__header {
      position: fixed;
      z-index: 10;
      margin-right: 0;
      width: calc(100% - 26px);
      padding-top: 20px;
      margin-top: -16px;
    }

    &__tab-items {
      background-color: transparent !important;
      margin-top: 90px;
    }

    &__radio {
      position: fixed;
      width: calc(100% - 26px);
      background: #e7f5e9;
      z-index: 10;
    }

    &__tooltip {
      background-color: black;
      font-size: 12px
    }
  }

  ::v-deep .radio-btn label {
    font-size: 14.5px !important;
    margin-left: -5px;
    position: relative;
    top: 1px;
  }

  ::v-deep .radio-btn i {
    font-size: 18px;
  }
</style>
