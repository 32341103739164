<template>
  <v-card elevation="0" color="green lighten-4" fixed>
    <v-row dense class="pa-0">
      <v-col md="7" class="pa-0">
        <v-container>
          <div>
            <h2 class='d-inline mr-3' v-if="race !== null">{{ headerData.trackName }} R{{ headerData.raceNumber }}</h2>
            <div class="d-inline subtitle-2 mr-2" v-if="race !== null">{{ headerData.raceName }}</div>
            <h3 class='d-inline' v-if="race !== null">{{ formattedRaceStartTime(parseISO(headerData.startTime)) }}</h3>
            <div class="d-inline subtitle-2 ml-2" v-if="race !== null">
              <v-icon small color="black" class="mt-n1">mdi-calendar</v-icon>
              {{ headerData.raceDate }}
            </div>
            <CountryFlags v-if="race !== null" class="alignFlags" :meeting="headerData.meeting"/>
          </div>
          <div>
            <div class="caption d-inline mr-2" v-if="race !== null"><b>Condition:</b> {{ getCondition(headerData.trackCondition) }}</div>
            <div class="caption d-inline mr-2" v-if="race !== null"><b>Distance:</b> {{ headerData.distance }}m</div>
            <div class="caption d-inline mr-2" v-if="race !== null"><b>Prize Money:</b> {{ headerData.prizeMoney }}</div>
            <div class="caption d-inline mr-2" v-if="race !== null"><b>Class:</b> {{ headerData.class }}</div>
            <div class="caption d-inline" v-if="race !== null"><b>Rail:</b> {{ headerData.railPosition }}</div>
          </div>
        <div class="mt-2 d-flex align-center">
          <FF5Ratings />
          <FilterForm :btnStyles="{elevation: '1', btnColor: 'success' }" />
          <SortField />
          <SpeedMap :race="race" :meeting="meeting" :speedMap="getSpeedMap" :saveSpeedMap="saveSpeedMap" :setSpeedMap="setSpeedMap" :resetSpeedMap="resetSpeedMap"/>
          <v-btn tile elevation="1" x-small color="success" class="d-inline mr-2" :disabled="isRaceDisabled" @click="raceResultsTable = true">Race Results</v-btn>
          <v-btn tile elevation="1" x-small color="success" class="d-inline mr-2" v-if="hasFeature('dualAcceptances')" @click="onOpenDualAcceptances">
            <span v-if="dualAcceptancesHasLoaded">Dual Acceptances</span>
            <span v-else>Loading...</span>
          </v-btn>
          <span class="mb-1 mt-n2 text-sm-body-2 font-weight-medium red--text" v-if="isTrial">Limited Functionality in Free Trial</span>
        </div>
        </v-container>
      </v-col>

      <v-col class="d-flex pa-0">
        <v-container class="mt-auto">
          <v-btn elevation="1" v-for="(r, i) in racesF()" :key="`${r.number}:${i}`" :color="navColor(r.number, race.number, r.status)" fab x-small class="font-weight-bold mr-2 subtitle-2 mb-4" @click="clickRace(r)">{{r.number}}</v-btn>
          <v-btn v-if="isDomestic && hasFeature('raceDayPDF')" @click="onPrintAllMeetingsAsAPDF" title="Generage Meetings As A PDF" elevation="1" fab x-small color="#37474f" class="font-weight-bold mr-2 subtitle-2 mb-4"><v-icon class="white--text">mdi-printer-settings</v-icon></v-btn>
          <v-textarea
          label="Race Notes"
          v-model="userRaceVenueNote"
          type="text"
          outlined
          dense
          hide-details
          color="green"
          height="40px"
          rows="1"
          row-height="0"
          background-color="white"
          @input="onDebounceInput"
          ></v-textarea>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog hide-overlay v-model="raceResultsTable" :width="750" persistent>
      <RaceResults v-if="allowResults" :closeResultsSummary="closeResultsSummary" :race="race" :meeting="meeting" v-moveable :runners="getRaceCompetitors" :showResults="getResultsShowHide"/>
      <v-card v-else class="resultsModal">
        <v-card-title class="headline"><v-icon class="green--text mr-2">mdi-google-downasaur</v-icon> No Results Available</v-card-title>
        <v-card-text class="font-weight-bold">
          Results will be availble when the race has resulted
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="raceResultsTable = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog hide-overlay v-model="dualAcceptancesDialog" :width="1000" persistent>
      <DualAcceptances />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { datadogLogs } from '@datadog/browser-logs'
import { formattedRaceStartTime, isEmpty } from '../common/methods'
import { parseISO, format } from 'date-fns'
import numeral from 'numeral'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'

import { BARRIER_TRIAL, LOGO_BASE_64, TRACKCONDITION_TRANSLATION } from '../common/constants'
import SpeedMap from './SpeedMap.vue'
import FilterForm from './FilterForm.vue'
import SortField from './SortField.vue'
import { setUserData } from '../services/userConfig'
import FF5Ratings from './FF5Ratings.vue'
import CountryFlags from './CountryFlags'
import RaceResults from './RaceResults.vue'
import DualAcceptances from './DualAcceptances.vue'

export default {
  components: {
    SpeedMap,
    FilterForm,
    SortField,
    FF5Ratings,
    CountryFlags,
    RaceResults,
    DualAcceptances
  },
  data: () => ({
    userRaceVenueNote: '',
    raceResultsTable: false,
    debounce: false,
    dualAcceptancesDialog: false
  }),
  watch: {
    raceVenueNote (newState) {
      if (!isEmpty(newState)) {
        if (newState.isRaceNoteEmpty) {
          this.userRaceVenueNote = ''
        } else {
          this.userRaceVenueNote = newState.raceVenueNote
        }
      }
    },
    status: function (newValue, oldValue) {
      const resShow = this.getResultsShowHide
      if (resShow) {
        if (newValue === 'INTERIM') {
          this.raceResultsTable = true
        }
        if (newValue === 'FINAL') {
          this.raceResultsTable = true
        }
      }
    },
    race: function (newValue, oldValue) {
      const resShow = this.getResultsShowHide
      if (newValue?.id !== oldValue?.id && resShow) {
        if (this.status === 'INTERIM') {
          this.raceResultsTable = true
        } else if (this.status === 'FINAL') {
          this.raceResultsTable = true
        } else {
          this.raceResultsTable = false
        }
      } else {
        // this.raceResultsTable = false
      }
    },
    isDualAcceptances (newState) {
      if (newState) {
        this.dualAcceptancesDialog = true
      } else {
        this.dualAcceptancesDialog = false
      }
    }
  },
  computed: {
    ...mapState(['raceVenueNote', 'dualAcceptancesHasLoaded', 'isDualAcceptances']),
    ...mapGetters(['getSelectedRace', 'getSelectedMeetingsRaces', 'getSpeedMap', 'getRaceCompetitors', 'getResultsShowHide', 'hasFeature', 'getOldSelectedMeeting']),
    ...mapGetters('account', ['authConfig']),
    ...mapState('account', ['isTrial']),
    race: function () {
      return this.getSelectedRace() ?? {}
    },
    isRaceDisabled: function () {
      return isEmpty(this.race?.results)
    },
    meeting: function () {
      return this.getSelectedRace()?.meeting ?? {
        track: {
          country: ''
        }
      }
    },
    status: function () {
      return this.race?.status
    },
    allowResults: function () {
      if (this.status === 'FINAL' || this.status === 'INTERIM') {
        // this.showResultsRaceStatusChange()
        return true
      } else {
        return false
      }
    },
    headerData () {
      const raceName = this.race?.name ? this.race?.name : '-'
      const trackName = this.meeting?.track?.name ? this.meeting?.track?.name : '-'
      const raceNumber = this.race?.number ? this.race?.number : '-'
      const meeting = this.race?.meeting ? this.race?.meeting : { track: { country: '' } }
      const trackCondition = this.race?.trackCondition ? this.race?.trackCondition : '-'
      const startTime = this.race?.startTime ? this.race?.startTime : '2022-06-10T01:18:00Z'
      const distance = this.race?.distance ? this.race?.distance : '-'
      const prizeMoney = this.race?.prizeMoney ? `$${numeral(this.race?.prizeMoney).format('0a')}` : '-'
      const raceClass = this.race?.class ? this.race?.class : '-'
      const railPosition = this.meeting?.railPosition ? this.meeting?.railPosition : '-'
      const raceDate = this.meeting?.date ? this.meeting?.date.split('T')[0] : '-'

      return {
        meeting,
        startTime,
        trackCondition,
        distance,
        prizeMoney,
        class: raceClass,
        railPosition,
        raceName,
        trackName,
        raceNumber,
        raceDate
      }
    },
    isDomestic () {
      const { race } = this
      return race?.meeting?.track?.country === 'AUS' || race?.meeting?.track?.country === 'NZL'
    }
  },
  methods: {
    ...mapActions(['selectRace', 'selectNavRace', 'saveSpeedMap', 'setSpeedMap', 'resetSpeedMap', 'updateRaceGridSettings', 'fetchMeetingsAndRaces', 'toggleDualAcceptances']),
    formattedRaceStartTime,
    isEmpty,
    parseISO,
    numeral,
    racesF () {
      if (!isEmpty(this.getSelectedMeetingsRaces)) {
        return this.getSelectedMeetingsRaces ?? []
      } else {
        return this.getOldSelectedMeeting.races ?? []
      }
    },
    clickRace (r) {
      this.selectRace({ id: r.id })
      this.selectNavRace({ id: r.id })
    },
    getCondition (condition) {
      return TRACKCONDITION_TRANSLATION[condition]
    },
    navColor: function (mappedNumber, raceNumber, raceStatus) {
      if (mappedNumber === raceNumber) {
        return 'normal'
      } else if (raceStatus === 'OPEN') {
        return 'success'
      } else {
        return 'error'
      }
    },
    closeResultsSummary () {
      this.raceResultsTable = false
    },
    onClick (setting) {
      this.updateRaceGridSettings({ filter: 'raceResults', value: setting })
    },
    onDebounceInput (value) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        const config = this.authConfig
        const raceVenueNote = { raceVenueNote: value }
        setUserData(`raceVenueNote-${this.race.id}`, raceVenueNote, config)
      }, 1200)
    },
    onCareerByTrack ({ race, careerDetail }) {
      if (isEmpty(careerDetail)) return '-'
      const byTrack = careerDetail.filter(detail => detail.key === race.meeting.track.name)
      if (byTrack.length === 0) {
        return '-'
      }
      return `${byTrack[0].rating}`
    },
    onCareerByDistance ({ race, careerDetail }) {
      if (isEmpty(careerDetail)) return '-'
      const raceDistance = race.distance.toString()
      const byDistance = careerDetail.filter(detail => detail.key === raceDistance)
      if (byDistance.length === 0) {
        return '-'
      }
      return `${byDistance[0].rating}`
    },
    onAddPageNumberToPDFDocument ({ pdf }) {
      const pages = pdf.internal.getNumberOfPages()
      const pageWidth = pdf.internal.pageSize.width
      const pageHeight = pdf.internal.pageSize.height

      pdf.setFontSize(10)

      for (let j = 1; j < pages + 1; j++) {
        const horizontalPos = pageWidth / 2
        const verticalPos = pageHeight - 10
        pdf.setPage(j)
        pdf.text(`${j} of ${pages}`, horizontalPos, verticalPos, { align: 'center' })
      }
    },
    onSetHeader ({ pdf, settings }) {
      const posX = settings.margin.left
      const posY = settings.margin.top
      const width = 130
      const height = 25
      pdf.addImage(LOGO_BASE_64, 'PNG', posX, posY, width, height)

      pdf.setFontSize(13)
      pdf.setTextColor(40, 47, 102)
      pdf.text('Raceday Report', settings.pdfWidth - 85, 35)
    },
    onReturnPrimaryHeader ({ race, index }) {
      if (index === 1) {
        return `R${race?.number} ${race?.meeting?.track?.name} (${race?.meeting?.track?.state}) ${format(new Date(race?.startTime), 'EEEE')}, ${format(new Date(race?.meeting?.date), 'yyyy/MM/dd')} @${format(new Date(race?.startTime), "h:mmaaaaa'm'")}`
      } else if (index === 2) {
        return `(${race?.name}) ${race?.distance}m, ${numeral(race?.prizeMoney).format('$0a')} - Class ${race?.class}`
      } else if (index === 3) {
        return ''
      } else {
        return ''
      }
    },
    async onPrintAllMeetingsAsAPDF () {
      datadogLogs.logger.info('Printing PDF...', 'DynamicForm - onPrintAllMeetingsAsAPDF')
      const date = this.race?.meeting?.date
      const data = await this.fetchMeetingsAndRaces({ date }) ?? {}
      datadogLogs.logger.info(data, 'DynamicForm - onPrintAllMeetingsAsAPDF')

      if (!isEmpty(data?.currentRaces)) {
        /* eslint new-cap: ["error", { "newIsCap": false }] */
        const pdf = new jsPDF({ orientation: 'p', unit: 'px', format: 'a4', putOnlyUsedFonts: true, compress: true })
        const title = `${data.currentMeeting?.track?.name} Raceday Report - ${format(new Date(data.currentMeeting?.date), 'yyyy/MM/dd')} (${data.currentMeeting?.track?.country})`

        const settings = {
          margin: {
            left: 15,
            right: 15,
            bottom: 15,
            top: 15
          },
          colors: {
            primary: [76, 175, 80],
            primaryLight: [200, 230, 201],
            textPrimary: [52, 52, 52],
            textSecondary: [111, 114, 129],
            backgroundPrimary: [255, 255, 255],
            lineColor: [128, 128, 128]
          },
          fontSize: {
            primary: 9.5,
            secondary: 8.5,
            tertirary: 10
          },
          firstContentStartY: 55,
          pdfWidth: pdf.internal.pageSize.getWidth(),
          pdfHeight: pdf.internal.pageSize.getHeight()
        }

        pdf.setProperties({ title })

        this.onSetHeader({ title, pdf, settings })

        data.currentRaces?.forEach((race, index) => {
          let finalY = 0

          if (index === 0) {
            finalY = settings.firstContentStartY
          } else {
            finalY = pdf?.lastAutoTable?.finalY + 20
          }

          const tableHeadPrimary1 = [{ content: this.onReturnPrimaryHeader({ race, index: 1 }), colSpan: 13, styles: { halign: 'left', fontStyle: 'bold', fontSize: settings.fontSize.tertirary } }]
          const tableHeadPrimary2 = [{ content: this.onReturnPrimaryHeader({ race, index: 2 }), colSpan: 13, styles: { halign: 'left', fontStyle: 'bold', fontSize: settings.fontSize.tertirary } }]
          const tableHeadSecondary = ['Runner', 'Form', 'Odds', 'Rtg', 'Jockey', 'Wgt', 'Bar', 'Pace', 'Career', 'Last', 'Dist', 'Track', 'Notes']

          const tableBody = race.competitors.map(co => {
            const lastStart = co?.formHistory.filter(history => !BARRIER_TRIAL.includes(history.raceName))

            return [`${co.tabNo}.) ${co.name || '-'}`,
              co.runnerStatistics?.recent?.last10Starts ?? '-',
              !isEmpty(co.oddsToDisplay) ? co.oddsToDisplay : '-',
              !isEmpty(co.ratingDry) ? co.ratingDry : '-',
              !isEmpty(co.jockey) ? co.jockey : '-',
              !isEmpty(co.weightTotal) ? co.weightTotal : '-',
              !isEmpty(co.barrier) ? co.barrier : '-',
              !isEmpty(co.speedmapPosition) ? co.speedmapPosition : '-',
              !isEmpty(co?.runnerStatistics?.career?.all?.rating) ? co.runnerStatistics.career.all.rating : '-',
              !isEmpty(lastStart[0]?.ratingUnadjusted) ? lastStart[0]?.ratingUnadjusted : '-',
              this.onCareerByDistance({ race, careerDetail: co?.runnerStatistics?.career?.detail?.byDistance }),
              this.onCareerByTrack({ race, careerDetail: co?.runnerStatistics?.career?.detail?.byTrack }),
              !isEmpty(co.competitorsNotes) ? co.competitorsNotes : '-']
          })

          autoTable(pdf, {
            head: [tableHeadPrimary1, tableHeadPrimary2, tableHeadSecondary],
            body: tableBody,
            startY: finalY,
            showHead: 'firstPage',
            margin: { left: settings.margin.left, right: settings.margin.right },
            styles: {
              halign: 'left'
            },
            headStyles: {
              fillColor: settings.colors.background,
              lineColor: settings.colors.lineColor,
              fontSize: settings.fontSize.primary,
              textColor: settings.colors.textPrimary,
              lineWidth: 0.01,
              halign: 'left'
            },
            bodyStyles: {
              fillColor: settings.colors.background,
              lineColor: settings.colors.lineColor,
              fontSize: settings.fontSize.secondary,
              textColor: settings.colors.textSecondary,
              lineWidth: 0.01
            },
            didParseCell: function (data) {
              if (data.section === 'body') {
                data.cell.styles.fillColor = settings.colors.background

                if (typeof data.cell.raw === 'string') {
                  if (data.cell.raw.includes('.)')) {
                    data.cell.styles.fontStyle = 'bold'
                  }
                }
              }
            }
          })
        })

        this.onAddPageNumberToPDFDocument({ pdf })

        pdf.output('save', title)
      }
    },
    onOpenDualAcceptances: function () {
      if (this.dualAcceptancesHasLoaded) this.toggleDualAcceptances({ boolean: true })
    }
  }
}
</script>

<style scope>
.alignFlags{
  bottom: -8px;
  vertical-align: bottom;
}
</style>
