<template>
  <v-dialog max-width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="mr-4 lighten-1" small><v-icon class="mr-1" small>mdi-horse-variant-fast</v-icon>Horse Search</v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card class="horse-search">
        <v-toolbar elevation="1" color="#37474f" class="white--text pa-0 text-lg-h6 font-weight-regular" dark dense>
          <v-toolbar-title>Horse Search</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon small @click="dialog.value = false"><v-icon size="20">mdi-close</v-icon></v-btn>
        </v-toolbar>
        <div class="pa-3">
          <v-card-text class="horse-search__content px-0 py-2">
            <div class="d-flex align-center">
              <div class="d-flex">
                <v-text-field
                  v-model="search"
                  outlined
                  dense
                  height="20px"
                  class="pa-0 mb-2 horse-search__search"
                  flat
                  hide-details
                  label="Search Horse"
                  prepend-inner-icon="mdi-magnify"
                 />
              </div>
            </div>
          </v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left px-1">
                    Name
                  </th>
                  <th class="text-left px-1">
                    Country
                  </th>
                  <th class="text-left px-1">
                    Sire
                  </th>
                  <th class="text-left px-1">
                    Dam
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in horseSearchRes" :key="item.runnerIdentifier.globalId" style="cursor:pointer" @click="onSearch({ runner: item, dialog })">
                  <td>{{ item.runnerIdentifier.name }}</td>
                  <td>{{ item.runnerIdentifier.countryOfBirth }}</td>
                  <td>{{ item.runnerIdentifier.sire }}</td>
                  <td>{{ item.runnerIdentifier.dam }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card elevation="0" v-if="horseSearchRes.length === 0" class="pa-2 mt-2 d-flex justify-center align-center" height="32px">
            <span class="text-caption ma-0 grey--text text-sm-body-2">No data avaiable</span>
          </v-card>
        </div>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  data () {
    return {
      search: null,
      debounce: null
    }
  },
  watch: {
    search (newValue) {
      this.debounceSearch(newValue)
    }
  },
  computed: {
    ...mapState(['horseSearchRes']),
    ...mapGetters(['hasFeature'])
  },
  methods: {
    ...mapActions(['searchHorse', 'openHorse']),
    async onSearch ({ runner, dialog }) {
      await this.openHorse(runner.runnerIdentifier)
      dialog.value = false
      this.search = ''
    },
    debounceSearch (value) {
      if (value.length < 3) return
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchHorse(value)
      }, 1000)
    }
  }
}
</script>

<style scoped lang="scss">
.horse-search {
  background-color: #fff;

  &__search {
    border: 1px solid rgba(0,0,0,0.1);
    width: 253px;
  }
}
</style>
