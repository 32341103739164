export default {
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
    // oauth: {
    //   domain: 'bmcpuat.auth.ap-southeast-2.amazoncognito.com',
    //   scope: ['graphql/api'],
    //   redirectSignIn: process.env.VUE_APP_BASE_URL || 'http://localhost:8081/',
    //   redirectSignOut: process.env.VUE_APP_BASE_URL || 'http://localhost:8081/',
    //   responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    //   label: ''
    // }
  }
}
