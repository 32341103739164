<template>
  <div>
    <div>
      <ChromeTabs :onPinTab="onPinTab" :onCloseTab="onCloseTab" :onSelectTab="onSelectTab" :onDragEnd="onDragEnd" />
      <v-btn elevation="1" color="#37474f" small class="clear-chrome-button white--text" @click="onClearChromeTabs" :disabled="chromeTabs.length === 0">Clear Tabs</v-btn>
    </div>
    <div>
      <v-tabs
        fixed-tabs
        flat
        height="35"
        v-model="tabRF"
        active-class="active-race-field-tab"
        background-color="transparent"
        color="black">
        <v-tabs-slider small color="green"></v-tabs-slider>
        <v-tab v-if="!isCustomTab" style="margin-left: 0 !important; position: relative; margin-right: -25px;" @click="onResetToZeroTabPosition">
          <v-icon class="mr-1" small>mdi-form-select</v-icon>
          Race Field
        </v-tab>

        <v-tab
          style="margin-left: 0 !important"
          v-for="(runner, index) in raceFieldTabs"
          :key="`${index}-${runner.runnerId}-${runner.tabType}`"
          @click="onActiveRunner({ pos: index, activeRunner: runner })">
          <v-icon v-if="runner.tabType === 'runnerTab'" class="mr-1 pa-1" size="14">mdi-horseshoe</v-icon>
          <v-icon v-if="runner.tabType === 'jockeyTab'" class="mr-1 pa-1" size="14">mdi-account</v-icon>
          <v-icon v-if="runner.tabType === 'trainerTab'" class="mr-1 pa-1" size="14">mdi-binoculars</v-icon>
          <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="mr-2">{{ runner.selectedTabTypeName}}</span>
          <v-btn @click="deleteRaceFieldSelectedRunner({ runner })" icon x-small><v-icon class="grey--text" size="16">mdi-close</v-icon></v-btn>
        </v-tab>

        <v-tab-item v-if="!isCustomTab" style="height:100vh; overflow-y: hidden; overflow-x: hidden;">
          <RaceHeader/>
          <RaceGrid v-if="isRaceGrid" :addRunnerToChromeTab="addRunnerToChromeTab" />
        </v-tab-item>

        <v-tab-item
          v-for="(runner, index) in raceFieldTabs"
          :key="index"
          style="overflow-y: hidden; overflow-x: hidden;"
        >
          <RunnerForm v-if="runner.tabType === 'runnerTab'" :runner="returnActiveRunner({ runner })" :addRunnerToChromeTab="addRunnerToChromeTab" />
          <JockeyTrainerInfo v-if="runner.tabType === 'jockeyTab' || runner.tabType === 'trainerTab'" :runner="returnActiveRunner({ runner })" />
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import RaceHeader from './RaceHeader'
import RaceGrid from './RaceGrid'
import RunnerForm from './RunnerForm'
import JockeyTrainerInfo from './JockeyTrainerInfo'
import ChromeTabs from './ChromeTabs.vue'
import { CUSTOM_CHROME_TAB } from '../common/constants'
import { isEmpty, moveAnArrayElementFromOneArrayPositionToAnother } from '../common/methods'

export default {
  data () {
    return {
      raceFieldTabs: [], // Contains All Runner/Jockey/Trainer
      isRaceGrid: true,
      tabRF: 0, // Tab Race Field Index/Position
      isInitialLoad: true,
      isClosing: false
    }
  },
  components: {
    // VueTabsChrome,
    RaceHeader,
    RaceGrid,
    RunnerForm,
    JockeyTrainerInfo,
    ChromeTabs
  },
  computed: {
    ...mapState(['chromeGrouping', 'runnerTabs', 'smartTabs', 'tabVenues', 'chromeTabs', 'currentVenue', 'blackbookChrome', 'upcomingRaces', 'runnerTabsAZList', 'horseSearchChrome']),
    ...mapGetters(['getSelectedRace', 'getRunnerTabs', 'getLast50RidesJockeyAndTrainer', 'getActiveRunner']),
    ...mapState('account', ['isTrial']),
    race: function () {
      const race = this.getSelectedRace()
      return race
    },
    isCustomTab () {
      return CUSTOM_CHROME_TAB.includes(this.currentVenue?.name)
    },
    azListRaceAndRunner: function () {
      return { race: this.race, runnerTabsAZList: this.runnerTabsAZList } // Watches two variable states
    }
  },
  watch: {
    currentVenue (newState) {
      if (CUSTOM_CHROME_TAB.includes(newState.name)) {
        this.clearSelectedRace()
      }
    },
    smartTabs (newState) {
      const { name } = this.currentVenue
      if (!isEmpty(name)) this.tabRF = newState[name]
    },
    upcomingRaces (newState) {
      const upcoming = newState[newState.length - 1]
      if (!isEmpty(upcoming)) {
        this.addUpcomingToChromeTab({ ...upcoming })
      }
    },
    blackbookChrome (newValue) {
      if (!isEmpty(newValue)) {
        const index = newValue.length - 1
        this.setCurrentVenue({ venue: { name: 'Blackbook', raceId: '', date: '' } })
        this.addBlackBookToChromeTab({ blackbook: newValue[index] })
      }
    },
    horseSearchChrome (newState) {
      if (!isEmpty(newState)) {
        const index = newState.length - 1
        this.setCurrentVenue({ venue: { name: 'Horse Search', raceId: '', date: '' } })
        this.addHorseSearchToChromeTab({ horseSearch: newState[index] })
      }
    },
    chromeGrouping (newValue, prevState) {
      if (!isEmpty(newValue)) {
        // Find current race venue
        const currentTab = newValue.find(race => {
          const venue = Object.keys(race)[0]
          return venue === this.currentVenue.name
        })

        if (!isEmpty(currentTab)) {
          // Dynamiclly change `raceFieldTabs` depending on `currentVenue`
          this.raceFieldTabs = currentTab[this.currentVenue.name]
          if (this.tabRF === 0 && this.isInitialLoad === false && this.isClosing === false) {
            this.tabRF = 1
          }
        }

        if (!isEmpty(this.smartTabs[this.currentVenue.name])) {
          this.tabRF = this.smartTabs[this.currentVenue.name]
        }
      }
    },
    race (newState) {
      let currentVenue = {}

      if ((CUSTOM_CHROME_TAB.includes(this.currentVenue?.name)) && this.isInitialLoad) return // Don't run rest of the code if it `currentVenue.name` is a custom tab

      if (!isEmpty(localStorage.currentVenue)) currentVenue = JSON.parse(localStorage.currentVenue)

      if (!isEmpty(newState)) {
        // Construct Race/Track Details
        const { id, meeting, number } = newState
        const { name } = meeting.track
        const concat = name.split(' ').join('')
        let venueName = `${name} R${number}`

        // Set Current Venue
        if ((isEmpty(currentVenue?.raceId) && Object.prototype.hasOwnProperty.call(currentVenue, 'raceId')) && this.isInitialLoad) {
          this.setCurrentVenue({ venue: { name: currentVenue?.name, raceId: currentVenue?.raceId, date: currentVenue?.date } })
          venueName = currentVenue?.name
        } else {
          this.setCurrentVenue({ venue: { name: venueName, raceId: id, date: meeting.date } })
        }

        // New chrome tab construction (Phantom) - Editable/Removeable chrome tab
        const newTab = {
          venueName: `${name} R${number}`,
          key: `${concat}R${number}`,
          raceId: id,
          isPinned: false,
          isPhantom: true,
          date: meeting.date
        }

        // Edit/Remove phantom chrome tab
        const currentTab = this.chromeTabs.find(tab => tab.raceId === id)
        const phantomTab = this.chromeTabs.find(tab => tab.isPhantom)

        // if (!isEmpty(phantomTab) && isEmpty(currentTab) && currentTab.isPinned === false) {
        if (!isEmpty(phantomTab) && isEmpty(currentTab)) {
          this.editChromeTabAXN({ newTab })
          this.raceFieldTabs = []
        } else {
          if (isEmpty(currentTab)) {
            if (this.isInitialLoad && isEmpty(this.chromeTabs)) {
              // Add new tab on initial load if ther is no existing tabs
              this.addNewChromeTabAXN({ newTab, isNew: true })
            } else {
              if (!this.isInitialLoad) {
                // Add new tab
                this.addNewChromeTabAXN({ newTab, isNew: true })
                this.raceFieldTabs = []
              } else {
                if (!isEmpty(currentVenue?.raceId) || isEmpty(currentVenue)) {
                  this.addNewChromeTabAXN({ newTab, isNew: true })
                  this.raceFieldTabs = []
                }
              }
            }
          } else {
            const found = this.chromeGrouping.find(venue => Object.keys(venue)[0] === currentTab.venueName)
            if (!isEmpty(found)) {
              const runners = found[venueName]
              this.raceFieldTabs = runners // Dynamiclly change `raceFieldTabs` to match the assigned runners to race venue
            }

            if (currentTab.isPhantom) this.raceFieldTabs = []
          }
        }
        this.isInitialLoad = false
      }
    },
    azListRaceAndRunner (newState) {
      const { race, runnerTabsAZList } = newState

      if (!isEmpty(runnerTabsAZList)) {
        const currentRunner = runnerTabsAZList[runnerTabsAZList.length - 1]

        // Check if the currect selected race matches the selected runner race id from AZ List
        if (race.id === currentRunner.co.raceID) {
          const found = race.competitors.find(co => co.name === currentRunner.co.runner)
          if (!isEmpty(found)) {
            this.addRunnerToChromeTab({ co: found, tabType: currentRunner.tabType })
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['deleteTab', 'clearChromeTabs', 'toggleDrawer', 'addNewChromeTabAXN', 'selectRace', 'setCurrentVenue', 'deleteRaceFieldSelectedRunnerFromTabsAXN', 'removeActiveRunners', 'deleteSelectedChromeTabAXN', 'addRunnerToTabGroup', 'smartTriggerTabsAXN', 'setChromeTabs', 'editChromeTabAXN', 'fetchGlobalIdAndLast50RidesJockey', 'fetchGlobalIdAndLast50RidesTrainer', 'clearRaceFieldTabPosition', 'addActiveRunners', 'clearSelectedRace']),
    onResetToZeroTabPosition () {
      const { name } = this.currentVenue
      if (!isEmpty(name)) this.smartTriggerTabsAXN({ [name]: 0 })
    },
    onActiveRunner ({ event, pos, activeRunner }) {
      this.onSetTabPosition({ pos })
      this.addActiveRunners({ runner: activeRunner })
    },
    onSetTabPosition ({ pos }) {
      const { name } = this.currentVenue
      let newPos = pos
      if (!isEmpty(name)) {
        if (name.includes('Blackbook') || name.includes('Horse Search')) {
          // Do somthing...
        } else {
          newPos += 1
        }
        this.smartTriggerTabsAXN({ [name]: newPos })
      }
    },
    onResumeTabPosition () {
      const smartTabs = { ...this.smartTabs }
      const { name } = this.currentVenue
      const currentTabPosition = smartTabs[name]

      if (!isEmpty(currentTabPosition)) {
        this.tabRF = currentTabPosition
      } else {
        this.tabRF = 0
      }
    },
    onPinTab ({ venueName, isPining }) {
      // if `isPining` is `true` - User is pining current chrome tab
      // if `isPining` is `false` - User is unpining current chrome tab
      let pinedTab = {}

      this.chromeTabs.forEach(tab => {
        if (tab.venueName === venueName) {
          pinedTab = { ...tab, isPinned: isPining, isPhantom: false }
        }
      })

      // Add new chrome tab
      this.addNewChromeTabAXN({ newTab: pinedTab, isNew: false })

      // Added to Chrome grouping if pin
      const chromeGroupingCopy = [...this.chromeGrouping]

      // If user is pinining - create a new chromeGrouping element
      if (isPining) {
        // Search for venue groupoing
        const index = chromeGroupingCopy.findIndex(venue => Object.keys(venue)[0] === venueName)

        if (index > -1) {
          //
        } else {
          // If no race venue is found create `runners` as an empty object
          const runners = []
          const venue = { [venueName]: runners } // Default venue
          this.addRunnerToTabGroup({ venue, isNew: true })
        }
      }
    },
    onCloseTab ({ event, tab: closingTab }) {
      const { chromeTabs, currentVenue } = this
      const indexOfClosingTab = chromeTabs.findIndex(tab => tab.venueName === closingTab.venueName)
      const indexOfCurrentTab = chromeTabs.findIndex(tab => tab.venueName === currentVenue.name)
      const beforeCurrentTab = chromeTabs[indexOfClosingTab - 1] // Go down one index
      const afterCurrentTab = chromeTabs[indexOfClosingTab + 1] // Go down one index

      if (indexOfClosingTab === indexOfCurrentTab) {
        if (!isEmpty(beforeCurrentTab)) {
          this.onSelectTab({ tab: beforeCurrentTab }) // Set current tab
        } else {
          if (!isEmpty(afterCurrentTab)) {
            this.onSelectTab({ tab: afterCurrentTab }) // Set current tab
          } else {
            this.raceFieldTabs = []
          }
        }
      }

      this.deleteSelectedChromeTabAXN({ tab: closingTab })
    },
    async onSelectTab ({ tab }) {
      const { venueName, raceId } = tab

      // Check if tab is  phantom tab
      if (!tab.isPhantom) {
        // Get runners for selected race venue
        const found = this.chromeGrouping.find(venue => Object.keys(venue)[0] === venueName)
        if (!isEmpty(found)) {
          this.setCurrentVenue({ venue: { name: venueName, raceId: '', date: '' } })
          const runners = found[venueName]
          this.raceFieldTabs = [] // Clear
          setTimeout(() => {
            this.raceFieldTabs = runners // Dynamiclly change `raceFieldTabs` to match the assigned runners to race venue
            this.onResumeTabPosition()
          }, 1)
        }

        if (CUSTOM_CHROME_TAB.includes(venueName)) {
          this.setCurrentVenue({ venue: { name: venueName, raceId: '', date: '' } })
          const position = this.smartTabs[venueName] ?? 0
          const runner = found[venueName][position]
          await this.addActiveRunners({ runner })
          this.clearSelectedRace()
        } else {
          this.selectRace({ id: raceId })
        }
      } else {
        this.selectRace({ id: raceId })
      }
    },
    onDragEnd ({ event, tabs }) {
      const { newIndex, oldIndex } = event
      const newTabPositions = moveAnArrayElementFromOneArrayPositionToAnother({ arrayToBeRearanged: tabs, oldIndex, newIndex })

      // Triggers at the end of a chrome tab drag
      const chromeGrouping = [...this.chromeGrouping]
      const chromeTabs = [...this.chromeTabs]
      const venueNamesArray = newTabPositions.map(tab => tab.venueName)

      // Sort array according to the positioning of `newTabPositions`
      chromeGrouping.sort(function (a, b) {
        const aKey = Object.keys(a)[0]
        const bKey = Object.keys(b)[0]
        return venueNamesArray.indexOf(aKey) - venueNamesArray.indexOf(bKey)
      })

      chromeTabs.sort(function (a, b) {
        return venueNamesArray.indexOf(a.venueName) - venueNamesArray.indexOf(b.venueName)
      })

      // Saving new positions
      this.setChromeTabs({ chromeTabs, isNew: false })
      this.addRunnerToTabGroup({ venue: chromeGrouping, isNew: false })
    },
    async addBlackBookToChromeTab ({ blackbook }) {
      // Construct Race/Track Details
      const raceId = blackbook.raceId
      const venueName = 'Blackbook'
      const venue = { [venueName]: [blackbook] } // Default venue

      // Set Current Runner
      await this.addActiveRunners({ runner: blackbook })

      /*
       * Check if race venue has already been added
       * If `index` is negative - There are NO duplicates
       * If `index` is positive - There IS a duplicate
       */
      const chromeGroupingCopy = [...this.chromeGrouping]
      const index = chromeGroupingCopy.findIndex(race => {
        const keyProperty = Object.keys(race)[0]
        return keyProperty === venueName
      })

      // Check for phantom tabs
      const containsPhantom = this.chromeTabs.find(tab => tab.isPhantom)

      // New chrome tab construction
      const newTab = {
        venueName,
        key: 'Blackbook',
        raceId,
        isPinned: false,
        isPhantom: false
      }

      if (index > -1) {
        const runners = chromeGroupingCopy[index][venueName]
        const found = runners.filter(runner => (blackbook.runnerId === runner.runnerId) && (blackbook.tabType === runner.tabType))

        // Only add runnner if there are no duplicates
        if (isEmpty(found)) {
          // Reposition race filed tab to currently selected runner
          this.smartTriggerTabsAXN({ [venueName]: runners.length })

          // Push to `chromeGrouping`
          chromeGroupingCopy[index][venueName].push(blackbook)
          this.addRunnerToTabGroup({ venue: chromeGroupingCopy, isNew: false })
        } else {
          const runners = chromeGroupingCopy[index][venueName]
          const runnersIndex = runners.findIndex(runner => (blackbook.runnerId === runner.runnerId) && (blackbook.tabType === runner.tabType))
          this.raceFieldTabs = runners
          this.smartTriggerTabsAXN({ [venueName]: runnersIndex })
        }

        this.setCurrentVenue({ venue: { name: venueName, raceId: '', date: '' } })
      } else {
        // Add new venue to vuex state
        this.addRunnerToTabGroup({ venue, isNew: true })

        if (containsPhantom) {
          this.editChromeTabAXN({ newTab })
        } else {
          // Add new chrome tab
          this.addNewChromeTabAXN({ newTab, isNew: true })
        }

        // Set Current Venue
        this.setCurrentVenue({ venue: { name: venueName, raceId: '', date: '' } })
      }
    },
    async addHorseSearchToChromeTab ({ horseSearch }) {
      const venueName = 'Horse Search'

      const newRunner = {
        tabType: 'runnerTab',
        runnerId: horseSearch?.runner?.runner?.id,
        jockeyId: horseSearch?.runner?.formHistory[0]?.jockeyGlobalId,
        trainerId: horseSearch?.runner?.formHistory[0]?.trainerGlobalId,
        raceId: null,
        selectedTabTypeName: horseSearch?.runner?.name,
        tabNumber: null,
        raceExternalId: null,
        meetingId: null,
        raceNumber: null
      }

      const venue = { [venueName]: [newRunner] }

      await this.addActiveRunners({ runner: newRunner })

      /*
       * Check if race venue has already been added
       * If `index` is negative - There are NO duplicates
       * If `index` is positive - There IS a duplicate
       */
      const chromeGroupingCopy = [...this.chromeGrouping]
      const index = chromeGroupingCopy.findIndex(race => {
        const keyProperty = Object.keys(race)[0]
        return keyProperty === venueName
      })

      // Check for phantom tabs
      const containsPhantom = this.chromeTabs.find(tab => tab.isPhantom)

      // New chrome tab construction
      const newTab = {
        venueName,
        key: 'Horse Search',
        raceId: '',
        isPinned: false,
        isPhantom: false
      }

      if (index > -1) {
        const runners = chromeGroupingCopy[index][venueName]
        const found = runners.filter(co => (newRunner.runnerId === co.runnerId) && (co.tabType === newRunner.tabType))

        // Only add runnner if there are no duplicates
        if (isEmpty(found)) {
          // Reposition race filed tab to currently selected runner
          this.smartTriggerTabsAXN({ [venueName]: runners.length })

          // Push to `chromeGrouping`
          chromeGroupingCopy[index][venueName].push(newRunner)
          this.addRunnerToTabGroup({ venue: chromeGroupingCopy, isNew: false })
        } else {
          const runners = chromeGroupingCopy[index][venueName]
          const runnersIndex = runners.findIndex(co => (newRunner.runnerId === co.runnerId) && (co.tabType === newRunner.tabType))
          this.raceFieldTabs = runners
          this.smartTriggerTabsAXN({ [venueName]: runnersIndex })
        }

        this.setCurrentVenue({ venue: { name: venueName, raceId: '', date: '' } })
      } else {
        // Add new venue to vuex state
        this.addRunnerToTabGroup({ venue, isNew: true })

        if (containsPhantom) {
          this.editChromeTabAXN({ newTab })
        } else {
          // Add new chrome tab
          this.addNewChromeTabAXN({ newTab, isNew: true })
        }

        // Set Current Venue
        this.setCurrentVenue({ venue: { name: venueName, raceId: '', date: '' } })
      }
    },
    addUpcomingToChromeTab ({ upcoming, runner }) {
      const { raceName, raceNumber, raceId, date } = upcoming

      // Construct Race/Track Details
      const concat = raceName.split(' ').join('')
      const venueName = `${concat} R${raceNumber}`
      const venue = { [venueName]: [runner] } // Default venue

      /*
       * Check if race venue has already been added
       * If `index` is negative - There are NO duplicates
       * If `index` is positive - There IS a duplicate
       */
      const chromeGroupingCopy = [...this.chromeGrouping]
      const index = chromeGroupingCopy.findIndex(race => {
        const keyProperty = Object.keys(race)[0]
        return keyProperty === venueName
      })

      if (index > -1) { // New Runner has been added
        const runners = chromeGroupingCopy[index][venueName] // e.g [{age: 3 barrier: 2...}, {age: 1, barrier: 8...}, {age: 4 barrier: 24...}]

        // Checks/filters if there is a duplicate runner when a user clicks on a runner in the race-tab ui
        const isDublicateRunner = runners.filter(item => (runner?.runner?.id === item.runner.id) && (item.tabType === runner.tabType))

        // Only add runnner if there are no duplicates
        if (isEmpty(isDublicateRunner)) {
          // Reposition race filed tab to currently selected runner
          const raceField = 1 // Race Field Tab is always in the `0` index
          this.smartTriggerTabsAXN({ [venueName]: runners.length + raceField })

          // Push to `chromeGrouping`
          chromeGroupingCopy[index][venueName].push(runner)
          this.addRunnerToTabGroup({ venue: chromeGroupingCopy, isNew: false })
        } else {
          // Dynimically change `tabRF` tab position
          const runners = chromeGroupingCopy[index][venueName]
          const raceField = 1 // Race Field Tab is always in the `0` index

          const runnersIndex = runners.findIndex(item => (runner?.runner?.id === item.runner.id) && (item.tabType === runner.tabType))
          const newTabPosition = runnersIndex + raceField

          this.raceFieldTabs = runners
          this.smartTriggerTabsAXN({ [venueName]: newTabPosition }) // Dynamic tab switch
        }

        // Set Current Race Venue
        this.setCurrentVenue({ venue: { name: venueName, raceId, date } })
      } else {
        this.addRunnerToTabGroup({ venue, isNew: true })

        // Set Current Race Venue
        this.setCurrentVenue({ venue: { name: venueName, raceId, date } })
      }
    },
    addRunnerToChromeTab ({ co, tabType }) {
      // Construct Race/Track Details
      const { track, date, id: meetingId } = this.race.meeting
      const { number: raceNumber, id: raceId } = this.race
      const concat = track.name.split(' ').join('')
      const venueName = `${track.name} R${raceNumber}`

      // New Runner/Jockey/Trainer
      const runnerId = co.runner.id
      const selectedTabTypeName = this.returnSelectedRunnerName({ runner: co, tabType })
      const newRunner = {
        tabType,
        runnerId,
        jockeyId: co.jockeyID,
        trainerId: co.trainerID,
        raceId,
        selectedTabTypeName,
        tabNumber: co.tabNo,
        raceExternalId: this.race?.meeting?.externalIDs[0].id,
        meetingId,
        raceNumber
      }

      // Set Current Runner
      this.addActiveRunners({ runner: newRunner })

      // Race Venue
      const venue = { [venueName]: [newRunner] } // Default venue

      /*
       * Check if race venue has already been added
       * If `index` is negative - There are NO duplicates
       * If `index` is positive - There IS a duplicate
       */
      const chromeGroupingCopy = [...this.chromeGrouping]
      const index = chromeGroupingCopy.findIndex(race => {
        const keyProperty = Object.keys(race)[0]
        return keyProperty === venueName
      })

      // New chrome tab construction
      const newTab = {
        venueName: `${track.name} R${raceNumber}`,
        key: `${concat}R${raceNumber}`,
        raceId,
        isPinned: false,
        isPhantom: false,
        date
      }

      if (index > -1) { // New Runner has been added
        const runners = chromeGroupingCopy[index][venueName] // e.g [{age: 3 barrier: 2...}, {age: 1, barrier: 8...}, {age: 4 barrier: 24...}]

        // Checks/filters if there is a duplicate runner when a user clicks on a runner in the race-tab ui
        const isDublicateRunner = runners.filter(item => (newRunner.runnerId === item.runnerId) && (item.tabType === tabType))

        // Only add new runnner if there are no duplicates found
        if (isEmpty(isDublicateRunner)) { // No duplicate found, add new runner
          // Add new runner
          chromeGroupingCopy[index][venueName].push(newRunner)
          this.addRunnerToTabGroup({ venue: chromeGroupingCopy, isNew: false })

          // Dynamic tab switch
          this.smartTriggerTabsAXN({ [venueName]: runners.length })
        } else { // Duplicate runner found
          // Dynimically change `tabRF` tab position
          const runners = chromeGroupingCopy[index][venueName]
          const raceField = 1 // Race Field Tab is always in the `0` index

          const runnersIndex = runners.findIndex(co => (newRunner.runnerId === co.runnerId) && (co.tabType === tabType))
          const newTabPosition = runnersIndex + raceField

          this.smartTriggerTabsAXN({ [venueName]: newTabPosition }) // Dynamic tab switch
        }

        // Set Current Race Venue
        this.setCurrentVenue({ venue: { name: venueName, raceId, date } })
      } else {
        // Add new race venue to vuex state
        this.addRunnerToTabGroup({ venue, isNew: true })

        // Add new chrome tab
        this.addNewChromeTabAXN({ newTab, isNew: true })

        // Set Current Race Venue
        this.setCurrentVenue({ venue: { name: venueName, raceId, date } })

        // Dynamic tab switch
        this.smartTriggerTabsAXN({ [venueName]: 1 })
      }
    },
    onClearChromeTabs () {
      this.clearChromeTabs()
      this.clearRaceFieldTabPosition({ venueName: this.currentVenue.name })
      this.setCurrentVenue({ venue: {} })
      this.raceFieldTabs = []
    },
    onCloseSelectedChromeTab (tab) {
      this.deleteSelectedChromeTabAXN({ currentClosingTab: tab })
    },
    deleteRaceFieldSelectedRunner ({ event, runner }) {
      const { chromeGrouping, currentVenue } = this
      const currentRace = chromeGrouping.find(race => {
        return Object.keys(race)[0] === currentVenue.name
      })

      if (!isEmpty(currentRace)) {
        this.deleteRaceFieldSelectedRunnerFromTabsAXN({ currentRunner: runner })
        this.removeActiveRunners({ runner })
        this.isClosing = true
        setTimeout(() => { this.isClosing = false }, 500)
      }
    },
    returnSelectedRunnerName ({ runner, tabType }) {
      let name = runner.name
      if (tabType === 'jockeyTab') {
        name = runner.jockey
      } else if (tabType === 'trainerTab') {
        name = runner.trainer
      }

      return name
    },
    returnActiveRunner ({ runner }) {
      const concatinatedVenueName = this.currentVenue?.name?.split(' ')?.join('')
      const combinedId = `${concatinatedVenueName}-${runner.runnerId}-${runner.tabType}`
      const currentRunner = this.getActiveRunner({ combinedId }) ?? {}
      return currentRunner
    }
  }
}
</script>

<style scoped>
.runner-btn {
  position: relative;
}

.remove-runner {
  position: absolute;
  background-color: #fe5252;
  padding: .5px;
  border-radius: 50%;
  top: -12px;
  right: -23px;
}

.clear-chrome-button {
  position: absolute;
  right: 0;
  z-index: 10;
  width: 100px;
  height: 46px !important;
  top: 0;
}
</style>
