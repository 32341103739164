<template>
  <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <alert-msg v-if='appErrors.error' />
    <v-snackbar :value="!isEmpty(errorMsg.msg)" :top="true" :timeout="3200" :color="errorMsg.type">
      <div class="d-flex align-center">
        <v-icon class="mr-2">{{ errorMsg.icon }}</v-icon><span class="text-md-body-1">{{ errorMsg.msg }}</span>
      </div>
    </v-snackbar>
    <v-card class="login content-center overflow-y-hidden">
      <v-container class="login__content">
        <form v-if="formType === 'login'" class="form" autocomplete="off">
          <legend class="d-flex align-center flex-column">
            <figure>
              <v-img width="270" :src="require('../assets/fa-logo.png')" alt="Dynamic Form Logo" />
            </figure>
            <p>Please login using your DynamicOdds Username and Password</p>
          </legend>
          <fieldset>
            <div class="form-group">
              <label for="username">Username</label>
              <input id="username" type="text" placeholder="Enter your username" v-model="username" required />
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input id="password" type="password" placeholder="●●●●●●●●●" v-model="password" required />
            </div>

            <button @click="onSubmitUserCredentials">Sign In</button>
            <p class="form__text mb-1">Forgot your password? <a @click="onToggleBetweenViews({ type: 'reset-password-1' })">Let's change it</a></p>
            <p class="form__text ma-0 mb-1">No account? <a @click="onToggleBetweenViews({ type: 'create-account' })">Create an account</a></p>
            <!-- <p class="form__text form__text-loner"><a @click="onToggleBetweenViews({ type: 'free-trial' })" target="_blank">FREE TRIAL!</a></p> -->
            <p class="form__text ma-0 mb-0">Join now and get your first 7 days access, FREE</p>
          </fieldset>
        </form>

        <form name="submit-to-google-sheet" v-if="formType === 'free-trial'" class="form" autocomplete="off">
          <legend class="d-flex align-center flex-column">
            <p>Join our mailing list for a free trial</p>
          </legend>
          <fieldset>
            <div class="form-group">
              <label for="firstName" v-if="isEmpty(this.$route.query)">First Name</label>
              <label for="firstName" v-else>DynamicOdds Username</label>
              <input id="firstName" name="firstName" type="firstName" placeholder="Enter your username" v-model="firstNameField" required />
            </div>
            <div class="form-group" v-show="isEmpty(this.$route.query)">
              <label for="lastName">Last Name</label>
              <input id="lastName" name="lastName" type="lastName" placeholder="lucy" v-model="lastNameField" required />
            </div>
            <div class="form-group">
              <label for="email">E-mail</label>
              <input id="email" name="email" type="email" placeholder="john.doe@gmail.com" v-model="emailField" required />
            </div>
            <button :class="isEmpty(this.$route.query) ? 'disabled' : ''" @click="onEnableFreeTrial" id="freeTrialButton" :disabled="isEmpty(this.$route.query) ? true : false">Sign Up (Free Trial)</button>
            <p class="form__text">Have an account already? <a @click="onToggleBetweenViews({ type: 'login' })">Lets sign in</a></p>
          </fieldset>
        </form>

        <form name="submit-to-google-sheet" v-if="formType === 'reset-password-1'" class="form" autocomplete="off">
          <legend class="d-flex align-center flex-column">
            <p>Reset Password</p>
          </legend>
          <fieldset>
            <div class="form-group">
              <label for="username">Username</label>
              <input id="username" name="username" type="username" placeholder="Enter your username" v-model="username" required />
            </div>
            <button @click="onSubmitUsername">Submit</button>
            <p class="form__text">Remembered your password? <a @click="onToggleBetweenViews({ type: 'login' })">Lets login!</a></p>
          </fieldset>
        </form>

        <form name="submit-to-google-sheet" v-if="formType === 'reset-password-2'" class="form" autocomplete="off">
          <legend class="d-flex align-center flex-column">
            <p>Reset Password</p>
          </legend>
          <fieldset>
            <div class="form-group">
              <label for="username">Username</label>
              <input id="username" name="username" type="username" placeholder="Enter your username" v-model="username" required />
            </div>
            <div class="form-group">
              <label for="code">code</label>
              <input id="code" name="code" type="code" placeholder="Enter code..." v-model="code" required />
            </div>
            <div class="form-group">
              <label for="newPassword">New Password</label>
              <input id="newPassword" name="newPassword" type="password" placeholder="Enter new password..." v-model="newPassword" required />
            </div>
            <button @click="onSubmitConfirmResetPassword">Submit</button>
            <p class="form__text">Remembered your password? <a @click="onToggleBetweenViews({ type: 'login' })">Lets login!</a></p>
          </fieldset>
        </form>

        <form v-if="formType === 'create-account'" class="form" autocomplete="off">
          <legend class="d-flex align-center flex-column">
            <p>Create Account</p>
          </legend>
          <fieldset>
            <div class="form-group">
              <label for="username">Username</label>
              <input id="username" name="username" type="username" placeholder="Enter your username" v-model="username" required />
            </div>
            <div class="form-group">
              <label for="email">E-Mail</label>
              <input id="email" name="email" type="email" placeholder="Enter your email" v-model="email" required />
            </div>
            <div class="form-group">
              <label for="phoneNo">Phone Number</label>
              <VuePhoneNumberInput default-country-code="AU" v-model="phoneNo" @update="updatePhoneNumber" />
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input id="password" name="password" type="password" placeholder="Enter your password" v-model="password" required />
            </div>
            <div class="form-group">
              <label for="passwordConfirm">Confirm Password</label>
              <input id="passwordConfirm" name="passwordConfirm" type="password" placeholder="Enter your password" v-model="passwordConfirm" required />
            </div>
            <button @click="onSubmitUserSignUpCredentials">Submit</button>
            <p class="form__text">Already have an account? <a @click="onToggleBetweenViews({ type: 'login' })">Lets login!</a></p>
          </fieldset>
        </form>

        <form v-if="formType === 'confirm-account'" class="form" autocomplete="off">
          <legend class="d-flex align-center flex-column">
            <p>Confirm your account</p>
          </legend>
          <fieldset>
            <div class="form-group">
              <label for="username">Username</label>
              <input id="username" name="username" type="username" placeholder="Enter your username" v-model="username" required />
            </div>
            <div class="form-group">
              <label for="code">Code</label>
              <input id="code" name="code" type="code" placeholder="Enter your code" v-model="code" required />
            </div>
            <button @click="onSubmitUserConfirmCredentials">Confirm Account</button>
            <p class="form__text">Didn't recieve the code? <a @click="onResendCode">Resend Code</a></p>
            <p class="form__text ma-0 form__text-loner" v-if="checkEmail">Please check your email</p>
          </fieldset>
        </form>
      </v-container>
    </v-card>
    <v-dialog v-model="alertNoSubscription" persistent width="540">
      <v-card>
        <v-toolbar elevation="1" color="#37474f" class="white--text pa-0 text-lg-h6 font-weight-regular" dark dense>
          <v-toolbar-title>Subscription Status</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon small @click="closeAlertNoSubscription"><v-icon size="20">mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-title class="contentDiv">
          DynamicForm is a Premium Product, which includes access to DynamicOdds.
          <br>
          Start your 7 day free trial, then $95/mo
        </v-card-title>
        <div class="contentDiv" v-if="username !== ''">
          <v-list three-line subheader>
            <iframe id="do-payment"
              title="Secure Payment"
              width="300"
              height="510"
              :src="getUrl"
              class="iframe">
            </iframe>
          </v-list>
        </div>
        <p class="red--text text-center py-4 px-4">Your first payment will be debited from your card in 7 days. Please contact support before your trial ends to cancel your subscription to not be charged.</p>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { Auth, Hub } from 'aws-amplify'
import VuePhoneNumberInput from 'vue-phone-number-input'
import { isEmpty } from '../common/methods'
import AlertMsg from './AlertMsg.vue'
import { DF_VALID_SUBSCRIPTION_CODES } from '../common/constants'

export default {
  components: { 'alert-msg': AlertMsg, VuePhoneNumberInput },
  name: 'AmplifyAuth',
  data () {
    return {
      dialog: false,
      username: '',
      password: '',
      firstName: '',
      lastName: '',
      email: '',
      code: '',
      newPassword: '',
      passwordConfirm: '',
      phoneNo: '',
      phoneNumberWithCode: '',
      formType: 'login',
      alertNoSubscription: false,
      checkEmail: false,
      errorMsg: {},
      subscriptionDetector: null
    }
  },
  mounted () {
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signIn':
          if (!this.isTrial) {
            Auth.currentAuthenticatedUser().then(async user => {
              this.accessToken = user.getSignInUserSession().getAccessToken()
              localStorage.setItem('fa_username', user.username)
              await this.$store.dispatch('fetchUserDetails')
              this.onFetchUserSubscription({ user })
            })
          }
          break
          // case 'signIn':
          //   this.dialog = false
          //   this.username = ''
          //   this.password = ''

          //   Auth.currentAuthenticatedUser().then(async user => {
          //     this.accessToken = user.getSignInUserSession().getAccessToken()
          //     localStorage.setItem('fa_username', user.username)
          //     await this.$store.dispatch('fetchUserDetails')
          //     this.$store.commit('updateLoadAZListData', true)
          //     await this.$store.dispatch('fetchRaces')
          //     // await this.$store.dispatch('fetchAzList')
          //   })
          //   break
        default:
          break
      }
    })

    this.addThirdPartyScripts()
  },
  async created () {
    const queryParameters = this.$route.query
    await this.fetchUser({ isTrial: false })
    if (!this.authorized) {
      this.dialog = true
      if (!isEmpty(queryParameters)) {
        if (queryParameters.open === 'freetrial') {
          this.firstName = queryParameters.u_name
          this.lastName = 'DO Customer'
          this.email = queryParameters.email.split(' ')[1]
          this.onToggleBetweenViews({ type: 'free-trial' })
        } else {
          this.onInitialToggleLoginView()
        }
      }
    } else {
      this.dialog = false
      if (!isEmpty(this.isTrial) && !this.isTrial) {
        await this.fetchUserDetails()
      }
      this.$store.commit('updateLoadAZListData', true)
      // await this.fetchAzList()
    }
  },
  watch: {
    doScriptionAlert (newState) {
      if (!isEmpty(newState) && newState === true) {
        this.username = this.$route?.query?.u_name
        this.alertNoSubscription = true
      } else {
        this.username = ''
        this.alertNoSubscription = false
      }
    },
    dialog (newState) {
      if (newState) {
        localStorage.clear()
      }
    }
  },
  computed: {
    ...mapState(['appErrors']),
    ...mapState('account', ['authorized', 'isTrial', 'doScriptionAlert']),
    ...mapGetters('account', ['getMemberId']),
    ...mapGetters(['hasFeature']),
    firstNameField: {
      get () {
        return this.firstName
      },
      set (value) {
        return this.updateFirstName(value)
      }
    },
    lastNameField: {
      get () {
        return this.lastName
      },
      set (value) {
        return this.updateLastName(value)
      }
    },
    emailField: {
      get () {
        return this.email
      },
      set (value) {
        return this.updateEmail(value)
      }
    },
    getUrl: function () {
      // return `https://old.dynamicodds.com/new/pages/purchase3.asp?p_id=3&currency=AUD&username=${this.username}&recurring=1`
      // return `https://old.dynamicodds.com/new/pages/purchase3.asp?p_id=35&currency=AUD&username=${this.username}&recurring=1`
      // return `https://old.dynamicodds.com/new/pages/purchase3.asp?p_id=35&currency=AUD&m_id=${this.getMemberId}&recurring=1`
      return `https://old.dynamicodds.com/new/pages/purchase3.asp?p_id=35&currency=AUD&m_id=${this.getMemberId}&recurring=1&daysToStart=7`
    }
  },
  methods: {
    ...mapActions(['freeTrialSelected', 'fetchUserDetails', 'fetchRaces', 'fetchAzList', 'loadingToggleAXN', 'apiErrosAXN', 'loginCreateUser', 'resetStateAXN']),
    ...mapActions('account', ['fetchUser', 'logout', 'bootsTrapNewSubscriptionWidget']),
    isEmpty,
    async onFetchUserSubscription ({ user }) {
      if (['int', 'uat', 'prodc'].includes(process.env.VUE_APP_ENV)) {
        const numberOfAttempts = 3

        for (let index = 1; index <= numberOfAttempts; index++) {
          try {
            const subscriptionLevel = await this.$store.dispatch('account/checkSubscription')

            if (DF_VALID_SUBSCRIPTION_CODES.includes(subscriptionLevel)) {
              this.dialog = false
              this.$store.commit('updateLoadAZListData', true)
              await this.$store.dispatch('fetchRaces')
              await this.$store.dispatch('fetchAzList')
            } else {
              this.loadingToggleAXN({ isLoading: false })
              // await this.$store.dispatch('account/logout')
              this.$store.commit('updateLoadAZListData', false)
              if (this.hasFeature('newSubscription')) {
                this.dialog = false
                await this.bootsTrapNewSubscriptionWidget()
              } else if (!isEmpty(subscriptionLevel)) this.alertNoSubscription = true
            }

            break
          } catch (error) {
          }
        }
      } else {
        this.dialog = false
        this.$store.commit('updateLoadAZListData', true)
        await this.$store.dispatch('fetchRaces')
        await this.$store.dispatch('fetchAzList')
      }
    },
    updateFirstName (value) {
      this.firstName = value
      this.enableButton()
      return this.firstName
    },
    updateLastName (value) {
      this.lastName = value
      this.enableButton()
      return this.lastName
    },
    updateEmail (value) {
      this.email = value
      this.enableButton()
      return this.email
    },
    enableButton () {
      const freeTrialButton = document.getElementById('freeTrialButton')
      if (this.firstName !== '' && this.lastName !== '' && this.email !== '') {
        freeTrialButton.classList.remove('disabled')
        freeTrialButton.disabled = false
      } else {
        freeTrialButton.classList.add('disabled')
        freeTrialButton.disabled = true
      }
    },
    async onEnableFreeTrial (event) {
      this.$store.dispatch('resetStateAXN')
      const scriptURL = 'https://script.google.com/macros/s/AKfycbxS_PMH2Tll4G1_KCXwiRN5UVsN99K9Z5_oRLKf50gNw08EivoHJj6SgXFAgO7oPe8Ufw/exec'
      const form = document.forms['submit-to-google-sheet']

      form.addEventListener('submit', e => {
        e.preventDefault()
        fetch(scriptURL, { method: 'POST', body: new FormData(form) })
          .then(async response => {})
          .catch(error => console.error('Error!', error.message))
      })

      await this.freeTrialSelected()
      this.$store.commit('updateLoadAZListData', true)
      await this.fetchRaces()
      // await this.fetchAzList()
      this.dialog = false
    },
    async onSubmitUserCredentials (event) {
      event.preventDefault()
      try {
        const { username, password } = this
        if (isEmpty(username) || isEmpty(password)) {
          return this.apiErrosAXN({ error: 'Login credential cannot be empty.', type: 'error' })
        }
        this.loadingToggleAXN({ isLoading: true })
        await Auth.signIn(username, password)
        // this.username = ''
        // this.password = ''
        if (!isEmpty(this.$router.query)) {
          this.$router.push('/')
        }
      } catch (error) {
        this.loadingToggleAXN({ isLoading: false })
        this.apiErrosAXN({ error: 'Incorrect username or password, please try again.', type: 'error' })
      }
    },
    async onSubmitUsername (event) {
      event.preventDefault()
      const { username } = this

      if (isEmpty(username)) {
        this.errorMsg = { msg: 'Username is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      try {
        await Auth.forgotPassword(username)
        this.errorMsg = { msg: 'Verification code had been sent to your email', type: 'success', icon: 'mdi-checkbox-marked-circle' }
        setTimeout(() => { this.errorMsg = {} }, 3200)
        this.formType = 'reset-password-2'
      } catch (err) {
        this.errorMsg = { msg: err.message, type: 'red', icon: 'mdi-alert' }
        setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    async onSubmitConfirmResetPassword (event) {
      event.preventDefault()
      const { username, code, newPassword } = this

      if (isEmpty(username)) {
        this.errorMsg = { msg: 'Username is required', type: 'red', icon: 'mdi-alert' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(code)) {
        this.errorMsg = { msg: 'Verification code is required', type: 'red', icon: 'mdi-alert' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(newPassword)) {
        this.errorMsg = { msg: 'Password is required', type: 'red', icon: 'mdi-alert' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      try {
        await Auth.forgotPasswordSubmit(username, code, newPassword)

        this.username = ''
        this.password = ''
        this.code = ''
        this.newPassword = ''
        this.formType = 'login'
        this.errorMsg = { msg: 'Password change was successful!', type: 'success', icon: 'mdi-checkbox-marked-circle' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      } catch (err) {
        this.errorMsg = { msg: err.message, type: 'red', icon: 'mdi-alert' }
        setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    async onSubmitUserSignUpCredentials (event) {
      event.preventDefault()
      const { username, email, password, passwordConfirm, phoneNo, phoneNumberWithCode } = this

      if (isEmpty(username)) {
        this.errorMsg = { msg: 'Username is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(email)) {
        this.errorMsg = { msg: 'Email is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(phoneNo)) {
        this.errorMsg = { msg: 'Phone Number is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(password?.trim())) {
        this.errorMsg = { msg: 'Password is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(passwordConfirm?.trim())) {
        this.errorMsg = { msg: 'Password confirmation is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (password?.trim() !== passwordConfirm?.trim()) {
        this.errorMsg = { msg: 'Passwords does not match', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      try {
        await Auth.signUp({
          username: username?.trim(),
          password: password?.trim(),
          attributes: {
            email: email?.trim(),
            phone_number: phoneNumberWithCode?.trim()
          }
        })
        this.onToggleBetweenViews({ type: 'confirm-account' })
        this.errorMsg = { msg: 'Please check your email for verification code', type: 'success', icon: 'mdi-checkbox-marked-circle' }
        this.checkEmail = true
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      } catch (err) {
        this.errorMsg = { msg: err.message, icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    async onResendCode () {
      const { username } = this
      try {
        await Auth.resendSignUp(username?.trim())
        this.errorMsg = { msg: 'Verification code has been resent to your email', type: 'success', icon: 'mdi-checkbox-marked-circle' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      } catch (err) {
        this.errorMsg = { msg: err.message, icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    async onSubmitUserConfirmCredentials (event) {
      event.preventDefault()
      const { username, code } = this

      if (isEmpty(username)) {
        this.errorMsg = { msg: 'Username is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      if (isEmpty(code)) {
        this.errorMsg = { msg: 'Verification code is required', icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }

      try {
        await Auth.confirmSignUp(username?.trim(), code?.trim())
        this.errorMsg = { msg: 'You account has been created!', type: 'success', icon: 'mdi-checkbox-marked-circle' }
        this.onClearInputFields()
        return setTimeout(() => {
          window.location.reload()
        }, 2000)
      } catch (err) {
        this.errorMsg = { msg: err.message, icon: 'mdi-alert', type: 'red' }
        return setTimeout(() => { this.errorMsg = {} }, 3200)
      }
    },
    onToggleBetweenViews ({ type }) {
      this.formType = type
      if (type === 'login') {
        this.$router.push('/')
      }
    },
    onLogout () {
      this.logout()
      setTimeout(() => {
        this.resetStateAXN()
      }, 1000)
    },
    closeAlertNoSubscription () {
      this.username = ''
      this.password = ''
      this.alertNoSubscription = false
      if (!isEmpty(this.$route.query.email) && !isEmpty(this.$route.query.u_name)) {
        this.onLogout()
        this.$router.push('/')
        window.location.reload()
      }
    },
    addThirdPartyScripts () {
      const mediality = document.createElement('script')
      mediality.setAttribute('type', 'text/javascript')
      mediality.setAttribute('id', 'hs-script-loader')
      mediality.setAttribute('src', '//js.hs-scripts.com/20555377.js')
      mediality.setAttribute('async', true)
      mediality.setAttribute('defer', true)
      document.head.appendChild(mediality)
    },
    updatePhoneNumber (data) {
      if (data.isValid) {
        this.phoneNumberWithCode = data.formattedNumber
      }
    },
    onClearInputFields () {
      this.username = ''
      this.password = ''
      this.code = ''
      this.email = ''
      this.phoneNo = ''
      this.phoneNumberWithCode = ''
      this.checkEmail = false
    },
    onInitialToggleLoginView () {
      if (this.$route?.query?.signup === 'true') {
        this.onToggleBetweenViews({ type: 'create-account' })
      } else {
        this.onToggleBetweenViews({ type: 'login' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), url('~@/assets/login_screen.jpeg') center center / cover;
  height: 100%;

  &__content {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.form {
  background-color: white;
  padding: 35px 40px;
  border-radius: 8px;
  width: 440px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

  &__text {
    color: rgba(0,0,0,0.6);
    text-align: left;
    margin-top: 15px;
    margin-bottom: 0;
    text-align: center;
    font-size: 16px;

    a {
      color: #4caf50;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &__text-loner {
    position: absolute;
    bottom: -30px;
    right: 0;
    color: #4caf50;
    left: 0;
    font-size: 16px;
  }

  legend {
    color: #152939;
    margin-bottom: 30px;
    text-align: center;

    p {
      width: 100%;
      font-weight: 500;
      margin-top: 20px;
      font-size: 18.4px;
      margin:0;
    }
  }

  figure {
    margin-bottom: 20px;
    img {
      display: inline-block;
      margin-bottom: 20px !important;
    }
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    min-width: 0;
    position: relative;
  }

  button {
    background: #4caf50;
    width: 100%;
    padding: 10px;
    text-transform: uppercase;
    color: white;
    font-weight: 500;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }
}

.form-group {
  margin-bottom: 20px;

  label {
    display: block;
    font-size: 13.5px;
    color: rgba(0,0,0,0.6);
    margin-bottom: 5px;
    font-weight: 500;
  }

  input {
    display: block;
    width: 100%;
    border:1px solid rgba(0,0,0,.2);
    padding: 0 11.5px;
    font-size: 13.5px;
    height: 42px !important;
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out;
    font-style: italic;
    color: black;
    border-radius: 5px;

    &::placeholder {
      color: rgba(0,0,0,.25);
    }

    &[type=date]:required:invalid::-webkit-datetime-edit {
        color: transparent;
    }
    &[type=date]:focus::-webkit-datetime-edit {
        color: black !important;
    }

    &[type="date"]:not(.has-value):before{
      color: rgba(0,0,0,.25);;
      content: attr(placeholder);
    }

    &[type="date"]:focus:before {
      content: '' !important;
    }

    &:focus,
    &:hover {
      border-color: #4caf50;
      outline: 0
    }
  }
}

.disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
  cursor: default !important;
}

.contentDiv {
  text-align: center;
}
</style>
