const COUNTRIES = ['AUS', 'NZL', 'GBR', 'IRL', 'ZAF', 'FRA', 'HKG', 'SGP', 'JPN']
const USA_CA = ['USA', 'CAN']
const OTHER = ['SWE', 'MEX', 'NOR', 'FIN', 'DEU', 'CHL', 'ARG', 'UAE', 'URY', 'KOR', 'PER', 'MUS', 'BRA', 'ITA', 'DNK', 'IND', 'ESP', 'MYS', 'ARE', 'BLE', 'SAU']
const CODES = ['THOROUGHBRED', 'HARNESS', 'GREYHOUND']
const MEETING_TYPES = ['T', 'N', 'P']
const STATUSES = ['Open', 'Closed', 'Interim/Paying', 'Abandoned']
const CONDITIONS = ['Fast', 'Good', 'Dead', 'Slow', 'Heavy']

const store = {
  state: {
    raceNavFilters: {
      countries: [...COUNTRIES, 'USA/CAN', 'OTHER'],
      codes: CODES,
      meetingTypes: MEETING_TYPES,
      statuses: STATUSES,
      starters: [1, 30],
      distances: [1, 10000],
      conditions: CONDITIONS,
      meetings: [],
      races: []
    },
    speedMap: null,
    raceGridSettings: {
      resultsShowHide: ''
    }
  },
  mutations: {
    setSpeedMap (state, speedMap) {
      state.speedMap = speedMap
    },
    updateResultsShowHide (state, value) {
      state.raceGridSettings.resultsShowHide = value
    }
  },
  actions: {
    updateSpeedMap (context, { speedMap }) {
      context.commit('setSepedMap', speedMap)
    }
  },
  getters: {
    getCountriesFilter (state) {
      let result = [...state.raceNavFilters.countries]
      if (state.raceNavFilters.countries.includes('OTHER')) {
        result = [...result, ...OTHER]
      } if (state.raceNavFilters.countries.includes('USA/CAN')) {
        result = [...result, ...USA_CA]
      }
      return result
    },
    getIsCountrySelectedFilter (state) {
      return state.raceNavFilters.countries
    },
    getIsCodesSelectedFilter (state) {
      return state.raceNavFilters.codes
    },
    getMeetingTypesSelectedFilter (state) {
      return state.raceNavFilters.meetingTypes
    },
    getStatusesFilter (state) {
      return state.raceNavFilters.statuses
    },
    getStartersFilter (state) {
      return state.raceNavFilters.starters
    },
    getDistanceFilter (state) {
      return state.raceNavFilters.distances
    },
    getConditionsFilter (state) {
      return state.raceNavFilters.conditions
    },
    getSpeedMap (state) {
      return state.speedMap
    },
    getResultsShowHide (state) {
      return state.raceGridSettings.resultsShowHide
    }
  }
}

export default store
