<template>
  <v-dialog v-model="dialog" max-width="300">
    <template v-slot:activator="{ on }">
      <v-btn
        x-small
        color="success"
        class="d-inline mr-2"
        v-on="on"
        elevation="1"
        tile
        @click.stop="openFilterForm"
      >
        <v-icon class="mr-1" small>
          mdi-sort-alphabetical-ascending
        </v-icon>
        <span>
          Sort Field
        </span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar
        elevation="1"
        class="black--text"
        color="#37474f"
        dark
        dense
        style="position: sticky; top: 0; z-index: 999;"
      >
        <div style="width: 100%;" class="d-flex align-center justify-space-between">
          <v-toolbar-title class="white--text">Sort Field</v-toolbar-title>
          <v-btn class="mr-n2" icon small @click.stop="dialog = false"><v-icon size="20">mdi-close</v-icon></v-btn>
        </div>
      </v-toolbar>
      <span class="mb-1 ml-3 text-sm-body-2 font-weight-medium red--text" v-if="isTrial">Limited Functionality in Free Trial</span>
      <v-container class="pl-3 py-0" fluid>
        <v-radio-group v-model="radios" class="d-flex" dense :disabled="isTrial">
          <!-- <v-radio value="default" color="#37474f">
            <template v-slot:label>
              <span class="ml-1">Default</span>
            </template>
          </v-radio> -->
          <v-radio value="tabNo" color="#37474f">
            <template v-slot:label>
              <span class="ml-1">Number</span>
            </template>
          </v-radio>
          <v-radio value="barrier" color="#37474f">
            <template v-slot:label>
              <span class="ml-1">Barrier</span>
            </template>
          </v-radio>
          <v-radio value="ratingDry">
            <template v-slot:label>
              <span class="ml-1">Rating</span>
            </template>
          </v-radio>
          <v-radio value="myPrice">
            <template v-slot:label>
              <span class="ml-1">Price</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-container>
      <!-- <v-card-actions class="pt-0">
        <v-btn small color="success" @click="onSaveFilter">Save</v-btn>
        <v-btn small color="error" @click="dialog = false">Cancel</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  data () {
    return {
      dialog: false,
      goingOptions: ['any']
    }
  },
  computed: {
    ...mapGetters(['getSortFieldsBy']),
    ...mapState('account', ['isTrial']),
    radios: {
      get () {
        return this.getSortFieldsBy
      },
      set (newValue) {
        this.sortFieldsByAXN({ sortBy: newValue })
        this.dialog = false
      }
    }
  },
  methods: {
    ...mapActions(['sortFieldsByAXN']),
    openFilterForm () {
      this.dialog = true
    },
    updateGoingOptions (option) {
      if (option === 'any') {
        this.goingOptions = ['any']
      } else {
        this.goingOptions = this.goingOptions.filter(
          (option) => option !== 'any'
        )
      }
    },
    updateClasses (classSelected) {
      if (classSelected === 'all') {
        this.classes = ['all']
      } else {
        this.classes = this.classes.filter((c) => c !== 'all')
      }
    }
  }
}
</script>

<style scoped>
  .radio-title {
    /* font-size: ; */
  }
</style>
