<template>
  <v-container @mouseleave="onMouseLeave" class="pa-0" fluid style="height: 100vh;">
    <v-layout fill-height>
      <div class="rounded-0 parent-class" style="width: 100%;">
        <v-toolbar height="70" color="#37474f" class="navigation-panel pa-0" dark flat prominent fixed-tabs>
          <v-container width="100%" class="pa-0">
            <div class="d-flex justify-center align-center">
              <v-text-field
              dense
              class="pa-0 mb-2 race-search"
              flat
              hide-details
              label="Race Day Search"
              prepend-inner-icon="mdi-magnify"
              solo-inverted
              v-model="search"
              @keydown.enter="searchResults()"
              style="margin-top: 5px;"
              ></v-text-field>
              <!-- <Indicators /> -->
              <div style="width: 100px;" class="d-flex pl-2 align-center">
                <v-checkbox :ripple="false" icon small v-model="isCheckbox" @change="toggleDrawer" class="checkbox text-caption" hide-details dense></v-checkbox>
                <span class="mr-n2 d-block auto-hide-text">Auto <br> Hide</span>
              </div>
            </div>
            <RaceNavNextToGo :race="getNextToGo" :onClick="clickNextToGoRace" :widthNextToGo="'width: 100%;'" />
          </v-container>

          <template v-slot:extension>
            <v-tabs v-model="tabPosition" centered fixed-tabs class="test">
              <v-tabs-slider color="green"></v-tabs-slider>
              <v-tab v-for="item in tabHeaders" :key="item"> {{ item }}</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-tabs-items v-model="tabPosition" class="navigation-panel__body pa-0" style="top: 115px;">
          <v-tab-item>
            <RaceDayNavigation />
          </v-tab-item>

          <v-tab-item>
            <MyRunners />
          </v-tab-item>
        </v-tabs-items>
      </div>

      <v-dialog content-class="overflow-y-hidden" v-model="azListModal">
        <AzList :closeAzList="closeAzList" :searchOnOpen="search" :raceDaySearchOpen="raceDaySearchOpen" :azListModal="azListModal" />
      </v-dialog>
  </v-layout>
  </v-container>
</template>

<script>
import { startOfDay } from 'date-fns'
import RaceDayNavigation from './RaceDayNavigation.vue'
import MyRunners from './MyRunners.vue'
import AzList from './AzList.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import RaceNavNextToGo from './RaceNavNextToGo.vue'

export default {
  name: 'NavigationPanel',
  mounted () {
    this.isCheckbox = this.isDrawer
  },
  data () {
    return {
      tabPosition: 0,
      tabHeaders: ['Schedule', 'Blackbook'],
      isCheckbox: false,
      search: '',
      raceDaySearchOpen: false,
      azListModal: false
    }
  },
  computed: {
    ...mapState(['isDrawer', 'smartTabs']),
    ...mapState('account', ['isTrial']),
    ...mapGetters(['getNextToGo'])
  },
  watch: {
    'smartTabs.navPanelTab': function (newState) {
      // this.tab = newState
    }
  },
  components: {
    // Indicators,
    MyRunners,
    RaceDayNavigation,
    AzList,
    RaceNavNextToGo
  },
  methods: {
    ...mapActions(['toggleDrawer', 'toggleAutoHide', 'fetchAzList', 'setDate', 'reFetchRaces', 'selectRace', 'selectNavRace']),
    async clickNextToGoRace (v) {
      await this.setDate(startOfDay(Date.now()))
      // this.setRaceLoading({ loading: true })
      await this.reFetchRaces()
      this.selectRace({
        id: v.id
      })
      this.selectNavRace({
        id: v.id
      })
      // this.setThisRaceOnLoad(2)
    },
    async searchResults () {
      this.raceDaySearchOpen = true
      this.azListModal = true
      await this.fetchAzList()
    },
    closeAzList () {
      this.azListModal = false
      this.raceDaySearchOpen = false
      this.search = ''
    },
    hideAzList () {
      this.azListModal = false
      this.raceDaySearchOpen = false
      this.search = ''
    },
    onMouseLeave () {
      if (this.isDrawer) {
        this.toggleAutoHide()
      }
    }
  }
}
</script>

<style scoped>
.checkbox >>> label {
  font-size: 16.5px;
  font-weight: 400;
  margin-left: -8.5px;
}

.checkbox >>> i {
  font-size: 20px;
  margin-left: -4px;
}

.navigation-panel {
  position: fixed;
  z-index: 1000;
  width: 100%;
}

.navigation-panel__body {
  position: relative;
  top: 180px;
  background: transparent;
}

.parent-class >>> .v-toolbar__content {
  padding: 4px 14px !important;
}

::v-deep .test {
  height: 35px !important;
  position: relative;
  bottom: -5px;
  /* background-color: red !important; */
}

::v-deep .race-search {
  width: 460px !important;
  font-size: 13px !important;
  margin-top: 5px;
}

::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 30px;
  font-size: 13px !important;
}

.auto-hide-text {
  font-size: 13px;
  margin-left: -5px !important;
  line-height: 15px;
}
</style>
