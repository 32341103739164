import Vue from 'vue'
import { getUnixTime, isSameDay, parseISO, startOfDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { getDefaultStateMain } from './state'
import { countdown, isEmpty } from '../../common/methods'

export const mutations = {
  updateAppMount (state, { boolean }) {
    state.isAppMounted = boolean
  },
  updateLoadingToggle (state, { isLoading }) {
    state.isLoading = isLoading
  },
  updateApiErrors (state, { error, type }) {
    state.appErrors = { error, type }
  },
  updateInitalRaceLoad (state, { boolean }) {
    state.isInitalRaceLoad = boolean
  },
  updateSmartTriggerTabs (state, { smartTabs }) {
    state.smartTabs = smartTabs
  },
  updateRunnerTabs (state, { tabs }) {
    state.runnerTabs = tabs
  },
  updateRunnerBlackbook (state, { blackbook, initialLoad }) {
    if (initialLoad) {
      state.runnerBlackbook = blackbook
    } else {
      state.runnerBlackbook.push(blackbook)
    }
  },
  updateJockeyBlackbook (state, { blackbook, initialLoad }) {
    if (initialLoad) {
      state.jockeyBlackbook = blackbook
    } else {
      state.jockeyBlackbook.push(blackbook)
    }
  },
  updateTrainerBlackbook (state, { blackbook, initialLoad }) {
    if (initialLoad) {
      state.trainerBlackbook = blackbook
    } else {
      state.trainerBlackbook.push(blackbook)
    }
  },
  updateRunnerBlackbookComments (state, { comments }) {
    state.runnerBlackbookComments = comments
  },
  updateJockeyBlackbookComments (state, { comments }) {
    state.jockeyBlackbookComments = comments
  },
  updateGlobalRunnerNotes (state, { runnerId, notes }) {
    Vue.set(state.globalRunnerNotes, runnerId, notes)
  },
  updateTrainerBlackbookComments (state, { comments }) {
    state.trainerBlackbookComments = comments
  },
  updateSingularRaces (state, { race }) {
    state.singularRaces.push(race)
  },
  removeRunnerBlackbook (state, { runnerBlackbook }) {
    state.runnerBlackbook = runnerBlackbook
  },
  removeJockeyFromBlackbook (state, { jockeyBlackbook }) {
    state.jockeyBlackbook = jockeyBlackbook
  },
  removeTrainerFromBlackbook (state, { trainerBlackbook }) {
    state.trainerBlackbook = trainerBlackbook
  },
  setToRunnerTabsFromAZList (state, { co, tabType }) {
    state.runnerTabsAZList.push({ co, tabType })
  },
  updateRunnerTabGroup (state, { venue, isNew }) {
    if (isNew) {
      state.chromeGrouping.push(venue)
    } else {
      state.chromeGrouping = venue
    }
  },
  updateWindowDimension (state, { width, height }) {
    state.windowDimension = { width, height }
  },
  updateNewChromeTab (state, { newTab, isNew }) {
    if (isNew) {
      state.chromeTabs.push(newTab)
    } else {
      state.chromeTabs = newTab
    }
  },
  updateCurentVenue (state, { venue }) {
    state.currentVenue = venue
  },
  updateUpcomingRaces (state, { upcoming }) {
    state.upcomingRaces.push(upcoming)
  },
  updateBlackbookChrome (state, { blackbook }) {
    state.blackbookChrome.push(blackbook)
  },
  updateHorseSearchChrome (state, { horseSearch }) {
    state.horseSearchChrome.push(horseSearch)
  },
  removeFromRunnerTabs (state, { competitors }) {
    state.runnerTabs = state.runnerTabs.filter(id => !competitors.includes(id))
    const filteredRunners = state.runnerTabs.filter(race => !competitors.includes(race.id))
    state.myRacesFull = filteredRunners
  },
  clearRaces (state, { isTrial }) {
    if (state.selectedRace.id !== null && !isTrial) {
      const detRace = state.detailedRaces[state.selectedRace.id]
      const keepMeetings = Object.values(state.meetings).filter(meeting => meeting.id === detRace.meeting.id)
      const keepRaces = Object.values(state.races).filter(race => race.meeting.id === detRace.meeting.id)
      const racesObject = {}
      keepRaces.forEach((race) => {
        racesObject[race.id] = race
      })
      const meetingObject = {}
      keepMeetings.forEach((meeting) => {
        meetingObject[meeting.id] = meeting
      })

      // state.raceIDAlerts.forEach(raceID => {
      //   const race = state.races[raceID]
      //   if (race?.status === 'OPEN') {
      //     racesObject[raceID] = race
      //   }
      // })

      state.meetings = meetingObject
      state.races = racesObject
    } else {
      state.meetings = {}
      state.races = {}
    }
  },
  clearTodaysRaces (state) {
    state.todaysRaces = {}
  },
  updateDetailedRace (state, { race }) {
    const key = `${race.id}`
    if (!isEmpty(race.results)) {
      race.competitors.forEach(c => {
        const result = race.results[0].positions.find(p => p.tabNo === c.tabNo)
        if (!isEmpty(result)) {
          c.result = result.position
        }
      })
    }
    // Object.freeze(race)
    Vue.set(state.detailedRaces, key, race)
  },
  updateRace (state, { raceUpdate }) {
    const key = `${raceUpdate.id}`
    const race = state.races[key]
    const meeting = raceUpdate.meeting
    meeting.externalIDs = race?.meeting?.externalIDs
    const newRace = { ...raceUpdate, meeting }
    if (race) {
      // Object.freeze(raceUpdate)
      Vue.set(state.races, key, newRace)
    }
    const todayRace = state.todaysRaces[key]
    if (todayRace) {
      // Object.freeze(todayRace)
      Vue.set(state.todaysRaces, key, newRace)
    }
    // const detailedRace = state.detailedRaces[key]
    // if (detailedRace) {
    //   if (raceUpdate.competitors && raceUpdate.competitors.length > 0) {
    //     const competitors = [...detailedRace.competitors]
    //     raceUpdate.competitors.forEach(competitor => {
    //       const index = competitors.findIndex(c => c.tabNo === competitor.tabNo)
    //       if (index) {
    //         competitors[index] = competitor
    //       }
    //     })
    //     const newDetailedRace = { ...raceUpdate, competitors }
    //     Vue.set(state.detailedRaces, key, newDetailedRace)
    //   }
    //   const newDetailedRace = { ...raceUpdate, competitors: [...detailedRace.competitors] }
    //   Vue.set(state.detailedRaces, key, newDetailedRace)
    // }
  },
  updateRaceSummary (state, { race, meeting }) {
    const newMeeting = meeting || state.meetings[race.meeting.id]
    // state.raceAlertsDate = meeting?.date
    const key = `${race.id}`
    Vue.set(state.meetings, newMeeting.id, newMeeting)
    const newRace = {
      ...race,
      isoStartTime: parseISO(race.startTime),
      meeting: newMeeting
    }
    // Object.freeze(newRace)
    if (isSameDay(parseISO(newMeeting.date), startOfDay(Date.now()))) {
      Vue.set(state.todaysRaces, key, newRace)
    }
    if (isSameDay(parseISO(newMeeting.date), state.selectedDate)) {
      Vue.set(state.races, key, newRace)
    }
  },
  updateRaceCountdown (state) {
    const races = state.races
    Object.keys(races).forEach((b) => {
      const race = races[b]
      const time = getUnixTime(parseISO(race.startTime))
      const formattedStartTime = countdown(time)
      Vue.set(race, 'formattedStartTime', formattedStartTime)
    })
  },
  setDate (state, d) {
    const date = zonedTimeToUtc(d, 'GMT')
    state.selectedDate = date
  },
  setTodaysDate (state, d) {
    state.todaysDate = d
  },
  clearSelectedRace (state) {
    state.selectedRace = {}
    state.selectedRace.id = null
    state.selectedRace.MeetingID = null
    state.selectedRaceRaceNumber = null
  },
  selectRace (state, { id }) {
    state.selectedRace = {}
    state.selectedRace.id = id
  },
  selectNavRace (state, { id }) {
    state.selectedNavRace.id = id
  },
  setRacesGroupByMeeting (state, { value }) {
    state.racesGroupByMeeting = value
  },
  updateLoadAZListData (state, value) {
    state.loadAZListData = value
  },
  clearAzSearchResults (state) {
    state.azSearchResults = []
  },
  clearHorseSearchResults (state) {
    state.horseSearchRes = []
  },
  clearTabPosition (state, { venueName }) {
    state.smartTabs = { [venueName]: 0 }
  },
  clearAzLists (state) {
    state.azListRunners = { A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [], I: [], J: [], K: [], L: [], M: [], N: [], O: [], P: [], Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [], Y: [], Z: [] }
    state.azListJockeysDrivers = { A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [], I: [], J: [], K: [], L: [], M: [], N: [], O: [], P: [], Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [], Y: [], Z: [] }
    state.azListTrainers = { A: [], B: [], C: [], D: [], E: [], F: [], G: [], H: [], I: [], J: [], K: [], L: [], M: [], N: [], O: [], P: [], Q: [], R: [], S: [], T: [], U: [], V: [], W: [], X: [], Y: [], Z: [] }
    state.azList = []
  },
  addAzList (state, { meetingType, runner, jockeyDriver, trainer, race, date, competitorNumber, barrier, handicap, raceID, startTime, status, raceName, raceNumber, formHistory, runnerId, externalMeetingID, tabNo, raceCountry, scratched, weight }) {
    state.azList.push({
      meetingType,
      runner,
      jockeyDriver,
      trainer,
      race,
      date,
      competitorNumber,
      barrier,
      handicap,
      raceID,
      startTime,
      status,
      raceName,
      raceNumber,
      formHistory,
      runnerId,
      externalMeetingID,
      tabNo,
      raceCountry,
      scratched,
      weight
    })

    if (typeof state.azListJockeysDrivers[runner.charAt(0)] !== 'undefined') {
      state.azListRunners[runner.charAt(0)].push({
        meetingType,
        runner,
        jockeyDriver,
        trainer,
        race,
        date,
        competitorNumber,
        barrier,
        handicap,
        raceID,
        startTime,
        formHistory
      })
    }

    if (typeof state.azListJockeysDrivers[jockeyDriver.charAt(0)] !== 'undefined') {
      state.azListJockeysDrivers[jockeyDriver.charAt(0)].push({
        meetingType,
        runner,
        jockeyDriver,
        trainer,
        race,
        date,
        competitorNumber,
        barrier,
        handicap,
        raceID,
        startTime,
        formHistory
      })
    }

    if (typeof state.azListJockeysDrivers[trainer.charAt(0)] !== 'undefined') {
      state.azListTrainers[trainer.charAt(0)].push({
        meetingType,
        runner,
        jockeyDriver,
        trainer,
        race,
        date,
        competitorNumber,
        barrier,
        handicap,
        raceID,
        startTime,
        formHistory
      })
    }
  },
  updateRaceFilters (state, { value }) {
    state.raceFilters = value
  },
  resetRaceFilters (state, { value }) {
    state.raceFilters = value
  },
  saveRunnerFormHistoryFiltered (state, { value, runnerId }) {
    state.runnerFormHistoryFiltered[runnerId] = value
  },
  resetRunnerFormHistoryFiltered (state) {
    state.runnerFormHistoryFiltered = {}
  },
  updateDrawer (state) {
    state.isDrawer = !state.isDrawer
  },
  updateAutoHide (state, { isAutoHide }) {
    state.isAutoHide = isAutoHide
  },
  sortFieldsMTN (state, { sortBy }) {
    state.sortFieldsBy = sortBy
  },
  updateBestBookie (state, arr) {
    state.bestOddsArray = arr
  },
  updateFlucsRace (state, race) {
    state.flucsRace = race
  },
  updateArrayOfBookies (state, arr) {
    state.bestPriceBookiesArrays = arr
  },
  updateChromeGrouping (state, value) {
    state.chromeGrouping = value
  },
  updateChromeTabs (state, value) {
    state.chromeTabs = value
  },
  updateFinishingPosition (state, value) {
    state.raceFilters.finishingPosition = value
  },
  updateFinishPositionOrMargin (state, value) {
    state.raceFilters.finishPositionOrMargin = value
  },
  updateMargin (state, value) {
    state.raceFilters.margin = value
  },
  updateSpell (state, value) {
    state.raceFilters.spell = value
  },
  updateTrack (state, value) {
    state.raceFilters.track = value
  },
  updateTrackConditions (state, value) {
    state.raceFilters.trackConditions = value
  },
  updateUserRaceVenueRace (state, value) {
    state.raceVenueNote = value
  },
  updateSortFieldsBy (state, sortField) {
    state.sortFieldsBy = sortField
  },
  updateRaceSubscription (state, { subscription }) {
    if (state.raceSubscription) {
      state.raceSubscription.unsubscribe()
    }
    state.raceSubscription = subscription
  },
  cancelRaceSubscription (state) {
    state.raceSubscription = null
  },
  setSingularRunners (state, { runner, name }) {
    Vue.set(state.singularRunners, name, runner)
  },
  setLast50RidesJockeyAndTrainer (state, { last50Rides, id }) {
    Vue.set(state.last50Rides, id, last50Rides)
  },
  setHorseSearchResults (state, res) {
    state.horseSearchRes = res
  },
  resetToDefault (state) {
    const { windowDimension, ...rest } = getDefaultStateMain()
    Object.assign(state, rest)
  },
  updateMarketRange (state, value) {
    state.marketRange = value
  },
  updateMarketPercentage (state, value) {
    state.marketPercentage = value
  },
  updateOddsToUse (state, value) {
    state.oddsToUse = value
  },
  updateOddsFormat (state, value) {
    state.oddsFormat = value
  },
  updateRatingToUse (state, value) {
    state.ratingToUse = value
  },
  saveCompetitorNotes (state, { competitorObj, runnerId }) {
    Vue.set(state.competitorsNotesFromDB, runnerId, competitorObj)
  },
  saveCompetitorPrices (state, { competitorObj, runnerId }) {
    Vue.set(state.myCompetitorPricesFromDB, runnerId, competitorObj)
  },
  saveCompetitorRatings (state, { competitorObj, runnerId }) {
    Vue.set(state.myCompetitorRatingsFromDB, runnerId, competitorObj)
  },
  updateUserData (state, { userData }) {
    // Chrome Tabs
    if (userData.chromeGrouping) {
      const parsed = JSON.parse(userData.chromeGrouping)
      state.chromeGrouping = parsed
    }

    if (userData.chromeTabs) {
      const parsed = JSON.parse(userData.chromeTabs)
      state.chromeTabs = parsed
    }
    if (userData.globalRunnerNotes) {
      const parsed = JSON.parse(userData.globalRunnerNotes)
      state.globalRunnerNotes = parsed
    }

    // Race Filters
    if (userData.distance) {
      const parsed = JSON.parse(userData.distance)
      state.raceFilters.distance = parsed
    }

    if (userData.finishingPosition) {
      const parsed = JSON.parse(userData.finishingPosition)
      state.raceFilters.finishingPosition = parsed
    }

    if (userData.finishPositionOrMargin) {
      const parsed = JSON.parse(userData.finishPositionOrMargin)
      state.raceFilters.finishPositionOrMargin = parsed
    }

    if (userData.margin) {
      const parsed = JSON.parse(userData.margin)
      state.raceFilters.margin = parsed
    }

    if (userData.spell) {
      const parsed = JSON.parse(userData.spell)
      state.raceFilters.spell = parsed
    }

    if (userData.track) {
      const parsed = JSON.parse(userData.track)
      state.raceFilters.track = parsed
    }

    if (userData.trackConditions) {
      const parsed = JSON.parse(userData.trackConditions)
      state.raceFilters.trackConditions = parsed
    }

    if (userData.classes) {
      const parsed = JSON.parse(userData.classes)
      state.raceFilters.classes = parsed
    }

    if (userData.sortFieldsBy) {
      const parsed = JSON.parse(userData.sortFieldsBy)
      state.sortFieldsBy = parsed
    } else {
      state.sortFieldsBy = 'default'
    }

    // Blackbook
    if (userData.runnerBlackbookComments) {
      const comments = JSON.parse(userData.runnerBlackbookComments)
      state.runnerBlackbookComments = comments
    }

    if (userData.jockeyBlackbookComments) {
      const comments = JSON.parse(userData.jockeyBlackbookComments)
      state.jockeyBlackbookComments = comments
    }

    if (userData.trainerBlackbookComments) {
      const comments = JSON.parse(userData.trainerBlackbookComments)
      state.trainerBlackbookComments = comments
    }

    if (userData.runnerBlackbook) {
      const blackbook = JSON.parse(userData.runnerBlackbook)
      state.runnerBlackbook = blackbook
    }

    if (userData.jockeyBlackbook) {
      const blackbook = JSON.parse(userData.jockeyBlackbook)
      state.jockeyBlackbook = blackbook
    }

    if (userData.trainerBlackbook) {
      const blackbook = JSON.parse(userData.trainerBlackbook)
      state.trainerBlackbook = blackbook
    }

    // Options
    if (userData.marketRange) {
      const parsed = JSON.parse(userData.marketRange)
      state.marketRange = parsed
    }
    if (userData.marketPercentage) {
      const parsed = JSON.parse(userData.marketPercentage)
      state.marketPercentage = parsed
    }

    if (userData.oddsToUse) {
      const parsed = JSON.parse(userData.oddsToUse)
      state.oddsToUse = parsed
    }

    if (userData.oddsFormat) {
      const parsed = JSON.parse(userData.oddsFormat)
      state.oddsFormat = parsed
    }

    if (userData.ratingToUse) {
      const parsed = JSON.parse(userData.ratingToUse)
      state.ratingToUse = parsed
    }
  },
  updatePrice (state, { price }) {
    const key = `${price.id}:${price.source}:${price.type}`
    const race = state.detailedRaces[price.id]
    const scratched = []
    if (race && race.competitors) {
      race.competitors.forEach(x => {
        if (x.scratched) {
          scratched.push(x.tabNo)
        }
      })
    }
    const prices = [...price.prices]
    const filteredPrices = [...price.prices.filter(price => !scratched.includes(price.tabNo))]
    let marketPercentage = 0
    filteredPrices.forEach(x => {
      if (x.price) {
        marketPercentage += 1 / x.price
      }
    })
    prices.MarketPercentage = marketPercentage * 100.0
    prices.poolSize = price.poolSize
    if (price.updated) {
      prices.updated = parseISO(price.updated)
    }
    prices.type = price.type
    prices.source = price.source
    Object.freeze(prices)
    Vue.set(state.prices, key, prices)
  },
  updateRelogin (state, { isReauthorize }) {
    state.isReauthorize = isReauthorize
  },
  updateActiveRunners (state, { competitor }) {
    const { currentVenue } = state
    const concatinatedVenueName = currentVenue?.name?.split(' ')?.join('')
    const combinedId = `${concatinatedVenueName}-${competitor.runnerId}-${competitor.tabType}`
    Vue.set(state.activeRunners, combinedId, competitor)
  },
  setActiveRunners (state, { competitors }) {
    state.activeRunners = competitors
  },
  setMeetingSelected (state, { meeting }) {
    state.meetingSelected = meeting
  },
  updateDualAccepteances (state, { boolean }) {
    state.isDualAcceptances = boolean
  },
  updateDualAcceptances (state, { azList }) {
    // Only thoroughbred runners that has a status of `OPEN` - Australia/New Zealand Races (Demestic Races Only)
    const thoroughbred = azList?.filter(co => {
      return co.meetingType === 'THOROUGHBRED' && co.status === 'OPEN' && (co.raceCountry === 'AUS' || co.raceCountry === 'NZL')
    })

    // Runner Id collector
    const runnerIds = []

    // Remove duplicates
    const uniqueRunners = thoroughbred.filter(runner => {
      const isDuplicate = runnerIds.includes(runner.runnerId)

      if (!isDuplicate) {
        runnerIds.push(runner.runnerId)
        return true
      }

      return false
    })

    // Collect runenrs that are racing in multiple venues within a 4day pirod
    const multiAcceptances = []

    // Map thrugh uniqe runners and with no duplicates
    uniqueRunners.forEach((item) => {
      // Grab runner this is racing in multiple races at different times/venue
      const sameRunnerInMulipleRaces = thoroughbred.filter(t => t.runnerId === item.runnerId)

      if (sameRunnerInMulipleRaces.length >= 2) {
        multiAcceptances.push(sameRunnerInMulipleRaces)
      }
    })

    state.dualAcceptances = multiAcceptances
    state.dualAcceptancesHasLoaded = true
  },
  updateRaceDetail (state, { detailedRace }) {
    const key = `${detailedRace.id}`
    Vue.set(state.detailedRaces, key, detailedRace)
  }
}

export const mutationsMain = mutations
