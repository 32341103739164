<template>
  <div class="race-grid pa-2 pb-16 overflow-x-hidden mt-3" id="raceGrid" :style="`max-height: ${longFormHeight}px; overflow-y: scroll;`">
    <v-row class="pb-1">
      <v-col cols="4" class="px-0 pt-0">
        <v-simple-table dense class="common-table box-shadow race-grid__table mt-6">
          <template v-slot:default>
            <thead class="green lighten-4 font-weight-bold">
              <tr>
                <th class="text-left">
                  Runner
                </th>
                <th class="text-left">
                  Form
                </th>
                <th class="text-left">
                  Bar
                </th>
                <th class="text-left">
                  Best Odds
                </th>
              </tr>
            </thead>
            <tbody v-if="race !== null">
              <tr
                class="cursor-pointer"
                v-for="item in race.competitors"
                :key="item.tabNo"
                :class="dynamicFilters(item)"
                @click="addRunnerToChromeTab({ co: item,  tabType: 'runnerTab' })">
                <v-tooltip bottom z-index="999" color="black" open-delay="400">
                  <template v-slot:activator="{ on, attrs }">
                    <td v-bind="attrs" v-on="on">
                      <div class="race-grid__table-figure d-flex align-center">
                        <div class="d-flex align-center">
                          <span small pill style="float:left;width: 27px; height: 25px; border: 1px solid rgb(216, 216, 216); background-color: rgb(255, 255, 255); font-weight:900; text-align: center;margin-bottom:3px;margin-top:3px;">
                            <span style="line-height:25px;">
                              {{ item.tabNo }}
                            </span>
                          </span>
                          <figure class="race-grid__figure mx-1">
                            <img @error="setAltImg" :src="'https://d36frgpkvm82k8.cloudfront.net/'+race.meeting.externalIDs[0].id+'_'+race.number+'_'+item.tabNo+'_64x64.png'" alt="jockey silks">
                          </figure>
                          <div v-if="!item.scratched">
                            <span class="race-grid__name ellipsis font-weight-medium d-block">
                              {{ item.name }}
                              <span v-if="item.emergencyIndicator" class="ml-1 red--text">E</span>
                              <v-menu open-on-hover :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-show="isDualAcceptance(item)" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
                                </template>
                                <v-list class="pa-0">
                                  <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`" @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                                    <p class="ma-0">
                                      <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                                    </p>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </span>
                            <span class="race-grid__weight ellipsis d-block">
                              <span>{{ item.weightTotal }}kg</span>
                              <v-icon v-if="blackbookCheck({ runner: item, tabType: 'runnerTab' })" class="ml-1" left size="15" color="black">
                                mdi-book-open-page-variant
                              </v-icon>
                            </span>
                          </div>
                          <div v-else>
                            <span class="race-grid__name ellipsis font-weight-medium d-block">
                              {{ item.name }}
                              <span v-if="item.emergencyIndicator" class="ml-1 red--text">E</span>
                              <v-menu open-on-hover :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-show="isDualAcceptance(item)" v-bind="attrs" v-on="on" class="ml-1" x-small color="red">mdi-flag-checkered</v-icon>
                                </template>
                                <v-list class="pa-0">
                                  <v-list-item v-for="(acceptance, index) in onReturnRunnerAcceptances({ acceptance: item })" :key="`${acceptance.raceID}-${index}-${acceptance.startTime}`" @click="selectRace({ id: acceptance?.raceID})" :class="`acceptance ${acceptance?.scratched ? 'acceptance-scratched' : ''}`">
                                    <p class="ma-0">
                                      <span class="font-weight-bold">{{ acceptanceRaceData({ acceptance })?.runnerName }}</span> - R{{ acceptance.raceNumber }} @ {{ acceptanceRaceData({ acceptance })?.startTime  }} (<span :class="`${dateToWord({ acceptance })}`">{{ dateToWord({ acceptance }) }}</span>) - {{ acceptance?.raceName }} - {{ acceptance?.distance }}m
                                    </p>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </span>
                            <span class="race-grid__weight ellipsis d-block scratchedDownName">SCRATCHED</span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </template>
                  <span class="font-weight-medium">{{ item.name }}</span>
                </v-tooltip>
                <!-- <td>
                  <Tooltip :text="checkFinalPos({ competitor: item })" :maxCharacters="30" />
                </td> -->
                <td>
                   <Tooltip :text="last10Starts({ competitor: item })" :maxCharacters="30" />
                </td>
                <td>{{ item.barrier }}</td>
                <td v-if="!item.scratched">
                  <div class="d-flex align-center race-grid__odds">
                    <figure class="race-grid__odds-figure">
                      <v-img :src="logoFromPriceCode(getRunnerFixedOddBookie(item.tabNo))" v-if="bestOddsArray.length >= 1 && getRunnerFixedOddBookie(item.tabNo)"></v-img>
                      <v-progress-circular
                        v-else-if="isLoading"
                        indeterminate
                        color="primary"
                        size="15"
                        width="2"
                      ></v-progress-circular>
                    </figure>

                    <span>
                      {{ getRunnerFixedOdd(item.tabNo) }}
                    </span>
                  </div>
                </td>
                <td v-else style="color: grey">-</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>

      <v-col cols="2" class="px-0 px-3 pt-0">
        <v-tabs fixed-tabs flat height="25" class="box-shadow">
          <v-tabs-slider small color="green"></v-tabs-slider>
          <v-tab>Jockey</v-tab>
          <v-tab>Trainer</v-tab>

          <v-tab-item>
            <v-simple-table class="common-table box-shadow race-grid__table" dense>
              <template v-slot:default>
                <thead class="green lighten-4 font-weight-bold">
                  <tr>
                    <th style="width: 25% !important;" class="text-left">
                      Name
                    </th>
                  </tr>
                </thead>
                <tbody v-if="race !== null">
                  <tr v-for="(item, index) in race.competitors" :key="`${item.runner.id}-jockeyTab-${index}`" :class="dynamicFilters(item)" class="cursor-pointer">
                    <td style="width: 25% !important;" class="px-0 pl-3" @click="onClickFetchRunnerLast50({ co: item, tabType: 'jockeyTab' })">
                      <span>{{ item.jockey }}</span> <span v-if="item.apprenticeIndicator">(a {{ item.allowanceWeight.toFixed(1) }})</span>
                      <v-icon class="ml-2 inline-block" v-if="blackbookCheck({ runner: item, tabType: 'jockeyTab' })" left size="15" color="black">
                        mdi-book-open-page-variant
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>

          <v-tab-item>
            <v-simple-table class="common-table box-shadow race-grid__table" dense>
              <template v-slot:default>
                <thead class="green lighten-4 font-weight-bold">
                  <tr>
                    <th style="width: 25% !important;" class="text-left">
                      Name
                    </th>
                  </tr>
                </thead>
                <tbody v-if="race !== null">
                  <tr v-for="item in race.competitors" :key="`${item.runner.id}-trainerTab`" :class="dynamicFilters(item)" class="cursor-pointer">
                    <td style="width: 25% !important;" class="px-0 pl-3" @click="onClickFetchRunnerLast50({ co: item, tabType: 'trainerTab' })">
                      <span>{{ item.trainer }}</span>
                      <v-icon class="ml-2 inline-block" v-if="blackbookCheck({ runner: item, tabType: 'trainerTab' })" left size="15" color="black">
                        mdi-book-open-page-variant
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs>
      </v-col>

      <v-col cols="4" class="px-0 pt-0">
        <v-tabs
          v-model="ratingTable"
          fixed-tabs
          flat
          height="25"
          class="box-shadow"
        >
          <v-tabs-slider small color="green"></v-tabs-slider>
          <v-tab
            v-for="item in ratings"
            :key="item"
            class="pa-0"
            style="font-size: 13px;"
          >
            <span class="race-grid__ratings ellipsis">{{ item }}</span>
          </v-tab>
          <v-tab-item>
            <!-- FF5 Ratings -->
            <v-simple-table class="common-table box-shadow race-grid__table" dense>
              <template v-slot:default>
                <thead class="green lighten-4 font-weight-bold">
                  <tr>
                    <th style="width: 25% !important;" class="text-left">
                      My Prices
                    </th>
                    <th style="width: 25% !important;" class="text-left">
                      Rating
                    </th>
                    <th style="width: 25% !important;" class="text-left">
                      Odds
                    </th>
                    <th style="width: 25% !important;" class="text-left">
                      Pace
                    </th>
                  </tr>
                </thead>
                <tbody v-if="race !== null">
                  <tr
                    v-for="item in race.competitors"
                    :key="item.tabNo"
                    :class="dynamicFilters(item)"
                  >
                    <td style="width: 25% !important;" class="px-0 pl-3">
                      <v-text-field prefix="$" type="number" min="0" flat class="race-grid__text-field pa-0 pl-3 ma-n2" dense :value="getMyCompetitorPrice({ runner: item })" @input="onInputChangeMyCompetitorPrice({ price: $event, runner: item  })" @blur="setMyCompetitorPrice({ price: $event.target.value, runnerId: item.runner.id })" :disabled="item.scratched"></v-text-field>
                    </td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-if="item.runnerStatistics !== null">
                      <v-text-field type="number" min="0" flat class="race-grid__text-field pa-0 pl-3 ma-n2 customizeRatings" dense :value="getMyCompetitorRating({ runner: item })" @input="onInputChangeMyCompetitorRating({ rating: $event, runner: item  })" @blur="setMyCompetitorRating({ rating: $event.target.value, runnerId: item.runner.id })" :disabled="item.scratched"></v-text-field>
                    </td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-else>-</td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-if="item.runnerStatistics !== null">{{ item.oddsToDisplay !== '' ? `$${item.oddsToDisplay || '-'}` : '' }}</td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-else>-</td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-if="item.runnerStatistics !== null">{{ item.speedmapPosition }}</td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-else>-</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item>
            <!-- Career Ratings -->
            <v-simple-table class="common-table box-shadow race-grid__table" dense>
              <template v-slot:default>
                <thead class="green lighten-4 font-weight-bold">
                  <tr>
                    <th style="width: 25% !important;" class="text-left">
                      Career
                    </th>
                    <th style="width: 25% !important;" class="text-left">
                      Last Start
                    </th>
                    <th style="width: 25% !important;" class="text-left">
                      Dist
                    </th>
                    <th style="width: 25% !important;" class="text-left">
                      Track
                    </th>
                  </tr>
                </thead>
                <tbody v-if="race !== null">
                  <tr
                    v-for="item in race.competitors"
                    :key="item.tabNo"
                    :class="dynamicFilters(item)"
                  >
                    <td style="width: 25% !important;" class="px-0 pl-3" v-if="item.runnerStatistics !== null">{{ item.runnerStatistics.career.all.rating }}</td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-else>-</td>
                    <td style="width: 25% !important;" class="px-0 pl-3">{{ ratingUnadjusted({ runner: item }) }}</td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-if="item.runnerStatistics !== null">{{ careerByDistance(item.runnerStatistics.career.detail.byDistance) }}</td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-else>-</td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-if="item.runnerStatistics !== null">{{ careerByTrack(item.runnerStatistics.career.detail.byTrack) }}</td>
                    <td style="width: 25% !important;" class="px-0 pl-3" v-else>-</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
          <v-tab-item>
            <!-- Career Stats -->
            <v-simple-table class="common-table box-shadow race-grid__table" dense>
              <template v-slot:default>
                <thead class="green lighten-4 font-weight-bold">
                  <tr>
                    <th style="width: 20% !important;" class="text-left">
                      Career
                    </th>
                    <th style="width: 20% !important;" class="text-left">
                      Api
                    </th>
                    <th style="width: 20% !important;" class="text-left">
                      Win %
                    </th>
                    <th style="width: 20% !important;" class="text-left">
                      Place %
                    </th>
                    <th style="width: 20% !important;" class="text-left">
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody v-if="race !== null">
                  <tr
                    v-for="item in race.competitors"
                    :key="item.tabNo"
                    :class="dynamicFilters(item)"
                  >
                    <td style="width: 20% !important;" class="px-0 pl-3" v-if="item.runnerStatistics !== null">{{ item.runnerStatistics.career.all.numberOfRuns }}:{{ item.runnerStatistics.career.all.firsts }}-{{ item.runnerStatistics.career.all.seconds }}-{{ item.runnerStatistics.career.all.thirds }}</td>
                    <td style="width: 20% !important;" class="px-0 pl-3" v-else>-</td>
                    <td style="width: 20% !important;" class="px-0 pl-3" v-if="item.runnerStatistics !== null">{{ numeral(item.runnerStatistics.career.all.averagePrizeMoneyWon).format('$0,0.00a') }}</td>
                    <td style="width: 20% !important;" class="px-0 pl-3" v-else>-</td>
                    <td style="width: 20% !important;" class="px-0 pl-3" v-if="item.runnerStatistics !== null">{{ item.runnerStatistics.career.all.winPercentage }}%</td>
                    <td style="width: 20% !important;" class="px-0 pl-3" v-else>-</td>
                    <td style="width: 20% !important;" class="px-0 pl-3" v-if="item.runnerStatistics !== null">{{ item.runnerStatistics.career.all.placePercentage }}%</td>
                    <td style="width: 20% !important;" class="px-0 pl-3" v-else>-</td>
                    <td style="width: 20% !important;" class="px-0 pl-3">{{ numeral(item.prizeMoney).format('$0,0.00a') }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
      <v-col cols="2" class="pl-3 mt-6 pt-0">
        <v-simple-table class="common-table box-shadow race-grid__table" dense>
          <template v-slot:default>
            <thead class="green lighten-4 font-weight-bold">
              <tr>
                <th class="text-left">
                  Notes
                </th>
              </tr>
            </thead>
            <tbody v-if="race !== null">
              <tr
                v-for="item in race.competitors"
                :key="item.tabNo"
                :class="dynamicFilters(item)"
              >
                <td class="pl-3">
                  <v-text-field flat class="race-grid__text-field pa-0 pl-3 ma-n2" dense :value="getCompetitorNotes({ runner: item })" @input="onInputChange({ comments: $event, runner: item  })"></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-if="race !== null">
      <Last5RunnerStartsColumns :race="race" :addRunnerToChromeTab="addRunnerToChromeTab"/>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import numeral from 'numeral'
import { isEmpty, sortByKeyValue, truncateTxt, filterFormHistory, formattDateToWords, lastRating } from '../common/methods'
import Tooltip from './Tooltip.vue'
import { format } from 'date-fns'
import Last5RunnerStartsColumns from './Last5RunnerStartsColumns'

export default {
  props: {
    addRunnerToChromeTab: Function
  },
  components: {
    Tooltip,
    Last5RunnerStartsColumns
  },
  data () {
    return {
      items: [
        'Jockey',
        'Trainer'
      ],
      ratings: [
        'Ratings',
        'Career Ratings',
        'Career Stats'
      ],
      tab: null,
      ratingTable: null,
      longFormHeight: 0,
      raceGridCompetitorNotes: {},
      raceGridMyPrice: {},
      raceGridMyRating: {},
      debounce: null
    }
  },
  mounted () {
    const { y: yPos } = this.getRaceGridRect()
    this.longFormHeight = this.windowDimension.height - yPos
  },
  computed: {
    ...mapState(['runnerTabs', 'windowDimension', 'sortFieldsBy', 'raceFilters', 'chromeGrouping', 'currentVenue', 'runnerBlackbook', 'jockeyBlackbook', 'trainerBlackbook', 'bestOddsArray', 'competitorsNotesFromDB', 'azList', 'azListRunners', 'myCompetitorPricesFromDB', 'myCompetitorRatingsFromDB', 'isLoading', 'dualAcceptances', 'dualAcceptancesHasLoaded']),
    ...mapGetters(['getSelectedRace']),
    ...mapGetters('account', ['authConfig']),
    race: function () {
      const race = this.getSelectedRace()
      if (!isEmpty(race)) {
        switch (this.sortFieldsBy) {
          case 'default':
            this.setCompetitorComments({ race })
            return race
          case 'tabNo': {
            const orderCompetitors = race.competitors.sort((a, b) => sortByKeyValue({ key: 'tabNo', secondKey: null, a, b }))
            const newRace = { ...race, competitors: orderCompetitors }
            this.setCompetitorComments({ race: newRace })
            return newRace
          }
          case 'barrier': {
            const orderCompetitors = race.competitors.sort((a, b) => sortByKeyValue({ key: 'barrier', secondKey: null, a, b }))
            const newRace = { ...race, competitors: orderCompetitors }
            this.setCompetitorComments({ race: newRace })
            return newRace
          }
          case 'ratingDry': {
            race.competitors.forEach(c => {
              if (this.getMyCompetitorRating({ runner: c }) !== '') {
                c.rating.dry = this.getMyCompetitorRating({ runner: c })
              }
            })
            const orderCompetitors = race.competitors.sort((a, b) => sortByKeyValue({ key: 'rating', secondKey: 'dry', a, b }))
            const newRace = { ...race, competitors: orderCompetitors }
            this.setCompetitorComments({ race: newRace })
            return newRace
          }
          case 'ratingWet': {
            const orderCompetitors = race.competitors.sort((a, b) => sortByKeyValue({ key: 'rating', secondKey: 'wet', a, b }))
            const newRace = { ...race, competitors: orderCompetitors }
            this.setCompetitorComments({ race: newRace })
            return newRace
          }
          case 'myPrice': {
            const scratched = race.competitors.filter(c => c.scratched)
            const orderCompetitors = race.competitors.filter(c => !c.scratched).sort((a, b) => sortByKeyValue({ key: 'myPrice', secondKey: null, a, b }))
            const newRace = { ...race, competitors: [...orderCompetitors, ...scratched] }
            this.setCompetitorComments({ race: newRace })
            return newRace
          }
          default:
            this.setCompetitorComments({ race })
            return race
        }
      } else {
        this.setCompetitorComments({ race })
        return race
      }
    },
    combinedBlackbook: function () {
      return [...this.runnerBlackbook, ...this.jockeyBlackbook, ...this.trainerBlackbook].filter(bb => bb !== undefined)
    },
    isDualAcceptance: function () {
      return item => {
        if (isEmpty(this.dualAcceptancesHasLoaded)) {
          return false
        }

        if (this.race.meeting?.type === 'THOROUGHBRED') {
          const found = this.dualAcceptances?.find(co => co[0]?.runnerId === item?.runner?.id)
          return !isEmpty(found)
        } else {
          return false
        }
      }
    }
  },
  watch: {
    optionDisplay (newState, oldState) {
      if (newState === 'Filter Form' || !isNaN(newState)) this.optionDisplay = oldState
    },
    windowDimension (newState) {
      const { y: yPos } = this.getRaceGridRect()
      this.longFormHeight = newState.height - yPos
    },
    race (newState) {
      this.calculateOdds()
    }
  },
  methods: {
    ...mapActions(['addToRunnerTabs', 'addNewChromeTabAXN', 'fetchGlobalIdAndLast50RidesJockey', 'fetchGlobalIdAndLast50RidesTrainer', 'setCompetitorNotes', 'setMyCompetitorPrice', 'setMyCompetitorRating', 'calculateOdds', 'selectRace']),
    numeral,
    isEmpty,
    // checkFinalPos,
    last10Starts ({ competitor }) {
      const last10 = competitor.runnerStatistics?.recent?.last10Starts
      if (isEmpty(last10)) return ''
      const newLast10 = last10.replace('?', '')
      return newLast10
    },
    async onClickFetchRunnerLast50 ({ co, tabType }) {
      await this.addRunnerToChromeTab({ co, tabType })

      if (tabType === 'jockeyTab') {
        await this.fetchGlobalIdAndLast50RidesJockey({ jockeyId: co.jockeyID })
      } else if (tabType === 'trainerTab') {
        await this.fetchGlobalIdAndLast50RidesTrainer({ trainerId: co.trainerID })
      }
    },
    logoFromPriceCode (code) {
      return 'https://betmakers.com/img/circle_logos/' + code + '.png'
    },
    // TODO: REMOVE THIS WHEN READY (addThisRunner)
    addThisRunner (co, tabType) {
      if (tabType === 'trainerTab' && isEmpty(co.trainer)) {
        return
      } else if (tabType === 'jockeyTab' && isEmpty(co.jockey)) {
        return
      }
      this.addToRunnerTabs({ competitor: co, tabType })
    },
    dynamicFilters (runner) {
      const { highlight } = filterFormHistory({ runner })
      return highlight
    },
    careerByTrack (careerDetail) {
      if (isEmpty(careerDetail)) return '-'
      const byTrack = careerDetail.filter(detail => detail.key === this.race.meeting.track.name)
      if (byTrack.length === 0) {
        return '-'
      }
      return `${byTrack[0].rating}`
    },
    careerByDistance (careerDetail) {
      if (isEmpty(careerDetail)) return '-'
      const raceDistance = this.race.distance.toString()
      const byDistance = careerDetail.filter(detail => detail.key === raceDistance)
      if (byDistance.length === 0) {
        return '-'
      }
      return `${byDistance[0].rating}`
    },
    uiIsEmpty (value) {
      return !isEmpty(value)
    },
    uiTruncateTxt ({ txt, max }) {
      return truncateTxt({ txt, max })
    },
    setAltImg (e) {
      e.target.src = require('../assets/d_silk.png')
    },
    blackbookCheck ({ runner, tabType }) {
      const found = this.combinedBlackbook.find(co => co.runnerId === runner?.runner?.id && co.tabType === tabType)
      return !isEmpty(found)
    },
    getRunnerFixedOdd (tabNo) {
      let fixedPrice = 0
      if (this.bestOddsArray.length >= 1) {
        this.bestOddsArray.forEach(b => {
          // eslint-disable-next-line eqeqeq
          if (tabNo === b.tabNo) {
            fixedPrice = b.price
          }
        })
      } else {
        this.getSelectedRace().competitors.forEach(b => {
          // eslint-disable-next-line eqeqeq
          if (tabNo === b.tabNo) {
            // eslint-disable-next-line eqeqeq
            const newPrices = b.prices.filter(b => b.type === 'WIN_FIXED_ODDS')
            const flucs = newPrices.map(a => a.price)
            fixedPrice = flucs[flucs.length - 1] || 0
          }
        })
      }

      return `$${fixedPrice.toFixed(2)}`
    },
    getRunnerFixedOddBookie (tabNo) {
      let bookie = 0
      this.bestOddsArray.forEach(b => {
        // eslint-disable-next-line eqeqeq
        if (tabNo === b.tabNo) {
          bookie = b.source
        }
      })
      return bookie
    },
    setCompetitorComments ({ race }) {
      if (!isEmpty(race)) {
        race.competitors.forEach((runner) => {
          const gbsMeetingId = race?.meeting?.externalIDs[0]?.id
          const raceNumber = race?.number
          const runnerId = runner?.runner?.id

          if (!isEmpty(runnerId)) {
            const comments = this.competitorsNotesFromDB[runnerId]
            const compNotes = comments?.find(note => note.gbsMeetingId === gbsMeetingId && note.raceNumber === raceNumber)

            if (!isEmpty(compNotes)) {
              Vue.set(this.raceGridCompetitorNotes, runnerId, compNotes.notes)
            } else {
              Vue.set(this.raceGridCompetitorNotes, runnerId, '')
            }

            const prices = this.myCompetitorPricesFromDB[runnerId]
            const compPrices = prices?.find(price => price.gbsMeetingId === gbsMeetingId && price.raceNumber === raceNumber)

            if (!isEmpty(compPrices)) {
              Vue.set(this.raceGridMyPrice, runnerId, compPrices.price)
            } else {
              Vue.set(this.raceGridMyPrice, runnerId, '')
            }

            const ratings = this.myCompetitorRatingsFromDB[runnerId]
            const compRatings = ratings?.find(rating => rating.gbsMeetingId === gbsMeetingId && rating.raceNumber === raceNumber)

            if (!isEmpty(compRatings)) {
              Vue.set(this.raceGridMyRating, runnerId, compRatings.rating)
            } else {
              Vue.set(this.raceGridMyRating, runnerId, runner.rating.dry)
            }
          }
        })
      }
    },
    onInputChange ({ comments, runner }) {
      const runnerId = runner?.runner?.id
      Vue.set(this.raceGridCompetitorNotes, runnerId, comments)
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.setCompetitorNotes({ notes: comments, runnerId: runner?.runner?.id })
      }, 1200)
    },
    onInputChangeMyCompetitorPrice ({ price, runner }) {
      const runnerId = runner?.runner?.id
      Vue.set(this.raceGridMyPrice, runnerId, price)
    },
    onInputChangeMyCompetitorRating ({ rating, runner }) {
      const runnerId = runner?.runner?.id
      Vue.set(this.raceGridMyRating, runnerId, rating)
    },
    getCompetitorNotes ({ runner }) {
      const runnerId = runner?.runner?.id
      if (!isEmpty(this.raceGridCompetitorNotes[runnerId])) {
        return this.raceGridCompetitorNotes[runnerId]
      } else {
        return ''
      }
    },
    getMyCompetitorPrice ({ runner }) {
      const runnerId = runner?.runner?.id
      return !isEmpty(this.raceGridMyPrice[runnerId]) ? this.raceGridMyPrice[runnerId] : ''
    },
    getMyCompetitorRating ({ runner }) {
      const runnerId = runner?.runner?.id
      return !isEmpty(this.raceGridMyRating[runnerId]) ? this.raceGridMyRating[runnerId] : ''
    },
    getRaceGridRect () {
      const raceGrid = document.querySelector('.race-grid')
      const raceGridRec = raceGrid?.getBoundingClientRect()
      return raceGridRec
    },
    ratingUnadjusted ({ runner }) {
      if (!isEmpty(runner?.formHistory)) {
        return lastRating(runner)
      }
      return '0'
    },
    onReturnRunnerAcceptances ({ acceptance }) {
      if (isEmpty(this.dualAcceptances)) return []
      const runnerAcceptance = this.dualAcceptances.find(co => co[0]?.runnerId === acceptance.runner?.id)

      if (!isEmpty(runnerAcceptance)) {
        const removeCurrentRaceFromAcceptances = runnerAcceptance?.filter(acceptance => acceptance?.raceID !== this.race?.id)
        return removeCurrentRaceFromAcceptances
      } else {
        return []
      }
    },
    acceptanceRaceData ({ acceptance }) {
      const startTime = format(new Date(acceptance?.startTime), 'HH:mmaaa')
      return {
        startTime,
        runnerName: acceptance?.runner
      }
    },
    dateToWord ({ acceptance }) {
      const toYyyyMmDd = acceptance?.date?.split('/').reverse().join('-')
      return formattDateToWords(toYyyyMmDd)
    }
  }
}
</script>

<style lang="scss">
  .race-grid {
    padding-bottom: 50px;

    &__table {
    }

    &__table td {
      height: 35px !important;
    }

    &__table-figure {
    }

    &__table-figure-item {
    }

    &__figure {
      width: 30px;
      height: 30px;
      margin-right: 0;
    }

    &__figure img {
      width: 100%;
      height: 100%;
    }

    &__barrier {
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 100%;
      background-color: red;
      font-size: 11px;
      border: 1px solid rgba(0,0,0,1);
      background: #37474f;

      span {
        // color: pink;
      }
    }

    &__name {
      max-width: 100px;

      @media (min-width: 1080px) {
        max-width: 130px;
      }
    }

    &__jt-name {
      max-width: 60px;
    }

    &__weight {
      max-width: 100px;
      font-weight: 400 !important;
      font-size: 11px;
      color: rgba(0,0,0,.6);
      margin-top: -4px;

      i {
        position: relative;
        top: -1px;
      }
    }

    &__odds {
    }

    &__odds-figure {
      width: 24px !important;
      height: 24px !important;
      margin-right: 10px;
    }

    &__ratings {
    }

    &__blackbook {
    }

    &__text-field {
      position: relative;
      top: 12px;
      height: 50px;
      font-size: 13.5px !important;
      border-style: none !important;
    }

    &__text-field  .v-input__slot::before,
    &__text-field  .v-input__slot::after {
      border-style: none !important;
    }

    &__notes-disabled {
      background-color: rgba(0,0,0,0.035) !important;
      cursor: not-allowed !important;
    }
  }

  .scratched {
    text-decoration: line-through;
    color: #777;
    opacity: 0.7;
  }

  .scratchedDownName {
    color: red;
    padding-top:5px;
    font-size: 10px;
    opacity: 0.7;
  }

  .highlight {
    background-color: rgba(200, 230, 201, 1);
  }

  .customizeRatings {
    width: 70px;
  }

  .acceptance {
  font-size: 13px;
}

.acceptance-scratched {
  background: repeating-linear-gradient(
    180deg,
    red 0%,
    red 100%
  );
  background-size: 100% 1px !important;
  background-position: center;
  background-repeat: no-repeat;
  color: #777 !important;
  opacity: 0.7 !important;
}
.acceptance-scratched img {
  opacity: 0.4 !important;
}

.Today {
  color: #4caf50;
  font-weight: bold;
}

.Tomorrow {
  font-weight: bold;
  color: orange;
}
</style>
