<template>
  <v-dialog style="background: transparent !important;" max-width="390" :value="dialog" persistent>
    <v-card>
      <v-toolbar elevation="1" color="#37474f" class="white--text pa-0 text-lg-h6 font-weight-regular" dark dense>
        <v-toolbar-title>Signup to DynamicForm!</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon small @click="onCloseModal"><v-icon size="20">mdi-close</v-icon></v-btn>
      </v-toolbar>

      <v-container class="d-flex justify-space-between">
          <v-btn style="width: 48%;" class="white--text mr-1 font-weight-regular text-sm-body-2 text-caption" @click="onSignUp" color="#4caf50">Yes, sign me up!</v-btn>
          <v-btn style="width: 48%;" class="white--text ml-1 font-weight-regular text-sm-body-2 text-caption" @click="onCloseModal" color="#37474f">Still evaluating...</v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      username: '',
      password: '',
      dialog: false,
      timer: null,
      timerDuration: 30000 // 30 seconds
    }
  },
  mounted () {
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  computed: {
    ...mapState('account', ['isTrial'])
  },
  watch: {
    isTrial (newState) {
      if (newState) {
        this.onSetTimerInterval()
      } else {
        this.onClearTimerInterval()
      }
    }
  },
  props: {
    onSignUp: {
      type: Function,
      required: true
    },
    onLogout: {
      type: Function,
      required: true
    }
  },
  methods: {
    onOpenModal () {
      this.dialog = true
      this.onClearTimerInterval()
    },
    onCloseModal () {
      this.dialog = false
      this.onSetTimerInterval()
    },
    onSetTimerInterval () {
      this.timer = setInterval(() => {
        this.onOpenModal()
      }, this.timerDuration)
    },
    onClearTimerInterval () {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped>
.signup-btn {
  background: green !important;
}

.evaluating-btn {
  background: red !important;
}
</style>
