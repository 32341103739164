<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import Tooltip from './Tooltip.vue'
import { isEmpty, sortByKeyValue } from '../common/methods'

export default {
  name: 'Stable',
  props: {
    isCheckboxMode: {
      type: Boolean,
      required: true
    },
    isCheckboxMode2: {
      type: Boolean,
      required: true
    },
    isExpandAll: {
      type: Boolean,
      required: true
    },
    onReturnCheckboxState: {
      type: Function,
      required: true
    },
    onReturnRunnerKey: {
      type: Function,
      required: true
    },
    onToggleDynamicCheckbox: {
      type: Function,
      required: true
    },
    combinedBlackbook: {
      type: Array,
      required: true
    }
  },
  components: {
    Tooltip
  },
  mounted () {
    this.onSetInitialBlackbookExpand()
    this.onSetInitialBlackbookComments()
  },
  data () {
    return {
      comments: {},
      isExpanded: {},
      isExpandedefault: {},
      isExpandedeAll: {},
      currentBlackbook: {},
      debounce: null
    }
  },
  computed: {
    ...mapState(['runnerBlackbook', 'chromeGrouping', 'chromeTabs', 'currentVenue', 'runnerBlackbookComments']),
    sortedBlackbook () {
      const blackbook = [...this.runnerBlackbook]
      const blackbookFilterd = blackbook?.filter(co => {
        return !co?.meetingType?.includes('GREYHOUND') || co?.meetingType?.includes('THOROUGHBRED')
      })
      const sorted = blackbookFilterd.sort((a, b) => sortByKeyValue({ key: 'selectedTabTypeName', a, b }))
      return sorted
    }
  },
  watch: {
    isExpandAll (newState) {
      if (newState) {
        this.isExpanded = this.isExpandedeAll
      } else {
        this.isExpanded = this.isExpandedefault
      }
    },
    combinedBlackbook () {
      this.onSetInitialBlackbookExpand()
      this.onSetInitialBlackbookComments()
    },
    currentBlackbook (newState) {
      this.isExpanded = { ...this.isExpanded, [this.returnCombinedId({ blackbook: newState })]: newState.isToggle }
    }
  },
  methods: {
    ...mapActions(['deleteRunnerFromBlackbook', 'addToRunnerTabs', 'addNewChromeTabAXN', 'triggerBlackbookChrome', 'setBlackbookComments']),
    isEmpty,
    onRemoveRunnerFromBlackbook ({ blackbook }) {
      this.deleteRunnerFromBlackbook({ blackbook })
    },
    onAddRunner ({ blackbook }) {
      this.triggerBlackbookChrome({ blackbook })
    },
    onInputChange ({ value: comments, blackbook }) {
      Vue.set(this.comments, this.returnCombinedId({ blackbook }), comments)
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.onSubmitBlackbookToDB({ blackbook })
      }, 1200)
    },
    onSubmitBlackbookToDB ({ blackbook }) {
      const runnerId = blackbook.runnerId
      const comments = { ...this.comments }

      const runnerBlackbookComments = {
        [runnerId]: comments[this.returnCombinedId({ blackbook })]
      }

      this.setBlackbookComments({ blackbook, newBlackbookComments: runnerBlackbookComments })
    },
    onReturnRunnerComment ({ blackbook }) {
      return !isEmpty(this.comments[this.returnCombinedId({ blackbook })]) ? this.comments[this.returnCombinedId({ blackbook })] : ''
    },
    onExpandBody ({ blackbook }) {
      if (this.isExpandAll) return
      this.currentBlackbook = { ...blackbook, isToggle: !this.isExpanded[this.returnCombinedId({ blackbook })] }
      this.isExpanded = this.isExpandedefault
    },
    onGetToggleState ({ blackbook }) {
      return this.isExpanded[this.returnCombinedId({ blackbook })]
    },
    onSetInitialBlackbookExpand () {
      this.runnerBlackbook.forEach((blackbook) => {
        Vue.set(this.isExpanded, this.returnCombinedId({ blackbook }), false)
        Vue.set(this.isExpandedefault, this.returnCombinedId({ blackbook }), false)
        Vue.set(this.isExpandedeAll, this.returnCombinedId({ blackbook }), true)
      })
    },
    onSetInitialBlackbookComments () {
      this.runnerBlackbook.forEach((blackbook) => {
        const runnerId = blackbook.runnerId
        const comments = this.runnerBlackbookComments[runnerId]
        if (!isEmpty(comments)) {
          Vue.set(this.comments, this.returnCombinedId({ blackbook }), comments)
        } else {
          Vue.set(this.comments, this.returnCombinedId({ blackbook }), '')
        }
      })
    },
    onGetHeaderComment ({ blackbook }) {
      const comment = this.onReturnRunnerComment({ blackbook })
      return !isEmpty(comment) ? comment : 'No Comments...'
    },
    returnCombinedId ({ blackbook }) {
      return `${blackbook.runnerId}-${blackbook.tabType}`
    }
  }
}
</script>

<template>
  <div>
    <div v-for="blackbook in sortedBlackbook" :key="returnCombinedId({ blackbook })" class="expander">
      <header class="expander__header">
        <h4 class="font-weight-medium cursor" @click="onAddRunner({ blackbook })">
          <Tooltip :text="blackbook.selectedTabTypeName" :maxCharacters="10" />
        </h4>
        <p class="cursor" @click="onExpandBody({ blackbook })" :title="isExpandAll && 'Expand all is currently checked'">
          <Tooltip :text="onGetHeaderComment({ blackbook })" :maxCharacters="10" />
        </p>
        <v-icon class="cursor" @click="onExpandBody({ blackbook })" :title="isExpandAll && 'Expand all is currently checked'">mdi-chevron-down</v-icon>
         <v-simple-checkbox
          v-if="isCheckboxMode"
          class="checkbox ma-0 px-0 mr-1"
          :ripple="false"
          @click="onToggleDynamicCheckbox({ blackbook })"
          :value="!isCheckboxMode2 ? onReturnCheckboxState({ blackbook }) : true"
          hide-details
        ></v-simple-checkbox>
    </header>
      <div v-if="onGetToggleState({ blackbook })" class="expander__body">
        <v-textarea
          id="comment"
          dense
          outlined
          hide-details
          @input="onInputChange({ value: $event, blackbook })"
          :value="onReturnRunnerComment({ blackbook })"
          placeholder="Add comments..."
          class="font-weight-light text-caption mb-2"
          />
        <v-btn @click="onRemoveRunnerFromBlackbook({ blackbook })" small color="#f44336" class="text-caption font-weight-medium white--text pointer" width="100%">
          Remove from blackbook<v-icon class="white--text ml-1" small>mdi-trash-can</v-icon>
        </v-btn>
      </div>
    </div>
    <v-card v-if="isEmpty(sortedBlackbook)" elevation="0"  class="pa-2 d-flex justify-center align-center" height="32px">
      <span class="text-caption ma-0 grey--text text-sm-body-2">No data avaiable</span>
    </v-card>
  </div>
</template>

<style scoped lang="scss">
  .expander {
    &:not(:first-child) {
      margin-top: 2px;
    }

    &__header {
      border-radius: 5px;
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      padding: 3px 5px;
      border-bottom: 1px solid #e8f5e9;

      h4 {
        font-size: 14px;
      }

      p {
        font-size: 14px;
        margin-left: auto;
        padding-right: 46px;
        margin-bottom: 0;
      }

      i {
        font-size: 20px;
      }
    }

    &__body {
      background-color: white;
      padding: 10px;
    }
  }
</style>
