<script>
export default {
  name: 'Loader'
}
</script>

<template>
<div class="loader">
  <div class="loader__ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
</template>

<style scoped>
.loader {
  position: absolute;
  z-index: 100000000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader__ellipsis {
  display: inline-block;
  position: relative;
}

.loader__ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #c8e6c9;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader__ellipsis div:nth-child(1) {
  left: 8px;
  animation: loader__ellipsis1 0.6s infinite;
}
.loader__ellipsis div:nth-child(2) {
  left: 8px;
  animation: loader__ellipsis2 0.6s infinite;
}
.loader__ellipsis div:nth-child(3) {
  left: 32px;
  animation: loader__ellipsis2 0.6s infinite;
}
.loader__ellipsis div:nth-child(4) {
  left: 56px;
  animation: loader__ellipsis3 0.6s infinite;
}
@keyframes loader__ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader__ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader__ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
