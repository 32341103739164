<script>
import { mapState, mapActions } from 'vuex'
import Tooltip from './Tooltip.vue'
import { isEmpty, sortByKeyValue } from '../common/methods'
import Vue from 'vue'

export default {
  name: 'BlackbookJockeyAndTrainer',
  props: {
    jt: {
      type: Array,
      required: true
    },
    isCheckboxMode2: {
      type: Boolean,
      required: true
    },
    radioGroupValue: {
      type: String,
      required: true
    },
    isCheckboxMode: {
      type: Boolean,
      required: true
    },
    isExpandAll: {
      type: Boolean,
      required: true
    },
    onReturnCheckboxState: {
      type: Function,
      required: true
    },
    onToggleDynamicCheckbox: {
      type: Function,
      required: true
    }
  },
  components: {
    Tooltip
  },
  mounted () {
    this.setRunnerBlackbookComments()
  },
  data () {
    return {
      comments: {},
      isExpanded: {},
      isExpandedefault: {},
      isExpandedeAll: {},
      currentBlackbook: {},
      debounce: null
    }
  },
  watch: {
    radioGroupValue () {
      this.setRunnerBlackbookComments()
    },
    isExpandAll (newState) {
      if (newState) {
        this.isExpanded = this.isExpandedeAll
      } else {
        this.isExpanded = this.isExpandedefault
      }
    },
    currentBlackbook (newState) {
      this.isExpanded = { ...this.isExpanded, [this.returnCombinedId({ blackbook: newState })]: newState.isToggle }
    },
    jt () {
      this.onSetInitialBlackbookExpand()
      this.onSetInitialBlackbookComments()
    }
  },
  computed: {
    ...mapState(['jockeyBlackbook', 'trainerBlackbook', 'jockeyBlackbookComments', 'trainerBlackbookComments']),
    sortedBlackbook () {
      const blackbook = [...this.jt]
      const sorted = blackbook.sort((a, b) => sortByKeyValue({ key: 'selectedTabTypeName', a, b }))
      return sorted
    },
    jtBlackbookComments: function () {
      const combined = { ...this.jockeyBlackbookComments, ...this.trainerBlackbookComments }
      return combined
    }
  },
  methods: {
    ...mapActions([
      'deleteRunnerFromBlackbook',
      'deleteJockeyFromBlackbook',
      'deleteTrainerFromBlackbook',
      'addToRunnerTabs',
      'triggerBlackbookChrome',
      'setBlackbookComments'
    ]),
    isEmpty,
    removeFromBlackbook ({ blackbook }) {
      if (blackbook.tabType === 'jockeyTab') {
        this.deleteJockeyFromBlackbook({ blackbook })
      } else if (blackbook.tabType === 'trainerTab') {
        this.deleteTrainerFromBlackbook({ blackbook })
      }
    },
    onAddRunner ({ blackbook: co }) {
      const jtBlackBook = [...this.jockeyBlackbook, ...this.trainerBlackbook]
      const blackbook = jtBlackBook.find(runner => {
        return co.runnerId === runner.runnerId && co.tabType === runner.tabType && co.tabNumber === runner.tabNumber
      })

      this.triggerBlackbookChrome({ blackbook })
    },
    onInputChange ({ value: comments, blackbook }) {
      Vue.set(this.comments, this.returnCombinedId({ blackbook }), comments)
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.onSubmitBlackbookToDB({ blackbook })
      }, 1200)
    },
    saveBlackbookNotesToDB ({ blackbook }) {
      const combinedId = this.returnCombinedId({ blackbook })
      const comments = { ...this.comments }

      const jtBlackbookComments = {
        [combinedId]: comments[combinedId]
      }

      this.setBlackbookComments({ blackbook, newBlackbookComments: jtBlackbookComments })
    },
    setRunnerBlackbookComments () {
      this.jt.forEach(co => {
        const combinedId = this.returnCombinedId({ blackbook: co })
        const comments = this.jtBlackbookComments[combinedId]

        if (!isEmpty(comments)) {
          Vue.set(this.comments, combinedId, comments)
        } else {
          Vue.set(this.comments, combinedId, '')
        }
      })
    },
    getBlackbookRunnerComment ({ runner }) {
      const combinedId = this.returnCombinedId({ blackbook: runner })
      return !isEmpty(this.comments[combinedId]) ? this.comments[combinedId] : ''
    },
    onExpandBody ({ blackbook }) {
      if (this.isExpandAll) return
      this.currentBlackbook = { ...blackbook, isToggle: !this.isExpanded[`${this.returnCombinedId({ blackbook })}`] }
      this.isExpanded = this.isExpandedefault
    },
    onGetToggleState ({ blackbook }) {
      return this.isExpanded[`${this.returnCombinedId({ blackbook })}`]
    },
    onSubmitBlackbookToDB ({ blackbook }) {
      const combinedId = this.returnCombinedId({ blackbook })
      const comments = { ...this.comments }

      const runnerBlackbookComments = {
        [combinedId]: comments[this.returnCombinedId({ blackbook })]
      }

      this.setBlackbookComments({ blackbook, newBlackbookComments: runnerBlackbookComments })
    },
    onReturnRunnerComment ({ blackbook }) {
      return !isEmpty(this.comments[this.returnCombinedId({ blackbook })]) ? this.comments[this.returnCombinedId({ blackbook })] : ''
    },
    onGetHeaderComment ({ blackbook }) {
      const comment = this.onReturnRunnerComment({ blackbook })
      return !isEmpty(comment) ? comment : 'No Comments...'
    },
    onSetInitialBlackbookExpand () {
      this.jt.forEach((blackbook) => {
        Vue.set(this.isExpanded, this.returnCombinedId({ blackbook }), false)
        Vue.set(this.isExpandedefault, this.returnCombinedId({ blackbook }), false)
        Vue.set(this.isExpandedeAll, this.returnCombinedId({ blackbook }), true)
      })
    },
    onSetInitialBlackbookComments () {
      this.jt.forEach(blackbook => {
        const combinedId = this.returnCombinedId({ blackbook })

        let comments = this.jockeyBlackbookComments[combinedId]
        if (blackbook.tabType === 'trainerTab') {
          comments = this.trainerBlackbookComments[combinedId]
        }

        if (!isEmpty(comments)) {
          Vue.set(this.comments, this.returnCombinedId({ blackbook }), comments)
        } else {
          Vue.set(this.comments, this.returnCombinedId({ blackbook }), '')
        }
      })
    },
    returnCombinedId ({ blackbook }) {
      return `${blackbook.runnerId}-${blackbook.tabType}`
    }
  }
}
</script>

<template>
  <div>
    <div v-for="blackbook in sortedBlackbook" :key="returnCombinedId({ blackbook })" class="expander">
      <header class="expander__header">
        <h4 class="font-weight-medium cursor" @click="onAddRunner({ blackbook })">
          <Tooltip :text="blackbook.selectedTabTypeName" :maxCharacters="10" />
        </h4>
        <p class="cursor" @click="onExpandBody({ blackbook })" :title="isExpandAll && 'Expand all is currently checked'">
          <Tooltip :text="onGetHeaderComment({ blackbook })" :maxCharacters="10" />
        </p>
        <v-icon class="cursor" @click="onExpandBody({ blackbook })" :title="isExpandAll && 'Expand all is currently checked'">mdi-chevron-down</v-icon>
         <v-simple-checkbox
          v-if="isCheckboxMode"
          class="checkbox ma-0 px-0 mr-1"
          :ripple="false"
          @click="onToggleDynamicCheckbox({ blackbook })"
          :value="!isCheckboxMode2 ? onReturnCheckboxState({ blackbook }) : true"
          hide-details
        ></v-simple-checkbox>
      </header>
      <div v-if="onGetToggleState({ blackbook })" class="expander__body">
        <v-textarea
          id="comment"
          dense
          outlined
          hide-details
          @input="onInputChange({ value: $event, blackbook })"
          :value="onReturnRunnerComment({ blackbook })"
          placeholder="Add comments..."
          class="font-weight-light text-caption mb-2"
          />
        <v-btn @click="removeFromBlackbook({ blackbook })" small color="#f44336" class="text-caption font-weight-medium white--text pointer" width="100%">
          Remove from blackbook<v-icon class="white--text ml-1" small>mdi-trash-can</v-icon>
        </v-btn>
      </div>
    </div>
    <v-card v-if="isEmpty(sortedBlackbook)" elevation="0"  class="pa-2 d-flex justify-center align-center" height="32px">
      <span class="text-caption ma-0 grey--text text-sm-body-2">No data avaiable</span>
    </v-card>
  </div>
</template>

<style scoped lang="scss">
 .expander {
    margin-top: 48px;

    &:not(:first-child) {
      margin-top: 2px;
    }

    &__header {
      border-radius: 5px;
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      padding: 3px 5px;
      border-bottom: 1px solid #e8f5e9;

      h4 {
        font-size: 14px;
      }

      p {
        font-size: 14px;
        margin-left: auto;
        padding-right: 46px;
        margin-bottom: 0;
      }

      i {
        font-size: 20px;
      }
    }

    &__body {
      background-color: white;
      padding: 10px;
    }
  }
</style>
