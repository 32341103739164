import { fromUnixTime, format, isBefore, isToday, isYesterday, isTomorrow, isValid, parseISO } from 'date-fns'
import numeral from 'numeral'

import store from '../store/index'
import account from '../store/account'

import countdownLib from 'countdown'
import { BARRIER_TRIAL, TRACKCONDITION, RACESTATUS } from './constants'

export const formattedRaceStartTime = (seconds) => {
  const formatted = format(seconds, 'HH:mm')
  return formatted
}

export const formatTime = seconds => {
  const formatted = format(fromUnixTime(seconds), 'HH:mm:ss')
  return formatted
}

export const countdown = function (unixSeconds) {
  const time = fromUnixTime(unixSeconds)
  const ts = countdownLib(time)
  let formatted = ''
  if (ts.months > 1) {
    formatted = ts.months + ' months'
  } else if (ts.days === 0) {
    if (ts.hours === 0 && ts.minutes >= 5) {
      formatted = ts.minutes + 'm '
    } else if (ts.hours === 0 && ts.minutes <= 5 && ts.minutes > 0) {
      formatted = ts.minutes + 'm ' + ts.seconds + 's'
    } else if (ts.hours === 0 && ts.minutes === 0) {
      formatted = ts.seconds + 's'
    } else {
      formatted = ts.hours + 'h ' + ts.minutes + 'm'
    }
  } else {
    formatted = ts.days + 'd'
  }

  if (isBefore(time, Date.now())) {
    formatted = '-' + formatted
  }
  return formatted
}

export const countdownObject = function (unixSeconds) {
  const time = fromUnixTime(unixSeconds)
  return countdownLib(time)
}

export const formattDateToWords = date => {
  if (isToday(new Date(date))) {
    return 'Today'
  } else if (isYesterday(new Date(date))) {
    return 'Yesterday'
  } else if (isTomorrow(new Date(date))) {
    return 'Tomorrow'
  } else {
    return date
  }
}
export const filterRacesByCodes = function (races, filters) {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => filters.includes(races[race].meeting.type) || !races[race].meeting.type)
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByCountry = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => filters.includes(races[race].meeting.track.country) || !races[race].meeting.track.country)
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByMeetingType = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => {
        return filters.includes(races[race].meeting.tabIndicator) || (filters.includes('P') && !races[race].meeting.tabIndicator)
      })
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByStarters = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => {
        return races[race].starters >= filters[0] && races[race].starters <= filters[1]
      })
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByDistance = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => {
        return races[race].distance >= filters[0] && races[race].distance <= filters[1]
      })
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByCondition = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => {
        const value = TRACKCONDITION[races[race].trackCondition]
        if (value && !filters.some(filter => value.includes(filter))) {
          //
        } else if (!value) {
          return true
        }
        return value ? (filters.some(filter => value.includes(filter)) || !value) : true
      })
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByStatuses = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => {
        const value = RACESTATUS[races[race].status]
        if (!value) {
          return true
        }
        return value ? (filters.some(filter => value.includes(filter)) || !value) : true
      })
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByShowHideMeeting = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => !filters.includes(races[race].meeting.id) || !races[race].meeting.id)
      .map(key => races[key])
  } else {
    return races
  }
}

export const filterRacesByShowHideRace = (races, filters) => {
  if (races && filters) {
    return Object.keys(races)
      .filter(race => !filters.includes(races[race].id) || !races[race].id)
      .map(key => races[key])
  } else {
    return races
  }
}

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0) ||
  (Object.entries(value).length === 0 && value.constructor === Object)

export const isEmptyOrSpaces = (value) =>
  value === null || value.match(/^ *$/) !== null

export const truncateTxt = ({ txt, max }) => {
  if (txt?.length > max) {
    return txt.substring(0, max) + '...'
  }
  return txt
}

export const sortByKeyValue = ({ key, secondKey, a, b }) => {
  if (secondKey === null) {
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  } else {
    if (secondKey === 'wet' || secondKey === 'dry') {
      if (a[key][secondKey] < b[key][secondKey]) {
        return 1
      }
      if (a[key][secondKey] > b[key][secondKey]) {
        return -1
      }
      return 0
    } else {
      if (a[key][secondKey] < b[key][secondKey]) {
        return -1
      }
      if (a[key][secondKey] > b[key][secondKey]) {
        return 1
      }
      return 0
    }
  }
}

export const moveAnArrayElementFromOneArrayPositionToAnother = ({ arrayToBeRearanged, oldIndex, newIndex }) => {
  const lengthOfArrayToBeRearanged = arrayToBeRearanged.length

  if (newIndex >= lengthOfArrayToBeRearanged) {
    let k = newIndex - lengthOfArrayToBeRearanged + 1
    while (k--) {
      arrayToBeRearanged.push(undefined)
    }
  }

  arrayToBeRearanged.splice(newIndex, 0, arrayToBeRearanged.splice(oldIndex, 1)[0])

  return arrayToBeRearanged
}

export const getReadableDate = ({ utcDate }) => {
  if (!utcDate) {
    return 'Invalid Date'
  }

  const parsedDate = parseISO(utcDate)
  const isValidDate = isValid(parsedDate)
  if (isValidDate) {
    // parsedDate is a `Date` object, so you can use it directly,
    // instead of `new Date(utcDate)`
    const messageDate = format(parsedDate, 'do MMMM, yyyy')
    return messageDate
  } else {
    return 'InvalidDate'
  }
}

export const modernNumberAbbreviator = ({ number, decimalPlaces }) => {
  // 2 decimal places => 100, 3 => 1000, etc
  decimalPlaces = Math.pow(10, decimalPlaces)

  // Enumerate number abbreviations
  const abbrev = ['K', 'M', 'B', 'T']

  // Go through the array backwards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3)

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decimalPlaces, round, and then divide by decimalPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round(number * decimalPlaces / size) / decimalPlaces

      // Handle special case where we round up to the next abbreviation
      if ((number === 1000) && (i < abbrev.length - 1)) {
        number = 1
        i++
      }

      // Add the letter for the abbreviation
      number += abbrev[i]

      // We are done... stop
      break
    }
  }

  return number
}

export function debounce ({ fn, ms }) {
  let timer
  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      fn.apply(timer, arguments)
    }, ms)
  }
}

export const checkFinalPos = ({ competitor }) => {
  if (competitor) {
    const pastStartsFilter = competitor.formHistory.filter(item => !item.isTrial)
    const pastStarts = pastStartsFilter.reverse()
    const finalPos = pastStarts.map((b, index) => {
      let a = { ...b }
      if (index !== pastStarts.length - 1) {
        const startTime = pastStarts[index].date
        const pastTimer = pastStarts[index + 1].date
        const startTimeMili = new Date(startTime).getTime()
        const pastTimeMili = new Date(pastTimer).getTime()
        const timeBetween = startTimeMili - pastTimeMili
        const daysBetween = timeBetween / (1000 * 60 * 60 * 24)
        const daysFormatted = Math.floor(daysBetween)
        a = { ...a, dslsLast5: Math.abs(daysFormatted) }
        // b.dslsLast5 = Math.abs(daysFormatted)
      }
      if (a.finishingPosition > 9) {
        return 0
      }
      if (a.dslsLast5 && a.dslsLast5 >= 84) {
        return `${a.finishingPosition}x`
      }
      return a.finishingPosition
    })
    const finalPosStr = finalPos.toString()
    const final = finalPosStr.replace(/,/g, '')
    if (!isEmpty(final)) {
      const lastTenRevers = final.split('').reverse()
      const newTenStarts = []
      let numCount = 0

      for (let i = 0; i < lastTenRevers.length; i++) {
        if (!isNaN(lastTenRevers[i])) numCount += 1
        newTenStarts.push(lastTenRevers[i])
        if (numCount === 10) break
      }

      return newTenStarts.reverse().join('')
    } else {
      return '-'
    }
  }
  return '-'
}

export const sortCompetitors = ({ race, sortFieldsBy }) => {
  switch (sortFieldsBy) {
    case 'default':
      return race?.competitors
    case 'tabNo': {
      return race.competitors.sort((a, b) => sortByKeyValue({ key: 'tabNo', secondKey: null, a, b }))
    }
    case 'barrier': {
      return race.competitors.sort((a, b) => sortByKeyValue({ key: 'barrier', secondKey: null, a, b }))
    }
    case 'ratingDry': {
      return race.competitors.sort((a, b) => sortByKeyValue({ key: 'rating', secondKey: 'dry', a, b }))
    }
    case 'ratingWet': {
      return race.competitors.sort((a, b) => sortByKeyValue({ key: 'rating', secondKey: 'wet', a, b }))
    }
    case 'myPrice': {
      return race.competitors.filter(c => !c.scratched).sort((a, b) => sortByKeyValue({ key: 'myPrice', secondKey: null, a, b }))
    }
    default:
      return race?.competitors
  }
}

export const filterByTrackConditions = ({ runner, raceFilters, formHistory }) => {
  if (isEmpty(formHistory)) formHistory = runner.formHistory

  const found = []

  raceFilters.trackConditions.forEach((condition) => {
    formHistory.filter((history) => history.trackCondition === condition).forEach(fh => {
      found.push(fh)
    })
  })

  return [found.length > 0, found]
}

export const filterByFinishingPosition = ({ runner, raceFilters, formHistory }) => {
  if (isEmpty(formHistory)) formHistory = runner.formHistory

  const firstPlace = 1
  const secondPlace = 2
  const thirdPlace = 3

  if (raceFilters.finishingPosition === 'Win') {
    const found = formHistory.filter(pos => pos.finishingPosition === firstPlace)
    // return !isEmpty(found)
    return [found.length > 0, found]
  } else if (raceFilters.finishingPosition === 'Place') {
    const found = formHistory.filter(pos => pos.finishingPosition === firstPlace || pos.finishingPosition === secondPlace || pos.finishingPosition === thirdPlace)
    // return !isEmpty(found)
    return [found.length > 0, found]
  }
}

export const filterByTrackLocation = ({ runner, raceFilters, formHistory }) => {
  if (isEmpty(formHistory)) formHistory = runner.formHistory

  const { name } = window.dfApp.$store.getters.getSelectedRace().meeting.track

  const track = raceFilters.track === 'This track' ? name : raceFilters.track

  const found = formHistory.filter((history) => history.track === track)
  return [found.length > 0, found]
}

export const filterBySpell = ({ runner, raceFilters }) => {
  // const { spell } = runner.runnerStatistics.career

  if (runner) {
    const pastStartsFilter = runner.formHistory
    const pastStarts = pastStartsFilter.reverse()
    pastStarts.map((b, index) => {
      let a = { ...b }
      if (index !== pastStarts.length - 1) {
        const startTime = pastStarts[index].date
        const pastTimer = pastStarts[index + 1].date
        const startTimeMili = new Date(startTime).getTime()
        const pastTimeMili = new Date(pastTimer).getTime()
        const timeBetween = startTimeMili - pastTimeMili
        const daysBetween = timeBetween / (1000 * 60 * 60 * 24)
        const daysFormatted = Math.floor(daysBetween)
        a = { ...a, dslsLast5: Math.abs(daysFormatted) }
        // b.dslsLast5 = Math.abs(daysFormatted)
      }
      if (a.finishingPosition > 9) {
        // return 0
      }
      if (a.dslsLast5 && a.dslsLast5 >= 84) {
        // return `${a.finishingPosition}x`
        if (index < pastStarts.length - 2) {
          pastStarts[index + 1].firstUp = true
        }
        if (index < pastStarts.length - 3) {
          pastStarts[index + 2].secondUp = true
        }
        if (index < pastStarts.length - 4) {
          pastStarts[index + 3].thirdUp = true
        }
        if (index < pastStarts.length - 5) {
          pastStarts[index + 4].fourthUp = true
        }
      }
      return a.finishingPosition
    })

    switch (raceFilters.spell) {
      case '1st Up':
        return [pastStarts.filter(ps => ps.firstUp === true).length > 0, pastStarts.filter(ps => ps.firstUp === true)]
      case '2nd Up':
        return [pastStarts.filter(ps => ps.secondUp === true).length > 0, pastStarts.filter(ps => ps.secondUp === true)]
      case '3rd Up':
        return [pastStarts.filter(ps => ps.thirdUp === true).length > 0, pastStarts.filter(ps => ps.thirdUp === true)]
      case '4th Up':
        return [pastStarts.filter(ps => ps.fourthUp === true).length > 0, pastStarts.filter(ps => ps.fourthUp === true)]
      default:
        return [false, []]
    }
  }
}

export const filterByMargin = ({ runner, raceFilters, formHistory }) => {
  if (isEmpty(formHistory)) formHistory = runner.formHistory

  const { margin } = raceFilters

  const found = formHistory.filter(history => history.margin <= margin)
  // return !isEmpty(found)
  return [found.length > 0, found]
}

export const filterByDistance = ({ runner, raceFilters, formHistory }) => {
  if (isEmpty(formHistory)) formHistory = runner.formHistory

  const { distance } = raceFilters

  const found = formHistory.filter(history => history.distance >= distance.from && history.distance <= distance.to)

  // return !isEmpty(found)
  return [found.length > 0, found]
}

export const filterByClass = ({ runner, raceFilters, formHistory }) => {
  if (isEmpty(formHistory)) formHistory = runner.formHistory

  const found = []

  raceFilters.classes.forEach((raceClass) => {
    formHistory.filter((history) => history.raceGroup === parseInt(raceClass)).forEach(fh => {
      found.push(fh)
    })
  })

  return [found.length > 0, found]
}

export const filterFormHistory = ({ runner }) => {
  const raceFilters = store.state.raceFilters
  const filterFlags = {}
  let runnerFormHistoryFiltered = []

  if (raceFilters.spell !== 'All') {
    const result = filterBySpell({ runner, raceFilters })
    filterFlags.spell = result[0]
    runnerFormHistoryFiltered = result[1]
  }

  if (!raceFilters.trackConditions.includes('Any')) {
    const result = filterByTrackConditions({ runner, raceFilters, formHistory: runnerFormHistoryFiltered })
    filterFlags.trackConditions = result[0]
    runnerFormHistoryFiltered = result[1]
  }

  if (!raceFilters.finishPositionOrMargin) {
    if (raceFilters.finishingPosition !== 'All') {
      const result = filterByFinishingPosition({ runner, raceFilters, formHistory: runnerFormHistoryFiltered })
      filterFlags.finishingPosition = result[0]
      runnerFormHistoryFiltered = result[1]
    }
  } else {
    const result = filterByMargin({ runner, raceFilters })
    filterFlags.margin = result[0]
    runnerFormHistoryFiltered = result[1]
  }

  if (raceFilters.track !== 'All') {
    const result = filterByTrackLocation({ runner, raceFilters, formHistory: runnerFormHistoryFiltered })
    filterFlags.track = result[0]
    runnerFormHistoryFiltered = result[1]
  }

  if (!isEmpty(raceFilters.distance.from) && !isEmpty(raceFilters.distance.to)) {
    const result = filterByDistance({ runner, raceFilters, formHistory: runnerFormHistoryFiltered })
    filterFlags.distance = result[0]
    runnerFormHistoryFiltered = result[1]
  }

  if (!raceFilters.classes.includes('all')) {
    const result = filterByClass({ runner, raceFilters, formHistory: runnerFormHistoryFiltered })
    filterFlags.classes = result[0]
    runnerFormHistoryFiltered = result[1]
  }

  const result = {}

  if (Object.values(filterFlags).includes(false) || isEmpty(filterFlags)) {
    result.highlight = ''
  } else {
    result.highlight = 'highlight'
  }

  result.runnerFormHistoryFiltered = runnerFormHistoryFiltered

  return result
}

export const hasTokenExpired = ({ token }) => {
  // Token expire within an 1hr
  if (isEmpty(localStorage.coreapi_auth_token)) return true

  if (isEmpty(token) || account.state.isTrial) return false

  const expiryTimeInMilliseconds = (JSON.parse(atob(token.split('.')[1]))).exp

  const expiryDate = new Date(expiryTimeInMilliseconds * 1000)

  const startdate = new Date(expiryDate)

  const expireTwentyMinutesBeforeTokenExpires = 20

  const newExpiryDate = startdate.setMinutes(expiryDate.getMinutes() - expireTwentyMinutesBeforeTokenExpires)

  return Math.floor((new Date()).getTime()) >= newExpiryDate
}

export const returnCorrectClass = ({ history }) => {
  if (history.raceGroup === 0) {
    return history.class
  } else if (history.raceGroup >= 1 && history.raceGroup <= 3) {
    return `Group ${history.raceGroup}`
  } else if (history.raceGroup === 4) {
    return 'Listed'
  } else {
    return '-'
  }
}

export const failedToFinishReason = ({ reason }) => {
  switch (reason) {
    case 'LostRider':
      return { abbeviation: 'LR', full: 'Lost Rider' }
    case 'FailedToFinish':
      return { abbeviation: 'FF', full: 'Failed To Finish' }
    case 'Fell':
      return { abbeviation: 'FF', full: 'Fell' }
    default:
      return { abbeviation: '-', full: '-' }
  }
}

export const historyTextDisplay = ({ history, key }) => {
  switch (key) {
    case 'raceSectionals': {
      if (history?.raceSectionals !== null && history?.raceSectionals?.length > 0) {
        const raceSectionals = history.raceSectionals[0] || {}
        if (raceSectionals?.distance === 600 && raceSectionals?.location === 'Last') {
          return `600m: ${raceSectionals.seconds}s`
        } else {
          return '-'
        }
      } else {
        return '-'
      }
    }
    case 'track': {
      let track = ''
      if (!isEmpty(history?.track)) {
        track = history?.track
      }
      // if (!isEmpty(history?.raceName)) {
      //   track = track + `, ${history?.raceName}`
      // }
      if (isEmpty(history?.raceName) && isEmpty(history?.track)) {
        return ''
      }

      return track
    }
    default:
      break
  }
}

export const formatTimeShortAndLongForm = (time) => {
  const minutes = Math.floor(time / 60000)
  const seconds = ((time % 60000) / 1000).toFixed(2)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

// export const someMethodFunctionInMethodFile = () => {
//   console.log(store)
//   const currentActiveTab = store.state.chromeGrouping.find(race => {
//     const venue = Object.keys(race)[0]
//     return venue === store.state.currentVenue.name
//   })

//   const activeRunners = currentActiveTab[store.state.currentVenue.name]

//   console.log('1111111')
//   console.log(activeRunners)

//   const keyActiveRunner = store.state.currentVenue.name.replace(/ /g, '') + '-' + activeRunners[0].runnerId + '-' + activeRunners[0].tabType

//   const runnerName = store.state.activeRunners[keyActiveRunner].name
//   return runnerName
// }

export const runnersPlacementToString = ({ selectedRunner, history }) => {
  const { finishingPosition, placeGetters } = history
  if (finishingPosition === 0 || finishingPosition > 4) {
    const placementArr = placeGetters.map((runner) => {
      if (runner.finishingPosition === 1) return { placement: 1, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 2) return { placement: 2, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 3) return { placement: 3, worded: `${runner.name} (${runner.weight}kg)`, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else return ''
    })

    const cleaned = placementArr.filter((element) => {
      return !isEmpty(element)
    })

    cleaned.sort((a, b) => {
      return a.placement - b.placement
    })
    return cleaned
  } else if (finishingPosition === 1) {
    const placementArr = placeGetters.map((runner) => {
      if (runner.finishingPosition === 1 && runner.name !== selectedRunner.name) return { placement: 1, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 2) return { placement: 2, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 3) return { placement: 3, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 4) return { placement: 4, worded: `${runner.name} (${runner.weight}kg)`, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else return ''
    })
    const cleaned = placementArr.filter((element) => {
      return !isEmpty(element)
    })

    cleaned.sort((a, b) => {
      return a.placement - b.placement
    })
    return cleaned
  } else if (finishingPosition === 2) {
    const placementArr = placeGetters.map((runner) => {
      if (runner.finishingPosition === 1) return { placement: 1, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 2 && runner.name !== selectedRunner.name) return { placement: 2, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 3) return { placement: 3, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 4) return { placement: 4, worded: `${runner.name} (${runner.weight}kg)`, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else return ''
    })
    const cleaned = placementArr.filter((element) => {
      return !isEmpty(element)
    })

    cleaned.sort((a, b) => {
      return a.placement - b.placement
    })
    return cleaned
  } else if (finishingPosition === 3) {
    const placementArr = placeGetters.map((runner) => {
      if (runner.finishingPosition === 1) return { placement: 1, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 2) return { placement: 2, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 4) return { placement: 4, worded: `${runner.name} (${runner.weight}kg)`, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else return ''
    })
    const cleaned = placementArr.filter((element) => {
      return !isEmpty(element)
    })

    cleaned.sort((a, b) => {
      return a.placement - b.placement
    })
    return cleaned
  } else if (finishingPosition === 4) {
    const placementArr = placeGetters.map((runner) => {
      if (runner.finishingPosition === 1) return { placement: 1, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 2) return { placement: 2, worded: `${runner.name} (${runner.weight}kg), `, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else if (runner.finishingPosition === 3) return { placement: 3, worded: `${runner.name} (${runner.weight}kg)`, runnerGlobalId: runner.runnerGlobalId, name: runner.name }
      else return ''
    })
    const cleaned = placementArr.filter((element) => {
      return !isEmpty(element)
    })

    cleaned.sort((a, b) => {
      return a.placement - b.placement
    })
    return cleaned
  } else return ''
}

export const placeGetterCalc = (p) => {
  const first = p.filter(x => x.finishingPosition === 1)[0]?.margin
  const second = p.filter(x => x.finishingPosition === 2)[0]?.margin
  const third = p.filter(x => x.finishingPosition === 3)[0]?.margin
  const calcMargin = third - second
  if (isEmpty(first) || isEmpty(second)) return '-'
  return `${first} x ${numeral(calcMargin).format('0.0[0000]')}`
}

export const getNumberWithOrdinal = (number) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = number % 100
  return number + (s[(v - 20) % 10] || s[v] || s[0])
}

export const findWinnerForm = ({ history }) => {
  let winnerName = ''
  history.placeGetters.forEach((b) => {
    if (b.finishingPosition === 1) {
      winnerName = `${b.name} (${b.weight}kg)`
    }
  })
  return winnerName
}

export const formatToMinutes = (time) => {
  const minutes = Math.floor(time / 60000)
  const seconds = ((time % 60000) / 1000).toFixed(2)
  return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
}

export const checkTrackCond = (condition) => {
  switch (condition) {
    case 'FIRM1':
      return 'Firm 1'
    case 'Firm1':
      return 'Firm 1'
    case 'FIRM2':
      return 'Firm 2'
    case 'Firm2':
      return 'Firm 2'
    case 'GOOD3':
      return 'Good 3'
    case 'Good3':
      return 'Good 3'
    case 'GOOD4':
      return 'Good 4'
    case 'Good4':
      return 'Good 4'
    case 'SOFT5':
      return 'Soft 5'
    case 'Soft5':
      return 'Soft 5'
    case 'SOFT6':
      return 'Soft 6'
    case 'Soft6':
      return 'Soft 6'
    case 'SOFT7':
      return 'Soft 7'
    case 'Soft7':
      return 'Soft 7'
    case 'HEAVY8':
      return 'Heavy 8'
    case 'Heavy8':
      return 'Heavy 8'
    case 'HEAVY9':
      return 'Heavy 9'
    case 'Heavy9':
      return 'Heavy 9'
    case 'HEAVY10':
      return 'Heavy 10'
    case 'Heavy10':
      return 'Heavy 10'
    case 'SYNTHETIC' || 'Synthetic':
      return 'Synthetic'
    default:
      return condition
  }
}

export const lastRating = (runner) => {
  const filteredHistory = runner?.formHistory.filter(x => x.class !== 'Barrier Trial' && x.class !== 'BTrial' && !BARRIER_TRIAL.includes(history.raceName))
  let lastStart = '0'

  if (filteredHistory[0]?.ratingUnadjusted === 0 && filteredHistory[0]?.starters === 0) {
    lastStart = filteredHistory.filter(x => x.ratingUnadjusted !== 0)[0].ratingUnadjusted
  } else {
    lastStart = filteredHistory[0]?.ratingUnadjusted ? filteredHistory[0]?.ratingUnadjusted : '0'

    // handle if the rating data has not yet been recieved for the last run, apply the last rating value > 0
    if (lastStart === '-' && filteredHistory.filter(x => x.ratingUnadjusted !== 0)[0]) {
      lastStart = filteredHistory.filter(x => x.ratingUnadjusted !== 0)[0].ratingUnadjusted
    }
  }
  return lastStart
}
