import { stateMain } from './main/state'
import { mutationsMain } from './main/mutations'
import { actionsMain } from './main/actions'
import { gettersMain } from './main/getters'

const store = {
  state: stateMain,
  mutations: mutationsMain,
  actions: actionsMain,
  getters: gettersMain,
  modules: {}
}

export default store
