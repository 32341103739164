import { isBefore } from 'date-fns'
import { filterRacesByShowHideRace, filterRacesByShowHideMeeting, filterRacesByCountry, filterRacesByCodes, filterRacesByMeetingType, filterRacesByStatuses, filterRacesByStarters, filterRacesByDistance, filterRacesByCondition } from '../../common/methods'

export const getters = {
  hasFeature () {
    return function (feature) {
      const features = process.env.VUE_APP_FEATURE_FLAGS.split(',')
      return features.some(x => x.trim() === feature.trim())
    }
  },
  getNextToGo (state, getters) {
    const r = Object.values([...getters.getTodaysRaces, ...getters.getRaces])
    let res = null
    r.sort((a, b) => (isBefore(a.isoStartTime, b.isoStartTime) ? -1 : 1))
    r.some(x => {
      if (x.status === 'OPEN') {
        res = x
        return true
      }
      return false
    })
    return res
  },
  getTodaysRaces (state, getters) {
    return filterRacesByCondition(
      filterRacesByDistance(
        filterRacesByStarters(
          filterRacesByStatuses(
            filterRacesByMeetingType(
              filterRacesByCodes(
                filterRacesByCountry(
                  filterRacesByShowHideMeeting(filterRacesByShowHideRace(state.todaysRaces, getters.getHideRacesSelected), getters.getShowHideMeetingsFilter),
                  getters.getCountriesFilter
                ),
                getters.getIsCodesSelectedFilter
              ),
              getters.getMeetingTypesSelectedFilter
            ),
            getters.getStatusesFilter
          ),
          getters.getStartersFilter
        ),
        getters.getDistanceFilter
      ),
      getters.getConditionsFilter
    )
  },
  getRaces (state, getters) {
    const races = filterRacesByCondition(
      filterRacesByDistance(
        filterRacesByStarters(
          filterRacesByStatuses(
            filterRacesByMeetingType(
              filterRacesByCodes(
                filterRacesByCountry(
                  filterRacesByShowHideMeeting(filterRacesByShowHideRace(state.races, getters.getHideRacesSelected), getters.getShowHideMeetingsFilter),
                  getters.getCountriesFilter
                ),
                getters.getIsCodesSelectedFilter
              ),
              getters.getMeetingTypesSelectedFilter
            ),
            getters.getStatusesFilter
          ),
          getters.getStartersFilter
        ),
        getters.getDistanceFilter
      ),
      getters.getConditionsFilter
    )
    return races
  },
  getRaceCompetitors (state, getters) {
    const sortedArray = getters.getRaceSortOrder
    let sortedCompetitors = []
    const competitors = state.detailedRaces[state.selectedRace?.id]?.competitors || []
    if (sortedArray) {
      sortedArray.forEach(tabNo => {
        const c = competitors.find(r => r.tabNo === tabNo)
        sortedCompetitors.push(c)
      })
      competitors.forEach(r => {
        if (!sortedArray.includes(r.tabNo)) {
          sortedCompetitors.push(r)
        }
      })
    } else {
      sortedCompetitors = competitors
    }
    return sortedCompetitors
  },
  getSelectedRace (state, getters) {
    return function () {
      if (state.selectedRace.id === null) {
        return null
      }
      const race = state.detailedRaces[state.selectedRace.id]
      return {
        ...race,
        competitors: getters.getRaceCompetitors
      }
    }
  },
  getRace (state, getters) {
    return function (meetingId, raceNumber) {
      const meeting = state.meetings[meetingId]
      const race = meeting?.races?.find(r => r.number === raceNumber)
      return { ...race, meeting }
    }
  },
  getRacesGroupByMeeting (state) {
    return state.racesGroupByMeeting
  },
  getSelectedMeeting (state) {
    if (state.selectedRace.id === null) {
      return null
    }
    const race = state.races[state.selectedRace.id]
    if (race) {
      const meeting = state.meetings[race.meeting.id]
      return meeting
    }
    return null
  },
  getSelectedMeetingsRaces (state, getters) {
    return Object.values(state.races).filter(race => race?.meeting?.id === getters.getSelectedMeeting?.id)
  },
  getAzList (state) {
    return function (letter, option, filters) {
      if (option === 'aZTrainers') {
        return state.azListTrainers[letter].filter(runner => filters.includes(runner.meetingType) || !runner.meetingType)
      } else if (option === 'aZJockeysDrivers') {
        return state.azListJockeysDrivers[letter].filter(runner => filters.includes(runner.meetingType) || !runner.meetingType)
      } else if (option === 'aZRunners') {
        return state.azListRunners[letter].filter(runner => filters.includes(runner.meetingType) || !runner.meetingType)
      } else if (option === 'search') {
        return state.azSearchResults.filter(runner => filters.includes(runner.meetingType) || !runner.meetingType)
      } else {
        return []
      }
    }
  },
  getLoadAZListData (state) {
    return state.loadAZListData
  },
  getRunnerTabs (state) {
    return state.runnerTabs
  },
  getRunnerFormHistoryFiltered (state) {
    return function (runnerId) {
      return state.runnerFormHistoryFiltered[runnerId]
    }
  },
  getSortFieldsBy (state) {
    return state.sortFieldsBy
  },
  getLast50RidesJockeyAndTrainer (state) {
    return function (id) {
      return state.last50Rides[id]
    }
  },
  getCompetitorsNotesFromDB (state) {
    return function (runnerId) {
      return state.competitorsNotesFromDB[runnerId]
    }
  },
  getActiveRunner (state) {
    return function ({ combinedId }) {
      return state.activeRunners[combinedId]
    }
  },
  getPrice (state) {
    return function (tabNo, key) {
      const prices = state.prices
      const bookiePrices = prices[key]
      return bookiePrices?.find(p => p.tabNo === tabNo) || null
    }
  },
  getSelectedDate (state) {
    return state.selectedDate
  },
  getOldSelectedMeeting (state) {
    return state.meetingSelected
  }
}

export const gettersMain = getters
