<template>
  <v-dialog max-width="390" :value="isReauthorize" persistent>
    <v-card>
      <v-card-title class="headline"><v-icon class="mr-2" color="red">mdi-access-point</v-icon> <span>Session has expired</span></v-card-title>
      <v-card-text>To enable DynamicForm we need to confirm it's you. Please re-enter your username & password. </v-card-text>
      <form class="form" autocomplete="off">
        <fieldset>
          <div class="form-group">
            <label for="username">Username</label>
            <input id="username" type="text" placeholder="Enter your username" v-model="username" required />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input id="password" type="password" placeholder="●●●●●●●●●" v-model="password" required />
          </div>
          <button @click="onSubmitUserCredentials">Sign In</button>
          <p class="form__text mb-1 pa-0">
            Issues re-entering your login details? <v-btn to="/" elevation="0" class="" :ripple="false" larges plain @click="onChangePassword()"><span style="font-size: 16px;">Lets go back.</span></v-btn>
          </p>
        </fieldset>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import Auth from '@aws-amplify/auth'
import { mapActions, mapState } from 'vuex'
import { isEmpty } from '../common/methods'
export default {
  data () {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapState(['isReauthorize'])
  },
  methods: {
    ...mapActions(['loadingToggleAXN', 'apiErrosAXN', 'updateReloginAXN']),
    ...mapActions('account', ['logout']),
    async onSubmitUserCredentials (event) {
      event.preventDefault()
      try {
        const { username, password } = this
        if (isEmpty(username) || isEmpty(password)) {
          return this.apiErrosAXN({ error: 'Login credential cannot be empty.', type: 'error' })
        }
        this.loadingToggleAXN({ isLoading: true })
        await Auth.signIn(username, password)
        this.updateReloginAXN({ isReauthorize: false })
      } catch (error) {
        this.loadingToggleAXN({ isLoading: false })
        this.apiErrosAXN({ error: 'Incorrect username or password, please try again.', type: 'error' })
      }
    },
    onChangePassword () {
      this.logout()
      this.updateReloginAXN({ isReauthorize: false })
    }
  }
}
</script>

<style scoped>
.form {
  background-color: white;
  padding: 10px 25px 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.form__text {
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
}

.form__text a {
  color: #4caf50;
  cursor: pointer;
  text-decoration: underline;
  text-transform: none !important;
  font-weight: 400;
  letter-spacing: normal;
}
.form legend {
  color: #152939;
  margin-bottom: 30px;
  text-align: center;
}
.form legend p {
  width: 100%;
  font-weight: 500;
  margin-top: 20px;
  font-size: 18.4px;
  margin: 0;
}
.form figure {
  margin-bottom: 20px;
}
.form figure img {
  display: inline-block;
  margin-bottom: 20px !important;
}
.form fieldset {
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
}
.form button {
  background: #4caf50;
  width: 100%;
  padding: 10px;
  text-transform: uppercase;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}
.form-group {
  margin-bottom: 20px;
}
.form-group label {
  display: block;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 5px;
  font-weight: 500;
}
.form-group input {
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 11.5px;
  font-size: 13.5px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  font-style: italic;
  color: black;
  border-radius: 5px;
}
.form-group input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.form-group input[type='date']:required:invalid::-webkit-datetime-edit {
  color: transparent;
}
.form-group input[type='date']:focus::-webkit-datetime-edit {
  color: black !important;
}
.form-group input[type='date']:not(.has-value):before {
  color: rgba(0, 0, 0, 0.25);
  content: attr(placeholder);
}
.form-group input[type='date']:focus:before {
  content: '' !important;
}
.form-group input:focus,
.form-group input:hover {
  border-color: #4caf50;
  outline: 0;
}
</style>
